/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { useEffect, useRef, useState } from 'react';
import { icons } from '../../../../../configs/Icons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import InfoEditorConfirm from '../../../../features/info-editor/InfoEditorConfirm';
import InfoEditorTextarea from '../../../../features/info-editor/InfoEditorTextarea';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import { handleDeleteResponse, handleUpdateResponse } from '../BmCustomerOverviewOperation';
import { useCustomerOverview } from '../BmCustomerOverviewProvider';

function OverviewSecQuickResponseItem({ item }) {
    // State to hold form data
    const [formData, setFormData] = useState({});
    const [mode, setMode] = useState({ type: '', index: 0 });

    // Get authentication info from AuthProvider
    const { authInfo } = useAuth();

    // Get editor functions and mode from TemplateProvider
    const { setMessage, createEditorFunction } = useTemplate();
    const { openEditor, closeEditor } = createEditorFunction(setFormData, setMode);
    const { handleResponse } = useCustomerOverview();

    // Refs for edit and delete buttons
    const editButtonRef = useRef();
    const deleteButtonRef = useRef();

    // Function to handle edit button click
    const editClick = (ev) => {
        ev.stopPropagation();

        console.log(formData);
        if (editButtonRef.current) {
            // Open the editor in edit mode with text and ID
            openEditor({ type: 'res-edit', index: item.id, name: 'text', value: item.text });
            editButtonRef.current.classList.add('active');
        }
    };

    // Function to handle delete button click
    const deleteClick = (ev) => {
        ev.stopPropagation();

        if (deleteButtonRef.current) {
            // Open the editor in delete mode with ID
            openEditor({ type: 'res-delete', index: item.id });
            deleteButtonRef.current.classList.add('active');
        }
    };

    // Effect to remove active mode from edit and delete buttons
    useEffect(() => {
        if (!mode.index) {
            editButtonRef.current.classList.remove('active');
            deleteButtonRef.current.classList.remove('active');
        }
    }, [mode.index]);

    // Function to handle save button click
    const handelSaveClick = () => {
        setMessage('Updating response....');
        // Update response using handleUpdateResponse function
        handleUpdateResponse(authInfo, formData?.text)
            .then(({ message }) => {
                setMessage(message);
                closeEditor();
                // handelReload(); - This seems to be commented out
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    // Function to handle delete confirmation click
    const handelDeleteClick = () => {
        setMessage('Adding notes....');
        // Delete response using handleDeleteResponse function
        handleDeleteResponse(authInfo, item.id)
            .then(({ message }) => {
                setMessage(message);
                closeEditor();
                // handelReload(); - This seems to be commented out
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    // Determine if the current item is active
    const isItemActive = mode.index === item.id;
    const isEditActive = mode.type === 'res-edit' && isItemActive;
    const isDeleteActive = mode.type === 'res-delete' && isItemActive;

    return (
        <li
            onClick={() => handleResponse(item?.text)}
            role="button"
            tabIndex={-1}
            key={item.id}
            className={isItemActive ? 'active' : ''}
        >
            <div>{item.text}</div>

            <div className="flex-row align-space-between">
                <button ref={editButtonRef} type="button" onClick={(ev) => editClick(ev)}>
                    <img src={icons.editGray} alt="" />
                    <img src={icons.editFill} alt="" />
                </button>

                <button ref={deleteButtonRef} type="button" onClick={(ev) => deleteClick(ev)}>
                    <img src={icons.crossGray} alt="" />
                    <img src={icons.crossRed} alt="" />
                </button>
            </div>

            {/* Display the text editor for editing the response */}
            <InfoEditorTextarea
                show={isEditActive}
                setShow={closeEditor}
                positionRef={editButtonRef}
                name="text"
                formData={formData}
                setFormData={setFormData}
                saveClick={handelSaveClick}
            />

            {/* Display the confirmation dialog for deleting the response */}
            <InfoEditorConfirm
                show={isDeleteActive}
                setShow={closeEditor}
                positionRef={deleteButtonRef}
                confirmClick={handelDeleteClick}
            />
        </li>
    );
}

export default OverviewSecQuickResponseItem;
