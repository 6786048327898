export const syncDemoData = [
    {
        id: 1691,
        updatedTime: 1692528975,
        fbConversationId: '---------------',
        senderId: '---------------',
        count: '----------',
        senderName: '-------------------',
        snippet: '-------------------------------------',
        empty: true
    },
    {
        id: 1701,
        updatedTime: 1692528975,
        fbConversationId: '---------------',
        senderId: '---------------',
        count: '----------',
        senderName: '-------------------',
        snippet: '-------------------------------------',
        empty: true
    },
    {
        id: 1711,
        updatedTime: 1692528975,
        fbConversationId: '---------------',
        senderId: '---------------',
        count: '----------',
        senderName: '-------------------',
        snippet: '-------------------------------------',
        empty: true
    }
];

export default null;
