import { useState } from 'react';
import { ButtonGray, ButtonGreen } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import LibModal from '../../../../lib/LibModal';
import AnimateInputField, {
    AnimateNumberField,
    AnimateSelectField,
    AnimateTextareaField,
    addOption
} from '../../../../utils/AnimateInputField';
import { handelAddProduct } from '../InvProductOperation';

const status = [
    {
        id: 1,
        title: 'Active',
        value: 'active'
    },
    {
        id: 2,
        title: 'Inactive',
        value: 'inactive'
    }
];

const initialState = {
    name: '',
    type: '',
    subType: '',
    description: '',
    price: '',
    status: ''
};

function ProductAddModal({ closeModal }) {
    const [formData, setFormData] = useState(initialState);

    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    const handelChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleAddButton = () => {
        setMessage('Adding Product....');
        handelAddProduct(authInfo, formData)
            .then(({ message }) => {
                setMessage(message);
                setFormData(initialState);
                closeModal(false);
                // handelReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <LibModal title="Add Product" setOpen={closeModal}>
            <div className="modal-body-cont">
                <div>
                    <div className="input-row">
                        <AnimateInputField
                            title="Name"
                            name="name"
                            formData={formData}
                            handelChange={handelChange}
                        />
                    </div>
                    <div className="input-row flex-row align-space-between">
                        <div className="inp-fill-2">
                            <AnimateInputField
                                title="Type"
                                name="type"
                                formData={formData}
                                handelChange={handelChange}
                            />
                        </div>
                        <div className="inp-fill-2">
                            <AnimateInputField
                                title="Sub-Type"
                                name="subType"
                                formData={formData}
                                handelChange={handelChange}
                            />
                        </div>
                    </div>
                    <div className="input-row">
                        <AnimateTextareaField
                            title="Description"
                            name="description"
                            formData={formData}
                            handelChange={handelChange}
                        />
                    </div>

                    <div className="input-row flex-row align-space-between">
                        <div className="inp-fill-2">
                            <AnimateNumberField
                                title="Price"
                                name="price"
                                formData={formData}
                                handelChange={handelChange}
                            />
                        </div>
                        <div className="inp-fill-2">
                            <AnimateSelectField
                                title="Status"
                                name="status"
                                formData={formData}
                                handelChange={handelChange}
                                options={status?.map((item) =>
                                    addOption(item.id, item.title, item.value)
                                )}
                            />
                        </div>
                    </div>
                </div>

                <div className="modal-action flex-row align-right action">
                    <ButtonGray evOnClick={() => closeModal(false)} title="Cancel" />
                    <ButtonGreen evOnClick={handleAddButton} title="Add" />
                </div>
            </div>
        </LibModal>
    );
}

export default ProductAddModal;
