import { useCustomerOverview } from '../BmCustomerOverviewProvider';
import OverviewSecCol1 from '../overview_sec_col1/OverviewSecCol1';
import OverviewSecCol2 from '../overview_sec_col2/OverviewSecCol2';
import OverviewSecHeader from '../overview_sec_header/OverviewSecHeader';

function CustomerOverviewSec() {
    const { overviewData } = useCustomerOverview();

    return (
        <div className="customer-overview-section">
            <OverviewSecHeader />
            <div className="section-body flex-row position-stretch align-space-between">
                <OverviewSecCol1 overviewData={overviewData} />
                <OverviewSecCol2 overviewData={overviewData} />
            </div>
        </div>
    );
}

export default CustomerOverviewSec;
