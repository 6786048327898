import { useState } from 'react';
import { ButtonMenu, ButtonPurple } from '../../../../../configs/Buttons';
import InstOption from '../../../../utils/InstOption';
import TypeAddModal from '../modals/TypeAddModal';
import TypeUpdateModal from '../modals/TypeUpdateModal';

const typeData = [
    {
        id: 10001010,
        type: 'service',
        subType: 'PVC Tank Wash'
    },
    {
        id: 10001011,
        type: 'service',
        subType: 'Concrete Tank Wash'
    },
    {
        id: 10001012,
        type: 'service',
        subType: 'service3'
    }
];

// const typeData = [];

function Menu({ showMenu, setShowMenu, openModal, item }) {
    return (
        <div className="flex-row align-right">
            <ButtonMenu evOnClick={() => setShowMenu(item.id)}>
                <InstOption setShowMenu={setShowMenu} showMenu={showMenu === item.id}>
                    <button type="button" onClick={() => openModal(item.id)}>
                        Edit
                    </button>
                </InstOption>
            </ButtonMenu>
        </div>
    );
}

function Tr({ openModal, mode }) {
    const [showMenu, setShowMenu] = useState(false);

    if (typeData && !typeData.length) {
        return (
            <tr>
                <td colSpan={5} className="text-left table-not-found-cont">
                    No Types Found!
                </td>
            </tr>
        );
    }
    return typeData.map((item) => (
        <tr key={item?.id}>
            <td className="text-left">{item.id}</td>
            <td className="text-left">{item.type}</td>
            <td className="text-left">{item.subType}</td>
            <td className="text-right">
                <Menu
                    setShowMenu={setShowMenu}
                    showMenu={showMenu}
                    openModal={openModal}
                    item={item}
                />
                {mode === item.id ? <TypeUpdateModal setOpen={openModal} data={item} /> : null}
            </td>
        </tr>
    ));
}

function ConfigTypeManageSection() {
    const [mode, setMode] = useState(false);

    const openModal = (st) => {
        setMode(st);
    };

    return (
        <div className="type-manage-section">
            <div className="flex-row align-space-between">
                <div>
                    <h2 className="section-title">
                        <span>Type Manage</span>
                    </h2>
                </div>

                <div>
                    <ButtonPurple evOnClick={() => openModal('add')} title="Add Type" />

                    {/* modal */}
                    {mode === 'add' ? <TypeAddModal setOpen={setMode} /> : null}
                </div>
            </div>

            <table className="bik-table">
                <thead>
                    <tr>
                        <th width="10%" className="text-left">
                            SL
                        </th>
                        <th width="40%" className="text-left">
                            Type
                        </th>
                        <th width="40%" className="text-left">
                            Sub-Type
                        </th>
                        <th width="10%" className="text-right">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody className="">
                    <Tr openModal={openModal} mode={mode} />
                </tbody>
            </table>
        </div>
    );
}

export default ConfigTypeManageSection;
