import { useEffect, useRef } from 'react';
import { icons } from '../../../configs/Icons';
import useEditorPositioning from './useEditorPositioning';

function Save({ show, saveClick }) {
    if (!show) {
        return null;
    }
    return (
        <div
            role="button"
            tabIndex={-1}
            onClick={saveClick}
            className="btn-1 flex-row align-center"
        >
            <img src={icons.tickIcon} alt="" />
        </div>
    );
}

function Close({ closeClick }) {
    const handelCloseClick = (ev) => {
        ev.stopPropagation();
        closeClick();
    };

    return (
        <div
            role="button"
            tabIndex={-1}
            onClick={handelCloseClick}
            className="btn-2 flex-row align-center"
        >
            <img src={icons.crossRed} alt="" />
        </div>
    );
}

function InfoEditorInputV2({
    show,
    setShow,
    positionRef,
    width = 100,
    height = 35,
    align = 'right-middle',
    outsideClick,
    name,
    placeholder,
    formData,
    setFormData,
    saveClick
}) {
    const editorRef = useRef(null);

    // editor SHOW/HIDE and positioning
    useEditorPositioning(positionRef, editorRef, show, setShow, align, outsideClick);

    const handelChange = (ev) => {
        if (ev.target) {
            setFormData((d) => ({ ...d, [ev.target.name]: ev.target.value }));
        }
    };

    useEffect(() => {
        if (!show) {
            setFormData({});
        }
    }, [setFormData, show]);

    if (!show) {
        return null;
    }
    return (
        <div
            role="button"
            tabIndex={-1}
            onClick={(ev) => ev.stopPropagation()}
            style={{ width: `${width}px`, height: `${height}px` }}
            ref={editorRef}
            className="info-editor default-v2"
        >
            <div className="info-editor-input-v2 flex-row position-middle align-space-between">
                <input
                    style={{ width: `calc(100% - 16px ${saveClick ? '- 16px' : ''} - 12px)` }}
                    name={name}
                    placeholder={placeholder || 'Type Something...'}
                    value={formData?.[name] || ''}
                    onChange={handelChange}
                    autoComplete="off"
                    type="text"
                />

                <Save show={saveClick} saveClick={saveClick} />
                <Close closeClick={() => setShow(false)} />
            </div>
        </div>
    );
}

export default InfoEditorInputV2;
