import { icons, menuIcon } from '../../../../configs/Icons';

const links = [
    {
        id: 'inventory',
        miniTitle: 'Inventory',
        title: 'Inventory',
        iconFill: menuIcon.financeFillS,
        iconLine: menuIcon.financeLineS,
        status: '',
        subMenu: [
            {
                id: 'inventory/dashboard',
                title: 'Dashboard',
                iconL: menuIcon.dashboardLine,
                iconF: menuIcon.dashboardFill
            },
            {
                id: 'inventory/product',
                title: 'Product/Service',
                iconL: menuIcon.configLine,
                iconF: menuIcon.configFill
            },
            {
                id: 'inventory/accounts',
                title: 'Accounts',
                iconL: menuIcon.userLine,
                iconF: menuIcon.userFill
            },
            {
                id: 'inventory/sell-invoice',
                title: 'Sell Invoices',
                iconL: menuIcon.invoiceLine,
                iconF: menuIcon.invoiceFill
            },
            {
                id: 'inventory/gateway-transactions',
                title: 'Gateway Transactions',
                iconL: menuIcon.gatewayTransactionsLine,
                iconF: menuIcon.gatewayTransactionsFill
            },
            {
                id: 'inventory/transactions',
                title: 'Transactions',
                iconL: menuIcon.transactionsLine,
                iconF: menuIcon.transactionsFill
            },
            {
                id: 'inventory/reports',
                title: 'Reports',
                iconL: menuIcon.reportsLine,
                iconF: menuIcon.reportsFill
            },
            {
                id: 'inventory/api2',
                title: 'API',
                iconL: menuIcon.codeLine,
                iconF: menuIcon.codeFill
            },
            {
                id: 'inventory/configuration',
                title: 'Configuration',
                iconL: menuIcon.configLine,
                iconF: menuIcon.configFill
            }
        ]
    },
    {
        id: 'push-notification',
        miniTitle: 'PUSH',
        title: 'PUSH Notification',
        iconFill: menuIcon.pushFillS,
        iconLine: menuIcon.pushLineS,
        status: '',
        subMenu: [
            {
                id: 'push-notification/dashboard',
                title: 'Dashboard',
                iconL: menuIcon.dashboardLine,
                iconF: menuIcon.dashboardFill
            },
            {
                id: 'push-notification/campaign',
                title: 'Campaign',
                iconL: menuIcon.campaignLine,
                iconF: menuIcon.campaignFill
            },
            {
                id: 'push-notification/reports',
                title: 'Reports',
                iconL: menuIcon.reportsLine,
                iconF: menuIcon.reportsFill
            },
            {
                id: 'push-notification/api',
                title: 'API',
                iconL: menuIcon.codeLine,
                iconF: menuIcon.codeFill
            },
            {
                id: 'push-notification/billing',
                title: 'Billing',
                iconL: menuIcon.financeLine,
                iconF: menuIcon.financeFill
            },
            {
                id: 'push-notification/access',
                title: 'Access Manage',
                iconL: menuIcon.manageLine,
                iconF: menuIcon.manageFill
            },
            {
                id: 'push-notification/configuration',
                title: 'Configuration',
                iconL: menuIcon.configLine,
                iconF: menuIcon.configFill
            }
        ]
    },
    {
        id: 'email-notification',
        miniTitle: 'EMAIL',
        title: 'Email Notification',
        iconFill: menuIcon.emailFillS,
        iconLine: menuIcon.emailLineS,
        status: '',
        subMenu: [
            {
                id: 'email-notification/dashboard',
                title: 'Dashboard',
                iconL: menuIcon.dashboardLine,
                iconF: menuIcon.dashboardFill
            },
            {
                id: 'email-notification/campaign',
                title: 'Campaign',
                iconL: menuIcon.campaignLine,
                iconF: menuIcon.campaignFill
            },
            {
                id: 'email-notification/people',
                title: 'People',
                iconF: menuIcon.userFill,
                iconL: menuIcon.userLine
            },
            {
                id: 'email-notification/library',
                title: 'Library',
                iconF: menuIcon.libraryFill,
                iconL: menuIcon.libraryLine
            },
            {
                id: 'email-notification/reports',
                title: 'Reports',
                iconL: menuIcon.reportsLine,
                iconF: menuIcon.reportsFill
            },
            {
                id: 'email-notification/api',
                title: 'API',
                iconL: menuIcon.codeLine,
                iconF: menuIcon.codeFill
            },
            {
                id: 'email-notification/billing',
                title: 'Billing',
                iconL: menuIcon.financeLine,
                iconF: menuIcon.financeFill
            },
            {
                id: 'email-notification/access',
                title: 'Access Manage',
                iconL: menuIcon.manageLine,
                iconF: menuIcon.manageFill
            },
            {
                id: 'email-notification/configuration',
                title: 'Configuration',
                iconL: menuIcon.configLine,
                iconF: menuIcon.configFill
            }
        ]
    },
    {
        id: 'hrms',
        miniTitle: 'HRMS',
        title: 'HRMS',
        iconFill: menuIcon.hrmFillS,
        iconLine: menuIcon.hrmLineS,
        status: '',
        subMenu: [
            {
                id: 'hrms/dashboard',
                title: 'Dashboard',
                iconL: menuIcon.dashboardLine,
                iconF: menuIcon.dashboardFill
            },
            {
                id: 'hrms/people',
                title: 'People',
                iconF: menuIcon.userFill,
                iconL: menuIcon.userLine
            },
            {
                id: 'hrms/roster',
                title: 'Roster',
                iconL: menuIcon.scheduleLine,
                iconF: menuIcon.scheduleFill
            },
            {
                id: 'hrms/salary',
                title: 'Salary',
                iconL: menuIcon.salaryLine,
                iconF: menuIcon.salaryFill
            },
            {
                id: 'hrms/attendance',
                title: 'Attendance',
                iconL: menuIcon.attendanceLine,
                iconF: menuIcon.attendanceFill
            },
            {
                id: 'hrms/reports',
                title: 'Reports',
                iconL: menuIcon.reportsLine,
                iconF: menuIcon.reportsFill
            },
            { id: 'hrms/api2', title: 'API', iconL: menuIcon.codeLine, iconF: menuIcon.codeFill },
            {
                id: 'hrms/billing',
                title: 'Billing',
                iconL: menuIcon.financeLine,
                iconF: menuIcon.financeFill
            },
            {
                id: 'hrms/access',
                title: 'Access Manage',
                iconL: menuIcon.manageLine,
                iconF: menuIcon.manageFill
            },
            {
                id: 'hrms/devices',
                title: 'Devices',
                iconL: menuIcon.deviceLine,
                iconF: menuIcon.deviceFill
            }
        ]
    },
    {
        id: 'business-manager',
        miniTitle: 'BM',
        title: 'Business Manager',
        iconFill: menuIcon.bmFillS,
        iconLine: menuIcon.bmLineS,
        status: '',
        subMenu: [
            {
                id: 'business-manager/dashboard',
                title: 'Dashboard',
                iconL: menuIcon.dashboardLine,
                iconF: menuIcon.dashboardFill
            },
            {
                id: 'business-manager/customers',
                title: 'Customers',
                iconL: menuIcon.userLine,
                iconF: menuIcon.userFill
            },
            {
                id: 'business-manager/conversation',
                title: 'Conversation',
                iconL: menuIcon.conversationLine,
                iconF: menuIcon.conversationFill
            },
            {
                id: 'business-manager/schedule',
                title: 'Schedule',
                iconL: menuIcon.scheduleLine,
                iconF: menuIcon.scheduleFill
            },
            {
                id: 'business-manager/accounts',
                title: 'Accounts',
                iconL: menuIcon.userLine,
                iconF: menuIcon.userFill
            },
            {
                id: 'business-manager/orders',
                title: 'Orders',
                iconL: menuIcon.orderLine,
                iconF: menuIcon.orderFill
            },
            {
                id: 'business-manager/teams',
                title: 'Teams',
                iconL: menuIcon.teamLine,
                iconF: menuIcon.teamFill
            },
            {
                id: 'business-manager/transactions',
                title: 'Transactions',
                iconL: menuIcon.transactionsLine,
                iconF: menuIcon.transactionsFill
            },
            {
                id: 'business-manager/reports',
                title: 'Reports',
                iconL: menuIcon.reportsLine,
                iconF: menuIcon.reportsFill
            },
            {
                id: 'business-manager/expense',
                title: 'Expenses',
                iconL: menuIcon.financeLine,
                iconF: menuIcon.financeFill
            },
            {
                id: 'business-manager/configuration/pageinfo',
                title: 'Configuration',
                iconL: menuIcon.configLine,
                iconF: menuIcon.configFill
            },
            {
                id: 'business-manager/api2',
                title: 'API',
                iconL: menuIcon.codeLine,
                iconF: menuIcon.codeFill
            },
            {
                id: 'business-manager/manage-tags',
                title: 'Manage Tags',
                iconL: menuIcon.codeLine,
                iconF: menuIcon.codeFill
            }
        ]
    },
    {
        id: 'project-manage',
        miniTitle: 'pm',
        title: 'Project Manage',
        iconFill: icons.defaultIcon,
        iconLine: icons.defaultIcon,
        status: ''
    }
];

export default links;
