import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import URLParse from 'url-parse';
import isAppActiveInAnyProject from '../../utils/isAppActiveInAnyProject';
import links from './components/links';

const InitContext = createContext();

// activeServices is used to control the active menu
const activeServices = ['inventory', 'email', 'hrms', 'bm'];

export function useAdvMenu() {
    return useContext(InitContext);
}

export function getBreadcrumb() {
    const cuPath = window.location.pathname;
    return cuPath.split('/').slice(1, 3);
}

export function setProject(navigate, project, initialPath) {
    const currentUrl = window.location.href;
    const URL = new URLParse(currentUrl, true);

    if (initialPath) {
        // Modify the initial path as needed
        URL.pathname = initialPath?.toString();
    }

    if (project?.id) {
        URL.query['project-id'] = project.id;

        const newUrl = URL.toString();
        if (newUrl !== currentUrl) {
            navigate(newUrl.split(URL.origin)[1]);
        }
    }
}
export function getProjectId() {
    const URL = new URLParse(window.location.href, true);
    return parseInt(URL.query['project-id'] || 0, 10);
}

export function mkUrl(path) {
    const URL = new URLParse(window.location.href, true);
    URL.pathname = path;
    return URL.toString();
}

export function mkRelativeUrl(path, queryParams = {}) {
    const URL = new URLParse(window.location.href, true);
    URL.pathname = path;
    URL.query = { 'project-id': getProjectId(), ...queryParams };

    return URL.toString().split(URL.origin)[1];
}

function AdvMenuProvider({ children }) {
    // Project Operation
    const [projectList, setProjectList] = useState([]);
    const [activeProject, setActiveProject] = useState([]);
    const [reloadKey, setReloadKey] = useState(Math.random());

    const linksWithStatus = links.map((link) => ({
        ...link,
        status: isAppActiveInAnyProject(link.id, activeProject) ? 'app-active' : 'app-inactive'
    }));

    // Menu Operation
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [activeMenu, setActiveMenu] = useState(linksWithStatus[0]);

    // select new project when projectList is updated or when the page is reloaded
    useEffect(() => {
        setActiveProject(() => {
            const id = getProjectId() || projectList?.[0]?.id;
            if (projectList) {
                return projectList?.find((item) => item?.id === id);
            }
            return {};
        });
    }, [projectList]);

    const handleProjectReload = () => {
        setReloadKey((d) => d + 1);
    };

    const value = useMemo(() => {
        const toggle = () => {
            setDrawerOpen((st) => !st);
        };

        const handelMenu = (location) => {
            // --Menu
            setActiveMenu((cuData) => {
                const loInfo = location.split('/').filter((d) => !!d);
                const menuSelected = links.find((data) => data.id === loInfo[0]);
                if (cuData.location !== location && menuSelected) {
                    const subMenu = menuSelected?.subMenu?.map((data) => ({
                        ...data,
                        active:
                            data.id === `${loInfo[0]}/${loInfo[1]}` ||
                            data.id.includes(`${loInfo[0]}/${loInfo[1]}`)
                    }));

                    return { ...menuSelected, subMenu, location };
                }
                return cuData;
            });

            // --Project
            setActiveProject(() => {
                const id = getProjectId();
                if (projectList) {
                    return projectList?.find((item) => item?.id === id);
                }
                return {};
            });
        };

        function getServiceStatus(path) {
            const status = isAppActiveInAnyProject(path?.split('/')?.[1], activeProject);
            if (path.length <= 1) {
                return false;
            }
            return status;
        }

        return {
            links: linksWithStatus,
            drawerOpen,
            setDrawerOpen,
            toggle,
            activeMenu,
            setActiveMenu,
            handelMenu,
            activeProject,
            reloadKey,
            handleProjectReload,
            projectList,
            setProjectList,
            activeServices,
            getServiceStatus
        };
    }, [activeMenu, activeProject, drawerOpen, linksWithStatus, projectList, reloadKey]);

    return <InitContext.Provider value={value}>{children}</InitContext.Provider>;
}

export default AdvMenuProvider;
