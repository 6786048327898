import { ButtonReload } from '../../../../../configs/Buttons';

function BmManageTagHeader({ handleReload }) {
    return (
        <div className="page-header-cont flex-row position-middle align-space-between">
            <div>
                <h2 className="page-header_title">Manage Tags</h2>
            </div>

            <div className="flex-row position-stretch">
                <ButtonReload evOnClick={handleReload} />
            </div>
        </div>
    );
}

export default BmManageTagHeader;
