import AssetItemRow from './AssetItemRow';

const concreteTanks = [
    {
        id: 4,
        size: 500
    },
    {
        id: 5,
        size: 800
    },
    {
        id: 6,
        size: 1000
    }
];

function AssetConcrete({ handleAssetItemClick, selectedItem }) {
    return concreteTanks.map((item) => {
        const isActive = selectedItem?.filter(
            (i) => i?.id.toString() === item?.id.toString()
        )?.length;
        return (
            <AssetItemRow
                active={isActive}
                evOnClick={handleAssetItemClick}
                key={item?.id}
                item={item}
            />
        );
    });
}

export default AssetConcrete;
