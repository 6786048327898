import { ButtonReload } from '../../../../../configs/Buttons';

function BmDashboardPageHeader() {
    const handleReload = () => {
        console.log('reload');
    };

    return (
        <div className="page-header-cont flex-row position-middle align-space-between">
            <div>
                <h2 className="page-header_title">Dashboard</h2>
            </div>

            <ButtonReload evOnClick={handleReload} />
        </div>
    );
}

export default BmDashboardPageHeader;
