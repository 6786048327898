import { icons } from '../../../../../configs/Icons';
import ConLeftColChatHeader from './ConLeftColChatHeader';

const userArray = [
    {
        id: 1,
        name: 'User 1',
        chat: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro, nobis quasi voluptatibus nostrum ab veritatis, quae totam officia cupiditate aspernatur expedita excepturi a, non vel dicta nesciunt amet dolorem praesentium aliquam quam soluta. Cupiditate tempore delectus perspiciatis nostrum soluta ratione dicta commodi reprehenderit repudiandae dignissimos, enim perferendis, ipsam fugit laboriosam necessitatibus quae velit animi nihil impedit facere? Quas, delectus ipsam?',
        avatar: 'https://via.placeholder.com/150',
        day: 'Fri'
    },
    {
        id: 2,
        name: 'User 2',
        chat: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro, nobis quasi voluptatibus nostrum ab veritatis, quae totam officia cupiditate aspernatur expedita excepturi a, non vel dicta nesciunt amet dolorem praesentium aliquam quam soluta. Cupiditate tempore delectus perspiciatis nostrum soluta ratione dicta commodi reprehenderit repudiandae dignissimos, enim perferendis, ipsam fugit laboriosam necessitatibus quae velit animi nihil impedit facere? Quas, delectus ipsam?',
        avatar: 'https://via.placeholder.com/150',
        day: 'Thu'
    },
    {
        id: 3,
        name: 'User 3',
        chat: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro, nobis quasi voluptatibus nostrum ab veritatis, quae totam officia cupiditate aspernatur expedita excepturi a, non vel dicta nesciunt amet dolorem praesentium aliquam quam soluta. Cupiditate tempore delectus perspiciatis nostrum soluta ratione dicta commodi reprehenderit repudiandae dignissimos, enim perferendis, ipsam fugit laboriosam necessitatibus quae velit animi nihil impedit facere? Quas, delectus ipsam?',
        avatar: 'https://via.placeholder.com/150',
        day: 'Wed'
    }
];

function ConLeftColChatUser({ name, chat, day }) {
    return (
        <div className="flex-row position-middle">
            <div className="con_chat-user-avatar">
                <img src={icons.avatar} alt="user avatar" />
            </div>
            <div>
                <h2 className="con_chat-user-name">
                    <span>{name}</span>
                </h2>
                <h2 className="con_user-chat">
                    <span>{chat}</span>
                </h2>
            </div>
            <div>
                <span className="con_chat-day">{day}</span>
            </div>
        </div>
    );
}

function ConLeftColChat() {
    return (
        <div className="con_chat-cont">
            <ConLeftColChatHeader />

            <div className="con_chat-list">
                {userArray.map((user) => (
                    <ConLeftColChatUser name={user.name} chat={user.chat} day={user.day} />
                ))}
            </div>
        </div>
    );
}

export default ConLeftColChat;
