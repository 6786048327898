import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import BmConfigurationPageHeader from './BmConfigurationPageHeader';

function BmConfigurationPage() {
    const [tab, setTab] = useState('pageinfo'); // ['pageinfo', 'branch'
    // const projectId = getProjectId();
    return (
        <div className="page-cont bm-config-page">
            <BmConfigurationPageHeader setTab={setTab} tab={tab} />

            <Outlet />
        </div>
    );
}

export default BmConfigurationPage;
