/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonGray, ButtonGreen } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { mkRelativeUrl } from '../../../../features/menus/AdvMenuProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import { handelMakeDuplicate } from '../InvoiceUpdateOperation';

const initialState = {
    address: true,
    products: false,
    notes: false
};

function InvoiceDuplicate({ setOpen, invoiceId }) {
    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();
    const [formData, setFormData] = useState(initialState);
    const navigate = useNavigate();

    const handelChangeValue = (name) => {
        if (name) {
            setFormData((d) => ({ ...d, [name]: !formData[name] }));
        }
    };

    const handelDuplicate = () => {
        setMessage('Duplicating Invoice...');
        handelMakeDuplicate(authInfo, invoiceId, formData)
            .then(({ message, id }) => {
                setMessage(message);
                setOpen(false);
                const url = mkRelativeUrl(`/finance/sell-invoice/${id}/update/`);
                navigate(url);
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <div className="invoice-duplicate-popup">
            <div className="flex-col gap-5">
                <div
                    role="button"
                    tabIndex={-1}
                    onClick={() => handelChangeValue('address')}
                    className="flex-row position-middle gap-5"
                >
                    <input
                        onChange={() => handelChangeValue('address')}
                        checked={formData?.address || false}
                        type="checkbox"
                        name="address"
                    />
                    <span>Address</span>
                </div>

                <div
                    role="button"
                    tabIndex={-1}
                    onClick={() => handelChangeValue('products')}
                    className="flex-row position-middle gap-5"
                >
                    <input
                        onChange={() => handelChangeValue('products')}
                        checked={formData?.products || false}
                        type="checkbox"
                        name="products"
                    />
                    <span>Products</span>
                </div>

                <div
                    role="button"
                    tabIndex={-1}
                    onClick={() => handelChangeValue('notes')}
                    className="flex-row position-middle gap-5"
                >
                    <input
                        onChange={() => handelChangeValue('notes')}
                        checked={formData?.notes || false}
                        type="checkbox"
                        name="notes"
                    />
                    <span>Notes</span>
                </div>
            </div>

            <div className="invoice-duplicate_action flex-row align-right position-middle">
                <ButtonGray evOnClick={() => setOpen(false)} title="Cancel" />

                <ButtonGreen evOnClick={handelDuplicate} title="Duplicate" />
            </div>
        </div>
    );
}

export default InvoiceDuplicate;
