import { useEffect, useRef, useState } from 'react';
import FilterArea from './components/FilterArea';
import SearchField from './components/SearchField';

function SearchWithFilter({
    name,
    setFormData,
    formData,
    handleChange,
    placeholder,
    evApplyClick,
    dateRef,
    children
}) {
    const [showFilter, setShowFilter] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dateRef) {
                if (dateRef?.current && !dateRef?.current?.node?.classList.contains('active')) {
                    if (ref.current && !ref.current?.contains(event?.target)) {
                        setShowFilter(false);
                    }
                }
            } else if (ref.current && !ref.current?.contains(event?.target)) {
                setShowFilter(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dateRef]);

    const handleApplyClick = () => {
        setShowFilter(false);
        evApplyClick();
    };

    const isActive = children && showFilter;

    return (
        <div ref={ref} className={`search-filter-container ${isActive ? 'active' : ''}`}>
            <SearchField
                setShowFilter={setShowFilter}
                name={name}
                setFormData={setFormData}
                formData={formData}
                handleChange={handleChange}
                placeholder={placeholder}
            />
            <FilterArea show={isActive} evApplyClick={handleApplyClick}>
                {children}
            </FilterArea>
        </div>
    );
}

export default SearchWithFilter;
