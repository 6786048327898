import React, { useState } from 'react';
import { ButtonGreen } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { getProjectId } from '../../../../features/menus/AdvMenuProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import { UserInfoV3 } from '../../../../features/user-info/UserInfo';
import LibModal from '../../../../lib/LibModal';
import AnimateInputField from '../../../../utils/AnimateInputField';
import { handleAddPage, handleCheckPage } from '../BmPageinfoOperation';

const initialState = { pageId: '', token: '' };

function ActionButton({ tokenInfo, handleCheck, handleAdd }) {
    if (tokenInfo) {
        return <ButtonGreen evOnClick={handleAdd} title="Add" className="fill-x" />;
    }
    return <ButtonGreen evOnClick={handleCheck} title="Check" className="fill-x" />;
}

function BmPageAddModal({ setOpen, handleReload }) {
    const [formData, setFormData] = useState(initialState);
    const [tokenInfo, setTokenInfo] = useState(null);

    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    const projectId = getProjectId();

    const handelChange = (ev) => {
        const { name } = ev.target;
        if (name) {
            setFormData((d) => ({ ...d, [name]: ev.target.value }));
        }
    };

    const handleCheck = () => {
        setMessage('Checking...');
        handleCheckPage(authInfo, projectId, formData?.token)
            .then(({ message, data }) => {
                setTokenInfo(data?.fbPage);
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    const handleAdd = () => {
        setMessage('Adding...');
        handleAddPage(authInfo, projectId, formData?.token)
            .then(({ message }) => {
                setMessage(message);
                setOpen(false);
                handleReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    const { profileImage, name, id } = tokenInfo || {};

    return (
        <LibModal title="Add Page" setOpen={setOpen}>
            <div className="add-page-modal-cont">
                <div className="flex-col gap-20">
                    {/* <AnimateInputField
                        title="Page ID"
                        name="pageId"
                        formData={formData}
                        handelChange={handelChange}
                    /> */}
                    <AnimateInputField
                        title="Access Token"
                        name="token"
                        formData={formData}
                        handelChange={handelChange}
                    />

                    <div className={`w-100p ${!tokenInfo ? 'placeholder-mode' : ''}`}>
                        <UserInfoV3
                            img={profileImage || icons.defaultIcon}
                            name={name || '-----------------'}
                            email={id || '-----------------'}
                        />
                    </div>
                </div>

                <div className="flex-row align-right">
                    <ActionButton
                        tokenInfo={tokenInfo}
                        handleCheck={handleCheck}
                        handleAdd={handleAdd}
                    />
                </div>
            </div>
        </LibModal>
    );
}

export default BmPageAddModal;
