import { useRef } from 'react';
import useEditorPositioning from './useEditorPositioning';

function Cancel({ cancelClick }) {
    return (
        <div
            className="btn-cancel"
            role="button"
            tabIndex={-1}
            onClick={(ev) => ev.stopPropagation() + cancelClick()}
        >
            Cancel
        </div>
    );
}

function Save({ saveClick }) {
    return (
        <div
            className="btn-save"
            role="button"
            tabIndex={-1}
            onClick={(ev) => ev.stopPropagation() + saveClick()}
        >
            Save
        </div>
    );
}

function InfoEditorInput({
    show,
    setShow,
    positionRef,
    width = 150,
    align = 'center',
    outsideClick,
    name,
    placeholder,
    formData,
    setFormData,
    saveClick
}) {
    const editorRef = useRef(null);

    // editor SHOW/HIDE and positioning
    useEditorPositioning(positionRef, editorRef, show, setShow, align, outsideClick);

    const handelChange = (ev) => {
        if (ev.target) {
            setFormData((d) => ({ ...d, [ev.target.name]: ev.target.value }));
        }
    };

    if (!show) {
        return null;
    }
    return (
        <div
            role="button"
            tabIndex={-1}
            onClick={(ev) => ev.stopPropagation()}
            style={{ width: `${width}px` }}
            ref={editorRef}
            className="info-editor default"
        >
            <div className="info-editor-input">
                <div className="row-1">
                    <input
                        name={name}
                        placeholder={placeholder || 'Type Something...'}
                        value={formData?.[name] || ''}
                        onChange={handelChange}
                        type="text"
                    />
                </div>

                <div className="row-2 flex-row align-right position-middle">
                    <div className="flex-row">
                        <Cancel cancelClick={() => setShow(false)} />
                        <Save saveClick={saveClick} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InfoEditorInput;
