export const overviewDemoData = {
    customerInfo: {
        name: 'Christopher Henry',
        phone: '01700000000',
        joined: 1691058390,
        address: 'Office No. 401, Training Center KUET Road, Khulna 9203'
    },
    assets: {
        house: 3,
        floor: 8,
        unit: 2,
        pvcTank: 2,
        concreteTank: 2
    },
    order: {
        lo: 1691058390,
        orders: [
            {
                id: 10000001,
                date: 1691058390,
                address: 'House No. N/1, Road No. 152, Housing Estate, Khalishpur, Khulna',
                title: 'PVC Tank Wash',
                quantity: 2,
                size: 2000,
                price: 1600,
                status: 'Incomplete'
            },
            {
                id: 10000002,
                date: 1691058390,
                address: 'House No. N/1, Road No. 152, Housing Estate, Khalishpur, Khulna',
                title: 'Concrete Tank Wash',
                quantity: 1,
                size: 3000,
                price: 2600,
                status: 'Complete'
            },
            {
                id: 10000003,
                date: 1691058390,
                address: 'House No. N/1, Road No. 152, Housing Estate, Khalishpur, Khulna',
                title: 'Pipe Wash',
                quantity: 2,
                size: 3000,
                price: 2600,
                status: 'Pending'
            }
        ]
    },
    responses: [
        {
            id: 1,
            text: 'Hello! Thank you for contacting our cleaning service. How can we assist you today?'
        },
        {
            id: 2,
            text: "Welcome to our cleaning service chat! We're here to help with any cleaning-related questions or bookings you have."
        },
        {
            id: 3,
            text: "Hi there! Let us know what area you need cleaning, and we'll provide you with a quote right away."
        },
        {
            id: 4,
            text: "Greetings! Our team is ready to tackle your cleaning needs. Feel free to ask anything you'd like to know about our services."
        },
        {
            id: 5,
            text: "Hey, how can we make your space sparkle today? Tell us about your cleaning requirements, and we'll take care of the rest."
        },
        {
            id: 6,
            text: "Good day! Ready to schedule a cleaning appointment? Just tell us when and where, and we'll make it happen."
        },
        {
            id: 7,
            text: "Hello! Need professional cleaning for your home or office? You're in the right place. What can we do for you today?"
        },
        {
            id: 8,
            text: "Hi! Want to learn more about our eco-friendly cleaning options? We're happy to share the details with you."
        },
        {
            id: 9,
            text: 'Greetings! Our experienced cleaners are at your service. Let us know your preferred date and time for a cleaning session.'
        },
        {
            id: 10,
            text: "Hello there! Any stains, dirt, or mess you need to bid farewell to? We're here to provide top-notch cleaning solutions."
        },
        {
            id: 11,
            text: "Hi! We're here to help you with any cleaning-related questions you have. Feel free to ask away."
        },
        {
            id: 12,
            text: "Hello! We're happy to assist you with any cleaning-related questions you have. Let us know how we can help."
        },
        {
            id: 13,
            text: "Hi there! We're here to help you with any cleaning-related questions you have. Feel free to ask away."
        },
        {
            id: 14,
            text: "Hello! We're happy to assist you with any cleaning-related questions you have. Let us know how we can help."
        }
    ],
    notes: [
        {
            id: 1,
            text: 'Hello! Thank you for contacting our cleaning service. How can we assist you today?'
        },
        {
            id: 2,
            text: "Welcome to our cleaning service chat! We're here to help with any cleaning-related questions or bookings you have. Welcome to our cleaning service chat! We're here to help with any cleaning-related questions or bookings you have. Welcome to our cleaning service chat! We're here to help with any cleaning-related questions or bookings you have."
        },
        {
            id: 3,
            text: "Hi there! Let us know what area you need cleaning, and we'll provide you with a quote right away. Welcome to our cleaning service chat! We're here to help with any cleaning-related questions or bookings you have. Welcome to our cleaning service chat! We're here to help with any cleaning-related questions or bookings you have. Welcome to our cleaning service chat! We're here to help with any cleaning-related questions or bookings you have."
        },
        {
            id: 4,
            text: "Greetings! Our team is ready to tackle your cleaning needs. Feel free to ask anything you'd like to know about our services."
        },
        {
            id: 5,
            text: "Hey, how can we make your space sparkle today? Tell us about your cleaning requirements, and we'll take care of the rest."
        },
        {
            id: 6,
            text: "Hi there! Let us know what area you need cleaning, and we'll provide you with a quote right away."
        },
        {
            id: 7,
            text: "Hi there! Let us know what area you need cleaning, and we'll provide you with a quote right away."
        },
        {
            id: 8,
            text: "Hi there! Let us know what area you need cleaning, and we'll provide you with a quote right away."
        },
        {
            id: 9,
            text: "Hi there! Let us know what area you need cleaning, and we'll provide you with a quote right away."
        },
        {
            id: 10,
            text: "Hi there! Let us know what area you need cleaning, and we'll provide you with a quote right away."
        },
        {
            id: 11,
            text: "Hi there! Let us know what area you need cleaning, and we'll provide you with a quote right away."
        },
        {
            id: 12,
            text: "Hi there! Let us know what area you need cleaning, and we'll provide you with a quote right away."
        }
    ]
};

export default null;
