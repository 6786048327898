import { useEffect, useState } from 'react';
import { ButtonPinkLine } from '../../../configs/Buttons';
import { icons } from '../../../configs/Icons';
import { useAuth } from '../auth/AuthProvider';
import { getProjectId } from '../menus/AdvMenuProvider';
import { useTemplate } from '../template/TemplateProvider';
import RoleManageProvider, { useRole } from './RoleManageProvider';
import { handleLoadRoleData } from './RoleOperation';
import RoleManageTable from './components/RoleManageTable';
import RoleAssignModal from './modals/RoleAssignModal';
import RoleMakeOwnerWarning from './modals/RoleMakeOwnerWarning';
import RoleModifyModal from './modals/RoleModifyModal';

// function Avatar({ img }) {
//     return (
//         <div className="role-avatar">
//             <span>
//                 <img src={img || icons.avatar} alt="" />
//             </span>
//         </div>
//     );
// }

function SectionContent({ handleOpenModal, projectId }) {
    const { setRoleData, roles, handleReload, reloadKey } = useRole();

    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();

    useEffect(() => {
        handleLoadRoleData(authInfo, projectId)
            .then(({ data }) => {
                setRoleData((cudata) => ({
                    ...cudata,
                    roles: data.data,
                    addButton: data.addButton
                }));
            })
            .catch((err) => {
                setMessage(err.toString(0));
            });
    }, [authInfo, projectId, setMessage, setRoleData, reloadKey]);

    return (
        <div className="role-manage-section">
            <div className="section-header flex-row position-middle align-space-between">
                <h2 className="section-title">Role Manage</h2>

                <div className="flex-row position-middle">
                    <ButtonPinkLine
                        evOnClick={() => handleOpenModal('role-assign')}
                        title="Assign User"
                    />
                    <div className="reload-button">
                        <button type="button" className="p-relative" onClick={handleReload}>
                            <img src={icons.reloadLine} alt="" />
                            <img src={icons.reloadFill} alt="" />
                        </button>
                    </div>
                </div>
            </div>

            <RoleManageTable
                handleOpenModal={handleOpenModal}
                handleReload={handleReload}
                roles={roles}
            />
        </div>
    );
}

function RoleManageSection() {
    const [showModal, setShowModal] = useState({ type: '', data: null });

    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();

    const handleOpenModal = (type, data) => {
        setShowModal({ type, data });
    };

    const handleCloseModal = () => {
        setShowModal({ type: '', data: null });
    };

    const projectId = getProjectId();

    return (
        <RoleManageProvider authInfo={authInfo} setMessage={setMessage} projectId={projectId}>
            <SectionContent handleOpenModal={handleOpenModal} projectId={projectId} />

            {/* modals */}
            {showModal.type === 'role-assign' ? (
                <RoleAssignModal
                    projectId={projectId}
                    data={showModal?.data}
                    authInfo={authInfo}
                    setMessage={setMessage}
                    handleCloseModal={handleCloseModal}
                />
            ) : null}

            {showModal.type === 'make-owner' ? (
                <RoleMakeOwnerWarning
                    projectId={projectId}
                    data={showModal?.data}
                    authInfo={authInfo}
                    setMessage={setMessage}
                    handleCloseModal={handleCloseModal}
                />
            ) : null}

            {showModal.type === 'modify' ? (
                <RoleModifyModal
                    projectId={projectId}
                    data={showModal?.data}
                    authInfo={authInfo}
                    setMessage={setMessage}
                    handleCloseModal={handleCloseModal}
                />
            ) : null}
        </RoleManageProvider>
    );
}

export default RoleManageSection;
