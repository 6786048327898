import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { icons } from '../../../../configs/Icons';
import { useAuth } from '../../../features/auth/AuthProvider';
import { getBreadcrumb, useAdvMenu } from '../../../features/menus/AdvMenuProvider';
import Notification from '../../../features/notification/Notification';
import ProjectDropdown, { addItem } from '../../../features/project-setting/ProjectDropdown';
import { useTemplate } from '../../../features/template/TemplateProvider';

function ProfileMenu({ show, setShow, cuUser, logOut }) {
    const ref = useRef();
    const { setMessage } = useTemplate();

    const handleLogoutClick = () => {
        setMessage('Logging out...');
        logOut()
            .then(({ message }) => {
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    useEffect(() => {
        function handelClickOutside(event) {
            if (ref?.current && !ref?.current.contains(event?.target)) {
                setShow(false);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handelClickOutside);
        return () => {
            document.removeEventListener('mousedown', handelClickOutside);
        };
    }, [setShow]);

    if (!show) return null;

    return (
        <div className="user-profile__menus" ref={ref}>
            <div className="profile-menus__outer">
                <div className="account-setting flex-row position-middle gap-15">
                    <div className="account-user-img">
                        <img src={cuUser.photoUrl} alt={cuUser.name} />
                    </div>
                    <div className="account-name">
                        <div className="name">{cuUser.name}</div>
                        <div className="email">{cuUser.email}</div>
                    </div>
                </div>
                <div className="account-manage">
                    <div className="w-100p flex-row align-space-around">
                        <Link
                            to="https://bikiran.com/user/profile-overview/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Manage your Bikiran Account
                        </Link>
                    </div>
                </div>
            </div>

            <div className="logout-div flex-row position-middle gap-10">
                <button
                    type="button"
                    className="logout-btn flex-row gap-10 position-middle"
                    onClick={handleLogoutClick}
                >
                    <img src={icons.logout} alt="Logout" />
                    <span>Logout</span>
                </button>
            </div>
        </div>
    );
}

function CurrentUser() {
    const { authInfo, logOut } = useAuth();
    const [show, setShow] = useState(false);

    return (
        <div className="user-profile">
            <button
                type="button"
                className="user-profile__btn"
                onClick={() => setShow((cuData) => !cuData)}
            >
                <img
                    src={authInfo?.currentUser.photoUrl || icons?.avatar}
                    alt={authInfo?.currentUser.name}
                />
            </button>

            <ProfileMenu
                show={show}
                setShow={setShow}
                cuUser={authInfo.currentUser}
                logOut={logOut}
            />
        </div>
    );
}

function WebMenus() {
    const path = getBreadcrumb();
    const { projectList } = useAdvMenu();
    return (
        <div className="adv-web-menu flex-row position-middle align-space-between">
            <div className="projects-div flex-row position-middle">
                <div className="dropdown-menus">
                    <ProjectDropdown
                        projects={projectList?.map((item) => {
                            if (item?.status === 'active') {
                                return addItem(item?.id, item?.icon, item?.title);
                            }
                            return null;
                        })}
                    />
                </div>

                <div className="breadcrumb flex-row">
                    {path.map((item, key) => (
                        <div className="flex-row position-middle" key={item}>
                            <span className="">{item}</span>
                            {key !== path.length - 1 && <img src={icons.rightArrow} alt="" />}
                        </div>
                    ))}
                </div>
            </div>

            <div className="user-panel flex-row align-center position-middle">
                {/* Notification Box */}
                <Notification />
                <button type="button" className="all-services">
                    <img src={icons.services} alt="Services" />
                </button>
                <CurrentUser />
            </div>
        </div>
    );
}

export default WebMenus;
