import { icons } from '../../../../configs/Icons';

export function UserInfoV3({ img, name, email = '', evOnClick, className = '' }) {
    return (
        <div
            role={evOnClick ? 'button' : ''}
            tabIndex={-1}
            onClick={evOnClick}
            className={`user-info v3 flex-row position-middle ${className}`}
        >
            <div>
                <div className="user-info_avatar">
                    <span>
                        <img src={img || icons.avatar} alt={name} referrerPolicy="no-referrer" />
                    </span>
                </div>
            </div>

            <div>
                <h2 className="user-info_name">
                    <span>{name || '----------------------'}</span>
                </h2>
                <h2 className="user-info_email">{email && <span>{email}</span>}</h2>
            </div>
        </div>
    );
}

export function UserInfoV2({ img, name, email, evOnClick, className = '' }) {
    return (
        <div
            role={evOnClick ? 'button' : ''}
            tabIndex={-1}
            onClick={evOnClick}
            className={`user-info v2  flex-row position-middle ${className}`}
        >
            <div>
                <div className="user-info_avatar">
                    <span>
                        <img src={img || icons.avatar} alt="avatar" />
                    </span>
                </div>
            </div>

            <div>
                <h2 className="user-info_name">
                    <span>{name || '---------------'}</span>
                </h2>
                <h2 className="user-info_email">{email && <span>{email}</span>}</h2>
            </div>
        </div>
    );
}

function UserInfo({ img, name, email, evOnClick, className = '' }) {
    return (
        <div
            role={evOnClick ? 'button' : ''}
            tabIndex={-1}
            onClick={evOnClick}
            className={`user-info flex-row position-middle ${className}`}
        >
            <div>
                <div className="user-info_avatar">
                    <span>
                        <img src={img || icons.avatar} alt="avatar" />
                    </span>
                </div>
            </div>

            <div>
                <h2 className="user-info_name">
                    <span>{name || '---------------'}</span>
                </h2>
                <h2 className="user-info_email">{email && <span>{email}</span>}</h2>
            </div>
        </div>
    );
}

export default UserInfo;
