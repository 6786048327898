import { useState } from 'react';
import { ButtonGray, ButtonGreen } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { getProjectId } from '../../../../features/menus/AdvMenuProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import LibModal from '../../../../lib/LibModal';
import AnimateInputField, {
    AnimateDateField,
    AnimateNumberField,
    AnimateSelectField,
    AnimateTextareaField,
    addOption
} from '../../../../utils/AnimateInputField';
import { showCurrency } from '../../../../utils/Show';
import { handleAddTeam } from '../BmTeamsOperation';

const initialState = {
    name: '',
    phone: '',
    designationId: '',
    branchId: '',
    joiningDate: '',
    salary: '',
    paymentInfo: ''
};

function TeamAddModal({ handleCloseModal, handleReload, requiredData }) {
    const [formData, setFormData] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();

    const projectId = getProjectId();

    const handleAddButton = () => {
        setMessage('Adding Team...');
        handleAddTeam(authInfo, formData, projectId)
            .then(({ message }) => {
                handleCloseModal();
                setFormData(initialState);
                setMessage(message);
                handleReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <div className="team-action-modal-cont">
            <LibModal title="Add New Team" setOpen={handleCloseModal}>
                <div className="modal-body-cont">
                    <div>
                        <div className="input-row">
                            <AnimateInputField
                                title="Name"
                                name="name"
                                handelChange={handleChange}
                                formData={formData}
                            />
                        </div>
                        <div className="input-row">
                            <AnimateInputField
                                title="Phone"
                                name="phone"
                                handelChange={handleChange}
                                formData={formData}
                                placeholder="880..."
                            />
                        </div>
                        <div className="input-row">
                            <AnimateSelectField
                                title="Select Designation"
                                name="designationId"
                                handelChange={handleChange}
                                formData={formData}
                                options={requiredData?.designations?.map((item) =>
                                    addOption(item?.id, item?.name, item?.id)
                                )}
                            />
                        </div>
                        <div className="input-row">
                            <AnimateSelectField
                                title="Select Branch"
                                name="branchId"
                                handelChange={handleChange}
                                formData={formData}
                                options={requiredData?.branches?.map((item) =>
                                    addOption(item.id, item?.name, item.id)
                                )}
                            />
                        </div>

                        <div className="input-row flex-row align-space-between">
                            <div className="inp-fill-2">
                                <AnimateDateField
                                    title="Date Joined"
                                    name="joiningDate"
                                    handelChange={handleChange}
                                    formData={formData}
                                />
                            </div>

                            <div className="inp-fill-2">
                                <AnimateNumberField
                                    title="Salary"
                                    name="salary"
                                    handelChange={handleChange}
                                    formData={formData}
                                    unit="/Month"
                                />
                            </div>
                        </div>

                        <div className="input-row">
                            {requiredData?.designations?.find(
                                (el) => el.id?.toString() === formData?.designationId.toString()
                            )?.durationType === 'week' && formData?.salary?.toString()?.length ? (
                                <p className="calculation-text">
                                    {showCurrency((formData.salary / 7) * 30)}
                                    /Month
                                </p>
                            ) : null}
                        </div>

                        <div className="input-row">
                            <AnimateTextareaField
                                title="Payment Information"
                                name="paymentInfo"
                                handelChange={handleChange}
                                formData={formData}
                            />
                        </div>
                    </div>

                    <div className="modal-action flex-row align-right">
                        <div className="flex-row position-middle">
                            <ButtonGray title="Cancel" evOnClick={handleCloseModal} />

                            <ButtonGreen title="Add" evOnClick={handleAddButton} />
                        </div>
                    </div>
                </div>
            </LibModal>
        </div>
    );
}

export default TeamAddModal;
