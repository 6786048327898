import { ButtonPurple } from '../../../../configs/Buttons';
import { icons } from '../../../../configs/Icons';
import { getProjectId, useAdvMenu } from '../../../features/menus/AdvMenuProvider';
import BmConfigBranchProvider, { useBranch } from './BmConfigBranchProvider';
import ConfigBranchTable from './BmConfigBranchTable';
import BmConfigBranchAddModal from './modals/BmConfigBranchAddModal';
import BmConfigBranchUpdateModal from './modals/BmConfigBranchUpdateModal';

function SectionHeader({ handleReload, handleOpenModal, disabled }) {
    return (
        <div className="section-header flex-row align-space-between position-stretch">
            <div className="flex-row position-middle">
                <h2 className="section-title">Branches</h2>
            </div>

            <div className="flex-row position-middle">
                <div className={`reload-button ${disabled ? 'disabled' : ''}`}>
                    <button type="button" className="p-relative button" onClick={handleReload}>
                        <img src={icons.reloadLine} alt="" />
                        <img src={icons.reloadFill} alt="" />
                    </button>
                </div>
                <ButtonPurple title="Add Branch" evOnClick={() => handleOpenModal('branch-add')} />
            </div>
        </div>
    );
}

function PageContent({ projectId }) {
    const {
        branchInfo,
        setBranchInfo,
        reloadKey,
        handleReload,
        handleOpenModal,
        handleCloseModal,
        modalType,
        modalData
    } = useBranch();
    return (
        <div className="config-branch-section">
            <SectionHeader handleReload={handleReload} handleOpenModal={handleOpenModal} />
            <ConfigBranchTable
                projectId={projectId}
                handleOpenModal={handleOpenModal}
                branchInfo={branchInfo}
                setBranchInfo={setBranchInfo}
                reloadKey={reloadKey}
            />

            {/* modal */}
            {modalType === 'branch-add' ? (
                <BmConfigBranchAddModal
                    handleCloseModal={handleCloseModal}
                    handleReload={handleReload}
                    projectId={projectId}
                />
            ) : null}

            {modalType === 'branch-update' ? (
                <BmConfigBranchUpdateModal
                    handleCloseModal={handleCloseModal}
                    modalData={modalData}
                    handleReload={handleReload}
                    projectId={projectId}
                />
            ) : null}
        </div>
    );
}

function BmConfigBranchSection() {
    const { activeProject } = useAdvMenu();
    const projectId = activeProject?.id || getProjectId();

    return (
        <BmConfigBranchProvider>
            <PageContent projectId={projectId} />
        </BmConfigBranchProvider>
    );
}

export default BmConfigBranchSection;
