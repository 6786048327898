import dayjs from 'dayjs';
import { icons } from '../../configs/Icons';

export const duration = (months) => {
    if (months < 12) {
        return `${months} Month`;
    }

    return `${months / 12} Year`;
};

export function GetDate(timeStamp) {
    if (!timeStamp) {
        return null;
    }
    const dateFormat = dayjs(timeStamp * 1000).format(`DD ${`MMMM`.slice(0, 3)} YYYY`);
    return dateFormat.toString() !== 'Invalid Date' ? dateFormat : '--';
}

export function GetTime(timeStamp) {
    if (!timeStamp) {
        return null;
    }
    const dateFormat = dayjs(timeStamp * 1000).format('h:mm:ss A');
    return dateFormat.toString() || null;
}

export function formatTime(hour) {
    if (hour < 0 || hour > 23 || Number.isNaN(hour)) {
        return '--';
    }

    let formattedHour;
    if (hour === 0) {
        formattedHour = 12;
    } else if (hour <= 12) {
        formattedHour = hour;
    } else {
        formattedHour = hour - 12;
    }

    const meridian = hour < 12 ? 'AM' : 'PM';
    const formattedTime = `${String(formattedHour).padStart(2, '0')}:${'00'} ${meridian}`;

    return formattedTime;
}
export const getDayDiff = (timestamp) => {
    const currentDate = dayjs();
    const expireDate = dayjs(timestamp * 1000);

    const days = expireDate?.diff(currentDate, 'd');

    if (!timestamp || days < 0) {
        return null;
    }

    return days;
};

// date duration (start to end)
export const getDateDuration = (start, end) => {
    const dateValue = typeof start === 'number' ? start * 1000 : start;

    const startDate = dayjs(dateValue).format('DD MMMM YYYY');

    const endDate = dayjs(dateValue, 'DD MMMM YYYY').add(end, 'month').format('DD MMMM YYYY');

    return `${startDate} to ${endDate}`;
};

// calculate discount percentige
export const calculateDiscountPercentage = (price, offerPrice) => {
    const total = parseFloat(price);
    const offer = parseFloat(offerPrice);

    if (total === offer) {
        return 0; // Return null if both prices are the same
    }

    const discountPercentage = ((total - offer) / total) * 100;

    // if (discountPercentage <= 0) {
    //     return NaN;
    // }

    return discountPercentage || 0;
};

// file size display
export const fileSizeDisplay = (bytes, text) => {
    const megabytes = bytes / (1024 * 1024);
    const gigabytes = megabytes / 1024;

    let usageText = `${megabytes.toFixed(2)} MB`;

    if (gigabytes >= 1) {
        usageText = `${gigabytes.toFixed(2)} GB`;
    }

    return <span>{`${usageText} ${text || 'Used'}` || '--'}</span>;
};

export function UpperCase(item) {
    const itemString = String(item);

    if (itemString.toLowerCase() === 'usd') {
        return 'USD';
    }

    return itemString?.charAt(0).toUpperCase() + itemString?.slice(1);
}

export function showCurrency(number) {
    if (Number.isNaN(number)) {
        return 0;
    }
    return number?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function showValueWithUnit(value, unit) {
    const map = {
        month: { s: 'Month', p: 'Months' },
        year: { s: 'Year', p: 'Years' },
        pcs: { s: 'Pcs', p: 'Pcs' },
        set: { s: 'Set', p: 'Sets' },
        emails: { s: 'Emails', p: 'Emails' },
        sms: { s: 'SMS', p: 'SMS' },
        package: { s: 'Package', p: 'Packages' },
        usd: { s: 'USD', p: 'USD' },
        noUnit: { s: '', p: '' }
    };

    if (value && map[unit]) {
        if (value > 1) {
            if (value >= 12 && unit === 'month') {
                const yearValue = (value / 12) % 1 === 0 ? value / 12 : (value / 12)?.toFixed(2);
                return (
                    <span>
                        <b>{yearValue || 0}</b> &nbsp;
                        {yearValue > 1 ? map.year.p : map.year.s}
                    </span>
                );
            }
            return (
                <span>
                    <b>{value || 0}</b> &nbsp;
                    {map[unit].p}
                </span>
            );
        }
        return (
            <span>
                <b>{value || 0}</b> &nbsp;
                {map[unit].s}
            </span>
        );
    }
    return (
        <span>
            <b>{value || 0}</b> &nbsp;
            {unit}
        </span>
    );
}

export function formatSingleDigit(i) {
    let item = i;
    item = item ? item?.toString() : null;

    return item && item?.length !== 1 ? item : '0'.concat(item) || '--';
}

// format an object into array of objet, by taking its key and value. should send keyName and valueName
export function transformObjToArr(obj, keyName, valueName) {
    const numPairs = Object.keys(obj).length / 2;
    const result = [];

    for (let i = 1; i <= numPairs; i += 1) {
        const objKey = `${keyName}${i}`;
        const objVal = `${valueName}${i}`;

        if (obj[objKey] && obj[objVal]) {
            const key = parseInt(obj[objKey], 10);
            const val = parseInt(obj[objVal], 10);

            if (!Number.isNaN(key) && !Number.isNaN(val)) {
                result.push({ [keyName]: key, [valueName]: val });
            }
        }
    }

    return result;
}

export const getFileIcon = (file) => {
    if (!file) {
        return null;
    }
    if (file?.type?.includes('image')) {
        return URL.createObjectURL(file);
    }
    if (file?.type?.includes('pdf')) {
        return icons.pdf;
    }
    if (file?.type?.includes('plain')) {
        return icons.txt;
    }
    if (file?.type?.includes('json')) {
        return icons.json;
    }
    if (file?.type?.includes('zip')) {
        return icons.zip;
    }
    if (file?.type?.includes('video')) {
        return icons.video;
    }
    if (file?.type?.includes('audio')) {
        return icons.audio;
    }
    if (file?.type?.includes('javascript') || file?.name?.includes('jsx')) {
        return icons.js;
    }
    if (file?.type?.includes('css')) {
        return icons.css;
    }
    if (
        file?.type?.includes('msword') ||
        file?.type?.includes('wordprocessingml') ||
        file?.type?.includes('document')
    ) {
        return icons.doc;
    }

    return icons.file;
};

export default function Show({ show = true, children }) {
    if (!show) {
        return null;
    }
    return children;
}
