export const customersDemoData = [
    {
        sl: 10001868,
        customerId: '712083c7c5e5027e096ccebdc66d5f2b',
        customerName: 'Facebook user',
        customerEmail: 'email@bikiran.com',
        customerPhone: '4564564154156',
        profilePhoto: '',
        addressFull: 'wqafd qw rdeqwer fdqwe3r few3r fwe rfwe',
        timeUpdated: 1693042815,
        status: 'active',
        demo: true
    },
    {
        sl: 10001869,
        customerId: 'f4b5de17eb7fadadbb67d91c0947d1c5',
        customerName: 'Meghla Adhikary',
        customerEmail: 'email@bikiran.com',
        customerPhone: '4564564154156',
        profilePhoto: '',
        addressFull: 'wqafd qw rdeqwer fdqwe3r few3r fwe rfwe',
        timeUpdated: 1693042815,
        status: 'active',
        demo: true
    },
    {
        sl: 10001870,
        customerId: 'f7ee94982a0d8c470922426b79aa2bdf',
        customerName: 'Md Hannan',
        customerEmail: 'email@bikiran.com',
        customerPhone: '4564564154156',
        profilePhoto: '',
        addressFull: 'wqafd qw rdeqwer fdqwe3r few3r fwe rfwe',
        timeUpdated: 1693042815,
        status: 'active',
        demo: true
    }
];

export default null;
