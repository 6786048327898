import { createContext, useContext, useMemo, useState } from 'react';
import { mkKey } from '../../../utils/String';

// Context creation
const BmTeamsPageContext = createContext();

// Custom hook to use the context
export const useTeams = () => useContext(BmTeamsPageContext);

const initialMode = {
    type: '',
    data: null
};

function BmTeamPageProvider({ children }) {
    const [teamsData, setTeamsData] = useState(null);
    const [mode, setMode] = useState(initialMode);
    const [reloadKey, setReloadKey] = useState(mkKey());

    // Create a memoized value object that holds various functions
    const value = useMemo(() => {
        // Function to trigger reloading data
        const handleReload = () => {
            setReloadKey((d) => d + 1);
            setTeamsData(null);
        };

        // Function to open modal
        const handleOpenModal = (type, data) => {
            setMode({
                type,
                data
            });
        };

        // Function to close modal
        const handleCloseModal = () => {
            setMode(initialMode);
        };

        // Return an object with all the values and functions
        return {
            setTeamsData,
            teamsData,
            reloadKey,
            handleReload,
            handleOpenModal,
            handleCloseModal,
            mode
        };
    }, [mode, reloadKey, teamsData]);

    // Provide the value through the context
    return <BmTeamsPageContext.Provider value={value}>{children}</BmTeamsPageContext.Provider>;
}

export default BmTeamPageProvider;
