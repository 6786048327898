// className and its output:
// 'rounded' will set border-radius 30px
// 'fill-x' will set width 100%
// 'fill-y' will set height 100%

import { icons } from './Icons';

export function ButtonGreen({ evOnClick, title, disabled, className }) {
    return (
        <button
            onClick={evOnClick}
            type="button"
            className={`button button-solid_green ${className}`}
            disabled={disabled}
        >
            {title}
        </button>
    );
}

export function ButtonGreenLine({ evOnClick, title, disabled, className }) {
    return (
        <button
            onClick={evOnClick}
            type="button"
            className={`button button-line_green ${className}`}
            disabled={disabled}
        >
            {title}
        </button>
    );
}

export function ButtonPurple({ evOnClick, title, disabled, className }) {
    if (disabled) {
        return (
            <button
                onClick={null}
                type="button"
                className="button button-solid_green disabled-view"
                disabled
            >
                {title}
            </button>
        );
    }
    return (
        <button
            onClick={evOnClick}
            type="button"
            className={`button button-solid_purple ${className}`}
            disabled={disabled}
        >
            {title}
        </button>
    );
}

export function ButtonPurpleLine({ evOnClick, title, disabled, className }) {
    return (
        <button
            onClick={evOnClick}
            type="button"
            className={`button button-line_purple ${className}`}
            disabled={disabled}
        >
            {title}
        </button>
    );
}

export function ButtonGray({ evOnClick, title, disabled, className }) {
    return (
        <button
            onClick={evOnClick}
            type="button"
            className={`button button-solid_gray ${className}`}
            disabled={disabled}
        >
            {title}
        </button>
    );
}

export function ButtonSky({ evOnClick, title, disabled, className }) {
    return (
        <button
            onClick={evOnClick}
            type="button"
            className={`button button-solid_sky ${className}`}
            disabled={disabled}
        >
            {title}
        </button>
    );
}

export function ButtonSkyLine({ evOnClick, title, disabled, className }) {
    return (
        <button
            onClick={evOnClick}
            type="button"
            className={`button button-line_sky ${className}`}
            disabled={disabled}
        >
            {title}
        </button>
    );
}

export function ButtonOrange({ evOnClick, title, disabled, className }) {
    return (
        <button
            onClick={evOnClick}
            type="button"
            className={`button button-solid_orange ${className}`}
            disabled={disabled}
        >
            {title}
        </button>
    );
}

export function ButtonOrangeLine({ evOnClick, title, disabled, className }) {
    return (
        <button
            onClick={evOnClick}
            type="button"
            className={`button button-line_orange ${className}`}
            disabled={disabled}
        >
            {title}
        </button>
    );
}

export function ButtonRed({ evOnClick, title, disabled, className }) {
    return (
        <button
            onClick={evOnClick}
            type="button"
            className={`button button-solid_red ${className}`}
            disabled={disabled}
        >
            {title}
        </button>
    );
}

export function ButtonRedLine({ evOnClick, title, disabled, className }) {
    return (
        <button
            onClick={evOnClick}
            type="button"
            className={`button button-line_red ${className}`}
            disabled={disabled}
        >
            {title}
        </button>
    );
}

export function ButtonPinkLine({ evOnClick, title, disabled, className }) {
    return (
        <button
            onClick={evOnClick}
            type="button"
            className={`button button-line_pink ${className}`}
            disabled={disabled}
        >
            {title}
        </button>
    );
}

export function ButtonMenu({ children, evOnClick, disabled, className }) {
    return (
        <div className="button-menu-cont">
            <button
                onClick={evOnClick}
                type="button"
                className={`button button-menu ${className}`}
                disabled={disabled}
            >
                <img src={icons?.optionFill} alt="" />
            </button>
            {children}
        </div>
    );
}

export function ButtonReload({ evOnClick, disabled, className = '' }) {
    return (
        <div className={`button button-reload ${disabled ? 'disabled-view' : ''} ${className}`}>
            <button onClick={evOnClick} type="button" className="p-relative">
                <img src={icons.reloadLine} alt="" />
                <img src={icons.reloadFill} alt="" />
            </button>
        </div>
    );
}

function Buttons() {
    return null;
}

export default Buttons;
