import { useState } from 'react';
import { ButtonGray, ButtonGreen } from '../../../../../configs/Buttons';
import {
    designationDays,
    designationDurationType,
    designationGrades
} from '../../../../../data/designationDemoData';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import LibModal from '../../../../lib/LibModal';
import AnimateInputField, {
    AnimateNumberField,
    AnimateSelectField,
    AnimateTimeField,
    addOption
} from '../../../../utils/AnimateInputField';
import { handleUpdateDesignation } from '../BmConfigDesignationOperation';

const statusArr = [
    {
        id: 'active',
        title: 'Active'
    },
    {
        id: 'inactive',
        title: 'Inactive'
    }
];

function BmConfigDesignationUpdateModal({ handleCloseModal, handleReload, projectId, modalData }) {
    const duration = modalData?.salaryDisburse?.split(':')?.[0] || '';
    const day = modalData?.salaryDisburse?.split(':')?.[1] || '';

    const [formData, setFormData] = useState({
        ...modalData,
        duration,
        day
    });

    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    const handleChange = (ev) => {
        const { name } = ev.target;
        if (name) {
            setFormData((d) => ({ ...d, [name]: ev.target.value }));
        }
    };

    const handleSave = () => {
        setMessage('Updating...');

        const data = { ...formData, salaryDisburse: `${duration}:${day}` };
        const designationId = modalData?.id;

        handleUpdateDesignation(authInfo, data, designationId, projectId)
            .then(({ message }) => {
                setMessage(message);
                handleCloseModal();
                handleReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    const daysArr = formData.duration === 'week' ? designationDays.week : designationDays.month;

    return (
        <div className="designation-action-modal">
            <LibModal title="Update Designation" setOpen={handleCloseModal}>
                <div className="modal-body-cont">
                    <div>
                        <div className="input-row">
                            <AnimateInputField
                                title="Designation"
                                name="designation"
                                formData={formData}
                                handelChange={handleChange}
                            />
                        </div>

                        <div className="flex-row flex-wrap align-space-between input-row">
                            <div className="inp-fill-2">
                                <AnimateNumberField
                                    title="Minimum Salary"
                                    name="salaryMin"
                                    formData={formData}
                                    handelChange={handleChange}
                                />
                            </div>

                            <div className="inp-fill-2">
                                <AnimateNumberField
                                    title="Maximum Salary"
                                    name="salaryMax"
                                    formData={formData}
                                    handelChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="input-row">
                            <AnimateSelectField
                                title="Select Grade"
                                name="grade"
                                handelChange={handleChange}
                                formData={formData}
                                options={designationGrades?.map((item) =>
                                    addOption(item.id, item.title, item.id)
                                )}
                            />
                        </div>

                        <div className="separator-cont flex-row position-middle">
                            <span className="separator-title">Salary Disbursement</span>
                            <hr className="separator" />
                        </div>

                        <div className="flex-row flex-wrap align-space-between input-row">
                            <div className="inp-fill-2">
                                <AnimateSelectField
                                    title="Duration Type"
                                    name="duration"
                                    formData={formData}
                                    handelChange={handleChange}
                                    options={designationDurationType.map((item) =>
                                        addOption(item.id, item.name, item.id)
                                    )}
                                />
                            </div>

                            <div className="inp-fill-2">
                                <AnimateSelectField
                                    title="Day"
                                    name="day"
                                    formData={formData}
                                    handelChange={handleChange}
                                    options={daysArr
                                        ?.filter((el) => el.id !== -1 && el.id !== -2)
                                        ?.map((item) => addOption(item.id, item.label, item.id))}
                                />
                            </div>
                        </div>

                        <div className="separator-cont flex-row position-middle">
                            <span className="separator-title">Weekly Holiday</span>
                            <hr className="separator" />
                        </div>
                        <div className="flex-row flex-wrap align-space-between input-row">
                            <div className="inp-fill-2">
                                <AnimateSelectField
                                    title="Weekly Holiday 1"
                                    name="weeklyHoliday1"
                                    formData={formData}
                                    handelChange={handleChange}
                                    options={designationDays.week
                                        .filter((el) => el.id !== -1)
                                        .map((item) => addOption(item.id, item.label, item.id))}
                                />
                            </div>

                            <div className="inp-fill-2">
                                <AnimateSelectField
                                    title="Weekly Holiday 2"
                                    name="weeklyHoliday2"
                                    formData={formData}
                                    handelChange={handleChange}
                                    options={designationDays.week.map((item) =>
                                        addOption(item.id, item.label, item.id)
                                    )}
                                />
                            </div>
                        </div>

                        <div className="separator-cont flex-row position-middle">
                            <span className="separator-title">Office Hour</span>
                            <hr className="separator" />
                        </div>
                        <div className="flex-row flex-wrap align-space-between input-row">
                            <div className="inp-fill-2">
                                <AnimateTimeField
                                    title="Reporting Time"
                                    name="reportingTime"
                                    formData={formData}
                                    handelChange={handleChange}
                                />
                            </div>

                            <div className="inp-fill-2">
                                <AnimateInputField
                                    title="Working Hour"
                                    name="workingHour"
                                    formData={formData}
                                    handelChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="input-row">
                            <AnimateSelectField
                                title="Select Status"
                                name="status"
                                formData={formData}
                                handelChange={handleChange}
                                options={statusArr.map((item) =>
                                    addOption(item.id, item.title, item.id)
                                )}
                            />
                        </div>
                    </div>
                    <div className="modal-action flex-row align-right">
                        <div className="flex-row position-middle">
                            <ButtonGray title="Cancel" evOnClick={handleCloseModal} />
                            <ButtonGreen title="Update" evOnClick={handleSave} />
                        </div>
                    </div>
                </div>
            </LibModal>
        </div>
    );
}

export default BmConfigDesignationUpdateModal;
