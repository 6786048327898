import { useLocation, useNavigate } from 'react-router-dom';
import { mkRelativeUrl } from '../../../features/menus/AdvMenuProvider';

const tabArray = [
    {
        name: 'pageinfo',
        label: 'Page Info'
    },
    {
        name: 'branches',
        label: 'Branches'
    },
    {
        name: 'designation',
        label: 'Designation'
    },
    {
        name: 'categories',
        label: 'Expense Categories'
    }
];

function BmConfigurationPageHeader() {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const handleTabClick = (tab = 'pageinfo') => {
        navigate(mkRelativeUrl(`/business-manager/configuration/${tab}`));
    };

    const isTabActive = (tab = 'pageinfo') => pathname?.split('/').includes(tab);

    return (
        <div className="page-header-cont flex-row position-middle align-space-between">
            <div>
                <h2 className="page-header_title">Configuration</h2>
            </div>

            <ul className="flex-row position-middle">
                {tabArray.map((tab) => (
                    <li className={isTabActive(tab.name) ? 'active' : ''} key={tab.name}>
                        <span onClick={() => handleTabClick(tab.name)} role="button" tabIndex={-1}>
                            {tab.label}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default BmConfigurationPageHeader;
