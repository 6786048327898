import { useRef, useState } from 'react';
import { icons } from '../../../../../configs/Icons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import InfoEditorConfirm from '../../../../features/info-editor/InfoEditorConfirm';
import InfoEditorTextarea from '../../../../features/info-editor/InfoEditorTextarea';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import { handleDeleteNote, handleUpdateNote } from '../BmCustomerOverviewOperation';

function ItemHeader({ editButtonRef, deleteButtonRef, editClick, deleteClick }) {
    return (
        <div className="flex-row position-middle align-space-between">
            <div className="flex-row position-middle">
                <div
                    className="note-author"
                    data-tooltip-id="myTooltip"
                    data-tooltip-content="Author"
                >
                    <img src={icons.avatar} alt="avatar" />
                </div>

                <div
                    ref={editButtonRef}
                    className="btn-edit"
                    role="button"
                    tabIndex={-1}
                    onClick={editClick}
                >
                    Edit
                </div>

                <div
                    ref={deleteButtonRef}
                    className="btn-delete"
                    role="button"
                    tabIndex={-1}
                    onClick={deleteClick}
                >
                    Delete
                </div>
            </div>
            <div className="note-date">12/12/2020</div>
        </div>
    );
}

function NoteText({ showMoreClick, textExpand, text }) {
    return (
        <div
            role="button"
            tabIndex={-1}
            onClick={showMoreClick}
            className={`note-text ${textExpand ? 'expand' : 'minimized'}`}
        >
            {text || '--'}
        </div>
    );
}

function OverviewSecNotesListItem({ mode, note, openEditor, closeEditor, formData, setFormData }) {
    const [textExpand, setTextExpand] = useState(0);

    const editButtonRef = useRef();
    const deleteButtonRef = useRef();

    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    const handelShowMore = () => {
        if (textExpand === note.id) {
            setTextExpand(0);
        } else {
            setTextExpand(note.id);
        }
    };

    const handelEditClick = () => {
        // openEditor('note-edit', 'note', note.text, note.id);
        openEditor({ type: 'note-edit', index: note.id, name: 'note', value: note.text });
        setTextExpand(0);
    };

    const handelDeleteClick = () => {
        setMessage('Deleting address....');
        handleDeleteNote(authInfo, note.id)
            .then(({ message }) => {
                setMessage(message);
                closeEditor();
                // handelReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    const handelSaveClick = () => {
        setMessage('Updating address....');
        handleUpdateNote(authInfo, formData?.note)
            .then(({ message }) => {
                setMessage(message);
                closeEditor();
                // handelReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <li key={note.id}>
            <ItemHeader
                editButtonRef={editButtonRef}
                deleteButtonRef={deleteButtonRef}
                editClick={handelEditClick}
                deleteClick={() => openEditor({ type: 'note-delete', index: note.id })}
            />

            <NoteText
                showMoreClick={handelShowMore}
                textExpand={textExpand === note.id}
                text={note.text}
            />

            <InfoEditorTextarea
                positionRef={editButtonRef}
                show={mode.type === 'note-edit' && mode.index === note.id}
                setShow={closeEditor}
                name="note"
                formData={formData}
                setFormData={setFormData}
                saveClick={handelSaveClick}
            />

            <InfoEditorConfirm
                show={mode.type === 'note-delete' && mode.index === note.id}
                setShow={closeEditor}
                positionRef={deleteButtonRef}
                text="Are you sure you want to delete this note ?"
                confirmClick={handelDeleteClick}
            />
        </li>
    );
}

export default OverviewSecNotesListItem;
