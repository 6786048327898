import { createContext, useContext, useMemo, useState } from 'react';
import { overviewDemoData } from '../../../../data/overviewDemoData';
import CreateVisitModal from './__modals/CreateVisitModal';
import UpdateBranchModal from './__modals/UpdateBranchModal';
import AddAssetsModal from './__modals/add-asset/AddAssetsModal';
import AddMediaModal from './__modals/add-media/AddMediaModal';
import CreateOrderModal from './__modals/order-create/CreateOrderModal';

const BmCustomerOverviewContext = createContext();

export const useCustomerOverview = () => useContext(BmCustomerOverviewContext);

function BmCustomerOverviewProvider({ children }) {
    const [overviewData, setOverviewData] = useState(overviewDemoData);
    // state to store the attachment files
    const [attachmentFiles, setAttachmentFiles] = useState([]);
    const [responseData, setResponseData] = useState('');
    const [modal, setModal] = useState({ type: '', data: null });

    const [formData, setFormData] = useState({});

    const modalType = modal.type || '';
    const modalData = modal.data || null;

    // Function to open a modal
    const handleOpenModal = (type, data) => {
        setModal({
            type,
            data
        });
    };

    // Function to close a modal
    const handleCloseModal = () => {
        setModal({
            type: '',
            data: null
        });
    };

    const value = useMemo(() => {
        const handelChange = (ev) => {
            const { name } = ev.target;
            if (name) {
                setFormData((d) => ({ ...d, [name]: ev.target.value }));
            }
        };

        const setValue = (name, val) => {
            if (!name && !val) {
                setFormData({});
            }
            if (name && val) {
                setFormData({ [name]: val });
            }
        };

        const handleResponse = (data) => {
            setResponseData(data);
        };

        // Function to handle input[type='file'] change. to pick file file explorer
        const handleFileInputChange = (event) => {
            // Get the files from the event
            const { files } = event.target;

            // Check if the file is already selected
            const isAlreadySelected = attachmentFiles?.find(
                (i) =>
                    i?.lastModified?.toString() ===
                    files?.[files?.length - 1]?.lastModified?.toString()
            );

            // Check if the files are not empty and the file is not already selected
            if (files?.length > 0 && !isAlreadySelected) {
                // Convert the files to an array and append to the existing files
                setAttachmentFiles((prevFiles) => [...prevFiles, ...Array.from(files)]);
            }
        };

        // Function to remove an attachment item
        const handleRemoveAttachmentItem = (ev, fileToRemove) => {
            ev.stopPropagation();
            setAttachmentFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
        };

        return {
            handleOpenModal,
            handleCloseModal,
            modalType,
            modalData,
            setFormData,
            formData,
            setValue,
            handelChange,
            overviewData,
            setOverviewData,
            handleResponse,
            responseData,
            attachmentFiles,
            handleFileInputChange,
            handleRemoveAttachmentItem
        };
    }, [formData, modalData, modalType, overviewData, responseData, attachmentFiles]);

    return (
        <BmCustomerOverviewContext.Provider value={value}>
            {children}

            {/* <Modals /> */}
            {modalType === 'add-asset' ? (
                <AddAssetsModal modalData={modalData} handleCloseModal={handleCloseModal} />
            ) : null}

            {modalType === 'add-media' ? (
                <AddMediaModal modalData={modalData} handleCloseModal={handleCloseModal} />
            ) : null}

            {modalType === 'create-order' ? (
                <CreateOrderModal modalData={modalData} handleCloseModal={handleCloseModal} />
            ) : null}

            {modalType === 'update-branch' ? (
                <UpdateBranchModal modalData={modalData} handleCloseModal={handleCloseModal} />
            ) : null}

            {modalType === 'create-visit' ? (
                <CreateVisitModal
                    modalData={modalData}
                    handleCloseModal={handleCloseModal}
                    customerInfo={overviewData?.customerInfo}
                />
            ) : null}
        </BmCustomerOverviewContext.Provider>
    );
}
export default BmCustomerOverviewProvider;
