import React from 'react';
import { GetTime } from '../../../../utils/Show';
import OverviewSecSenderMenu from './OverviewSecSenderMenu';

function OverviewSecThreadSender({ data, show, showThreadMenu }) {
    return (
        <div className={`con-thread flex-col align-right ${show ? 'active' : ''}`}>
            <div>
                <p className="con-time">{GetTime(data?.time)}</p>
                <p className="con-text">
                    <span>{data?.text}</span>
                </p>

                <OverviewSecSenderMenu data={data} showThreadMenu={showThreadMenu} show={show} />
            </div>
        </div>
    );
}

export default OverviewSecThreadSender;
