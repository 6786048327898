import InvConfigPageHeader from './InvConfigPageHeader';
import InvConfigurationProvider from './InvConfigurationProvider';
import ConfigCurrencySection from './components/ConfigCurrencySection';
import ConfigInvoiceSection from './components/ConfigInvoiceSection';
import ConfigTypeManageSection from './components/ConfigTypeManageSection';

function InvConfigurationPage() {
    return (
        <InvConfigurationProvider>
            <div className="page-cont inv-config-page-cont">
                <InvConfigPageHeader />

                <ConfigCurrencySection />

                <ConfigInvoiceSection />

                <ConfigTypeManageSection />
            </div>
        </InvConfigurationProvider>
    );
}

export default InvConfigurationPage;
