import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonPurple, ButtonSky } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';
import { mkRelativeUrl } from '../../../../features/menus/AdvMenuProvider';
import LibModal from '../../../../lib/LibModal';
import { useInvoiceUpdate } from '../InvoiceUpdateProvider';
import FinInvoiceDuplicate from './InvoiceDuplicate';
import FinInvoicePrintOptions from './InvoicePrintOptions';

function ButtonDuplicate({ invoiceId }) {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        console.log('clicked!!!');
        setOpen(true);
    };

    return (
        <>
            <ButtonSky evOnClick={handleClick} title="Duplicate" className="fill-y" />

            {open && (
                <LibModal title="Duplicate Invoice" setOpen={setOpen}>
                    <FinInvoiceDuplicate setOpen={setOpen} invoiceId={invoiceId} />
                </LibModal>
            )}
        </>
    );
}

function InvoiceSearchField({ searchRef, inputRef, handleFocus, handleBlur, handleKeyPress }) {
    return (
        <div
            ref={searchRef}
            className="invoice-search-box w-100p flex-row align-space-between position-middle"
        >
            <input
                className="fill"
                type="text"
                name="id"
                autoComplete="off"
                ref={inputRef}
                maxLength={8}
                placeholder="Invoice Number"
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKeyPress={handleKeyPress}
            />

            <span className="invoice-search-icon">
                <img
                    src="https://files.bikiran.com/assets/images/Search-icon-black.svg"
                    alt="Search"
                />
            </span>
        </div>
    );
}

function InvoicePageHeader({ invoiceId }) {
    const searchRef = useRef();
    const inputRef = useRef();
    const navigate = useNavigate();

    const [showMenu, setShowMenu] = useState(false);
    const { handelReload } = useInvoiceUpdate() || { handelReload: null };

    useEffect(() => {
        inputRef.current.onchange = (ev) => {
            ev.preventDefault();
            const { value } = ev.target;

            //--
            // if (!(value.length === 8 && /^\d*$/.test(value))) {
            //     classList.add('danger');
            // } else {
            //     classList.remove('danger');
            // }

            if (value.length === 8 && /^\d*$/.test(value)) {
                navigate(mkRelativeUrl(`/finance/sell-invoice/${ev.target.value}/update/`));
            }
        };

        inputRef.current.value = invoiceId;
    }, [invoiceId, navigate]);

    const handleFocus = () => {
        searchRef.current.style.borderColor = 'rgba(245, 3, 3, 0.5)';
    };

    const handleBlur = () => {
        searchRef.current.style.borderColor = 'rgb(28, 38, 73, 0.2)';
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            searchRef.current.style.borderColor = 'rgb(28, 38, 73, 0.2)';
        }
    };

    return (
        <div className="page-header-cont flex-row align-space-between position-middle">
            <div className="flex-row position-middle gap-50">
                <div>
                    <h2 className="page-header_title">Update Invoice:</h2>
                </div>

                <InvoiceSearchField
                    searchRef={searchRef}
                    inputRef={inputRef}
                    handleBlur={handleBlur}
                    handleFocus={handleFocus}
                    handleKeyPress={handleKeyPress}
                />
            </div>

            <div>
                <div className="flex-row position-stretch gap-10">
                    <div className="reload-button">
                        <button type="button" className="p-relative" onClick={handelReload}>
                            <img src={icons.reloadLine} alt="" />
                            <img src={icons.reloadFill} alt="" />
                        </button>
                    </div>

                    <div>
                        <ButtonDuplicate invoiceId={invoiceId} />
                    </div>

                    <div className="p-relative">
                        <ButtonPurple
                            evOnClick={() => setShowMenu(true)}
                            title="Print Invoice"
                            className="fill-y"
                        />
                        <FinInvoicePrintOptions showMenu={showMenu} setShowMenu={setShowMenu} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InvoicePageHeader;
