import ConLeftColChat from '../con_leftcol_chat/ConLeftColChat';
import ConLeftColConversation from '../con_leftcol_conversation/ConLeftColConversation';

function ConLeftCol() {
    return (
        <div className="con_left-col flex-row align-space-between">
            <div>
                <ConLeftColChat />
            </div>
            <div>
                <ConLeftColConversation />
            </div>
        </div>
    );
}

export default ConLeftCol;
