import { Link } from 'react-router-dom';
import { ButtonGray, ButtonGreen, ButtonPurple } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';
import { syncDemoData } from '../../../../../data/syncDemoData';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import { UserInfoV3 } from '../../../../features/user-info/UserInfo';
import { GetDate, GetTime } from '../../../../utils/Show';
import { mkKey } from '../../../../utils/String';
import { useBmConfig } from '../BmConfigProvider';
import {
    handleSyncConvData,
    handleSyncDataReload,
    handleSyncNextData,
    handleSyncPrevData
} from '../BmPageinfoOperation';
import ConvSyncStartButton from './ConvSyncStartButton';

function TableHeadContent({ handleDataReloadClick, disabled }) {
    return (
        <div className="flex-row position-middle align-space-between section-header">
            <h3 className="section-title">Page Data:</h3>
            <div className={`reload-button ${disabled ? 'disabled' : ''}`}>
                <button type="button" className="p-relative button" onClick={handleDataReloadClick}>
                    <img src={icons.reloadLine} alt="" />
                    <img src={icons.reloadFill} alt="" />
                </button>
            </div>
        </div>
    );
}

function TableFooter({ disabled, projectId, handleSyncNextClick, handleSyncPrevClick }) {
    return (
        <tr className="table-data-action">
            <td colSpan={7}>
                <div className="flex-row align-space-between position-middle">
                    <ConvSyncStartButton projectId={projectId} disabled={disabled} />
                    <div className="flex-row align-right position-middle right-col">
                        <ButtonGray
                            disabled={disabled}
                            evOnClick={handleSyncPrevClick}
                            title="Previous"
                        />
                        <ButtonPurple
                            disabled={disabled}
                            evOnClick={handleSyncNextClick}
                            title="Next"
                        />
                    </div>
                </div>
            </td>
        </tr>
    );
}

function Tr({ syncData, syncInfo, handleSyncConvClick, pageNumber }) {
    return syncData?.map((item, index, array) => {
        const itemsPerPage = array?.length;
        const startingIndex = pageNumber * itemsPerPage;
        const displayIndex = startingIndex + index + 1;

        const isSynced =
            syncInfo?.find((d) => d?.conId === item?.fbConversationId) ||
            item?.count === item?.counted;
        return (
            <tr key={`${mkKey()}`} className={item?.empty ? 'placeholder-mode' : ''}>
                <td className="text-left">
                    <span>{displayIndex || item?.id}</span>
                </td>

                <td className="text-left">
                    <UserInfoV3 img={icons.avatar} name={item.senderName} email={item.senderId} />
                </td>

                <td className="text-left">
                    <Link
                        to={`/business-manager/customers/${item?.senderId}/overview`}
                        className="snippet-text"
                    >
                        {item.snippet}
                    </Link>
                </td>

                <td className="text-left">
                    <span>{item.fbConversationId}</span>
                </td>

                <td className="text-center">
                    <span>{item.count}</span>
                </td>

                <td className="text-left">
                    <span>{GetDate(item.updatedTime)}</span>
                    <br />
                    <span>{GetTime(item.updatedTime)}</span>
                </td>

                <td className="text-right">
                    <span>
                        {isSynced ? (
                            <div className="flex-row align-right">
                                <img src={icons.tickIcon} alt="" />
                            </div>
                        ) : (
                            <ButtonGreen
                                evOnClick={() => handleSyncConvClick(item.fbConversationId)}
                                title="Sync"
                            />
                        )}
                    </span>
                </td>
            </tr>
        );
    });
}

function BmConfigPageInfoSyncTable({ projectId }) {
    const {
        pageInfo,
        syncData,
        setSyncData,
        handleSyncData,
        currentToken,
        // prevToken,
        before,
        after,
        disabled
    } = useBmConfig();

    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();

    // Function to handle loading next set of synced data
    const handleSyncNextClick = () => {
        // Display loading message and disable sync button
        setMessage('Loading...');
        setSyncData((cuData) => ({ ...cuData, disabled: true }));
        // Fetch next data using handleSyncNextData
        handleSyncNextData(authInfo, projectId, after)
            .then(({ data, newPageNumber }) => {
                // loading message showing stop
                setMessage('');

                handleSyncData({
                    ...data,
                    newPageNumber,
                    currentToken: after,
                    disabled: false
                });
            })
            .catch((err) => {
                // Display error message and enable sync button
                setMessage(err.toString(0));
                setSyncData((cuData) => ({ ...cuData, disabled: false }));
            });
    };

    // Function to handle loading previous set of synced data
    const handleSyncPrevClick = () => {
        // loading message showing start
        setMessage('Loading...');

        // disable sync button
        setSyncData((cuData) => ({ ...cuData, disabled: true }));
        // Fetch previous data using handleSyncPrevData
        handleSyncPrevData(authInfo, projectId, before)
            .then(({ data, newPageNumber }) => {
                // loading message showing stop
                setMessage('');
                // Update syncData and pageInfo with previous data
                handleSyncData({
                    ...data,
                    newPageNumber,
                    prevToken: before,
                    disabled: false
                });
            })
            .catch((err) => {
                // Display error message and enable sync button
                setMessage(err.toString(0));
                setSyncData((cuData) => ({ ...cuData, disabled: false }));
            });
    };

    // Function to reload data
    const handleDataReloadClick = () => {
        // Reset syncData with empty demo data to indicate loading state
        setSyncData({ data: syncDemoData, empty: true });

        // Reload data using handleSyncDataReload
        handleSyncDataReload(authInfo, projectId, { after: currentToken })
            .then(({ data }) => {
                handleSyncData({ ...data });
            })
            .catch((err) => {
                // Display error message
                setMessage(err.toString(0));
            });
    };

    // Function to handle syncing conversation data
    const handleSyncConvClick = (conId) => {
        // Sync conversation data using handleSyncConvData
        handleSyncConvData(authInfo, projectId, conId)
            .then(({ message, counted }) => {
                // Update syncData with conversation data
                setMessage(message);

                setSyncData((cuData) => {
                    const prevData = { ...cuData };
                    const prevSyncInfoData = prevData?.syncInfo
                        ? prevData.syncInfo
                        : { counted, conId };
                    return {
                        ...cuData,
                        syncInfo: [...prevSyncInfoData, { counted, conId }],

                        currentToken: syncData.currentToken,
                        empty: false
                    };
                });
            })
            .catch((err) => {
                // Display error message
                setMessage(err.toString(0));
            });
    };

    if (syncData !== undefined && !syncData?.data?.length) {
        return null;
    }

    return (
        <div className="page-info-sync-table">
            <TableHeadContent handleDataReloadClick={handleDataReloadClick} disabled={disabled} />
            <div className="bik-table-parent">
                <table className="bik-table">
                    <thead>
                        <tr>
                            <th width="5%" className="text-left">
                                SL
                            </th>
                            <th className="text-left">Customer</th>
                            <th className="text-left">Snippet</th>
                            <th width="15%" className="text-left">
                                Conversation ID
                            </th>
                            <th width="7%" className="text-center">
                                Count
                            </th>
                            <th width="13%" className="text-left">
                                Updated Time
                            </th>
                            <th width="8%" className="text-right">
                                Sync
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <Tr
                            syncData={syncData?.data || []}
                            pageNumber={pageInfo?.pageCount}
                            syncInfo={syncData?.syncInfo}
                            handleSyncConvClick={handleSyncConvClick}
                        />
                    </tbody>
                    <tfoot>
                        <TableFooter
                            projectId={projectId}
                            disabled={disabled}
                            handleSyncNextClick={handleSyncNextClick}
                            handleSyncPrevClick={handleSyncPrevClick}
                        />
                    </tfoot>
                </table>
            </div>
        </div>
    );
}

export default BmConfigPageInfoSyncTable;
