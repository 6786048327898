import { useState } from 'react';
import { ButtonGreen } from '../../../configs/Buttons';
import { icons } from '../../../configs/Icons';
import { AnimateNumberField, AnimateSelectField, addOption } from '../../utils/AnimateInputField';

const homeQuantity = [
    {
        id: 1
    },
    {
        id: 2
    },
    {
        id: 3
    },
    {
        id: 4
    },
    {
        id: 5
    },
    {
        id: 6
    },
    {
        id: 7
    },
    {
        id: 8
    }
];

function PvcAddRow({
    itemIndex,
    formData,
    cftValue,
    handleChange,
    handelCftValue,
    handleRemoveRow
}) {
    return (
        <div className="feature-asset-selection-row concrete flex-row position-middle align-space-between">
            <div>
                <AnimateNumberField
                    title="Length"
                    name={`length${itemIndex}`}
                    formData={cftValue}
                    handelChange={handelCftValue}
                    required
                />
            </div>
            <div>
                <AnimateNumberField
                    title="Width"
                    name={`width${itemIndex}`}
                    formData={cftValue}
                    handelChange={handelCftValue}
                    required
                />
            </div>
            <div>
                <AnimateNumberField
                    title="Height"
                    name={`height${itemIndex}`}
                    formData={cftValue}
                    handelChange={handelCftValue}
                    required
                />
            </div>

            <div className="flex-row position-middle align-center">
                <AnimateSelectField
                    title="Select Quantity"
                    name={`quantity${itemIndex}`}
                    formData={formData}
                    handelChange={handleChange}
                    options={homeQuantity?.map((item) => addOption(item.id, item.id, item.id))}
                />
            </div>

            <div className="">
                <div
                    onClick={() => handleRemoveRow(itemIndex)}
                    role="button"
                    tabIndex={-1}
                    className="flex-row position-middle align-right"
                >
                    <img src={icons.crossRed} alt="" />
                </div>
            </div>
        </div>
    );
}

function TotalPriceRow({ handleAddMore }) {
    return (
        <div className="feature-asset-selection-row flex-row position-middle align-space-between">
            <div className="flex-row position-middle align-space-between">
                <ButtonGreen
                    evOnClick={handleAddMore}
                    title="Add More"
                    className="feature-asset-add-more-btn"
                />
            </div>

            <div className="flex-row position-middle align-start" />

            <div className="" />
        </div>
    );
}

function AssetConcreteTank({ formData, setFormData }) {
    const [concreteRow, setConcreteRow] = useState([{ id: 1 }]);
    const [cftValue, setCftValue] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Update the form data
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));

        // Extract the itemIndex from the name (e.g., length1 => itemIndex = 1)
        const itemIndex = parseInt(name.match(/\d+/)[0], 10);

        // Calculate the size based on the provided length, width, and height
        let length = 0;
        let width = 0;
        let height = 0;
        if (
            cftValue?.[`length${itemIndex}`] &&
            cftValue?.[`width${itemIndex}`] &&
            cftValue?.[`height${itemIndex}`]
        ) {
            length = parseFloat(cftValue?.[`length${itemIndex}`]) || 0;
            width = parseFloat(cftValue?.[`width${itemIndex}`]) || 0;
            height = parseFloat(cftValue?.[`height${itemIndex}`]) || 0;
        }

        const size = length * width * height;

        // Update the formData with the calculated size
        setFormData((prevData) => ({
            ...prevData,
            [`size${itemIndex}`]: size
        }));
    };

    const handelCftValue = (e) => {
        const { name, value } = e.target;
        setCftValue((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleRemoveRow = (id) => {
        // remove except id 1
        if (id !== 1) {
            setConcreteRow((prevData) => prevData.filter((item) => item.id !== id));
            setFormData((prevData) => {
                const newData = { ...prevData };
                delete newData[`quantity${id}`];
                return newData;
            });
        }
    };

    const handleAddMore = () => {
        const lastRowFormData =
            formData[`size${concreteRow[concreteRow.length - 1].id}`] &&
            formData[`quantity${concreteRow[concreteRow.length - 1].id}`];
        if (!lastRowFormData) {
            return;
        }
        setConcreteRow((prevData) => {
            const newId = prevData.length > 0 ? prevData[prevData.length - 1].id + 1 : 1;
            return [...prevData, { id: newId }];
        });
    };

    return (
        <div className="">
            <div className="feature-asset-selection-cont">
                {concreteRow?.map((item) => (
                    <PvcAddRow
                        key={item?.id}
                        itemIndex={item?.id}
                        formData={formData}
                        cftValue={cftValue}
                        handleChange={handleChange}
                        handelCftValue={handelCftValue}
                        handleRemoveRow={handleRemoveRow}
                    />
                ))}
            </div>
            <TotalPriceRow handleAddMore={handleAddMore} />
        </div>
    );
}

export default AssetConcreteTank;
