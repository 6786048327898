import AxiosAPI from '../../../utils/AxiosAPI';

export function handleLoadCustomers(authInfo, projectId, page) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/bm/customer/list/`, { projectId, page })
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handleAddCustomer(authInfo, formData, projectId) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/bm/customer/add/`)
            .post({ ...formData, projectId })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export default null;
