import BmReportsPageHeader from './components/BmReportsPageHeader';

function BmReportsPage() {
    return (
        <div className="page-cont">
            <BmReportsPageHeader />
        </div>
    );
}

export default BmReportsPage;
