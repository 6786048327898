/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ButtonMenu } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { getProjectId, mkRelativeUrl } from '../../../../features/menus/AdvMenuProvider';
import NotFound from '../../../../features/not-found/NotFound';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import { UserInfoV3 } from '../../../../features/user-info/UserInfo';
import InstOption from '../../../../utils/InstOption';
import { GetDate, GetTime } from '../../../../utils/Show';
import { handleLoadCustomers } from '../BmCustomerOperation';
import { useBmCustomer } from '../BmCustomersProvider';

function Paginate({ paginate, pageNum, handleNavigate }) {
    const { pages } = paginate || { pages: [] };
    const pageCount = pages || [];

    const paginateInputRef = useRef();

    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState({
        mode: 0,
        value: ''
    });

    const setMode = (mode) => {
        setCurrentPage((cuData) => ({ ...cuData, mode }));
    };

    const setValue = (value) => {
        setCurrentPage((cuData) => ({ ...cuData, value }));
    };

    const isSearchField = currentPage?.mode;

    const handleCurrentPageNum = (item) => {
        handleNavigate(navigate, item);
    };

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            if (currentPage.value) {
                setMode(0);
                handleCurrentPageNum(currentPage.value);
            }
        }
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setValue(value);
    };

    useEffect(() => {
        if (isSearchField) {
            paginateInputRef.current?.focus();
            document.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    setMode(false);
                }
            });
        }
    }, [isSearchField]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (paginateInputRef.current && !paginateInputRef.current?.contains(event.target)) {
                setMode(0);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="bik-paginate right">
            <ul className="bik-paginate__item">
                {pageCount?.map((item, index) => {
                    if (item === '.') {
                        if (isSearchField === index) {
                            return (
                                <li key={item}>
                                    <input
                                        ref={paginateInputRef}
                                        type="text"
                                        className="form-input"
                                        maxLength={3}
                                        onChange={handleChange}
                                        onKeyDown={handleEnter}
                                    />
                                </li>
                            );
                        }
                        return (
                            <li onClick={() => setMode(index)} key={item}>
                                . . .
                            </li>
                        );
                    }
                    return (
                        <li
                            key={item}
                            onClick={() => handleCurrentPageNum(item)}
                            className={pageNum?.toString() === item?.toString() ? 'active' : ''}
                        >
                            {item}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

function Tr({ item, loading }) {
    const [showMenu, setShowMenu] = useState(false);

    const navigate = useNavigate();

    const handleCustomerOverview = (id) => {
        navigate(mkRelativeUrl(`/business-manager/customers/${id}/overview/`));
    };

    return (
        <tr
            className={item.demo || loading ? 'placeholder-mode' : ''}
            // ref={ref}
            // onClick={handleBgActive}
        >
            <td className="text-left">
                <span>{item?.sl}</span>
            </td>

            <td>
                <UserInfoV3
                    name={item?.customerName}
                    email={item?.customerEmail}
                    img={item?.profilePhoto || icons.avatar}
                />
            </td>

            <td className="text-left">
                <span>{item?.addressFull || '--'}</span>
            </td>
            <td className="text-center">
                <span>{item?.customerPhone || '--'}</span>
            </td>
            <td className="text-left">
                <span>{GetDate(item.timeUpdated)}</span>
                <br />
                <span>{GetTime(item.timeUpdated)}</span>
            </td>
            <td className="text-left">
                <span className={`status ${item?.status}`}>{item?.status}</span>
            </td>
            <td className="text-center">
                <div className="flex-row align-right">
                    <span>
                        <ButtonMenu evOnClick={() => setShowMenu(true)}>
                            <InstOption setShowMenu={setShowMenu} showMenu={showMenu}>
                                <button
                                    type="button"
                                    onClick={() => handleCustomerOverview(item?.sl)}
                                >
                                    Overview
                                </button>
                            </InstOption>
                        </ButtonMenu>
                    </span>
                </div>
            </td>
        </tr>
    );
}

function CustomersTable() {
    const { cusData, paginate, loading, setCustomersData, reloadKey, handleNavigate } =
        useBmCustomer();
    const { showing } = paginate || { showing: [] };

    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();
    const [searchParams] = useSearchParams();

    const pageNum = searchParams.get('page') || paginate?.page || 1;

    const projectId = getProjectId();

    useEffect(() => {
        setCustomersData((cuData) => ({ ...cuData, loading: true }));
        handleLoadCustomers(authInfo, projectId, pageNum)
            .then(({ data, pagination }) => {
                setCustomersData({
                    data,
                    pagination,
                    loading: false
                });
                // setMessage(message);
            })
            .catch((err) => {
                setCustomersData((cuData) => ({ ...cuData, loading: false }));
                setMessage(err.toString(0));
            });
    }, [authInfo, projectId, setCustomersData, setMessage, reloadKey, pageNum]);

    // useEffect(() => {

    //     handleNavigate(navigate, currentPage);
    // }, [currentPage, cusData, handleNavigate, navigate, page]);

    if (cusData && !cusData?.length) {
        return <NotFound title="No Customers Found" />;
    }

    return (
        <div className="bik-table-parent">
            <table className="bik-table">
                <thead>
                    <tr>
                        <th width="9%" className="text-left">
                            SL
                        </th>
                        <th width="23%" className="text-left">
                            Customer
                        </th>
                        <th width="25%" className="text-left">
                            Address
                        </th>
                        <th width="15%">Phone Number</th>
                        <th width="15%" className="text-left">
                            Last Update
                        </th>
                        <th width="9%" className="text-left">
                            {' '}
                            Status
                        </th>
                        <th width="9%" className="text-right">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {cusData?.map((item) => (
                        <Tr key={item?.sl} item={item} loading={loading} />
                    ))}
                </tbody>
            </table>
            <div className="flex-row align-space-between position-middle">
                <div>
                    <p className="data-length-text">
                        Showing <span>{showing?.join('-')}</span>
                    </p>
                </div>

                <Paginate paginate={paginate} pageNum={pageNum} handleNavigate={handleNavigate} />
            </div>
        </div>
    );
}

export default CustomersTable;
