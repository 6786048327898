import React from 'react';
import { ButtonGray, ButtonGreen } from '../../../../configs/Buttons';
import InstOption from '../../../utils/InstOption';
import { mkKey } from '../../../utils/String';
import { useAuth } from '../../auth/AuthProvider';
import { getProjectId } from '../../menus/AdvMenuProvider';
import { useTemplate } from '../../template/TemplateProvider';
import { handleCancelInvite, handleRemoveRole, handleResendInvite } from '../RoleOperation';

function RoleManageOptions({ item, showMenu, setShowMenu, handleOpenModal, handleReload }) {
    const { setMessage, setConfirm } = useTemplate();

    const { authInfo } = useAuth();

    const projectId = getProjectId();

    const handleButtonClick = (button) => {
        const owner = button === 'makeAsOwner';
        const modify = button === 'modify';
        const remove = button === 'remove';
        const cancel = button === 'cancel';
        const resend = button === 'resend';

        if (owner) {
            handleOpenModal('make-owner', item);
        }

        if (resend) {
            const handleClick = () => {
                setMessage('resending...');
                handleResendInvite(authInfo, projectId, item?.roleId)
                    .then(({ message }) => {
                        setMessage(message);
                        setConfirm(null);
                        handleReload();
                    })
                    .catch((err) => {
                        setMessage(err.toString(0));
                    });
            };

            setConfirm({
                text: 'Are you sure you want to resend this invitation?',
                buttons: [
                    <ButtonGray evOnClick={() => setConfirm(null)} title="No" />,
                    <ButtonGreen evOnClick={handleClick} title="Yes" />
                ]
            });
        }

        if (cancel) {
            const handleClick = () => {
                setMessage('canceling...');
                handleCancelInvite(authInfo, projectId, item?.roleId)
                    .then(({ message }) => {
                        setMessage(message);
                        setConfirm(null);
                        handleReload();
                    })
                    .catch((err) => {
                        setMessage(err.toString(0));
                    });
            };

            setConfirm({
                text: 'Are you sure you want to cancel this invitation?',
                buttons: [
                    <ButtonGray evOnClick={() => setConfirm(null)} title="No" />,
                    <ButtonGreen evOnClick={handleClick} title="Yes" />
                ]
            });
        }

        if (modify) {
            handleOpenModal('modify', item);
        }

        if (remove) {
            setConfirm({
                text: 'Are you sure you want to remove this role?',
                textAction: 'Remove',
                clickAction: () => {
                    setMessage('removing...');
                    handleRemoveRole(authInfo, projectId, item?.roleId)
                        .then(({ message }) => {
                            setMessage(message);
                            setConfirm(null);
                            handleReload();
                        })
                        .catch((err) => {
                            setMessage(err.toString(0));
                        });
                }
            });
        }
    };

    return (
        <div className="flex-row align-right p-relative action">
            <InstOption
                setShowMenu={setShowMenu}
                showMenu={item?.buttons?.length && showMenu === item.roleId}
            >
                {item?.buttons?.map((button, index) => (
                    <button
                        key={mkKey(index)}
                        type="button"
                        onClick={() => handleButtonClick(button)}
                    >
                        {button}
                    </button>
                ))}
            </InstOption>
        </div>
    );
}

export default RoleManageOptions;
