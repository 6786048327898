import { ButtonPurple, ButtonReload } from '../../../../configs/Buttons';

function BmExpensePageHeader({ openModal, handleReload }) {
    return (
        <div className="page-header-cont flex-row position-middle align-space-between">
            <div>
                <h2 className="page-header_title">Expenses</h2>
            </div>

            <div className="flex-row position-stretch">
                <ButtonReload evOnClick={handleReload} className="mr-2" />

                <ButtonPurple evOnClick={() => openModal('add')} title="Add Expense" />
            </div>
        </div>
    );
}

export default BmExpensePageHeader;
