export const calculateApproxHours = (selectedHour = []) => {
    if (!selectedHour?.length) {
        return null;
    }

    const sortedHours = [...selectedHour].sort((a, b) => {
        const [hourA, minuteA] = a.title.split(':');
        const [hourB, minuteB] = b.title.split(':');
        return (
            parseInt(hourA, 10) * 60 +
            parseInt(minuteA, 10) -
            (parseInt(hourB, 10) * 60 + parseInt(minuteB, 10))
        );
    });

    let totalMinutes = 30;
    let prevHour = null;
    let prevMinute = null;

    sortedHours.forEach((hour) => {
        const [hourStr, minuteStr] = hour.title.split(':');
        const hourValue = parseInt(hourStr, 10);
        const minuteValue = parseInt(minuteStr, 10);

        if (prevHour !== null && prevMinute !== null) {
            totalMinutes += (hourValue - prevHour) * 60 + (minuteValue - prevMinute);
        }
        prevHour = hourValue;
        prevMinute = minuteValue;
    });

    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;

    let result = '';
    if (totalHours > 0) {
        result += `${totalHours}hr `;
    }
    if (remainingMinutes > 0) {
        result += `${remainingMinutes}min`;
    }

    return result;
};

export const findClosestPickedHours = (hours, pickedHours, selectedHour) => {
    if (selectedHour?.length > 0) {
        const firstSelectedHour = selectedHour[0];
        const firstSelectedIndex = hours.findIndex((hour) => hour?.id === firstSelectedHour?.id);
        const closestPickedHours = [];

        let closestPickedBefore = null;
        let closestPickedAfter = null;

        for (let i = firstSelectedIndex - 1; i >= 0; i -= 1) {
            if (pickedHours.includes(hours[i].title)) {
                closestPickedBefore = hours[i];
                break;
            }
        }

        for (let i = firstSelectedIndex + 1; i < hours.length; i += 1) {
            if (pickedHours.includes(hours[i]?.title)) {
                closestPickedAfter = hours[i];
                break;
            }
        }

        if (closestPickedBefore) {
            closestPickedHours?.push(closestPickedBefore);
        }

        if (closestPickedAfter) {
            closestPickedHours?.push(closestPickedAfter);
        }

        return closestPickedHours;
    }

    return [];
};

export const calculateAvailableHours = (hours, pickedHours, selectedHour) => {
    if (selectedHour?.length > 0) {
        const lastSelectedHour = selectedHour[selectedHour.length - 1];
        const lastSelectedIndex = hours?.findIndex((item) => item?.id === lastSelectedHour?.id);

        let nextPickedIndex = hours?.findIndex(
            (hour) => pickedHours.includes(hour?.title) && hour?.id > lastSelectedHour?.id
        );

        if (nextPickedIndex === -1) {
            nextPickedIndex = hours?.length;
        }

        const availableHours = [];
        for (let i = 0; i < hours?.length; i += 1) {
            if (i <= lastSelectedIndex || i >= nextPickedIndex) {
                availableHours.push(hours[i]);
            }
        }

        const clickedHourIndex = hours?.findIndex((item) => item?.id === selectedHour?.[0]?.id);
        for (let i = clickedHourIndex + 1; i <= lastSelectedIndex; i += 1) {
            availableHours?.push(hours[i]);
        }

        const closestPickedHours = findClosestPickedHours();

        // Remove the clicked item, picked items, and closestPickedHours range items and previous items of clicked item till picked item
        const filteredAvailableHours = availableHours?.filter(
            (hour) =>
                !selectedHour?.some((selected) => selected?.id === hour?.id) &&
                !pickedHours?.includes(hour.title) &&
                !closestPickedHours.some((closestPicked) => closestPicked?.id === hour?.id) &&
                !(closestPickedHours[0]?.id < hour?.id && hour?.id < selectedHour[0]?.id)
        );

        return filteredAvailableHours;
    }

    return [];
};

export default null;
