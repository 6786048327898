import AxiosAPI from '../../../utils/AxiosAPI';

export function handleLoadPageInfo(authInfo, projectId) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/bm/config/page-info/`)
            .post({ projectId })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handleCheckPage(authInfo, projectId, token) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/bm/config/add-facebook-page/`, {
                projectId,
                token
            })
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handleAddPage(authInfo, projectId, token) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/bm/config/add-facebook-page/`)
            .post({ projectId, token })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handleSyncStart(authInfo, projectId) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/bm/config/facebook-sync/`, { projectId })
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handleSyncResume(authInfo, projectId, after) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/bm/config/facebook-sync/`, { projectId, after })
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handleSyncNextData(authInfo, projectId, after) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/bm/config/facebook-sync/`, { projectId, after })
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handleSyncPrevData(authInfo, projectId, before) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/bm/config/facebook-sync/`, { projectId, before })
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handleSyncConvData(authInfo, projectId, conversationId) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/bm/config/facebook-sync-conversation/`, { projectId, conversationId })
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handleSyncMessages(authInfo, projectId) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/bm/config/facebook-sync-conversation-task/`, { projectId })
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handleSyncDataReload(authInfo, projectId, token) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/bm/config/facebook-sync/`, { projectId, ...token })
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export default null;
