import { useEffect, useState } from 'react';
import { ButtonGray, ButtonGreen } from '../../../../../../configs/Buttons';
import { useAuth } from '../../../../../features/auth/AuthProvider';
import AssetConcreteTank from '../../../../../features/customer-asset/AssetConcreteTank';
import AssetPvcTank from '../../../../../features/customer-asset/AssetPvcTank';
import { useTemplate } from '../../../../../features/template/TemplateProvider';
import LibModal from '../../../../../lib/LibModal';
import { AnimateSelectField, addOption } from '../../../../../utils/AnimateInputField';
import { transformObjToArr } from '../../../../../utils/Show';
import { handleUpdateAsset } from '../../BmCustomerOverviewOperation';
import AssetSelectionTab from './AssetSelectionTab';

const homeQuantity = [
    {
        id: 1
    },
    {
        id: 2
    },
    {
        id: 3
    },
    {
        id: 4
    },
    {
        id: 5
    },
    {
        id: 6
    },
    {
        id: 7
    },
    {
        id: 8
    }
];

const formatFormData = (assetType, formData) => {
    if (assetType === 'house' || assetType === 'floor' || assetType === 'unit') {
        return formData?.quantity;
    }
    // transformObjToArr make array of object from object key and value. here key and value set as size and quantity and formData is object
    return transformObjToArr(formData, 'size', 'quantity') || [];
};

function AddAssetsModal({ handleCloseModal }) {
    const [formData, setFormData] = useState({});
    const [assetType, setAssetType] = useState('house');

    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleAssetTypeClick = (type) => {
        setAssetType(type);
    };

    useEffect(() => {
        if (assetType?.length) {
            setFormData({});
        }
    }, [assetType]);

    const dataObj = formatFormData(assetType, formData);

    const handelSaveClick = () => {
        console.log(dataObj);
        setMessage('Updating....');
        handleUpdateAsset(authInfo, dataObj, assetType)
            .then(({ message }) => {
                setMessage(message);
                handleCloseModal();
                // handelReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <div className="add-assets-modal">
            <LibModal title="Add Assets" setOpen={handleCloseModal}>
                <div className="modal-body-cont">
                    <div>
                        <AssetSelectionTab
                            handleAssetTypeClick={handleAssetTypeClick}
                            assetType={assetType}
                        />
                    </div>

                    <div>
                        {assetType === 'house' ? (
                            <AnimateSelectField
                                title="Select House"
                                name="quantity"
                                formData={formData}
                                handelChange={handleChange}
                                options={homeQuantity?.map((item) =>
                                    addOption(item.id, item.id, item.id)
                                )}
                            />
                        ) : null}
                        {assetType === 'floor' ? (
                            <AnimateSelectField
                                title="Select Floor"
                                name="quantity"
                                formData={formData}
                                handelChange={handleChange}
                                options={homeQuantity?.map((item) =>
                                    addOption(item.id, item.id, item.id)
                                )}
                            />
                        ) : null}
                        {assetType === 'unit' ? (
                            <AnimateSelectField
                                title="Select Floor Unit"
                                name="quantity"
                                formData={formData}
                                handelChange={handleChange}
                                options={homeQuantity?.map((item) =>
                                    addOption(item.id, item.id, item.id)
                                )}
                            />
                        ) : null}

                        {assetType === 'pvc' ? (
                            <AssetPvcTank formData={formData} setFormData={setFormData} />
                        ) : null}

                        {assetType === 'concrete' ? (
                            <AssetConcreteTank formData={formData} setFormData={setFormData} />
                        ) : null}
                    </div>

                    <div className="modal-action flex-row align-right">
                        <div className="flex-row align-right">
                            <ButtonGray title="Cancel" evOnClick={handleCloseModal} />
                            <ButtonGreen title="Save" evOnClick={handelSaveClick} />
                        </div>
                    </div>
                </div>
            </LibModal>
        </div>
    );
}

export default AddAssetsModal;
