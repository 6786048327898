import React, { useState } from 'react';
import { icons } from '../../../../../configs/Icons';
import TagSelector from '../../../../features/tag-selector/TagSelector';

function OverviewSecConversationHeader({ handelReload }) {
    const [selectedTags, setSelectedTags] = useState([]);

    const handleSave = () => {
        console.log('save');
    };

    return (
        <div className="con-header flex-row position-stretch align-space-between">
            <div className="flex-row position-stretch">
                <div className="flex-row position-middle">
                    <h2 className="title">Conversation</h2>
                </div>

                <TagSelector
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    handleSave={handleSave}
                />
            </div>

            <div className="flex-row position-middle">
                <div className="reload-button">
                    <button type="button" className="p-relative" onClick={handelReload}>
                        <img src={icons.reloadLine} alt="" />
                        <img src={icons.reloadFill} alt="" />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default OverviewSecConversationHeader;
