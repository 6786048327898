import { useState } from 'react';
import { ButtonGray, ButtonPurple } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import LibModal from '../../../../lib/LibModal';
import AnimateInputField from '../../../../utils/AnimateInputField';
import { handelAddType } from '../InvConfigOperation';

const initialState = {
    type: '',
    subType: ''
};

function TypeAddModal({ setOpen }) {
    const [formData, setFormData] = useState(initialState);

    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    const handelChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handelAddButton = () => {
        setMessage('Adding Type....');
        handelAddType(authInfo, formData)
            .then(({ message }) => {
                setMessage(message);
                setFormData(initialState);
                setOpen(false);
                // handelReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <LibModal title="Add Type" setOpen={setOpen}>
            <div className="modal-body-cont">
                <div>
                    <div className="input-row">
                        <AnimateInputField
                            title="Type"
                            name="type"
                            formData={formData}
                            handelChange={handelChange}
                        />
                    </div>
                    <div className="input-row">
                        <AnimateInputField
                            title="Sub-Type"
                            name="subType"
                            formData={formData}
                            handelChange={handelChange}
                        />
                    </div>
                </div>
                <div className="modal-action flex-row action align-right">
                    <ButtonGray evOnClick={() => setOpen(false)} title="Cancel" />
                    <ButtonPurple evOnClick={handelAddButton} title="Add" />
                </div>
            </div>
        </LibModal>
    );
}

export default TypeAddModal;
