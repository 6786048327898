export const designationDemoData = [
    {
        id: 10000001,
        designation: 'Field Worker',
        salaryMin: '1000',
        salaryMax: '2000',
        salaryDisburse: 'month/5',
        weeklyHoliday1: 5,
        weeklyHoliday2: 6,
        jobDescription:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        reportingTime: '09:00',
        workingHour: 23,
        status: 'active',
        demo: true
    },
    {
        id: 10000002,
        designation: 'Branch Manager',
        salaryMin: '2000',
        salaryMax: '3000',
        salaryDisburse: 'month/5',
        weeklyHoliday1: 5,
        weeklyHoliday2: 6,
        jobDescription:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        reportingTime: '09:00',
        workingHour: 23,
        status: 'active',
        demo: true
    }
];

export const designationDurationType = [
    { id: 'week', name: 'Weekly' },
    { id: 'month', name: 'Monthly' }
];

export const designationDays = {
    month: [
        {
            id: 1,
            label: ' 1'
        },
        {
            id: 2,
            label: ' 2'
        },
        {
            id: 3,
            label: ' 3'
        },
        {
            id: 4,
            label: ' 4'
        },
        {
            id: 5,
            label: ' 5'
        },
        {
            id: 6,
            label: ' 6'
        },
        {
            id: 7,
            label: ' 7'
        },
        {
            id: 8,
            label: ' 8'
        },
        {
            id: 9,
            label: ' 9'
        },
        {
            id: 10,
            label: '10'
        },
        {
            id: 11,
            label: '11'
        },
        {
            id: 12,
            label: '12'
        },
        {
            id: 13,
            label: '13'
        },
        {
            id: 14,
            label: '14'
        },
        {
            id: 15,
            label: '15'
        },
        {
            id: 16,
            label: '16'
        },
        {
            id: 17,
            label: '17'
        },
        {
            id: 18,
            label: '18'
        },
        {
            id: 19,
            label: '19'
        },
        {
            id: 20,
            label: '20'
        },
        {
            id: 21,
            label: '21'
        },
        {
            id: 22,
            label: '22'
        },
        {
            id: 23,
            label: '23'
        },
        {
            id: 24,
            label: '24'
        },
        {
            id: 25,
            label: '25'
        },
        {
            id: 26,
            label: '26'
        },
        {
            id: 27,
            label: '27'
        },
        {
            id: 28,
            label: '28'
        },
        {
            id: 29,
            label: '29'
        },
        {
            id: 30,
            label: '30'
        },
        {
            id: 31,
            label: '31'
        }
    ],
    week: [
        {
            id: 6,
            label: 'Saturday'
        },
        {
            id: 0,
            label: 'Sunday'
        },
        {
            id: 1,
            label: 'Monday'
        },
        {
            id: 2,
            label: 'Tuesday'
        },
        {
            id: 3,
            label: 'Wednesday'
        },
        {
            id: 4,
            label: 'Thursday'
        },
        {
            id: 5,
            label: 'Friday'
        },
        {
            id: -1,
            label: '*No Holiday'
        },
        {
            id: -2,
            label: '*Random'
        }
    ]
};

export const designationGrades = [
    {
        id: 1,
        title: 'Grade-1'
    },
    {
        id: 2,
        title: 'Grade-2'
    },
    {
        id: 3,
        title: 'Grade-3'
    },
    {
        id: 4,
        title: 'Grade-4'
    },
    {
        id: 5,
        title: 'Grade-5'
    },
    {
        id: 6,
        title: 'Grade-6'
    },
    {
        id: 7,
        title: 'Grade-7'
    },
    {
        id: 8,
        title: 'Grade-8'
    },
    {
        id: 9,
        title: 'Grade-9'
    },
    {
        id: 10,
        title: 'Grade-10'
    }
];
export default null;
