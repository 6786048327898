import { ButtonPurple } from '../../../../configs/Buttons';
import { icons } from '../../../../configs/Icons';
import { getProjectId, useAdvMenu } from '../../../features/menus/AdvMenuProvider';
import BmConfigCategoriesProvider, { useCategories } from './BmConfigCategoriesProvider';
import BmConfigCategoriesTable from './BmConfigCategoriesTable';

function SectionHeader({ handleReload, handleOpenModal, disabled }) {
    return (
        <div className="section-header flex-row align-space-between position-stretch">
            <div className="flex-row position-middle">
                <h2 className="section-title">Expense Categories</h2>
            </div>

            <div className="flex-row position-middle">
                <div className={`reload-button ${disabled ? 'disabled' : ''}`}>
                    <button type="button" className="p-relative button" onClick={handleReload}>
                        <img src={icons.reloadLine} alt="" />
                        <img src={icons.reloadFill} alt="" />
                    </button>
                </div>
                <ButtonPurple title="Add Category" evOnClick={() => handleOpenModal('add')} />
            </div>
        </div>
    );
}

function PageContent({ projectId }) {
    const {
        categoryInfo,
        seCategoryInfo,
        reloadKey,
        handleReload,
        handleOpenModal
        // handleCloseModal,
        // modalType,
        // modalData
    } = useCategories();
    return (
        <div className="config-categories-section">
            <SectionHeader handleReload={handleReload} handleOpenModal={handleOpenModal} />
            <BmConfigCategoriesTable
                projectId={projectId}
                handleOpenModal={handleOpenModal}
                categoryInfo={categoryInfo}
                seCategoryInfo={seCategoryInfo}
                reloadKey={reloadKey}
            />
        </div>
    );
}

function BmConfigCategoriesSection() {
    const { activeProject } = useAdvMenu();
    const projectId = activeProject?.id || getProjectId();

    return (
        <BmConfigCategoriesProvider projectId={projectId}>
            <PageContent projectId={projectId} />
        </BmConfigCategoriesProvider>
    );
}

export default BmConfigCategoriesSection;
