import { useState } from 'react';
import OverviewSecConversation from '../overview_sec_conversation/OverviewSecConversation';
import OverviewSecNotes from '../overview_sec_notes/OverviewSecNotes';
import OverviewSecQuickResponse from '../overview_sec_quickresponse/OverviewSecQuickResponse';

function OverviewSecCol1Row2({ responses, notes }) {
    const [expand, setExpand] = useState(1);

    const handleExpand = (index) => {
        setExpand(index);
    };

    return (
        <div className="row-2 flex-row align-space-between">
            {/* <div>Message</div> */}
            <div className="col-1">
                <OverviewSecConversation />
            </div>

            <div className="col-2 flex-col align-stretch position-space-between">
                <OverviewSecQuickResponse
                    expand={expand === 1}
                    handleExpand={handleExpand}
                    responsesData={responses}
                />
                <OverviewSecNotes
                    expand={expand === 2}
                    handleExpand={handleExpand}
                    notesData={notes}
                />
            </div>
        </div>
    );
}

export default OverviewSecCol1Row2;
