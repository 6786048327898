import { useEffect } from 'react';

// Custom hook that observes the editor position and handles show state based on user interactions
function useEditorPositioning(positionRef, editorRef, show, setShow, align, outsideClick = true) {
    // Effect to set the position of the editor
    useEffect(() => {
        const editButton = positionRef?.current;
        const editor = editorRef?.current;

        if (editButton && editor) {
            // Calculate dimensions and positioning based on the specified alignment
            const buttonRect = editButton.getBoundingClientRect();
            const editorRect = editor.getBoundingClientRect();
            const buttonWidth = buttonRect.width;
            const buttonHeight = buttonRect.height;
            const editorHeight = editorRect.height;
            const editorWidth = editorRect.width;

            let calculatedTop;
            let calculatedLeft;

            // Calculate the top and left position based on the specified alignment
            if (align === 'left') {
                calculatedTop = buttonRect.top + 19 + buttonHeight;
                calculatedLeft = buttonRect.left - 30;
            } else if (align === 'center') {
                calculatedTop = buttonRect.top + 19 + buttonHeight;
                calculatedLeft = buttonRect.left + buttonWidth / 2 - 35;
            } else if (align === 'right') {
                calculatedTop = buttonRect.top + 19 + buttonHeight;
                calculatedLeft = buttonRect.left + buttonWidth - 35;
            } else if (align === 'right-middle') {
                calculatedTop = buttonRect.top + (buttonHeight - editorHeight) / 2;
                calculatedLeft = buttonRect.left - editorWidth + buttonWidth;
            }

            // Apply calculated position to the editor element
            editor.style.top = `${calculatedTop}px`;
            editor.style.left = `${calculatedLeft}px`;
        }
    }, [show, positionRef, editorRef, align]);

    // Effect to handle focusing on the input or textarea when the editor is shown
    useEffect(() => {
        if (show && editorRef?.current) {
            const inputOrTextarea = editorRef?.current.querySelector('input, textarea');

            if (inputOrTextarea) {
                inputOrTextarea.focus();
                inputOrTextarea.setSelectionRange(
                    inputOrTextarea.value.length,
                    inputOrTextarea.value.length
                );
                inputOrTextarea.scrollTop = inputOrTextarea?.scrollHeight;
            }
        }
    }, [editorRef, show]);

    // Effect to handle clicks and scrolls outside the editor
    useEffect(() => {
        function handleDocumentClick(event) {
            if (editorRef.current && !editorRef.current.contains(event.target) && outsideClick) {
                setShow(false);
            }
        }

        function handleScrollAndResize() {
            setShow(false);
        }

        if (show) {
            // Add event listeners for clicks and scrolls
            document.addEventListener('mousedown', handleDocumentClick);

            const specificElements = document.querySelectorAll('ul, div.info-scroll');
            specificElements.forEach((element) => {
                element.addEventListener('scroll', handleScrollAndResize);
            });

            window.addEventListener('resize', handleScrollAndResize);
        }

        // Clean up event listeners when the component unmounts or the show state changes
        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);

            const specificElements = document.querySelectorAll('ul, div.info-scroll');
            specificElements.forEach((element) => {
                element.removeEventListener('scroll', handleScrollAndResize);
            });

            window.removeEventListener('resize', handleScrollAndResize);
        };
    }, [show, setShow, editorRef, outsideClick]);
}

export default useEditorPositioning;
