import { useState } from 'react';
import { icons } from '../../../../../configs/Icons';

// const tabArray = [
//     {
//         id: 1,
//         name: '01795617763'
//     },
//     {
//         id: 2,
//         name: '01898042454'
//     },
//     {
//         id: 3,
//         name: '01954456363'
//     },
//     {
//         id: 4,
//         name: '01105645564'
//     },

//     {
//         id: 5,
//         name: '01795617763'
//     }
// ];

function TabHome({ onClick }) {
    return (
        <li className="flex-row position-stretch">
            <div
                role="button"
                tabIndex={-1}
                onClick={onClick}
                className="flex-row position-middle align-center"
            >
                <img src={icons.homeWhite} alt="home" />
            </div>
        </li>
    );
}

function TabUser({ tabName, isActive, onClick, closeClick }) {
    const handelTabCloseClick = (ev) => {
        ev.stopPropagation();
        closeClick();
    };

    return (
        <li className={`flex-row position-stretch ${isActive ? 'active' : ''}`}>
            <div
                role="button"
                tabIndex={-1}
                onClick={onClick}
                className="flex-row position-stretch"
            >
                <div className="tab-title flex-row position-middle">{tabName}</div>
                <div
                    role="button"
                    tabIndex={-1}
                    onClick={handelTabCloseClick}
                    className="tab-close flex-row position-middle"
                >
                    <img src={icons.crossWhite} alt="cross" />
                </div>
            </div>
        </li>
    );
}

function TabNew({ onClick }) {
    return (
        <li className="flex-row position-stretch ">
            <div
                role="button"
                tabIndex={-1}
                onClick={onClick}
                className="flex-row position-stretch align-center"
            >
                <div className="flex-row position-middle">+</div>
            </div>
        </li>
    );
}

function OverviewSecHeader() {
    const [tabs, setTabs] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const handelHomeClick = () => {
        console.log('home clicked');
    };

    // Function to generate a random one-digit number (0 to 9)
    const getRandomOneDigitNumber = () => tabs?.length + 1;

    // Function to generate a random 4-digit number (1000 to 9999)
    const generateRandom4DigitNumber = () => Math.floor(Math.random() * 9000) + 1000;

    const addTab = () => {
        // Keep the first 7 digits the same (0179561) and generate a new random 4-digit number
        const newLastFourDigits = generateRandom4DigitNumber();
        const newTab = { id: getRandomOneDigitNumber(), name: `0179561${newLastFourDigits}` };
        setTabs((prevTabs) => [...prevTabs, newTab]);
    };

    const handelNewTabClick = () => {
        console.log('new tab clicked');
        addTab();
    };

    const handelTabClick = (id) => {
        console.log('tab clicked');
        setActiveTab(id);
    };

    const handelCloseClick = (id) => {
        console.log(`close clicked${id}`);
        setTabs((prevTabs) => prevTabs.filter((el) => el.id !== id));
    };

    return (
        <div className="overview-section-header">
            <ul className="tab-container flex-row position-stretch">
                <TabHome onClick={handelHomeClick} />
                {tabs?.map((data) => (
                    <TabUser
                        key={data.id}
                        onClick={() => handelTabClick(data?.id)}
                        closeClick={() => handelCloseClick(data?.id)}
                        tabName={data.name}
                        isActive={data.id === activeTab}
                    />
                ))}

                {/* <TabUser
                    onClick={handelTabClick}
                    closeClick={handelCloseClick}
                    tabName="01898042454"
                />

                <TabUser
                    onClick={handelTabClick}
                    closeClick={handelCloseClick}
                    tabName="01954456363"
                />
                <TabUser
                    onClick={handelTabClick}
                    closeClick={handelCloseClick}
                    tabName="01105645564"
                /> */}

                <TabNew onClick={handelNewTabClick} />
            </ul>
        </div>
    );
}

export default OverviewSecHeader;
