import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonGray, ButtonGreen } from '../../../../configs/Buttons';
import LibModal from '../../../lib/LibModal';
import AnimateInputField from '../../../utils/AnimateInputField';
import { useAuth } from '../../auth/AuthProvider';
import { setProject } from '../../menus/AdvMenuProvider';
import { useTemplate } from '../../template/TemplateProvider';
import { handelAddProject } from '../ProjectOperation';

// const initialState = {
//     title: '',
//     domain: '',
//     name: '',
//     email: '',
//     phone: ''
// };

function ProjectAddModal({ setOpen, handleReload, closeDropdownMenu }) {
    const { authInfo } = useAuth();
    const currentUser = authInfo.currentUser || {};

    const [formData, setFormData] = useState({ ...currentUser });

    const { setMessage } = useTemplate();
    const navigate = useNavigate();

    const handelChange = (ev) => {
        const { name } = ev.target;
        if (name) {
            setFormData((d) => ({ ...d, [name]: ev.target.value }));
        }
    };

    const handleAddButton = () => {
        setMessage('Adding Project...');
        handelAddProject(authInfo, formData)
            .then(({ message, id }) => {
                setOpen(false);
                setProject(navigate, { id });
                closeDropdownMenu();
                setMessage(message);
                if (handleReload) {
                    handleReload();
                }
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <LibModal title="Add Project" setOpen={setOpen}>
            <div className="project-add-modal">
                <div>
                    <div className="flex-col gap-20">
                        <AnimateInputField
                            title="Project Title"
                            name="title"
                            required
                            formData={formData}
                            handelChange={handelChange}
                        />

                        <AnimateInputField
                            title="Domain"
                            name="domain"
                            formData={formData}
                            handelChange={handelChange}
                        />
                    </div>

                    <div className="divider flex-row position-middle gap-10">
                        <h2>Focal Person</h2>
                        <hr />
                    </div>

                    <div className="flex-col gap-20">
                        <AnimateInputField
                            title="Name"
                            name="name"
                            formData={formData}
                            handelChange={handelChange}
                        />

                        <div className="flex-row align-space-between w-100p">
                            <div className="inp-fill-2">
                                <AnimateInputField
                                    title="Email"
                                    name="email"
                                    formData={formData}
                                    handelChange={handelChange}
                                />
                            </div>
                            <div className="inp-fill-2">
                                <AnimateInputField
                                    title="Phone"
                                    name="phone"
                                    formData={formData}
                                    handelChange={handelChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex-row align-right gap-10">
                    <ButtonGray evOnClick={() => setOpen(false)} title="Cancel" />
                    <ButtonGreen evOnClick={handleAddButton} title="Add" />
                </div>
            </div>
        </LibModal>
    );
}

export default ProjectAddModal;
