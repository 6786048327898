import { useState } from 'react';
import { ButtonMenu } from '../../../../configs/Buttons';
import InstOption from '../../../utils/InstOption';
import { GetDate, UpperCase } from '../../../utils/Show';

function Tr({ arr, handleOpenModal }) {
    const [showMenu, setShowMenu] = useState(false);

    if (arr && !arr?.length) {
        return (
            <tr>
                <td colSpan="12" className="text-left">
                    <span>No Expenses Found!</span>
                </td>
            </tr>
        );
    }
    return arr?.map((item) => (
        <tr key={item?.id} className={item?.demo ? 'placeholder-mode' : ''}>
            <td className="text-left">
                <span>{GetDate(item?.date)}</span>
            </td>
            <td className="text-left">
                <span>{item?.branch}</span>
            </td>
            <td className="text-left">
                <span>{item?.category}</span>
            </td>
            <td className="text-left">
                <span>{item?.title}</span>
            </td>

            <td className="text-left">
                <span>{item?.note}</span>
            </td>

            <td className="text-center">
                <span>{item?.quantity}</span>
            </td>
            <td className="text-left">
                <span>{item?.type}</span>
            </td>
            <td className="text-center">
                <span>{item?.unitPrice}</span>
            </td>
            <td className="text-center">
                <span>{item?.priceTotal}</span>
            </td>
            <td className="text-left">
                <span>
                    <b>{item?.user}</b>
                </span>
            </td>
            <td className="text-left">
                <span className={`status ${item.status}`}>{UpperCase(item?.status)}</span>
            </td>
            <td>
                <div className="flex-row align-right">
                    <span>
                        <ButtonMenu evOnClick={() => setShowMenu(item.id)}>
                            <InstOption setShowMenu={setShowMenu} showMenu={showMenu === item.id}>
                                <button
                                    type="button"
                                    onClick={() => handleOpenModal('update', item)}
                                >
                                    Edit
                                </button>
                            </InstOption>
                        </ButtonMenu>
                    </span>
                </div>
            </td>
        </tr>
    ));
}

function BmExpenseTable({ expenseInfo, handleOpenModal, handelReload }) {
    const arr = expenseInfo;

    return (
        <div className="bik-table-parent">
            <table className="bik-table">
                <thead>
                    <tr>
                        <th width="" className="text-left">
                            Date
                        </th>
                        <th width="" className="text-left">
                            Branch
                        </th>
                        <th width="10%" className="text-left">
                            Category
                        </th>

                        <th width="" className="text-left">
                            Title
                        </th>

                        <th width="" className="text-left">
                            Note
                        </th>

                        <th width="8%" className="text-center">
                            Quantity
                        </th>
                        <th width="6%" className="text-left">
                            Type
                        </th>
                        <th width="7%" className="text-center">
                            Price
                        </th>
                        <th width="7%" className="text-center">
                            Total
                        </th>
                        <th width="" className="text-left">
                            User
                        </th>
                        <th width="6%" className="text-left">
                            Status
                        </th>
                        <th width="6%" className="text-center">
                            #
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <Tr arr={arr} handleOpenModal={handleOpenModal} handelReload={handelReload} />
                </tbody>
            </table>
        </div>
    );
}

export default BmExpenseTable;
