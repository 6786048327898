export const tags = [
    {
        id: 'black',
        color: '#000000',
        notes: 'বাজে ব্যবহার অতিরিক্ত কৃপণ (৫০% থেকে কম মূল্য বলে) বিনা কারণে হয়রানি করেছে এলোমেলো তথ্য দেয়'
    },
    {
        id: 'red',
        color: '#F50303',
        notes: 'রাগ বেশি কিছুটা কৃপণ দুইবারের বেশি Follow-Up হইছে'
    },
    {
        id: 'orange',
        color: '#FFA113',
        notes: 'একবার Follow-Up হইছে নিতে চায় না পরে জানাবে'
    },
    {
        id: 'gray',
        color: 'gray',
        notes: 'আউট অফ সার্ভিস এরিয়া'
    },
    {
        id: 'blue',
        color: '#005FFF',
        notes: 'Long Distance'
    },
    {
        id: 'green',
        color: '#185B33',
        notes: 'সার্ভিস নিয়েছে'
    },
    {
        id: 'pink',
        color: '#FF7A8D',
        notes: 'To Call'
    },
    {
        id: 'yellow',
        color: '#FFD446',
        notes: 'Follow-Up'
    }
];

export default null;
