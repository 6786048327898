import { ButtonPurple } from '../../../../configs/Buttons';
import { icons } from '../../../../configs/Icons';
import { getProjectId, useAdvMenu } from '../../../features/menus/AdvMenuProvider';
import BmConfigDesignationProvider, { useDesignation } from './BmConfigDesignationProvider';
import ConfigDesignationTable from './BmConfigDesignationTable';
import BmConfigDesignationAddModal from './modals/BmConfigDesignationAddModal';
import BmConfigDesignationUpdateModal from './modals/BmConfigDesignationUpdateModal';

function SectionHeader({ handleReload, handleOpenModal, disabled }) {
    return (
        <div className="section-header flex-row align-space-between position-stretch">
            <div className="flex-row position-middle">
                <h2 className="section-title">Designation</h2>
            </div>

            <div className="flex-row position-middle">
                <div className={`reload-button ${disabled ? 'disabled' : ''}`}>
                    <button type="button" className="p-relative button" onClick={handleReload}>
                        <img src={icons.reloadLine} alt="" />
                        <img src={icons.reloadFill} alt="" />
                    </button>
                </div>
                <ButtonPurple
                    title="Add Designation"
                    evOnClick={() => handleOpenModal('Designation-add')}
                />
            </div>
        </div>
    );
}

function PageContent({ projectId }) {
    const {
        designationInfo,
        setDesignationInfo,
        reloadKey,
        handleReload,
        handleOpenModal,
        handleCloseModal,
        modalType,
        modalData,
        getSalaryDisburse,
        getHolidayInfo
    } = useDesignation();

    return (
        <div className="config-designation-section">
            <SectionHeader handleReload={handleReload} handleOpenModal={handleOpenModal} />
            <ConfigDesignationTable
                handleOpenModal={handleOpenModal}
                projectId={projectId}
                designationInfo={designationInfo}
                setDesignationInfo={setDesignationInfo}
                reloadKey={reloadKey}
                getSalaryDisburse={getSalaryDisburse}
                getHolidayInfo={getHolidayInfo}
            />

            {/* modal */}
            {modalType === 'Designation-add' ? (
                <BmConfigDesignationAddModal
                    handleCloseModal={handleCloseModal}
                    handleReload={handleReload}
                    projectId={projectId}
                />
            ) : null}

            {modalType === 'Designation-update' ? (
                <BmConfigDesignationUpdateModal
                    handleCloseModal={handleCloseModal}
                    modalData={modalData}
                    handleReload={handleReload}
                    projectId={projectId}
                />
            ) : null}
        </div>
    );
}

function BmConfigDesignationSection() {
    const { activeProject } = useAdvMenu();
    const projectId = activeProject?.id || getProjectId();

    return (
        <BmConfigDesignationProvider>
            <PageContent projectId={projectId} />
        </BmConfigDesignationProvider>
    );
}

export default BmConfigDesignationSection;
