import RoleManageSection from '../../features/role-manage/RoleManageSection';
import ProjectManageHeader from './ProjectManageHeader';

function ProjectManagePage() {
    return (
        <div className="page-cont project-manage-cont">
            <ProjectManageHeader />

            <RoleManageSection />
        </div>
    );
}

export default ProjectManagePage;
