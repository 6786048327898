import React, { useState } from 'react';
import { ButtonPurple } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';
import BmPageAddModal from '../modals/BmPageAddModal';

function BmConfigFbPageInfoHeader({ handleReload, disabled }) {
    const [open, setOpen] = useState(false);

    return (
        <div className="flex-row position-middle align-space-between section-header">
            <div className="flex-row position-middle">
                <h3 className="section-title">Page Info</h3>
            </div>

            <div className="flex-row position-middle">
                <div className={`reload-button ${disabled ? 'disabled' : ''}`}>
                    <button type="button" className="p-relative button" onClick={handleReload}>
                        <img src={icons.reloadLine} alt="" />
                        <img src={icons.reloadFill} alt="" />
                    </button>
                </div>

                <ButtonPurple title="Add Page" evOnClick={() => setOpen(true)} />

                {open ? <BmPageAddModal setOpen={setOpen} handleReload={handleReload} /> : null}
            </div>
        </div>
    );
}

export default BmConfigFbPageInfoHeader;
