import { BrowserRouter, Route, Routes } from 'react-router-dom';
import BmAccountsPage from './app/bm/accounts/BmAccountsPage';
import BmApiPage from './app/bm/api/BmApiPage';
import BmConfigurationPage from './app/bm/configuration/BmConfigurationPage';
import BmConfigBranchSection from './app/bm/configuration_branch/BmConfigBranchSection';
import BmConfigCategoriesSection from './app/bm/configuration_categories/BmConfigCategoriesSection';
import BmConfigDesignationSection from './app/bm/configuration_designation/BmConfigDesignationSection';
import BmConfigPageInfoSection from './app/bm/configuration_pageinfo/BmConfigPageInfoSection';
import BmConversationPage from './app/bm/conversation/BmConversationPage';
import BmCustomersPage from './app/bm/customers/BmCustomersPage';
import BmCustomerOverviewPage from './app/bm/customers_overview/BmCustomerOverviewPage';
import BmDashboardPage from './app/bm/dashboard/BmDashboardPage';
import BmExpensePage from './app/bm/expense/BmExpensePage';
import BmManageTagsPage from './app/bm/manage_tags/BmManageTagsPage';
import BmOrdersPage from './app/bm/orders/BmOrdersPage';
import BmReportsPage from './app/bm/reports/BmReportsPage';
import BmSchedulePage from './app/bm/schedule/BmSchedulePage';
import BmTeamsPage from './app/bm/teams/BmTeamsPage';
import BmTransactionsPage from './app/bm/transactions/BmTransactionsPage';
import HomePage from './app/home/HomePage';
import InvConfigurationPage from './app/inventory/configuration/InvConfigurationPage';
import InvoiceListPage from './app/inventory/invoice_list/InvoiceListPage';
import InvoiceUpdatePage from './app/inventory/invoice_update/InvoiceUpdatePage';
import InvProductPage from './app/inventory/product/InvProductPage';
import ProjectManagePage from './app/project_manage/ProjectManagePage';
import AuthPage from './features/auth/AuthPage';

function RouteHandler() {
    return (
        <BrowserRouter>
            <AuthPage>
                <Routes>
                    {/* Index Page */}
                    <Route path="/*" element={<HomePage />} />
                    <Route path="/project-manage/" element={<ProjectManagePage />} />

                    {/* Inventory */}
                    <Route path="/inventory/dashboard/" element={<HomePage />} />
                    <Route path="/inventory/accounts/" element={<HomePage />} />
                    <Route path="/inventory/sell-invoice/" element={<InvoiceListPage />} />
                    <Route
                        path="/inventory/sell-invoice/:invoiceId/update"
                        element={<InvoiceUpdatePage />}
                    />
                    <Route path="/inventory/configuration/" element={<InvConfigurationPage />} />
                    <Route path="/inventory/product/" element={<InvProductPage />} />
                    <Route path="/inventory/gateway-transactions/" element={<HomePage />} />
                    <Route path="/inventory/transactions/" element={<HomePage />} />
                    <Route path="/inventory/reports/" element={<HomePage />} />
                    <Route path="/inventory/api/" element={<HomePage />} />

                    {/* HRMS */}
                    <Route path="/hrms/dashboard/" element={<HomePage />} />
                    <Route path="/hrms/people/" element={<HomePage />} />
                    <Route path="/hrms/roster/" element={<HomePage />} />
                    <Route path="/hrms/salary/" element={<HomePage />} />
                    <Route path="/hrms/attendance/" element={<HomePage />} />
                    <Route path="/hrms/reports/" element={<HomePage />} />
                    <Route path="/hrms/api2/" element={<HomePage />} />
                    <Route path="/hrms/billing/" element={<HomePage />} />

                    {/* Business Manager */}
                    <Route path="/business-manager/dashboard/" element={<BmDashboardPage />} />
                    <Route path="/business-manager/customers/" element={<BmCustomersPage />} />
                    <Route
                        path="/business-manager/customers/:id/overview/"
                        element={<BmCustomerOverviewPage />}
                    />
                    <Route
                        path="/business-manager/conversation/"
                        element={<BmConversationPage />}
                    />
                    <Route path="/business-manager/schedule/" element={<BmSchedulePage />} />
                    <Route path="/business-manager/accounts/" element={<BmAccountsPage />} />
                    <Route path="/business-manager/orders/" element={<BmOrdersPage />} />
                    <Route path="/business-manager/teams/" element={<BmTeamsPage />} />
                    <Route
                        path="/business-manager/transactions/"
                        element={<BmTransactionsPage />}
                    />
                    <Route path="/business-manager/reports/" element={<BmReportsPage />} />
                    <Route
                        path="/business-manager/configuration/"
                        element={<BmConfigurationPage />}
                    >
                        <Route path="pageinfo" element={<BmConfigPageInfoSection />} />
                        <Route path="branches" element={<BmConfigBranchSection />} />
                        <Route path="designation" element={<BmConfigDesignationSection />} />
                        <Route path="categories" element={<BmConfigCategoriesSection />} />
                    </Route>
                    <Route path="/business-manager/expense/" element={<BmExpensePage />} />
                    <Route path="/business-manager/api2/" element={<BmApiPage />} />
                    <Route path="/business-manager/manage-tags/" element={<BmManageTagsPage />} />
                </Routes>
            </AuthPage>
        </BrowserRouter>
    );
}
export default RouteHandler;
