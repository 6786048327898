import OverviewSecCol2Asset from '../overview_sec_col2_asset/OverviewSecCol2Asset';
import OverviewSecCol2Graph from './OverviewSecCol2Graph';
import OverviewSecCol2Media from './OverviewSecCol2Media';
import OverviewSecCol2Order from './OverviewSecCol2Order';

function OverviewSecCol2({ overviewData }) {
    const { order, assets } = overviewData || {};
    return (
        <div className="overview-col-2 info-scroll">
            <OverviewSecCol2Order order={order} />
            <OverviewSecCol2Graph />
            <OverviewSecCol2Asset assets={assets} />
            <OverviewSecCol2Media />
        </div>
    );
}

export default OverviewSecCol2;
