import ConLeftCol from '../con_leftcol/ConLeftCol';
import ConRightCol from '../con_rightcol/ConRightCol';

function BmConversationBody() {
    return (
        <div className="conversation-container flex-row align-space-between">
            <ConLeftCol />
            <ConRightCol />
        </div>
    );
}

export default BmConversationBody;
