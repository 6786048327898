import { useRef, useState } from 'react';
import { icons } from '../../../../../configs/Icons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import InfoEditorInputV2 from '../../../../features/info-editor/InfoEditorInputV2';
import InfoEditorTextarea from '../../../../features/info-editor/InfoEditorTextarea';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import { handleAddResponse } from '../BmCustomerOverviewOperation';
import OverviewSecQuickResponseItem from './OverviewSecQuickResponseItem';

function SearchButton({ formData, setFormData }) {
    const buttonRef = useRef();

    const [show, setShow] = useState(false);

    return (
        <div
            ref={buttonRef}
            role="button"
            tabIndex={-1}
            onClick={() => setShow(true)}
            className="list-search"
        >
            <img src={icons.search} alt="search" />

            <InfoEditorInputV2
                width={220}
                show={show}
                setShow={setShow}
                positionRef={buttonRef}
                name="text"
                placeholder="Search response..."
                formData={formData}
                setFormData={setFormData}
                saveClick={null}
            />
        </div>
    );
}

function AddButton({ expand, positionRef, showEditor, handleAddClick }) {
    if (!expand) {
        return null;
    }
    return (
        <div
            ref={positionRef}
            role="button"
            tabIndex={-1}
            onClick={handleAddClick}
            className={`list-add-button ${showEditor ? 'active' : ''}`}
        >
            +
        </div>
    );
}

function OverviewSecQuickResponse({ handleExpand, expand, responsesData }) {
    const [formData, setFormData] = useState({});

    const [mode, setMode] = useState({ type: '' });

    const positionRef = useRef();

    const { createEditorFunction, setMessage } = useTemplate();
    const { authInfo } = useAuth();

    const { openEditor, closeEditor } = createEditorFunction(setFormData, setMode);

    const arr = formData?.text?.length
        ? responsesData.filter((item) =>
              item?.text?.toLowerCase()?.includes(formData?.text.toLowerCase())
          )
        : responsesData;

    const addClick = (ev) => {
        ev.stopPropagation();
        openEditor({ type: 'response-add' });
    };

    const handelSaveClick = () => {
        setMessage('Adding response....');
        handleAddResponse(authInfo, formData?.response)
            .then(({ message }) => {
                setMessage(message);
                closeEditor();
                // handelReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <div
            role="button"
            tabIndex={-1}
            onFocus={() => handleExpand(1)} // Trigger handleExpand with true (expanded) on focus
            className={`overview_q-response-list-cont overview_section-item ${
                expand ? 'active' : ''
            }`}
        >
            <div className="flex-row position-stretch align-space-between">
                <div className="flex-row position-middle">
                    <h2 className="title">Quick Response</h2>
                </div>

                <div className="flex-row position-middle list-action">
                    <SearchButton setFormData={setFormData} formData={formData} />
                    <AddButton
                        positionRef={positionRef}
                        expand={expand}
                        handleAddClick={addClick}
                        closeEditor={closeEditor}
                        showEditor={mode.type === 'response-add'}
                    />

                    <InfoEditorTextarea
                        show={mode.type === 'response-add'}
                        setShow={closeEditor}
                        positionRef={positionRef}
                        name="response"
                        formData={formData}
                        setFormData={setFormData}
                        saveClick={handelSaveClick}
                    />
                </div>
            </div>

            <ul className="scroll-event">
                {arr.map((item) => (
                    <OverviewSecQuickResponseItem key={item.id} item={item} />
                ))}
            </ul>
        </div>
    );
}

export default OverviewSecQuickResponse;
