import { useEffect, useState } from 'react';
import { ButtonMenu } from '../../../../configs/Buttons';
import { icons } from '../../../../configs/Icons';
import { branchDemoData } from '../../../../data/branchDemoData';
import { useAuth } from '../../../features/auth/AuthProvider';
import { useTemplate } from '../../../features/template/TemplateProvider';
import { UserInfoV3 } from '../../../features/user-info/UserInfo';
import InstOption from '../../../utils/InstOption';
import { UpperCase, formatSingleDigit } from '../../../utils/Show';
import { handleLoadBranchList } from './BmConfigBranchOperation';

function Tr({ branchInfo, handleOpenModal }) {
    const [showMenu, setShowMenu] = useState(false);

    const arr = branchInfo || branchDemoData;

    if (arr && !arr?.length) {
        return (
            <tr>
                <td colSpan="8" className="text-left">
                    No data found
                </td>
            </tr>
        );
    }

    return arr.map((item) => (
        <tr key={item.id} className={item?.demo ? 'placeholder-mode' : ''}>
            <td className="text-left">
                <span>{item.id}</span>
            </td>
            <td className="text-left">
                <span>{item.branchName}</span>
            </td>
            <td className="text-left">
                <span>{item.branchAddress}</span>
            </td>

            <td className="text-left">
                <UserInfoV3 img={item.img || icons.avatar} name={item.name} email={item.contact} />
            </td>

            <td className="text-center">
                <span className="amount-font">{formatSingleDigit(item?.position) || '--'}</span>
            </td>
            <td className="text-left">
                <span>{UpperCase(item.district)}</span>
            </td>
            <td className="text-left">
                <span className={`status ${item.status}`}>{item.status}</span>
            </td>
            <td className="text-right">
                <div className="flex-row align-right">
                    <span>
                        <ButtonMenu evOnClick={() => setShowMenu(item.id)}>
                            <InstOption setShowMenu={setShowMenu} showMenu={showMenu === item.id}>
                                <button
                                    type="button"
                                    onClick={() => handleOpenModal('branch-update', item)}
                                >
                                    Edit
                                </button>
                            </InstOption>
                        </ButtonMenu>
                    </span>
                </div>
            </td>
        </tr>
    ));
}

function BmConfigBranchTable({ projectId, branchInfo, setBranchInfo, reloadKey, handleOpenModal }) {
    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    useEffect(() => {
        setBranchInfo(null);
        handleLoadBranchList(authInfo, projectId)
            .then(({ data }) => {
                setBranchInfo(data);
            })
            .catch((err) => {
                setMessage(err.toString(0));
            });
    }, [authInfo, projectId, setMessage, reloadKey, setBranchInfo]);

    return (
        <div className="bik-table-parent">
            <table className="bik-table">
                <thead>
                    <tr>
                        <th width="12%" className="text-left">
                            Branch Code
                        </th>
                        <th width="13%" className="text-left">
                            Branch Name
                        </th>
                        <th className="text-left">Address</th>
                        <th className="text-left">Branch Manager</th>

                        <th width="10%" className="text-center">
                            Position Allocated
                        </th>
                        <th width="8%" className="text-left">
                            District
                        </th>
                        <th width="7%" className="text-left">
                            Status
                        </th>
                        <th width="8%" className="text-right">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <Tr branchInfo={branchInfo} handleOpenModal={handleOpenModal} />
                </tbody>
            </table>
        </div>
    );
}

export default BmConfigBranchTable;
