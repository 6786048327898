import { createContext, useContext, useMemo, useState } from 'react';
import { customersDemoData } from '../../../../data/customersDemoData';
import { mkRelativeUrl } from '../../../features/menus/AdvMenuProvider';
import { mkKey } from '../../../utils/String';

// Context creation
const BmCustomersContext = createContext();

// Custom hook to use the context
export const useBmCustomer = () => useContext(BmCustomersContext);

const initialState = {
    mode: false,
    type: '',
    data: null
};

function BmCustomersProvider({ children }) {
    const [customersData, setCustomersData] = useState({
        data: customersDemoData,
        pagination: {},
        loading: false
    });
    const [showModal, setShowModal] = useState(initialState);
    const [reloadKey, setReloadKey] = useState(mkKey());

    // Create a memoized value object that holds various functions
    const value = useMemo(() => {
        // Function to trigger reloading data
        const handleReload = () => {
            setReloadKey((d) => d + 1);
            setCustomersData({
                data: customersDemoData
            });
        };

        // Function to open modal
        const openModal = (type, data) => {
            setShowModal({
                mode: true,
                type,
                data
            });
        };

        // Function to close modal
        const closeModal = () => {
            setShowModal({
                mode: false,
                type: ''
            });
        };

        // Function to set query parameter on change or click
        const handleNavigate = (navigate, page) => {
            navigate(
                mkRelativeUrl(`/business-manager/customers/`, {
                    page
                })
            );
        };

        // Return an object with all the values and functions
        return {
            reloadKey,
            setCustomersData,
            cusData: customersData?.data,
            paginate: customersData?.pagination,
            loading: customersData?.loading,
            handleNavigate,
            handleReload,
            openModal,
            closeModal,
            showModal
        };
    }, [customersData, reloadKey, showModal]);

    // Provide the value through the context
    return <BmCustomersContext.Provider value={value}>{children}</BmCustomersContext.Provider>;
}

export default BmCustomersProvider;
