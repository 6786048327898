import { useInvoiceUpdate } from '../InvoiceUpdateProvider';
import FinInvoiceAddNotes from '../modals/FinInvoiceAddNotes';
import FinInvoiceAddPayment from '../modals/FinInvoiceAddPayment';
import FinInvoiceAddRefund from '../modals/FinInvoiceAddRefund';
import FinInvoiceEditAddress from '../modals/FinInvoiceEditAddress';
import FinInvoiceEditTitle from '../modals/FinInvoiceEditTitle';
import FinInvoiceEditUser from '../modals/FinInvoiceEditUser';
import FinInvoiceReOpen from '../modals/FinInvoiceReOpen';
import FinInvoiceSendEmail from '../modals/FinInvoiceSendEmail';
import FinInvoiceSendPush from '../modals/FinInvoiceSendPush';
import FinInvoiceSendSms from '../modals/FinInvoiceSendSms';
import FinInvoiceVatPay from '../modals/FinInvoiceVatPay';

function FinInvoiceModalController() {
    const {
        modal,
        openModal,
        invoiceData,
        handelChangeTitle,
        handelChangeUser,
        handelChangeAddress,
        handelChangeNote,
        handelReload
    } = useInvoiceUpdate() || {};

    const { info, user, addresses } = invoiceData || {};
    const { id, noteTitle, noteDescription } = info || {};

    // invoice title update modal
    if (modal === 'inv-title') {
        return (
            <FinInvoiceEditTitle
                setOpen={openModal}
                invoiceData={invoiceData}
                handelChangeTitle={handelChangeTitle}
            />
        );
    }

    // invoice user update or create modal
    if (modal === 'user') {
        return (
            <FinInvoiceEditUser
                setOpen={openModal}
                user={user}
                handelChangeUser={handelChangeUser}
            />
        );
    }

    // invoice address update modal
    if (modal === 'address') {
        return (
            <FinInvoiceEditAddress
                setOpen={openModal}
                address={addresses.billing}
                handelChangeAddress={handelChangeAddress}
            />
        );
    }

    // invoice note add or update modal
    if (modal === 'inv-note') {
        return (
            <FinInvoiceAddNotes
                show={modal === 'inv-note'}
                setOpen={openModal}
                title={noteTitle}
                description={noteDescription}
                handelChangeNote={handelChangeNote}
            />
        );
    }

    // invoice paymenty add  modal
    if (modal === 'inv-add-payment') {
        return (
            <FinInvoiceAddPayment
                invoiceData={invoiceData}
                handelReload={handelReload}
                setOpen={openModal}
            />
        );
    }

    // invoice paymenty add  modal
    if (modal === 'inv-payment-refund') {
        return (
            <FinInvoiceAddRefund
                invoiceData={invoiceData}
                handelReload={handelReload}
                setOpen={openModal}
            />
        );
    }

    // invoice paymenty add  modal
    if (modal === 'inv-vat-pay') {
        return (
            <FinInvoiceVatPay
                invoiceData={invoiceData}
                handelReload={handelReload}
                setOpen={openModal}
            />
        );
    }

    // invoice paymenty add  modal
    if (modal === 'inv-reopen') {
        return (
            <FinInvoiceReOpen
                show={modal === 'inv-reopen'}
                handelReload={handelReload}
                setOpen={openModal}
                invoiceId={id}
            />
        );
    }

    // invoice send sms  modal
    if (modal === 'inv-send-sms') {
        return <FinInvoiceSendSms setOpen={openModal} invoiceId={id} handelReload={handelReload} />;
    }

    // invoice send sms  modal
    if (modal === 'inv-send-email') {
        return (
            <FinInvoiceSendEmail setOpen={openModal} invoiceId={id} handelReload={handelReload} />
        );
    }

    // invoice send push modal
    if (modal === 'inv-send-push') {
        return (
            <FinInvoiceSendPush setOpen={openModal} invoiceId={id} handelReload={handelReload} />
        );
    }

    // else
    return null;
}

export default FinInvoiceModalController;
