import { useState } from 'react';
import { ButtonGray, ButtonGreen } from '../../../../configs/Buttons';
import LibModal from '../../../lib/LibModal';
import { AnimateSelectField, addOption } from '../../../utils/AnimateInputField';
import { useRole } from '../RoleManageProvider';
import { handleChangeRole } from '../RoleOperation';

const roles = [
    { id: 'manager', title: 'Manager' },
    { id: 'developer', title: 'Developer' }
];

function RoleModifyModal({ projectId, data, authInfo, setMessage, handleCloseModal }) {
    const [formData, setFormData] = useState({ role: '' });

    const { handleReload } = useRole();

    const handelChange = (ev) => {
        const { name, value } = ev.target;
        setFormData((cuData) => ({ ...cuData, [name]: value }));
    };

    const handleSave = () => {
        handleChangeRole(authInfo, projectId, data?.roleId, data?.roleKey)
            .then(() => {
                handleCloseModal();
                handleReload();
            })
            .catch((err) => {
                setMessage(err.toString(0));
            });
    };

    const isValue = Object.values(formData).some((item) => item);

    return (
        <div className="role-modify-modal">
            <LibModal title="Choose Role" setOpen={handleCloseModal}>
                <div className="modal-body-cont">
                    <div>
                        <AnimateSelectField
                            title="Select Role"
                            name="role"
                            options={roles.map((item) => addOption(item.id, item.title, item.id))}
                            formData={formData}
                            handelChange={handelChange}
                        />
                    </div>

                    <div className="modal-action flex-row align-right position-middle action">
                        <ButtonGray evOnClick={handleCloseModal} title="Cancel" />
                        <ButtonGreen evOnClick={handleSave} disabled={!isValue} title="Save" />
                    </div>
                </div>
            </LibModal>
        </div>
    );
}

export default RoleModifyModal;
