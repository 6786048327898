import { ButtonGray, ButtonGreen } from '../../../configs/Buttons';
import { useInvoiceUpdate } from '../../app/inventory/invoice_update/InvoiceUpdateProvider';
import { useTemplate } from '../template/TemplateProvider';
import ProductEditorBody from './components/ProductEditorBody';
import ProductEditorBodyCustom from './components/ProductEditorBodyCustom';
import ProductEditorBodyEmail from './components/ProductEditorBodyEmail';
import ProductEditorBodySms from './components/ProductEditorBodySms';
import { getProperty } from './components/PropertyController';

function InvoiceProductEditor({ formData, modalProperty, handleCancelClick }) {
    const { setMessage } = useTemplate();
    const propertyInputs = getProperty(modalProperty.property);
    const { handelAddProduct, handelUpdateProduct } = useInvoiceUpdate();

    // amount without comma
    const price = parseFloat(formData?.price?.toString()?.replace(/,/g, ''));
    const priceOffer = parseFloat(formData?.priceOffer?.toString()?.replace(/,/g, ''));

    const handelAddEditClick = (extra) => {
        if (modalProperty.mode === 'add') {
            setMessage('Saving...');
            handelAddProduct({
                ...formData,
                price,
                priceOffer,
                ...extra,
                property: modalProperty.property
            })
                .then(({ error, message }) => {
                    if (error === 0) {
                        handleCancelClick();
                    }
                    setMessage(message);
                })
                .catch((err) => {
                    setMessage(err.toString());
                });
        } else {
            setMessage('Updating...');
            handelUpdateProduct({
                ...formData,
                price,
                priceOffer,
                ...extra,
                property: modalProperty.property
            })
                .then(({ error, message }) => {
                    if (error === 0) {
                        handleCancelClick();
                    }
                    setMessage(message);
                })
                .catch((err) => {
                    setMessage(err.toString());
                });
        }
    };

    if (modalProperty.property === 'sms') {
        return (
            <div className="invoice-product-editor">
                <ProductEditorBodySms
                    formData={formData}
                    modalProperty={modalProperty}
                    propertyInputs={propertyInputs}
                />

                <div className="invoice-product-action-cont flex-row align-right">
                    <ButtonGray title="Cancel" evOnClick={handleCancelClick} />
                    <ButtonGreen
                        title="Save"
                        evOnClick={() => {
                            handelAddEditClick({ duration: 1, unitName: 'sms' });
                        }}
                    />
                </div>
            </div>
        );
    }

    if (modalProperty.property === 'email') {
        return (
            <div className="invoice-product-editor">
                <ProductEditorBodyEmail
                    formData={formData}
                    modalProperty={modalProperty}
                    propertyInputs={propertyInputs}
                />

                <div className="invoice-product-action-cont flex-row align-right">
                    <ButtonGray title="Cancel" evOnClick={handleCancelClick} />
                    <ButtonGreen
                        title="Save"
                        evOnClick={() => {
                            handelAddEditClick({ duration: 1, unitName: 'email' });
                        }}
                    />
                </div>
            </div>
        );
    }

    if (modalProperty.property === 'custom_product') {
        return (
            <div className="invoice-product-editor">
                <ProductEditorBodyCustom
                    formData={formData}
                    modalProperty={modalProperty}
                    propertyInputs={propertyInputs}
                />

                <div className="invoice-product-action-cont flex-row align-right">
                    <ButtonGray title="Cancel" evOnClick={handleCancelClick} />
                    <ButtonGreen
                        title="Save"
                        evOnClick={() => {
                            handelAddEditClick({});
                        }}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="invoice-product-editor">
            <ProductEditorBody
                formData={formData}
                modalProperty={modalProperty}
                propertyInputs={propertyInputs}
            />

            <div className="invoice-product-action-cont flex-row align-right">
                <ButtonGray title="Cancel" evOnClick={handleCancelClick} />
                <ButtonGreen
                    title="Save"
                    evOnClick={() => {
                        handelAddEditClick({ unitName: 'month' });
                    }}
                />
            </div>
        </div>
    );
}

export default InvoiceProductEditor;
