import { icons } from '../../../../../configs/Icons';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import { useCustomerOverview } from '../BmCustomerOverviewProvider';

const filesArr = [
    {
        id: 1,
        title: 'This is filename title and type with other details',
        fileIcon: icons.file
    },
    {
        id: 2,
        title: 'This is filename title and type with other details',
        fileIcon: icons.file
    },
    {
        id: 3,
        title: 'This is filename title and type with other details',
        fileIcon: icons.file
    },
    {
        id: 4,
        title: 'This is filename title and type with other details',
        fileIcon: icons.file
    },
    {
        id: 5,
        title: 'This is filename title and type with other details',
        fileIcon: icons.file
    }
];

function FileView({ closeClick, item }) {
    return (
        <div className="file-info-row-cont flex-row position-middle align-space-between">
            <div className="file-view-cont flex-row position-middle align-space-between">
                <div className="">
                    <img src={icons.file} alt="fileIcon" />
                </div>

                <div>
                    <div className="flex-row position-middle">
                        <h2 className="file-title">{item?.title || '--'}</h2>
                    </div>
                </div>
            </div>

            <div onClick={closeClick} role="button" tabIndex={-1} className="file-delete">
                <img src={icons.crossRed} alt="delete" />
            </div>
        </div>
    );
}

function OverviewSecCol2Media() {
    const { setConfirm } = useTemplate();
    const { handleOpenModal } = useCustomerOverview();

    const handleCloseClick = (id) => {
        setConfirm({
            text: 'Are you sure you want to delete this media?',
            textAction: 'Delete',
            clickAction: () => {
                console.log('delete item', id);
                setConfirm(null);
            }
        });
    };

    return (
        <div className="section media-section overview_section-item">
            <div className="flex-row position-middle align-space-between">
                <h2 className="section-title">Media</h2>

                <div
                    onClick={() => handleOpenModal('add-media')}
                    role="button"
                    tabIndex={-1}
                    className="add-btn"
                >
                    Add
                </div>
            </div>

            {filesArr?.map((item) => (
                <FileView
                    key={item?.id}
                    item={item}
                    closeClick={() => handleCloseClick(item?.id)}
                />
            ))}
        </div>
    );
}

export default OverviewSecCol2Media;
