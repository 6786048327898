import { useState } from 'react';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import OverviewSecNotesHeader from './OverviewSecNotesHeader';
import OverviewSecNotesListItem from './OverviewSecNotesListItem';

function OverviewSecNotes({ handleExpand, expand, notesData }) {
    const [formData, setFormData] = useState({});
    const [mode, setMode] = useState({ type: '', index: 0 });

    const { createEditorFunction } = useTemplate();

    const { openEditor, closeEditor } = createEditorFunction(setFormData, setMode);

    const arr = formData?.text?.length
        ? notesData.filter((item) =>
              item?.text?.toLowerCase()?.includes(formData?.text.toLowerCase())
          )
        : notesData;

    return (
        <div
            role="button"
            tabIndex={-1}
            onFocus={() => handleExpand(2)}
            className={`overview_notes-list-cont overview_section-item ${expand ? 'active' : ''}`}
        >
            <OverviewSecNotesHeader
                openEditor={openEditor}
                closeEditor={closeEditor}
                showEditor={mode.type === 'note-add'}
                isExpand={expand}
                setFormData={setFormData}
                formData={formData}
            />

            <ul className="scroll-event">
                {arr.map((note) => (
                    <OverviewSecNotesListItem
                        key={note.id}
                        note={note}
                        formData={formData}
                        setFormData={setFormData}
                        openEditor={openEditor}
                        closeEditor={closeEditor}
                        mode={mode}
                    />
                ))}
            </ul>
        </div>
    );
}

export default OverviewSecNotes;
