import BmManageTagHeader from './components/BmManageTagHeader';
import BmManageTagTable from './components/BmManageTagTable';

function PageContent() {
    return (
        <div className="page-cont tags-page-cont">
            <BmManageTagHeader />

            <BmManageTagTable />
        </div>
    );
}

function BmManageTagsPage() {
    return <PageContent />;
}

export default BmManageTagsPage;
