import { useState } from 'react';
import { ButtonMenu } from '../../../../configs/Buttons';
import InstOption from '../../../utils/InstOption';

function Tr({ categoryInfo, handleOpenModal }) {
    const [showMenu, setShowMenu] = useState(false);

    const arr = categoryInfo;

    if (arr && !arr?.length) {
        return (
            <tr>
                <td colSpan="8" className="text-left">
                    No data found
                </td>
            </tr>
        );
    }

    return arr.map((item) => (
        <tr key={item.id} className={item?.demo ? 'placeholder-mode' : ''}>
            <td className="text-left">
                <span>{item.id}</span>
            </td>
            <td>
                <span>{item.category}</span>
            </td>
            <td className="text-right">
                <div className="flex-row align-right">
                    <span>
                        <ButtonMenu evOnClick={() => setShowMenu(item.id)}>
                            <InstOption setShowMenu={setShowMenu} showMenu={showMenu === item.id}>
                                <button
                                    type="button"
                                    onClick={() => handleOpenModal('update', item)}
                                >
                                    Edit
                                </button>
                            </InstOption>
                        </ButtonMenu>
                    </span>
                </div>
            </td>
        </tr>
    ));
}

function BmConfigCategoriesTable({
    // projectId,
    categoryInfo,
    // seCategoryInfo,
    // reloadKey,
    handleOpenModal
}) {
    // const { authInfo } = useAuth();
    // const { setMessage } = useTemplate();

    // useEffect(() => {
    //     seCategoryInfo(null);
    //     handleLoadCategoryList(authInfo, projectId)
    //         .then(({ data }) => {
    //             seCategoryInfo(data);
    //         })
    //         .catch((err) => {
    //             setMessage(err.toString(0));
    //         });
    // }, [authInfo, projectId, setMessage, reloadKey, seCategoryInfo]);

    return (
        <div className="bik-table-parent">
            <table className="bik-table">
                <thead>
                    <tr>
                        <th width="8%" className="text-left">
                            SL
                        </th>
                        <th>Category</th>
                        <th width="8%" className="text-right">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <Tr categoryInfo={categoryInfo} handleOpenModal={handleOpenModal} />
                </tbody>
            </table>
        </div>
    );
}

export default BmConfigCategoriesTable;
