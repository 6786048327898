import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PdfLayout from '../../utils/PdfLayout';
import { GetDate } from '../../utils/Show';
import { EditBtn } from './_models/ActionButtons';
import getInvoiceTitle from './_models/GetInvoiceTitle';
import UserInfo from './components/UserInfo';
import FinInvoicePaymentTable from './invoice_table/FinInvoicePaymentTable';
import FinInvoiceProductTable from './invoice_table/FinInvoiceProductTable';

function Notes({ show = true, info, openModal }) {
    const { noteTitle, noteDescription } = info;
    const style = { display: 'inline' };

    if (!show) {
        return null;
    }
    if (!noteTitle?.length) {
        return (
            <div
                style={style}
                className="non-printable"
                role="button"
                tabIndex={-1}
                onClick={() => openModal('inv-note')}
            >
                <p style={style}>Add Notes</p>
            </div>
        );
    }

    return (
        <div className="inv-notes-cont clickable">
            <h2 className="title">{noteTitle}</h2>

            {ReactHtmlParser(noteDescription)}

            <div className="info-action-button">
                <EditBtn onClickEdit={() => openModal('inv-note')} />
            </div>
        </div>
    );
}

function InvoiceTitleArea({ invoiceData, openModal }) {
    // const defaultTitle = `Invoice-${invoiceData?.info?.id || ''}-`;

    return (
        <div
            onClick={() => openModal('inv-title')}
            role="button"
            tabIndex={-1}
            className="inv-title-header non-printable clickable"
        >
            <h2>
                <span>
                    {`${getInvoiceTitle(
                        'Invoice',
                        invoiceData?.info?.id,
                        invoiceData?.info?.title
                    )}` || '... ... ...'}
                </span>
            </h2>
        </div>
    );
}

function UserView({ user, isInvoiceActive, openModal }) {
    return (
        <UserInfo
            evOnClick={!isInvoiceActive ? () => openModal('user') : null}
            className={!isInvoiceActive ? 'hover' : ''}
            img={user?.photoUrl}
            name={user?.name}
            email={[user?.mobile, user?.email].filter((d) => !!d).join(', ')}
        />
    );
}

function AddressView({ header, address, isInvoiceActive, openModal }) {
    const { city, country, email, line1, line2, mobile, name, org, state, zip } = address || {}; // telephone, fax

    return (
        <div
            role={!isInvoiceActive ? 'button' : ''}
            tabIndex={-1}
            className={`address-view  ${!isInvoiceActive ? 'clickable' : ''}`}
            onClick={null}
        >
            <h4 className="header">
                <span>{header || '-----'}</span>
            </h4>
            <div className="co-info">
                <div className="name">{name}</div>
                <div className="name">{org ? `${org}`.toUpperCase() : null}</div>
            </div>
            <p className="address">
                <span>
                    {[line1, line2, city, state, country, zip].filter((d) => !!d).join(', ') ||
                        '----------------------'}
                </span>
            </p>
            <p className="contact">
                {mobile || email ? ' Contact: ' : ''}
                <span>
                    {[mobile, email].filter((d) => !!d).join(', ') || '----------------------'}
                </span>
            </p>
            <div className="info-action-button">
                <EditBtn onClickEdit={() => openModal('address')} />
            </div>
        </div>
    );
}

function InvoiceInfo({ invoiceData }) {
    return (
        <div className="invoice-info">
            <h4>Invoice Info</h4>
            <div className="flex-row align-space-between">
                <span>Number:</span>
                <span>#{invoiceData?.info.id}</span>
            </div>
            <div className="flex-row align-space-between">
                <span>Date of Issue:</span>
                <span>{GetDate(invoiceData?.info.timeIssue)}</span>
            </div>
            <div className="flex-row align-space-between">
                <span>Payment Due Date:</span>
                <span>{GetDate(invoiceData?.info.timeDue)}</span>
            </div>
            <div className="flex-row align-space-between">
                <span>Currency:</span>
                <span>{invoiceData?.info.currency}</span>
            </div>
            <div className="flex-row align-space-between non-printable">
                <span>Status:</span>
                <span>{invoiceData?.info.status}</span>
            </div>
        </div>
    );
}

function Row1({ invoiceData, openModal }) {
    const isInvoiceActive = invoiceData?.info.status === 'active';

    return (
        <div className="flex-row align-space-between position-top row-1">
            <div>
                {/* <AddressView header="From" address={invoiceData?.addresses.billing} /> */}
                <div className="non-printable">
                    <UserView
                        user={invoiceData?.user}
                        isInvoiceActive={isInvoiceActive}
                        openModal={openModal}
                    />
                </div>

                {/* <div className="invoice-logo" id="printable">
                    <img src={icons.bikLogo} alt="" />
                </div> */}

                <AddressView
                    header="To"
                    address={invoiceData?.addresses.billing}
                    isInvoiceActive={isInvoiceActive}
                    openModal={openModal}
                />
            </div>

            <InvoiceInfo invoiceData={invoiceData} />
        </div>
    );
}

function InvoiceView({
    invoiceData,
    printVersion,
    header,
    footer,
    openModal
    // handelRemoveProduct,
    // openProductModal
}) {
    const placeholder = !invoiceData ? 'placeholder-mode' : '';
    const isClickable = typeof openModal !== 'function' ? 'non-clickable' : '';

    const handleOpenModal = (type) => {
        if (typeof openModal === 'function') {
            openModal(type);
        }
        return null;
    };

    return (
        <PdfLayout version={printVersion} header={header} footer={footer}>
            <div className={`invoice ${isClickable} ${placeholder}`}>
                <InvoiceTitleArea invoiceData={invoiceData} openModal={handleOpenModal} />
                <div className="invoice-header">
                    <Row1 invoiceData={invoiceData} openModal={handleOpenModal} />
                </div>

                <h4 className="order-header">
                    <span>Order Summery</span>
                </h4>

                <FinInvoiceProductTable items={invoiceData?.items} />

                <FinInvoicePaymentTable
                    priceTotal={invoiceData?.info?.priceTotal || 0}
                    priceOffer={invoiceData?.info?.priceOffer || 0}
                    discount={invoiceData?.info?.discount || 0}
                    vatTotal={invoiceData?.info?.vatTotal || 0}
                    billPaid={invoiceData?.info?.billPaid || 0}
                    billPayable={invoiceData?.info?.billPayable || 0}
                    currency={invoiceData?.info.currency}
                />

                {/* <FinInvoiceUpdateBtn /> */}

                <Notes
                    show={invoiceData?.info?.status !== 'active' && typeof openModal === 'function'}
                    info={invoiceData?.info || {}}
                    openModal={handleOpenModal}
                />
            </div>
        </PdfLayout>
    );
}

export default InvoiceView;
