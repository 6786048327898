import BmSchedulePageHeader from './components/BmSchedulePageHeader';

function BmSchedulePage() {
    return (
        <div className="page-cont">
            <BmSchedulePageHeader />
        </div>
    );
}

export default BmSchedulePage;
