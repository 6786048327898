export const expenseDemoData = [
    {
        id: 10000001,
        user: 'Chris Henry',
        title: 'Salary Payment',
        branch: 'Khulna Branch',
        category: 'Salary',
        note: 'Salary Payment',
        date: 1694335514,
        time: 1694335514,
        quantity: 1,
        type: 'unit',
        unitPrice: 4000,
        priceTotal: 4000,
        status: 'active',
        demo: false
    },
    {
        id: 10000002,
        user: 'Christopher Nolan',
        title: 'Breakfast',
        branch: 'Jashore Branch',
        category: 'Food',
        note: 'Breakfast at Hotel',
        date: 1694335514,
        time: 1694335514,
        quantity: 1,
        type: 'unit',
        unitPrice: 200,
        priceTotal: 200,
        status: 'inactive',
        demo: false
    }
];

export const expenseBranchData = [
    {
        id: 'Khulna Branch',
        title: 'Khulna Branch'
    },
    {
        id: 'Jashore Branch',
        title: 'Jashore Branch'
    },
    {
        id: 'Dhaka Branch',
        title: 'Dhaka Branch'
    },
    {
        id: 'Rajshahi Branch',
        title: 'Rajshahi Branch'
    },
    {
        id: 'Barishal Branch',
        title: 'Barishal Branch'
    },
    {
        id: 'Chittagong Branch',
        title: 'Chittagong Branch'
    },
    {
        id: 'Sylhet Branch',
        title: 'Sylhet Branch'
    },
    {
        id: 'Rangpur Branch',
        title: 'Rangpur Branch'
    },
    {
        id: 'Mymensingh Branch',
        title: 'Mymensingh Branch'
    }
];

export const expenseCategoryData = [
    {
        id: 'ads',
        title: 'Ads'
    },
    {
        id: 'chemical',
        title: 'Chemical'
    },
    {
        id: 'food',
        title: 'Food'
    },
    {
        id: 'fixed',
        title: 'Fixed'
    },
    {
        id: 'fuel',
        title: 'Fuel'
    },
    {
        id: 'instrument',
        title: 'Instrument'
    },
    {
        id: 'marketingMaterial',
        title: 'Marketing Material'
    },
    {
        id: 'mobileRecharge',
        title: 'Mobile Recharge'
    },
    {
        id: 'other',
        title: 'Other'
    },
    {
        id: 'officeSetup',
        title: 'Office Setup'
    },
    {
        id: 'repair',
        title: 'Repair'
    },
    {
        id: 'roomRent',
        title: 'Room Rent'
    },
    {
        id: 'salary',
        title: 'Salary'
    },
    {
        id: 'tips',
        title: 'Tips & Convince'
    },
    {
        id: 'transport',
        title: 'Transport'
    },
    {
        id: 'workingMaterial',
        title: 'Working Material'
    }
];

export const bmExpenseTypes = [
    {
        id: 'unit',
        title: 'Unit'
    },
    {
        id: 'quantity',
        title: 'Quantity'
    },
    {
        id: 'fixed',
        title: 'Fixed'
    },
    {
        id: 'variable',
        title: 'Variable'
    },
    {
        id: 'one-time',
        title: 'One-time'
    },
    {
        id: 'recurring',
        title: 'Recurring'
    },
    {
        id: 'travel',
        title: 'Travel'
    },
    {
        id: 'entertainment',
        title: 'Entertainment'
    },
    {
        id: 'utilities',
        title: 'Utilities'
    },
    {
        id: 'rent',
        title: 'Rent'
    },
    {
        id: 'maintenance',
        title: 'Maintenance'
    },
    {
        id: 'marketing',
        title: 'Marketing'
    },
    {
        id: 'supplies',
        title: 'Supplies'
    },
    {
        id: 'insurance',
        title: 'Insurance'
    },
    {
        id: 'taxes',
        title: 'Taxes'
    },
    {
        id: 'software',
        title: 'Software'
    },
    {
        id: 'hardware',
        title: 'Hardware'
    },
    {
        id: 'consulting',
        title: 'Consulting'
    },
    {
        id: 'training',
        title: 'Training'
    },
    {
        id: 'employeeBenefits',
        title: 'Employee Benefits'
    },
    {
        id: 'miscellaneous',
        title: 'Miscellaneous'
    }
];

export default null;
