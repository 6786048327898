import { useEffect, useState } from 'react';
import { ButtonMenu } from '../../../../configs/Buttons';
import { designationDemoData } from '../../../../data/designationDemoData';
import { useAuth } from '../../../features/auth/AuthProvider';
import { useTemplate } from '../../../features/template/TemplateProvider';
import InstOption from '../../../utils/InstOption';
import { formatSingleDigit, formatTime } from '../../../utils/Show';
import { handleLoadDesignationList } from './BmConfigDesignationOperation';

function Tr({ handleOpenModal, designationInfo, getSalaryDisburse, getHolidayInfo }) {
    const [showMenu, setShowMenu] = useState(false);

    const arr = designationInfo || designationDemoData;

    if (arr && !arr?.length) {
        return (
            <tr>
                <td colSpan="9" className="text-left">
                    No data found
                </td>
            </tr>
        );
    }

    return arr.map((item) => (
        <tr key={item.id} className={item?.demo ? 'placeholder-mode' : ''}>
            <td className="text-left">
                <span>{item.id}</span>
            </td>
            <td className="text-left">
                <span>{item.designation || '--'}</span>
            </td>
            <td className="text-center">
                <span className="amount-font">{formatSingleDigit(item?.grade) || '--'}</span>
            </td>
            <td className="text-left">
                <span className="amount-font">{`${item.salaryMin}-${item.salaryMax}`}</span>
            </td>

            <td className="text-left">
                <span>{getSalaryDisburse(item)}</span>
            </td>

            <td className="text-left">
                <span>{getHolidayInfo(item)}</span>
            </td>
            <td className="text-left">
                <span>{formatTime(parseInt(item.reportingTime, 10))}</span>
            </td>
            <td className="text-left">
                <span>{item.workingHour}Hr</span>
            </td>

            <td className="text-left">
                <span className={`status ${item.status}`}>{item.status}</span>
            </td>
            <td className="text-right">
                <div className="flex-row align-right">
                    <span>
                        <ButtonMenu evOnClick={() => setShowMenu(item.id)}>
                            <InstOption setShowMenu={setShowMenu} showMenu={showMenu === item.id}>
                                <button
                                    type="button"
                                    onClick={() => handleOpenModal('Designation-update', item)}
                                >
                                    Edit
                                </button>
                            </InstOption>
                        </ButtonMenu>
                    </span>
                </div>
            </td>
        </tr>
    ));
}

function BmConfigDesignationTable({
    projectId,
    designationInfo,
    setDesignationInfo,
    reloadKey,
    handleOpenModal,
    getSalaryDisburse,
    getHolidayInfo
}) {
    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    useEffect(() => {
        setDesignationInfo(null);
        handleLoadDesignationList(authInfo, projectId)
            .then(({ data }) => {
                setDesignationInfo(data);
            })
            .catch((err) => {
                setMessage(err.toString(0));
            });
    }, [authInfo, projectId, setMessage, reloadKey, setDesignationInfo]);

    return (
        <div className="bik-table-parent">
            <table className="bik-table">
                <thead>
                    <tr>
                        <th width="10%" className="text-left">
                            ID
                        </th>
                        <th width="20%" className="text-left">
                            Designation
                        </th>
                        <th width="7%" className="text-center">
                            Grade
                        </th>
                        <th width="12%" className="text-left">
                            Salary
                        </th>
                        <th width="15%" className="text-left">
                            Salary Disburse
                        </th>
                        <th width="16%" className="text-left">
                            Weekly Holiday
                        </th>
                        <th width="10%" className="text-left">
                            Reporting Time
                        </th>
                        <th width="8%" className="text-left">
                            Working Hour
                        </th>
                        <th width="7%" className="text-left">
                            Status
                        </th>
                        <th width="8%" className="text-right">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <Tr
                        designationInfo={designationInfo}
                        handleOpenModal={handleOpenModal}
                        getSalaryDisburse={getSalaryDisburse}
                        getHolidayInfo={getHolidayInfo}
                    />
                </tbody>
            </table>
        </div>
    );
}

export default BmConfigDesignationTable;
