import { useEffect, useState } from 'react';
import { useAuth } from '../../../features/auth/AuthProvider';
import { getProjectId, useAdvMenu } from '../../../features/menus/AdvMenuProvider';
import { useTemplate } from '../../../features/template/TemplateProvider';
import BmTeamPageProvider, { useTeams } from './BmTeamPageProvider';
import { handleLoadTeamSupportList } from './BmTeamsOperation';
import BmTeamsPageHeader from './components/BmTeamsPageHeader';
import BmTeamsPageTable from './components/BmTeamsPageTable';
import TeamAddModal from './modals/TeamAddModal';
import TeamUpdateModal from './modals/TeamUpdateModal';

function PageContent() {
    const [requiredData, setRequiredData] = useState([]);
    const {
        setTeamsData,
        teamsData,
        reloadKey,
        handleReload,
        handleOpenModal,
        handleCloseModal,
        mode
    } = useTeams();

    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();

    const { activeProject } = useAdvMenu();
    const projectId = activeProject?.id || getProjectId();

    useEffect(() => {
        handleLoadTeamSupportList(authInfo, projectId)
            .then(({ data }) => {
                setRequiredData(data);
            })
            .catch((err) => {
                setMessage(err.toString(0));
            });
    }, [authInfo, projectId, setMessage]);

    return (
        <div className="page-cont teams-page-cont">
            <BmTeamsPageHeader
                handleOpenModal={handleOpenModal}
                handleReload={handleReload}
                requiredData={requiredData}
            />

            <BmTeamsPageTable
                handleOpenModal={handleOpenModal}
                setTeamsData={setTeamsData}
                teamsData={teamsData}
                reloadKey={reloadKey}
                projectId={projectId}
            />

            {/* modals */}
            {mode.type === 'add-team' ? (
                <TeamAddModal
                    handleCloseModal={handleCloseModal}
                    handleReload={handleReload}
                    requiredData={requiredData}
                />
            ) : null}
            {mode.type === 'update-team' ? (
                <TeamUpdateModal
                    handleCloseModal={handleCloseModal}
                    handleReload={handleReload}
                    teamData={mode?.data}
                    requiredData={requiredData}
                />
            ) : null}
        </div>
    );
}

function BmTeamsPage() {
    return (
        <BmTeamPageProvider>
            <PageContent />
        </BmTeamPageProvider>
    );
}

export default BmTeamsPage;
