/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { images } from '../../../configs/Icons';
import WebMenus from '../../app/home/components/WebMenus';
import AppActivationSection from '../app-manage/AppActivationSection';
import AdvMenu from '../menus/AdvMenu';
import { setProject, useAdvMenu } from '../menus/AdvMenuProvider';
import { useAuth } from './AuthProvider';

function AuthPage({ children }) {
    const { projectList, handelMenu, drawerOpen, activeProject, getServiceStatus, setDrawerOpen } =
        useAdvMenu();
    const { authInfo } = useAuth();
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const bodyRef = useRef();

    const status = getServiceStatus(pathname);

    useEffect(() => {
        // Function to update the bodyWidth
        const updateWidth = () => {
            if (bodyRef.current) {
                const newWidth = parseInt(bodyRef.current.offsetWidth, 10);
                if (newWidth < 1230) {
                    setDrawerOpen(false);
                } else if (newWidth > 1460) {
                    setDrawerOpen(true);
                }
            }
        };
        // Add event listener on component mount
        window.addEventListener('resize', updateWidth);

        // Call the updateWidth function initially
        updateWidth();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, [setDrawerOpen]);

    // --Menu Control
    useEffect(() => {
        handelMenu(pathname);
    }, [handelMenu, pathname, projectList, search]);

    // --Project Control
    useEffect(() => {
        let initialPath = '';
        if (pathname && pathname.length <= 1) {
            initialPath = 'inventory/dashboard';
            setProject(navigate, activeProject, initialPath);
        }
        setProject(navigate, activeProject);
    }, [activeProject, navigate, pathname]);

    if (authInfo.loading || authInfo?.error !== 0) {
        return (
            <div className="screen-center">
                <img src={images.loading} alt="Loading..." />
            </div>
        );
    }

    return (
        <div className="page">
            <WebMenus status={status} />
            <AdvMenu />
            <div
                ref={bodyRef}
                className={`menu-cont ${drawerOpen && status ? 'd-opened' : 'd-closed'}`}
            >
                {status || pathname.includes('project-manage') ? (
                    children
                ) : (
                    <AppActivationSection show={status !== null} />
                )}
            </div>
            <Tooltip id="myTooltip" style={{ maxWidth: '250px' }} />
        </div>
    );
}

export default AuthPage;
