import { MD5 } from 'crypto-js';
import dayjs from 'dayjs';

export const mkKey = () => MD5(`${Math.random()}/${dayjs.unix()}`);

export const mkToken = (length = 32) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export default null;
