import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ButtonReload } from '../../../../../configs/Buttons';
import { mkRelativeUrl } from '../../../../features/menus/AdvMenuProvider';

function BmOrdersPageHeader({ handleReload }) {
    const searchRef = useRef();

    const navigate = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const customerId = queryParams.get('customerId');

    const handleFocus = () => {
        searchRef.current.style.borderColor = 'rgba(245, 3, 3, 0.5)';
    };

    const handleBlur = () => {
        searchRef.current.style.borderColor = 'rgb(28, 38, 73, 0.2)';
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            searchRef.current.style.borderColor = 'rgb(28, 38, 73, 0.2)';
        }
    };

    useEffect(() => {
        searchRef.current.onchange = (ev) => {
            ev.preventDefault();
            const { value } = ev.target;

            if (value.length === 8 && /^\d*$/.test(value)) {
                navigate(
                    mkRelativeUrl(`/business-manager/orders/`, {
                        customerId: value
                    })
                );
            }
        };

        searchRef.current.value = customerId;
    }, [customerId, navigate]);

    return (
        <div className="page-header-cont flex-row position-middle align-space-between">
            <div className="flex-row position-middle">
                <h2 className="page-header_title">Orders</h2>

                <div className="search-input">
                    <input
                        ref={searchRef}
                        type="text"
                        placeholder="Ex: 10000000"
                        className="form-input w-100p"
                        onKeyPress={handleKeyPress}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                    />
                </div>
            </div>

            <ButtonReload evOnClick={handleReload} />
        </div>
    );
}

export default BmOrdersPageHeader;
