import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonPurple, ButtonReload } from '../../../../../configs/Buttons';
import { mkRelativeUrl } from '../../../../features/menus/AdvMenuProvider';
import SearchWithFilter from '../../../../features/search-filter/SearchWithFilter';
import TableFiltersOptions from './TableFiltersOptions';

// const initialState = {
//     q: '',
//     source: '',
//     dateFrom: '',
//     dateTo: '',
//     status: ''
// };

function BmCustomersPageHeader({ openModal, handleReload }) {
    // get query params
    // const { search } = useLocation();
    // const url = new URLSearchParams(search);
    // const q = url.get('q');
    // const source = url.get('source');
    // const dateFrom = url.get('dateFrom');
    // const dateTo = url.get('dateTo');
    // const status = url.get('status');

    // const initialObject = {
    //     q,
    //     source,
    //     dateFrom,
    //     dateTo,
    //     status
    // };

    const [formData, setFormData] = useState({});
    const navigate = useNavigate();

    const dateRef = useRef();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleInitialData = () => {
        const queryParams = {};

        if (formData.q) {
            queryParams.q = formData?.q;
        }

        if (formData.source) {
            queryParams.source = formData?.source;
        }

        if (formData.dateFrom) {
            queryParams.dateFrom = formData?.dateFrom;
        }

        if (formData.dateTo) {
            queryParams.dateTo = formData?.dateTo;
        }

        if (formData.status) {
            queryParams.status = formData?.status;
        }

        return queryParams;
    };

    const handleApplyClick = () => {
        navigate(mkRelativeUrl('/business-manager/customers/', handleInitialData()));
        setFormData(handleInitialData());
    };

    return (
        <div className="page-header-cont flex-row position-middle align-space-between">
            <div>
                <h2 className="page-header_title">Customers</h2>
            </div>

            <div className="w-100p">
                <SearchWithFilter
                    dateRef={dateRef}
                    name="q"
                    setFormData={setFormData}
                    formData={formData}
                    placeholder="Name or Phone Number"
                    evApplyClick={handleApplyClick}
                >
                    <TableFiltersOptions
                        setFormData={setFormData}
                        formData={formData}
                        handleChange={handleChange}
                        dateRef={dateRef}
                    />
                </SearchWithFilter>
            </div>

            <div className="flex-row position-stretch">
                <ButtonReload evOnClick={handleReload} className="mr-2" />

                <ButtonPurple evOnClick={() => openModal('addCustomer')} title="Add Customer" />
            </div>
        </div>
    );
}

export default BmCustomersPageHeader;
