/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import { ButtonGray, ButtonGreen } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import LibModal from '../../../../lib/LibModal';
import { handelMakeReopen } from '../InvoiceUpdateOperation';

function FinInvoiceReOpen({ show, invoiceId, handelReload, setOpen }) {
    const [formData, setFormData] = useState({ note: '' });

    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    const handleValueChange = (ev) => {
        const { name, value } = ev.target;

        if (name) {
            setFormData((cuData) => ({ ...cuData, [name]: value }));
        }
    };

    const handelReopen = () => {
        setMessage('Reopening...');

        handelMakeReopen(authInfo, invoiceId, formData.note)
            .then(({ message }) => {
                setMessage(message);
                setOpen(false);
                handelReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    if (!show) {
        return null;
    }

    return (
        <LibModal title="Reopen Invoice" subTitle={`#${invoiceId}`} setOpen={setOpen}>
            <div className="invoice-reopen-popup">
                <div className="input-area">
                    <label htmlFor="note" className="label">
                        Notes:
                    </label>
                    <br />
                    {/* <small className="label-suggestion">
                        Please provide a concise explanation for reopening the invoice, including
                        any specific changes or corrections needed. Feel free to include relevant
                        supporting details. Thank you for your cooperation!
                    </small> */}
                    <textarea
                        onChange={handleValueChange}
                        value={formData.note}
                        type="text"
                        name="note"
                        placeholder="Please provide a concise explanation for reopening the invoice, including any specific changes or corrections needed. Feel free to include relevant supporting details. Thank you for your cooperation!"
                        className="form-input"
                    />
                </div>

                <div className="flex-row align-right">
                    <ButtonGray evOnClick={() => setOpen(false)} title="Cancel" />

                    <ButtonGreen evOnClick={handelReopen} title="Open" />
                </div>
            </div>
        </LibModal>
    );
}

export default FinInvoiceReOpen;
