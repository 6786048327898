import AxiosAPI from '../../../utils/AxiosAPI';

export function handelLoadProducts(authInfo) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/inventory/products/`)
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handelAddProduct(authInfo, formData) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/inventory/product/add/`)
            .post({ ...formData })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handelUpdateProduct(authInfo, formData) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/inventory/product/update/`)
            .post({ ...formData })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export default null;
