import { ButtonGray, ButtonGreen } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import LibModal from '../../../../lib/LibModal';
import AnimateInputField from '../../../../utils/AnimateInputField';
import { handleUpdateCategory } from '../BmConfigCategoryOperation';

function BmConfigCategoryUpdateModal({
    handleCloseModal,
    handleReload,
    formData,
    handleChange,
    projectId
}) {
    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    const handleSave = () => {
        setMessage('Updating Category...');
        handleUpdateCategory(authInfo, formData, projectId)
            .then(({ message }) => {
                setMessage(message);
                handleCloseModal();
                handleReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <div className="categories-action-modal">
            <LibModal title="Update category" setOpen={handleCloseModal}>
                <div className="modal-body-cont">
                    <div>
                        <div className="input-row">
                            <AnimateInputField
                                title="Category"
                                name="category"
                                formData={formData}
                                handelChange={handleChange}
                            />
                        </div>

                        {/* <div className="input-row">
                            <AnimateTextareaField
                                title="Sub Category"
                                name="subCategory"
                                formData={formData}
                                handelChange={handleChange}
                            />
                        </div> */}
                    </div>
                    <div className="modal-action flex-row align-right">
                        <div className="flex-row position-middle">
                            <ButtonGray title="Cancel" evOnClick={handleCloseModal} />
                            <ButtonGreen title="Update" evOnClick={handleSave} />
                        </div>
                    </div>
                </div>
            </LibModal>
        </div>
    );
}

export default BmConfigCategoryUpdateModal;
