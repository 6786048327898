import ConLeftColConversationBody from './ConLeftColConversationBody';
import ConLeftColConversationHeader from './ConLeftColConversationHeader';

function ConLeftColConversation() {
    return (
        <div className="con_conversation-cont">
            <ConLeftColConversationHeader />

            <ConLeftColConversationBody />
        </div>
    );
}

export default ConLeftColConversation;
