import { useState } from 'react';
import { ButtonGray, ButtonGreen } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { getProjectId } from '../../../../features/menus/AdvMenuProvider';
import PhoneInput from '../../../../features/phone-input/PhoneInput';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import LibModal from '../../../../lib/LibModal';
import AnimateInputField, { AnimateTextareaField } from '../../../../utils/AnimateInputField';
import { handleAddCustomer } from '../BmCustomerOperation';

const initialState = {
    name: '',
    phone: '',
    address: ''
};

function CustomerAddModal({ setOpen, handleReload }) {
    const [formData, setFormData] = useState(initialState);

    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();

    const projectId = getProjectId();

    const handelChange = (ev) => {
        const { name } = ev.target;
        if (name) {
            setFormData((d) => ({ ...d, [name]: ev.target.value }));
        }
    };

    const handleAddButton = () => {
        setMessage('Adding Customer...');
        handleAddCustomer(authInfo, formData, projectId)
            .then(({ message }) => {
                setOpen(false);
                setFormData(initialState);
                setMessage(message);
                handleReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <LibModal title="Add Customer" setOpen={setOpen}>
            <div className="customer-add-modal">
                <div>
                    <div className="input-row flex-row flex-wrap align-space-between">
                        <div className="inp-fill-2">
                            <AnimateInputField
                                title="Name"
                                name="name"
                                required
                                formData={formData}
                                handelChange={handelChange}
                            />
                        </div>

                        <div className="inp-fill-2">
                            <PhoneInput
                                title="Phone"
                                name="phone"
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </div>
                    </div>
                    <div className="input-row">
                        <AnimateTextareaField
                            title="Address"
                            name="address"
                            formData={formData}
                            handelChange={handelChange}
                        />
                    </div>
                </div>

                <div className="flex-row align-right gap-10">
                    <ButtonGray evOnClick={() => setOpen(false)} title="Cancel" />
                    <ButtonGreen evOnClick={handleAddButton} title="Add" />
                </div>
            </div>
        </LibModal>
    );
}

export default CustomerAddModal;
