import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonPurple, ButtonReload } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';
import { mkRelativeUrl } from '../../../../features/menus/AdvMenuProvider';
import SearchWithFilter from '../../../../features/search-filter/SearchWithFilter';
import FilterRow from '../../../../features/search-filter/components/FilterRow';
import AnimateInputField, {
    AnimateDateField,
    AnimateSelectField,
    addOption
} from '../../../../utils/AnimateInputField';
import InstOption from '../../../../utils/InstOption';

function BmTeamsPageHeader({ handleOpenModal, handleReload, requiredData }) {
    const [formData, setFormData] = useState({});
    const [showMenu, setShowMenu] = useState(false);

    const navigate = useNavigate();
    // const [searchParams] = useSearchParams();

    const dateRef = useRef();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleInitialData = () => {
        const queryParams = {};

        if (formData.q) {
            queryParams.q = formData?.q;
        }

        if (formData.name) {
            queryParams.name = formData?.name?.split(' ')?.join('-');
        }

        if (formData.designation) {
            queryParams.designation = formData?.designation?.split(' ')?.join('-');
        }

        if (formData.branch) {
            queryParams.branch = formData?.branch?.split(' ')?.join('-');
        }

        if (formData.dateJoined) {
            queryParams.dateJoined = formData?.dateJoined;
        }

        return queryParams;
    };

    const handleApplyClick = () => {
        navigate(mkRelativeUrl('/business-manager/teams/', handleInitialData()));
        setFormData(handleInitialData());
    };

    return (
        <div className="page-header-cont flex-row position-middle align-space-between">
            <div>
                <h2 className="page-header_title">Teams</h2>
            </div>

            <div>
                <div className="w-100p">
                    <SearchWithFilter
                        dateRef={dateRef}
                        name="q"
                        setFormData={setFormData}
                        formData={formData}
                        placeholder="Search Something...."
                        evApplyClick={handleApplyClick}
                    >
                        <div className="table-filter-option">
                            <FilterRow title="Name">
                                <AnimateInputField
                                    title="Type Name"
                                    name="name"
                                    formData={formData}
                                    handelChange={handleChange}
                                />
                            </FilterRow>
                            <FilterRow title="Designation">
                                <AnimateSelectField
                                    title="Select Designation"
                                    name="designation"
                                    formData={formData}
                                    handelChange={handleChange}
                                    options={requiredData?.designations?.map((item) =>
                                        addOption(item.id, item.name, item?.name?.toLowerCase())
                                    )}
                                />
                            </FilterRow>
                            <FilterRow title="Branch Name">
                                <AnimateSelectField
                                    title="Select Branch"
                                    name="branch"
                                    formData={formData}
                                    handelChange={handleChange}
                                    options={requiredData?.branches?.map((item) =>
                                        addOption(item.id, item.name, item?.name?.toLowerCase())
                                    )}
                                />
                            </FilterRow>
                            <FilterRow title="Joined Date">
                                <AnimateDateField
                                    title="Select Joined Date"
                                    name="dateJoined"
                                    formData={formData}
                                    handelChange={handleChange}
                                    dRef={dateRef}
                                />
                            </FilterRow>
                        </div>
                    </SearchWithFilter>
                </div>
            </div>

            <div className="flex-row position-stretch action">
                <div className="reload-button">
                    <ButtonReload evOnClick={handleReload} />
                </div>

                <ButtonPurple title="Add New" evOnClick={() => handleOpenModal('add-team')} />
                <div className="p-relative">
                    <div
                        role="button"
                        tabIndex={-1}
                        onClick={() => setShowMenu((prev) => !prev)}
                        className="flex-row position-stretch"
                    >
                        <img src={icons?.optionFill} alt="" />
                    </div>
                    <InstOption setShowMenu={setShowMenu} showMenu={showMenu}>
                        <button type="button" onClick={null}>
                            Manage
                        </button>
                    </InstOption>
                </div>
            </div>
        </div>
    );
}

export default BmTeamsPageHeader;
