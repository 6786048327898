import AssetItemRow from './AssetItemRow';

const pvcTanks = [
    {
        id: 1,
        size: 500
    },
    {
        id: 2,
        size: 500
    },
    {
        id: 3,
        size: 1000
    }
];

function AssetPvc({ handleAssetItemClick, selectedItem }) {
    return pvcTanks.map((item) => {
        const isActive = selectedItem?.filter(
            (i) => i?.id.toString() === item?.id.toString()
        )?.length;
        return (
            <AssetItemRow
                active={isActive}
                evOnClick={handleAssetItemClick}
                key={item?.id}
                item={item}
            />
        );
    });
}

export default AssetPvc;
