import { getProjectId, useAdvMenu } from '../../../features/menus/AdvMenuProvider';
import BmConfigProvider from './BmConfigProvider';
import BmConfigFbPageInfo from './configuration_fbpageinfo/BmConfigFbPageInfo';
import BmConfigPageInfoSyncTable from './configuration_infosynctable/BmConfigPageInfoSyncTable';

function BmConfigPageInfoSection() {
    const { activeProject } = useAdvMenu();
    const projectId = activeProject?.id || getProjectId();

    return (
        <BmConfigProvider>
            <BmConfigFbPageInfo projectId={projectId} />

            <BmConfigPageInfoSyncTable projectId={projectId} />
        </BmConfigProvider>
    );
}

export default BmConfigPageInfoSection;
