import { useEffect, useState } from 'react';
import { useAuth } from '../../../features/auth/AuthProvider';
import { useTemplate } from '../../../features/template/TemplateProvider';
import { handelLoadProducts } from './InvProductOperation';
import InvProductPageHeader from './InvProductPageHeader';
import ProductsTable from './components/ProductsTable';

const productsData = [
    {
        id: 10001010,
        name: 'Product 1',
        type: 'service',
        subType: 'Pvc Tank Wash',
        description: 'Pvc Tank Wash',
        price: 100,
        status: 'active'
    },
    {
        id: 10001011,
        name: 'Product 2',
        type: 'service',
        subType: 'Concrete Tank Wash',
        description: 'Concrete Tank Wash',
        price: 200,
        status: 'inactive'
    },
    {
        id: 10001012,
        name: 'Product 3',
        type: 'service',
        subType: 'Pvc Tank Wash',
        description: 'Pvc Tank Wash',
        price: 300,
        status: 'active'
    },
    {
        id: 10001013,
        name: 'Product 4',
        type: 'service',
        subType: 'Concrete Tank Wash',
        description: 'Concrete Tank Wash',
        price: 400,
        status: 'active'
    }
];

// const products = [];

function InvProductPage() {
    const [products, setProducts] = useState(productsData);
    const [reloadKey, setReloadKey] = useState(Math.random());

    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    useEffect(() => {
        handelLoadProducts(authInfo)
            .then(() => {
                // setProducts(data?.products);
                setProducts(products);
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    }, [authInfo, setMessage, reloadKey, products]);

    const handleReload = () => {
        // setProducts(null);
        setReloadKey((d) => d + 1);
    };

    return (
        <div className="page-cont inv-product-page-cont">
            <InvProductPageHeader handleReload={handleReload} />
            <ProductsTable products={products} />
        </div>
    );
}

export default InvProductPage;
