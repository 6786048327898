import { useState } from 'react';
import { ButtonGreen } from '../../../configs/Buttons';
import { icons } from '../../../configs/Icons';
import { AnimateSelectField, addOption } from '../../utils/AnimateInputField';

const pricePerSize = [
    {
        id: 1,
        size: 500,
        price: 399
    },
    {
        id: 2,
        size: 800,
        price: 500
    },
    {
        id: 3,
        size: 1000,
        price: 600
    },
    {
        id: 4,
        size: 1500,
        price: 700
    },
    {
        id: 5,
        size: 2000,
        price: 800
    },
    {
        id: 6,
        size: 3000,
        price: 1000
    },
    {
        id: 7,
        size: 5000,
        price: 1200
    },
    {
        id: 8,
        size: 7000,
        price: 1400
    },
    {
        id: 9,
        size: 10000,
        price: 1500
    }
];

const quantity = [
    {
        id: 1
    },
    {
        id: 2
    },
    {
        id: 3
    },
    {
        id: 4
    },
    {
        id: 5
    },
    {
        id: 6
    },
    {
        id: 7
    },
    {
        id: 8
    }
];

function PvcAddRow({ itemIndex, formData, handleChange, handleRemoveRow }) {
    return (
        <div className="feature-asset-selection-row pvc flex-row position-middle align-space-between">
            <div className="">
                <AnimateSelectField
                    title="Select Tank Size(LTR)"
                    name={`size${itemIndex}`}
                    formData={formData}
                    handelChange={handleChange}
                    options={pricePerSize?.map((item) => addOption(item.id, item.size, item.size))}
                />
            </div>

            <div className="flex-row position-middle align-center">
                <AnimateSelectField
                    title="Select Quantity"
                    name={`quantity${itemIndex}`}
                    formData={formData}
                    handelChange={handleChange}
                    options={quantity?.map((item) => addOption(item.id, item.id, item.id))}
                />
            </div>

            <div className="">
                <div
                    onClick={() => handleRemoveRow(itemIndex)}
                    role="button"
                    tabIndex={-1}
                    className="flex-row position-middle align-right"
                >
                    <img src={icons.crossRed} alt="" />
                </div>
            </div>
        </div>
    );
}

function TotalPriceRow({ handleAddMore }) {
    return (
        <div className="feature-asset-selection-row flex-row position-middle align-space-between">
            <div className="flex-row position-middle align-space-between">
                <ButtonGreen
                    evOnClick={handleAddMore}
                    title="Add More"
                    className="feature-asset-add-more-btn"
                />
            </div>

            <div className="flex-row position-middle align-start" />

            <div className="" />
        </div>
    );
}

function AssetPvcTank({ formData, setFormData }) {
    const [pvcRow, setPvcRow] = useState([{ id: 1 }]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleRemoveRow = (id) => {
        // remove except id 1
        if (id !== 1) {
            setPvcRow((prevData) => prevData.filter((item) => item.id !== id));
            setFormData((prevData) => {
                const newData = { ...prevData };
                delete newData[`size${id}`];
                return newData;
            });
        }
    };

    const handleAddMore = () => {
        const lastRowFormData =
            formData[`size${pvcRow[pvcRow.length - 1].id}`] &&
            formData[`quantity${pvcRow[pvcRow.length - 1].id}`];
        if (!lastRowFormData) {
            return;
        }

        setPvcRow((prevData) => {
            const newId = prevData.length > 0 ? prevData[prevData.length - 1].id + 1 : 1;
            return [...prevData, { id: newId }];
        });
    };

    return (
        <div className="">
            <div className="feature-asset-selection-cont">
                {pvcRow?.map((item) => (
                    <PvcAddRow
                        key={item?.id}
                        itemIndex={item?.id}
                        formData={formData}
                        handleChange={handleChange}
                        handleRemoveRow={handleRemoveRow}
                    />
                ))}
            </div>
            <TotalPriceRow handleAddMore={handleAddMore} />
        </div>
    );
}

export default AssetPvcTank;
