import { useState } from 'react';
import { icons } from '../../../../configs/Icons';
import { GetDate } from '../../../utils/Show';
import { UserInfoV3 } from '../../user-info/UserInfo';
import { getRoleStatus } from '../__models/getRoleStatus';
import RoleManageOptions from './RoleManageOptions';

function Tr({ roles, handleOpenModal, handleReload }) {
    const [showMenu, setShowMenu] = useState(false);

    const handleMenu = (ev, id) => {
        ev.stopPropagation();
        setShowMenu(id);
    };

    if (roles && !roles?.length) {
        return (
            <tr>
                <td colSpan="4" className="text-center">
                    No Roles Found!
                </td>
            </tr>
        );
    }

    return roles?.map((item) => (
        <tr key={item?.roleId + 1} className={item?.empty ? 'placeholder-mode' : ''}>
            <td className="text-left">
                <UserInfoV3
                    name={
                        <span>
                            {item?.name} {getRoleStatus(item)}
                        </span>
                    }
                />
            </td>

            <td className="text-left">
                <span>{GetDate(item?.timeAccepted)}</span>
            </td>

            <td className="text-left">
                <span>{item?.roleTitle}</span>
            </td>

            <td className="text-right">
                <div className="flex-row align-right p-relative action">
                    {item?.buttons?.length ? (
                        <span>
                            <button
                                type="button"
                                className=""
                                onClick={(ev) => handleMenu(ev, item.roleId)}
                            >
                                <img src={icons?.optionFill} alt="" />
                            </button>
                        </span>
                    ) : null}

                    <RoleManageOptions
                        item={item}
                        showMenu={showMenu}
                        setShowMenu={setShowMenu}
                        handleOpenModal={handleOpenModal}
                        handleReload={handleReload}
                    />
                </div>
            </td>
        </tr>
    ));
}

function RoleManageTable({ roles, handleOpenModal, handleReload }) {
    return (
        <table className="bik-table">
            <thead>
                <tr>
                    <th className="text-left">User</th>
                    <th width="13%" className="text-left">
                        Assign Date
                    </th>
                    <th width="9%" className="text-left">
                        Role
                    </th>
                    <th width="8%" className="text-right">
                        Action
                    </th>
                </tr>
            </thead>
            <tbody className="">
                <Tr roles={roles} handleOpenModal={handleOpenModal} handleReload={handleReload} />
            </tbody>
        </table>
    );
}

export default RoleManageTable;
