import { Link, useParams } from 'react-router-dom';
import { mkRelativeUrl } from '../../../../features/menus/AdvMenuProvider';
import { GetDate } from '../../../../utils/Show';

function OrderedProduct({ date, title, quantity, amount }) {
    return (
        <div className="flex-row align-space-between position-middle">
            <div>
                <p className="order-date">
                    <span>{GetDate(date) || '--'}</span>
                </p>
                <h2 className="order-title">
                    <span>{title || '--'}</span>
                </h2>
                <p className="order-quantity">
                    <span>{quantity || '--'}</span>
                </p>
            </div>

            <div>
                <h2 className="order-amount">BDT {amount}</h2>
            </div>
        </div>
    );
}

function OverviewSecCol2Graph({ order }) {
    const { lo, orders } = order || {};

    const { id } = useParams();

    return (
        <div className="section order-section overview_section-item">
            <div className="section-header flex-row position-stretch align-space-between">
                <div className="flex-row position-middle">
                    <h2 className="section-title">Orders</h2>
                </div>
                <div className="flex-row position-middle">
                    <p className="lo-date">LO: {GetDate(lo)}</p>
                </div>
            </div>

            {orders?.slice(0, 2)?.map((item) => (
                <OrderedProduct
                    key={item?.id}
                    date={item.date}
                    title={item?.title}
                    quantity={`${item?.size} * ${item?.quantity}`}
                    amount={item?.price}
                />
            ))}

            {/* <OrderedProduct date={1690438553} title="Pipe Wash" quantity="500*5" amount={800} /> */}

            {orders?.length > 2 ? (
                <div className="text-right">
                    <Link
                        to={mkRelativeUrl(`/business-manager/orders/`, {
                            customerId: id
                        })}
                        role="button"
                        tabIndex={-1}
                        className="view-all-text"
                    >
                        View All Order
                    </Link>
                </div>
            ) : null}

            <div className="flex-row align-space-between position-middle">
                <p className="total-text">Total Spend:</p>
                <p className="total-amount">BDT 9600</p>
            </div>
        </div>
    );
}

export default OverviewSecCol2Graph;
