import { useState } from 'react';
import { ButtonGray, ButtonGreen } from '../../../../configs/Buttons';
import LibModal from '../../../lib/LibModal';
import AnimateInputField, { AnimateSelectField, addOption } from '../../../utils/AnimateInputField';
import { useAuth } from '../../auth/AuthProvider';
import { useTemplate } from '../../template/TemplateProvider';
import { useRole } from '../RoleManageProvider';
import { handleSendInvite } from '../RoleOperation';

const roles = [
    { id: 'manager', title: 'Manager' },
    { id: 'developer', title: 'Developer' }
];

function RoleAssignModal({ handleCloseModal, projectId }) {
    const [formData, setFormData] = useState({});

    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();

    const { handleReload } = useRole();

    const handelChange = (ev) => {
        const { name, value } = ev.target;
        setFormData((cuData) => ({ ...cuData, [name]: value }));
    };

    // check if formData object is empty
    const isValue = Object.values(formData).some((item) => item);

    const handleAssign = () => {
        handleSendInvite(authInfo, projectId, formData.email, formData.role)
            .then(() => {
                handleCloseModal();
                handleReload();
            })
            .catch((err) => {
                setMessage(err.toString(0));
            });
    };

    return (
        <div className="role-assign-modal">
            <LibModal title="Assign User" setOpen={handleCloseModal}>
                <div className="modal-body-cont">
                    <div>
                        <div className="input-row">
                            <AnimateInputField
                                title="User Email"
                                name="email"
                                formData={formData}
                                handelChange={handelChange}
                            />
                        </div>
                        <div className="input-row">
                            <AnimateSelectField
                                title="Select Role"
                                name="role"
                                options={roles.map((item) =>
                                    addOption(item.id, item.title, item.id)
                                )}
                                formData={formData}
                                handelChange={handelChange}
                            />
                        </div>
                    </div>
                    <div className="modal-action flex-row align-right position-middle action">
                        <ButtonGray evOnClick={handleCloseModal} title="Cancel" />
                        <ButtonGreen evOnClick={handleAssign} disabled={!isValue} title="Assign" />
                    </div>
                </div>
            </LibModal>
        </div>
    );
}

export default RoleAssignModal;
