import BmConversationBody from './components/BmConversationBody';
import BmConversationPageHeader from './components/BmConversationPageHeader';

function BmConversationPage() {
    return (
        <div className="page-cont conversation-page">
            <BmConversationPageHeader />

            <BmConversationBody />
        </div>
    );
}

export default BmConversationPage;
