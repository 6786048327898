import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import { icons } from '../../configs/Icons';
import { evaluate } from './Math';

// usage
/* 

pass title -- <label htmlFor='titleName'>*PASSED TITLE*</label>

pass name -- <input type='text' name=*PASSED NAME* />

pass info(Text) -- will show a info icon when click on input field and PASSED TEXT will be shown on hover on the info icon

pass unit(Text) -- will show as unit of typed text on input on right side [100 *PASSED UNIT(EX: USD)*]

pass handelChange(Function) -- \/

        const handelChange = (ev) => {
            const { name } = ev.target;
            if (name) {
                setFormData((d) => ({ ...d, [name]: ev.target.value }));
            }
        };

        * destructure name from event(input name property) and set it on setFormData State. take previews sate and add current data on property of [name]

pass formData(State) --  const [formData, setFormData] = useState({});

pass option for select input field --
Example -- \/

assume optionArray = [{
        id: 1,
        title: 'USD',
        value: 'usd'
    },
    {
        id: 2,
        title: 'Pcs',
        value: 'pcs'
    },]

*options={optionArray?.map((item) => addOption(item.id, item.title, item.value))}

*here addOption function should be import and pass the mapped item id, item title, item value

 */

export function Label({ name, title, info, required, inputRef }) {
    const iconRef = useRef(null);
    const labelRef = useRef(null);

    const handelClick = () => {
        labelRef.current.classList.add('active');
        const inputElement =
            inputRef?.current?.querySelector('input') || inputRef?.current?.querySelector('select');
        if (inputElement) {
            inputElement.focus();
        }
    };

    const handelHover = () => {
        iconRef.current.src = icons.info;
    };

    const handelHoverOut = () => {
        iconRef.current.src = icons.infoV2;
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (labelRef.current && !labelRef.current?.contains(event.target)) {
                labelRef.current.classList.remove('active');
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div
            role="button"
            tabIndex={-1}
            className="label flex-row position-middle align-right"
            ref={labelRef}
            onClick={handelClick}
        >
            <label htmlFor={name}>
                {title}
                {required ? <span>*</span> : null}
            </label>

            {info && (
                <span
                    className="animate-input_info"
                    data-tooltip-id="myTooltip"
                    data-tooltip-content={info}
                >
                    <img
                        onMouseEnter={handelHover}
                        onMouseLeave={handelHoverOut}
                        ref={iconRef}
                        src={icons.infoV2}
                        alt="info"
                    />
                </span>
            )}
        </div>
    );
}

export function addOption(id, title, value) {
    return { id, title, value };
}

const Opt = (formData, name, selectInput = false) => {
    const ref = useRef();
    const [focused, setFocused] = useState(false);

    const hOnFocus = () => {
        setFocused(true);
        ref.current.classList.add('focused');
    };

    const hOnBlur = () => {
        setFocused(false);
        ref.current.classList.remove('focused');
    };

    useEffect(() => {
        if (formData[name] || focused) {
            ref.current.classList.add('has-value');
        } else if (!selectInput) {
            ref.current.classList.remove('has-value');
        }
    }, [focused, formData, name, selectInput]);

    return { hOnFocus, hOnBlur, ref };
};

// date
export function AnimateDateField({
    title,
    name,
    handelChange,
    formData,
    required,
    disabled,
    info,
    dRef
}) {
    const { hOnFocus, hOnBlur, ref } = Opt(formData, name);

    const handelOnChange = ([date]) => {
        const ev = {
            target: {
                name,
                value: date ? dayjs(date).format('YYYY-MM-DD') : ''
            }
        };
        handelChange(ev);
    };

    const value = () => {
        if (formData[name] && typeof formData[name] === 'number') {
            return dayjs(formData[name] * 1000).format('YYYY-MM-DD');
        }
        return formData[name];
    };

    return (
        <div className={`animate-input-area ${disabled ? 'disabled' : ''}`} ref={ref}>
            <Label name={name} title={title} info={info} required={required} inputRef={ref} />

            <Flatpickr
                options={{ dateFormat: 'Y-m-d' }}
                value={value() || ''}
                onChange={handelOnChange}
                name={name}
                className="form-input"
                readOnly={false}
                onFocus={hOnFocus}
                onClose={hOnBlur}
                ref={dRef}
            />
        </div>
    );
}

// select
export function AnimateSelectField({
    title,
    name,
    options,
    handelChange,
    formData,
    info,
    required,
    disabled,
    unit,
    height = '40px'
}) {
    const { hOnFocus, hOnBlur, ref } = Opt(formData, name, true);

    return (
        <div className={`animate-input-area has-value ${disabled ? 'disabled' : ''}`} ref={ref}>
            <Label name={name} title={title} info={info} required={required} inputRef={ref} />

            <select
                style={{ height }}
                onChange={handelChange}
                name={name}
                value={formData[name] || ''}
                className="form-input"
                onFocus={hOnFocus}
                onBlur={hOnBlur}
                disabled={disabled}
            >
                <option value="">Select {unit}</option>
                {options?.map((option) => (
                    <option key={option?.id} value={option?.value}>
                        {option?.title}
                    </option>
                ))}
            </select>
        </div>
    );
}

// number
export function AnimateNumberField({
    title,
    name,
    handelChange,
    formData,
    info,
    required,
    disabled,
    unit,
    height = '40px'
}) {
    const { hOnFocus, hOnBlur, ref } = Opt(formData, name);

    const handelOnChange = (ev) => {
        const outputObj = {
            target: {
                name: ev.target.name,
                value: evaluate(ev.target.value)
            }
        };

        handelChange(outputObj);
    };

    return (
        <div className={`animate-input-area ${disabled ? 'disabled' : ''}`} ref={ref}>
            <Label name={name} title={title} info={info} required={required} inputRef={ref} />

            <div className="inp-cont flex-row position-middle">
                <input
                    style={{ height }}
                    name={name}
                    min={1}
                    value={formData[name] || ''}
                    onChange={handelOnChange}
                    className="form-input"
                    type="text"
                    onFocus={hOnFocus}
                    onBlur={hOnBlur}
                />
                {unit && <span className="animate-input_unit">{unit}</span>}
            </div>
        </div>
    );
}

// textarea
export function AnimateTextareaField({
    title,
    name,
    handelChange,
    formData,
    info,
    required,
    disabled,
    height = '40px'
}) {
    const { hOnFocus, hOnBlur, ref } = Opt(formData, name);

    return (
        <div className={`animate-input-area ${disabled ? 'disabled' : ''}`} ref={ref}>
            <Label name={name} title={title} info={info} required={required} inputRef={ref} />

            <textarea
                style={{ height }}
                name={name}
                value={formData[name] || ''}
                onChange={handelChange}
                className="form-input"
                rows="20"
                onFocus={hOnFocus}
                onBlur={hOnBlur}
            />
        </div>
    );
}

// input
export function AnimateTimeField({
    title,
    name,
    handelChange,
    formData,
    info,
    required,
    disabled,
    unit,
    height = '40px'
}) {
    const { hOnFocus, hOnBlur, ref } = Opt(formData, name, true);

    const modifyValue = (value) => {
        if (value && typeof value === 'number') {
            return dayjs(value * 1000)?.format('H:MM');
        }
        return value || dayjs()?.format('H:MM');
    };

    return (
        <div className={`animate-input-area has-value ${disabled ? 'disabled' : ''}`} ref={ref}>
            <Label name={name} title={title} info={info} required={required} inputRef={ref} />

            <div className="inp-cont flex-row position-middle">
                <input
                    style={{ height }}
                    name={name}
                    value={modifyValue(formData?.[name])}
                    onChange={handelChange}
                    className="form-input"
                    type="time"
                    onFocus={hOnFocus}
                    disabled={disabled}
                    onBlur={hOnBlur}
                />
                {unit && <span className="animate-input_unit">{unit}</span>}
            </div>
        </div>
    );
}

// input
function AnimateInputField({
    title,
    name,
    handelChange,
    formData,
    info,
    required,
    disabled,
    unit,
    placeholder,
    height = '40px'
}) {
    const { hOnFocus, hOnBlur, ref } = Opt(formData, name);

    return (
        <div className={`animate-input-area ${disabled ? 'disabled' : ''}`} ref={ref}>
            <Label name={name} title={title} info={info} required={required} inputRef={ref} />

            <div className="inp-cont flex-row position-middle">
                <input
                    style={{ height }}
                    name={name}
                    value={formData[name] || ''}
                    onChange={handelChange}
                    className="form-input"
                    type="text"
                    onFocus={hOnFocus}
                    disabled={disabled}
                    onBlur={hOnBlur}
                    placeholder={placeholder}
                />
                {unit && <span className="animate-input_unit">{unit}</span>}
            </div>
        </div>
    );
}

export default AnimateInputField;
