import { createContext, useContext, useMemo, useState } from 'react';
import { rolesData } from '../../../data/rolesData';
import { mkKey } from '../../utils/String';

const RoleManageContext = createContext();

export function useRole() {
    return useContext(RoleManageContext);
}

// const initialState = {
//     roles: null,
//     addButton: null
// };

function RoleManageProvider({ children }) {
    const [roleData, setRoleData] = useState({
        roles: rolesData.data,
        addButton: rolesData.addButton
    });

    const [reloadKey, setReloadKey] = useState(mkKey());

    const value = useMemo(() => {
        const handleReload = () => {
            setReloadKey(mkKey());
            // setRoleData(initialState);
            setRoleData({
                roles: rolesData.data,
                addButton: rolesData.addButton
            });
        };

        return {
            roles: roleData.roles,
            addButton: roleData.addButton,
            setRoleData,
            reloadKey,
            handleReload
        };
    }, [reloadKey, roleData.addButton, roleData.roles]);

    return <RoleManageContext.Provider value={value}>{children}</RoleManageContext.Provider>;
}

export default RoleManageProvider;
