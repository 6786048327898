import { useState } from 'react';
import { ButtonGray, ButtonGreen } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import LibModal from '../../../../lib/LibModal';
import AnimateInputField, {
    AnimateNumberField,
    AnimateSelectField,
    AnimateTextareaField,
    addOption
} from '../../../../utils/AnimateInputField';
import districts from '../../../../utils/districts.json';
import { handleAddBranch } from '../BmConfigBranchOperation';

const initialState = {
    branchName: '',
    address: '',
    name: '',
    phone: '',
    position: '',
    district: ''
};

function BmConfigBranchAddModal({ handleCloseModal, handleReload, projectId }) {
    const [formData, setFormData] = useState(initialState);

    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    const handleChange = (ev) => {
        const { name } = ev.target;
        if (name) {
            setFormData((d) => ({ ...d, [name]: ev.target.value }));
        }
    };

    const handleSave = () => {
        setMessage('Adding...');
        handleAddBranch(
            authInfo,
            { ...formData, address: formData?.branchAddress, phone: formData?.contact },
            projectId
        )
            .then(({ message }) => {
                setMessage(message);
                handleCloseModal();
                handleReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <div className="branch-action-modal">
            <LibModal title="Add Branch" setOpen={handleCloseModal}>
                <div className="modal-body-cont">
                    <div>
                        <div className="input-row">
                            <AnimateInputField
                                title="Branch Name"
                                name="branchName"
                                formData={formData}
                                handelChange={handleChange}
                            />
                        </div>

                        <div className="input-row">
                            <AnimateTextareaField
                                title="Address"
                                name="branchAddress"
                                formData={formData}
                                handelChange={handleChange}
                            />
                        </div>

                        <div className="flex-row flex-wrap align-space-between input-row">
                            <div className="inp-fill-2">
                                <AnimateNumberField
                                    title="Number of Position"
                                    name="position"
                                    formData={formData}
                                    handelChange={handleChange}
                                />
                            </div>

                            <div className="inp-fill-2">
                                <AnimateSelectField
                                    title="Select District"
                                    name="district"
                                    formData={formData}
                                    handelChange={handleChange}
                                    options={districts.map((item) =>
                                        addOption(item.id, item.name, item.name?.toLowerCase())
                                    )}
                                />
                            </div>
                        </div>

                        <div className="separator-cont flex-row position-middle">
                            <span className="separator-title">Branch Manager Info</span>
                            <hr className="separator" />
                        </div>

                        <div className="flex-row flex-wrap align-space-between input-row">
                            <div className="inp-fill-2">
                                <AnimateInputField
                                    title="Name"
                                    name="name"
                                    formData={formData}
                                    handelChange={handleChange}
                                />
                            </div>

                            <div className="inp-fill-2">
                                <AnimateInputField
                                    title="Phone"
                                    name="contact"
                                    formData={formData}
                                    handelChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-action flex-row align-right">
                        <div className="flex-row position-middle">
                            <ButtonGray title="Cancel" evOnClick={handleCloseModal} />
                            <ButtonGreen title="Save" evOnClick={handleSave} />
                        </div>
                    </div>
                </div>
            </LibModal>
        </div>
    );
}

export default BmConfigBranchAddModal;
