import { useState } from 'react';
import TagSelectionModal from './modals/TagSelectionModal';

function TagSelector({ selectedTags, setSelectedTags, handleSave }) {
    const [open, setOpen] = useState(false);

    return (
        <div className="tag-selector flex-row position-middle">
            {selectedTags?.map((item) => (
                <div
                    key={item?.id}
                    data-tooltip-id="myTooltip"
                    data-tooltip-content={item?.notes}
                    style={{ backgroundColor: item.color }}
                    className="tag-selector_item"
                />
            ))}

            <div
                role="button"
                tabIndex={-1}
                onClick={() => setOpen(true)}
                className="tag-selector_item tag-selector_add-item"
            />

            {open && (
                <TagSelectionModal
                    setOpen={setOpen}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    handleSave={handleSave}
                />
            )}
        </div>
    );
}

export default TagSelector;
