import { useState } from 'react';
import TransactionSummary from '../../../features/amount_summary/TransactionSummary';
import InfoSidebar from '../../../features/info_sidebar/InfoSidebar';
import { mkRelativeUrl } from '../../../features/menus/AdvMenuProvider';
import { showCurrency } from '../../../utils/Show';
import InvoiceListPageHeader from './InvoiceListPageHeader';
import InvoiceListProvider, { useInvoiceList } from './InvoiceListProvider';
import InvoiceListTable from './components/InvoiceListTable';

function CustomChildren({ summary }) {
    return (
        <div className={`w-100p flex-col gap-5 ${summary?.noData ? 'placeholder-mode' : ''}`}>
            <div className=" flex-row align-space-between w-100p">
                <div>Product Price</div>
                <span>{showCurrency(summary?.productPrice)}</span>
            </div>
            <div className=" flex-row align-space-between w-100p">
                <div>Discount</div>
                <span>{showCurrency(summary?.discount)}</span>
            </div>
            <div className=" flex-row align-space-between w-100p">
                <div>
                    VAT <small style={{ fontSize: '12px' }}>(Where Applicable)</small>
                </div>
                <span>{showCurrency(summary?.vat)}</span>
            </div>
            <div className=" flex-row align-space-between w-100p">
                <div>Paid</div>
                <span>{showCurrency(summary?.paid)}</span>
            </div>
            <div className=" flex-row align-space-between w-100p">
                <div className="font-600">Payable</div>
                <span className="font-600">{showCurrency(summary?.payable)}</span>
            </div>
        </div>
    );
}

function PageCont({ setInfoId }) {
    const { selectInvoice, invoiceSummary, invoices, setSelectInvoice } = useInvoiceList();
    const summary = invoiceSummary;
    return (
        <div className="page-cont invoice-page-cont">
            <InvoiceListPageHeader />
            <InvoiceListTable
                invoices={invoices}
                selectInvoice={selectInvoice}
                setSelectInvoice={setSelectInvoice}
                setInfoId={setInfoId}
            />

            <TransactionSummary cuData={selectInvoice}>
                <CustomChildren summary={summary} />
            </TransactionSummary>
        </div>
    );
}

function InvoiceListPage() {
    const [infoId, setInfoId] = useState(0);
    return (
        <InvoiceListProvider>
            <PageCont setInfoId={setInfoId} />

            <InfoSidebar
                show={infoId}
                path={infoId ? mkRelativeUrl(`/inventory/sell-invoice/${infoId}/update/`) : null}
            >
                {/* <FinInvoiceUpdateProvider id={infoId}>
                    <InvoiceViewWrapper />
                </FinInvoiceUpdateProvider> */}
            </InfoSidebar>
        </InvoiceListProvider>
    );
}

export default InvoiceListPage;
