import { useEffect, useState } from 'react';
import { ButtonGray, ButtonGreen } from '../../../../../../configs/Buttons';
import { pvcTankPrice } from '../../../../../../data/assetsData';
import { useAuth } from '../../../../../features/auth/AuthProvider';
import { getProjectId, useAdvMenu } from '../../../../../features/menus/AdvMenuProvider';
import { useTemplate } from '../../../../../features/template/TemplateProvider';
import LibModal from '../../../../../lib/LibModal';
import Show from '../../../../../utils/Show';
import { handleCreateOrder } from '../../BmCustomerOverviewOperation';
import AssetConcrete from './AssetConcrete';
import AssetPvc from './AssetPvc';
import AssetTypeSelectionArea from './AssetTypeSelectionArea';

function Divider({ title }) {
    return (
        <div className="section-divider flex-row align-space-between position-middle">
            <div>{title}</div>
            <hr className="separator" />
        </div>
    );
}

function CreateOrderModal({ handleCloseModal }) {
    const [formData, setFormData] = useState({});
    const [selectedItem, setSelectedItem] = useState([]);
    const [assetType, setAssetType] = useState(['pvc']);

    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();

    const { activeProject } = useAdvMenu();
    const projectId = activeProject?.id || getProjectId();

    const handleSave = () => {
        setMessage('Creating Order...');
        handleCreateOrder(authInfo, formData, projectId)
            .then(({ message }) => {
                // handleCloseModal();
                setFormData({});
                setMessage(message);
                // handleReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    const handleAssetTypeClick = (type) => {
        setAssetType((prev) => {
            if (prev.includes(type)) {
                return prev.filter((item) => item !== type);
            }
            return [...prev, type];
        });
    };

    const handleAssetItemClick = (item) => {
        setSelectedItem((prev) => {
            if (prev.filter((i) => i?.id.toString() === item?.id?.toString()).length) {
                return prev.filter((i) => i?.id.toString() !== item?.id?.toString());
            }
            return [...prev, item];
        });
    };
    // calculate total price using selectedItem array and pricePerSize array.
    useEffect(() => {
        const totalPrice = selectedItem.reduce((acc, item) => {
            const price = pvcTankPrice.find((i) => i.size === item.size)?.price || 0;
            return acc + price;
        }, 0);
        setFormData((prev) => ({ ...prev, totalPrice }));
    }, [selectedItem]);

    console.log(selectedItem);

    return (
        <div className="bm-create-order-modal">
            <LibModal title="Create Order" setOpen={handleCloseModal}>
                <div className="modal-body-cont">
                    <AssetTypeSelectionArea
                        handleAssetTypeClick={handleAssetTypeClick}
                        assetType={assetType}
                    />

                    <div>
                        <Show show={assetType?.includes('pvc')}>
                            <ul className="assets-container">
                                <Divider title="PVC Tank" />
                                <AssetPvc
                                    handleAssetItemClick={handleAssetItemClick}
                                    selectedItem={selectedItem}
                                />
                            </ul>
                        </Show>
                        <Show show={assetType?.includes('concrete')}>
                            <ul className="assets-container">
                                <Divider title="Concrete Tank" />
                                <AssetConcrete
                                    handleAssetItemClick={handleAssetItemClick}
                                    selectedItem={selectedItem}
                                />
                            </ul>
                        </Show>
                    </div>

                    <div className="total-calc-area">
                        <hr className="separator" />
                        <div className="flex-row align-right">Total: {formData?.totalPrice}</div>
                    </div>

                    <div className="modal-action flex-row align-right">
                        <div className="flex-row align-right">
                            <ButtonGray title="Cancel" evOnClick={handleCloseModal} />
                            <ButtonGreen title="Save" evOnClick={handleSave} />
                        </div>
                    </div>
                </div>
            </LibModal>
        </div>
    );
}

export default CreateOrderModal;
