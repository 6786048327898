import ConLeftColConversationThread from './ConLeftColConversationThread';

function ConLeftColConversationBody() {
    return (
        <div className="con_conversation-body">
            <ConLeftColConversationThread />
            <ConLeftColConversationThread />
            <ConLeftColConversationThread />
            <ConLeftColConversationThread />
            <ConLeftColConversationThread />
            <ConLeftColConversationThread />
            <ConLeftColConversationThread />
            <ConLeftColConversationThread />
            <ConLeftColConversationThread />
            <ConLeftColConversationThread />
            <ConLeftColConversationThread />
            <ConLeftColConversationThread />
            <ConLeftColConversationThread />
            <ConLeftColConversationThread />
            <ConLeftColConversationThread />
            <ConLeftColConversationThread />
        </div>
    );
}

export default ConLeftColConversationBody;
