import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonGray, ButtonRed } from '../../../../configs/Buttons';
import LibModal from '../../../lib/LibModal';
import { AnimateSelectField, AnimateTextareaField } from '../../../utils/AnimateInputField';
import { useAuth } from '../../auth/AuthProvider';
import { getProjectId, setProject, useAdvMenu } from '../../menus/AdvMenuProvider';
import { useTemplate } from '../../template/TemplateProvider';
import { handleChangeStatusOpe } from '../ProjectOperation';

function ProjectStatusModal({ item, closeModal, handleProjectReload }) {
    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();
    const [formData, setFormData] = useState({
        status: item?.status || '',
        note: ''
    });

    const navigate = useNavigate();

    // all project list
    const { projectList } = useAdvMenu();

    // current project id from url
    const projectId = getProjectId();

    // handle change input
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((st) => ({ ...st, [name]: value }));
    };

    // handle change status
    const handleChangeStatus = () => {
        handleChangeStatusOpe(authInfo, item.id, formData)
            .then(({ message }) => {
                setMessage(message);
                closeModal();
                handleProjectReload();

                // navigate to project after change status
                if (item?.status === 'active' && projectId !== item?.id) {
                    return;
                }
                if (formData?.status === 'inactive' && item?.id === projectList?.[0]?.id) {
                    setProject(navigate, { id: projectList?.[1]?.id });
                    return;
                }
                if (formData?.status === 'inactive' && projectId === item?.id) {
                    setProject(navigate, { id: projectList?.[0]?.id });
                } else {
                    setProject(navigate, { id: item?.id || projectList?.[0]?.id });
                }
            })
            .catch((error) => {
                setMessage(error.message);
            });
    };

    return (
        <div className="project-sub-modal">
            <LibModal title="Change Status" setOpen={closeModal}>
                <div className="flex-col gap-20 p-20">
                    <div className="mb-10 text-18 font-400 text-center">
                        Are sure you want change this project status?
                    </div>
                    <AnimateSelectField
                        title="Status"
                        name="status"
                        options={[
                            { id: 1, value: 'active', title: 'Active' },
                            { id: 2, value: 'inactive', title: 'Inactive' }
                        ]}
                        formData={formData}
                        handelChange={handleChange}
                    />
                    <AnimateTextareaField
                        title="Note"
                        name="note"
                        formData={formData}
                        handelChange={handleChange}
                        required
                    />
                    <div className="flex-row align-right position-middle gap-10 w-100p">
                        <ButtonGray title="Cancel" evOnClick={() => closeModal()} />
                        <ButtonRed title="Change" evOnClick={handleChangeStatus} />
                    </div>
                </div>
            </LibModal>
        </div>
    );
}

export default ProjectStatusModal;
