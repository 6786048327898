import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { expenseDemoData } from '../../../../data/expenseDemoData';
import BmExpenseAddModal from './modals/BmExpenseAddModal';
import BmExpenseUpdateModal from './modals/BmExpenseUpdateModal';

// Context creation
const BmExpenseContext = createContext();

// Custom hook to use the context
export const useExpense = () => useContext(BmExpenseContext);

// BmExpenseProvider component definition
function BmExpenseProvider({ children, projectId }) {
    const [expenseInfo, setExpenseInfo] = useState(expenseDemoData);
    const [requiredData, setRequiredData] = useState([]);
    const [reloadKey, setReloadKey] = useState(Math.random());
    const [selectedUser, setSelectedUser] = useState(null);
    const [modal, setModal] = useState({
        type: '',
        data: null
    });

    const modalType = modal.type;
    const modalData = modal.data;

    const [formData, setFormData] = useState({});

    useEffect(() => {
        if (modalType === 'update' && modalData)
            setFormData(
                {
                    ...modalData,
                    userId: selectedUser ? selectedUser?.id : modalData?.userId,
                    branchId: requiredData?.branches?.find((i) => i?.name === modalData?.branch)?.id
                } || {}
            );
    }, [modalData, modalType, requiredData.branches, selectedUser]);

    // Function to handle form data change
    const handleChange = (e) => {
        const { name, value: v } = e.target;
        setFormData((d) => ({ ...d, [name]: v }));
    };

    // Function to open a modal
    const handleOpenModal = (type, data) => {
        setModal({
            type,
            data
        });
    };

    // Function to close a modal
    const handleCloseModal = () => {
        setModal({
            type: '',
            data: null
        });

        setFormData({});
        setSelectedUser(null);
    };

    const handleReload = () => {
        setReloadKey((d) => d + 1);
        // setExpenseInfo(null);
    };

    // Create a memoized value object that holds various functions
    const value = useMemo(
        () =>
            // Function to trigger reloading data

            // Return an object with all the values and functions
            ({
                formData,
                expenseInfo,
                requiredData,
                selectedUser,
                setSelectedUser,
                setExpenseInfo,
                setRequiredData,
                reloadKey,
                handleReload,
                handleChange,
                modalType,
                modalData,
                handleOpenModal,
                handleCloseModal
            }),
        [expenseInfo, formData, modalData, modalType, reloadKey, requiredData, selectedUser]
    );

    // Provide the value through the context
    return (
        <BmExpenseContext.Provider value={value}>
            {children}

            {modalType === 'add' ? (
                <BmExpenseAddModal
                    handleCloseModal={handleCloseModal}
                    formData={formData}
                    handleChange={handleChange}
                    projectId={projectId}
                    handleReload={handleReload}
                    requiredData={requiredData}
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                />
            ) : null}

            {modalType === 'update' ? (
                <BmExpenseUpdateModal
                    handleCloseModal={handleCloseModal}
                    modalData={modalData}
                    formData={formData}
                    handleChange={handleChange}
                    projectId={projectId}
                    handleReload={handleReload}
                    requiredData={requiredData}
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                />
            ) : null}
        </BmExpenseContext.Provider>
    );
}

export default BmExpenseProvider;
