import { useState } from 'react';
import AnimateInputField, {
    AnimateSelectField,
    addOption
} from '../../../../utils/AnimateInputField';

const options = [
    {
        id: 1,
        title: 'New',
        value: 'new'
    },
    {
        id: 2,
        title: 'Old',
        value: 'old'
    }
];

function ConLeftColChatHeader() {
    const [formData, setFormData] = useState({});

    const handelChange = (e) => {
        const { name, value } = e.target;
        setFormData((d) => ({ ...d, [name]: value }));
    };
    return (
        <div className="con_chat-header">
            <div className="flex-row align-space-between">
                <div className="inp-fill">
                    <AnimateInputField
                        height="35px"
                        title="Search"
                        name="search"
                        formData={formData}
                        handelChange={handelChange}
                    />
                </div>

                <div className="inp-fill-3">
                    <AnimateSelectField
                        height="35px"
                        title="Select"
                        name="filter"
                        options={options?.map((item) => addOption(item.id, item.title, item.value))}
                        formData={formData}
                        handelChange={handelChange}
                    />
                </div>
            </div>
        </div>
    );
}

export default ConLeftColChatHeader;
