function FilterRow({ title, children }) {
    return (
        <div className="filter-row flex-row align-space-between position-middle">
            <div>{title}</div>
            <div className="flex-row align-right">{children}</div>
        </div>
    );
}

export default FilterRow;
