import graph from '../../../../../assets/images/conversation-graph.svg';

function OverviewSecCol2Graph() {
    return (
        <div className="section graph-section overview_section-item">
            <div>
                <h2 className="section-title">Conversation Graph</h2>
            </div>
            <div>
                <img src={graph} alt="" />
            </div>
        </div>
    );
}

export default OverviewSecCol2Graph;
