import React, { useState } from 'react';
import { ButtonGray, ButtonPurple } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import UserInfo from '../../../../features/user-info/UserInfo';
import LibModal from '../../../../lib/LibModal';
import CheckBox from '../../../../utils/CheckBox';
import { evaluate } from '../../../../utils/Math';
import { showCurrency } from '../../../../utils/Show';
import { handelMakeRefund } from '../InvoiceUpdateOperation';

function PayOption({ evOnClick, title, value, checked }) {
    return (
        <div
            onClick={evOnClick}
            role="button"
            tabIndex={-1}
            className={`pay-option flex-row align-space-between position-middle ${
                checked ? 'active' : ''
            }`}
        >
            <div className="flex-row position-middle">
                <CheckBox checked={checked} /> <span className="ml-1">{title}</span>
            </div>
            <div>
                <span>{value}</span>
            </div>
        </div>
    );
}

function InfoItem({ title, value }) {
    return (
        <div className="flex-row align-space-between position-middle">
            <span>{title}</span>
            <span>{value}</span>
        </div>
    );
}

const initialState = {
    type: '',
    amount: 0
};

function FinInvoiceAddRefund({ invoiceData, handelReload, setOpen }) {
    const [formData, setFormData] = useState({ ...initialState });

    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();

    const { user, info } = invoiceData || {};
    const { currency, balance } = user || {};

    const newBalance = formData.amount ? parseFloat(balance) + parseFloat(formData.amount) : 0;

    const handelChange = (ev) => {
        const { name, value } = ev.target;
        if (name) {
            setFormData((d) => ({ ...d, [name]: evaluate(value) }));
        }
    };

    //   pay option click
    const handelPayOptionClick = (type, value) => {
        setFormData((d) => ({ ...d, type, amount: value }));
    };

    // amount without comma
    const payAmount = parseFloat(formData?.amount?.toString()?.replace(/,/g, ''));

    const handlePayClick = () => {
        setMessage('Refunding....');
        handelMakeRefund(authInfo, user.id, info?.id, formData.type, payAmount)
            .then(({ message }) => {
                setMessage(message);
                setFormData(initialState);
                setOpen(false);
                handelReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <LibModal title="Refund" setOpen={setOpen}>
            <div className="inv-add-refund">
                <div>
                    <UserInfo img={user?.photoUrl} name={user?.name} email={user?.email} />
                </div>

                <div className="info">
                    <InfoItem
                        title="Balance"
                        currency="BDT"
                        value={`${currency} ${showCurrency(balance || 0)}`}
                    />

                    <PayOption
                        evOnClick={() => handelPayOptionClick('principal', info.amountPaid)}
                        checked={formData.type === 'principal'}
                        title="Principal Amount"
                    />
                    <PayOption
                        evOnClick={() => handelPayOptionClick('vat', info.vatPayable)}
                        checked={formData.type === 'vat'}
                        title="VAT Amount"
                    />

                    <div className="refund-inp-cont flex-row position-middle align-space-between">
                        <div>
                            <input
                                onChange={handelChange}
                                value={formData.amount || ''}
                                disabled={formData.type === 'vat'}
                                name="amount"
                                placeholder="10"
                                className="form-input"
                                type="text"
                                autoComplete="off"
                            />
                        </div>

                        <div>
                            <input
                                onChange={handelChange}
                                value={`${currency} ${showCurrency(newBalance)}`}
                                disabled
                                name="amount"
                                placeholder="10"
                                className="form-input total"
                                type="text"
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </div>

                <div className="action flex-row align-right">
                    <ButtonGray evOnClick={() => setOpen(false)} title="Cancel" />

                    <ButtonPurple evOnClick={handlePayClick} title="Refund" />
                </div>
            </div>
        </LibModal>
    );
}

export default FinInvoiceAddRefund;
