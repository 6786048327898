import { useEffect, useRef, useState } from 'react';
import { icons } from '../../../configs/Icons';

function Label({ name, title, info, required, inputRef }) {
    const iconRef = useRef(null);
    const labelRef = useRef(null);

    const handelClick = () => {
        labelRef.current.classList.add('active');
        const inputElement =
            inputRef?.current?.querySelector('input') || inputRef?.current?.querySelector('select');
        if (inputElement) {
            inputElement.focus();
        }
    };

    const handelHover = () => {
        iconRef.current.src = icons.info;
    };

    const handelHoverOut = () => {
        iconRef.current.src = icons.infoV2;
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (labelRef.current && !labelRef.current?.contains(event.target)) {
                labelRef.current.classList.remove('active');
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div
            role="button"
            tabIndex={-1}
            className="label flex-row position-middle align-right"
            ref={labelRef}
            onClick={handelClick}
        >
            <label htmlFor={name}>
                {title}
                {required ? <span>*</span> : null}
            </label>

            {info && (
                <span
                    className="animate-input_info"
                    data-tooltip-id="myTooltip"
                    data-tooltip-content={info}
                >
                    <img
                        onMouseEnter={handelHover}
                        onMouseLeave={handelHoverOut}
                        ref={iconRef}
                        src={icons.infoV2}
                        alt="info"
                    />
                </span>
            )}
        </div>
    );
}

const Opt = (formData, name) => {
    const ref = useRef();
    const [focused, setFocused] = useState(false);

    const hOnFocus = () => {
        setFocused(true);
        ref.current.classList.add('focused');
    };

    const hOnBlur = () => {
        setFocused(false);
        ref.current.classList.remove('focused');
    };

    useEffect(() => {
        if (formData[name] || focused) {
            ref.current.classList.add('has-value');
        } else {
            ref.current.classList.remove('has-value');
        }
    }, [focused, formData, name]);

    return { hOnFocus, hOnBlur, ref };
};

function PhoneInput({
    title,
    name,
    setFormData,
    formData,
    info,
    required,
    disabled,
    unit,
    height = '40px'
}) {
    const { hOnFocus, hOnBlur, ref } = Opt(formData, name);

    const handelChange = (ev) => {
        if (ev?.target?.name) {
            setFormData((d) => ({ ...d, [ev.target.name]: ev.target.value }));
        }
    };

    return (
        <div className={`animate-input-area phone ${disabled ? 'disabled' : ''}`} ref={ref}>
            <Label name={name} title={title} info={info} required={required} inputRef={ref} />

            <div className="inp-cont flex-row position-middle">
                <div className="default-value">
                    <span>+880</span>
                </div>
                <input
                    style={{ height }}
                    name={name}
                    min={1}
                    value={formData[name] || ''}
                    onChange={handelChange}
                    className="form-input"
                    type="text"
                    onFocus={hOnFocus}
                    onBlur={hOnBlur}
                />
                {unit && <span className="animate-input_unit">{unit}</span>}
            </div>
        </div>
    );
}

export default PhoneInput;
