import BmDashboardPageHeader from './components/BmDashboardPageHeader';

function BmDashboardPage() {
    return (
        <div className="page-cont">
            <BmDashboardPageHeader />
        </div>
    );
}

export default BmDashboardPage;
