import { useState } from 'react';
import { ButtonMenu } from '../../../../../configs/Buttons';
import NotFound from '../../../../features/not-found/NotFound';
import InstOption from '../../../../utils/InstOption';
import { UpperCase } from '../../../../utils/Show';
import ProductUpdateModal from '../modals/ProductUpdateModal';

function Menu({ showMenu, setShowMenu, item }) {
    const [showModal, setShowModal] = useState(false);

    const handleEditOption = () => {
        setShowModal(true);
        console.log(item);
    };
    return (
        <div className="flex-row align-right">
            <ButtonMenu evOnClick={() => setShowMenu(item.id)}>
                <InstOption setShowMenu={setShowMenu} showMenu={showMenu === item.id}>
                    <button type="button" onClick={handleEditOption}>
                        Edit
                    </button>
                </InstOption>
            </ButtonMenu>
            {showModal ? <ProductUpdateModal closeModal={setShowModal} data={item} /> : null}
        </div>
    );
}

function Tr({ products }) {
    // const [mode, setMode] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    return products.map((item) => (
        <tr key={item?.id} className={item?.empty ? 'placeholder-mode' : ''}>
            <td className="text-left">{item.id}</td>
            <td className="text-left">{item.description}</td>
            <td className="text-left">{UpperCase(item.type)}</td>
            <td className="text-left">{item.subType}</td>
            <td className="text-center">{item.price}</td>
            <td className="text-right">
                <Menu showMenu={showMenu} setShowMenu={setShowMenu} item={item} />
            </td>
        </tr>
    ));
}

function ProductsTable({ products }) {
    if (products && !products.length) {
        return <NotFound title="Product/Service Not Found!" />;
    }
    return (
        <table className="bik-table">
            <thead>
                <tr>
                    <th width="10%" className="text-left">
                        SL
                    </th>
                    <th width="40%" className="text-left">
                        Description
                    </th>
                    <th width="10%" className="text-left">
                        Type
                    </th>
                    <th width="20%" className="text-left">
                        Sub-Type
                    </th>
                    <th width="10%" className="text-center">
                        Price
                    </th>
                    <th width="10%" className="text-right">
                        Action
                    </th>
                </tr>
            </thead>
            <tbody className="">
                <Tr products={products} />
            </tbody>
        </table>
    );
}

export default ProductsTable;
