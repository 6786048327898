import { useState } from 'react';
import OverviewSecConversationBody from './OverviewSecConversationBody';
import OverviewSecConversationFooter from './OverviewSecConversationFooter';
import OverviewSecConversationHeader from './OverviewSecConversationHeader';

function OverviewSecConversation() {
    const [formData, setFormData] = useState({ text: '' });

    return (
        <div className="customer-conversation-container overview_section-item flex-col align-stretch">
            <OverviewSecConversationHeader handelReload={null} />

            <OverviewSecConversationBody
                formData={formData}
                setFormData={setFormData}
                data="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloremque corrupti saepe expedita earum sunt? Repellendus dicta animi quidem natus eius, ducimus laboriosam, maxime illo optio dignissimos soluta velit architecto dolores."
            />

            <OverviewSecConversationFooter formData={formData} setFormData={setFormData} />
        </div>
    );
}

export default OverviewSecConversation;
