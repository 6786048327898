import { useRef } from 'react';
import useEditorPositioning from './useEditorPositioning';

function Button1({ cancelClick, text }) {
    return (
        <div
            className="btn-1 flex-row position-middle align-center"
            role="button"
            tabIndex={-1}
            onClick={cancelClick}
        >
            {text}
        </div>
    );
}

function Button2({ saveClick, text }) {
    return (
        <div
            className="btn-2 flex-row position-middle align-center"
            role="button"
            tabIndex={-1}
            onClick={saveClick}
        >
            {text}
        </div>
    );
}

function InfoEditorConfirm({
    show,
    setShow,
    positionRef,
    align = 'center',
    outsideClick,
    text,
    button1 = 'No',
    button2 = 'Yes',
    confirmClick
    // buttons=[]
}) {
    const editorRef = useRef(null);

    // editor SHOW/HIDE and positioning
    useEditorPositioning(positionRef, editorRef, show, setShow, align, outsideClick);

    if (!show) {
        return null;
    }
    return (
        <div
            role="button"
            tabIndex={-1}
            onClick={(ev) => ev.stopPropagation()}
            ref={editorRef}
            className="info-editor confirm"
        >
            <div className="info-editor-confirm flex-col position-middle align-center">
                <div className="row-1">{text || 'Are you sure you want to delete this ?'}</div>

                <div className="row-2 flex-row position-middle">
                    <Button1
                        text={button1}
                        cancelClick={(ev) => ev.stopPropagation() + setShow(false)}
                    />
                    <Button2 text={button2} saveClick={confirmClick} />
                </div>
            </div>
        </div>
    );
}

export default InfoEditorConfirm;
