import { useRef } from 'react';
import useEditorPositioning from './useEditorPositioning';

function Cancel({ cancelClick }) {
    return (
        <div className="btn-cancel" role="button" tabIndex={-1} onClick={cancelClick}>
            Cancel
        </div>
    );
}

function Save({ saveClick }) {
    return (
        <div className="btn-save" role="button" tabIndex={-1} onClick={saveClick}>
            Save
        </div>
    );
}

function InfoEditorTextarea({
    show,
    setShow,
    positionRef,
    width = 200,
    align = 'center',
    outsideClick,
    placeholder,
    name,
    formData,
    setFormData,
    saveClick
}) {
    const editorRef = useRef(null);

    // editor SHOW/HIDE and positioning
    useEditorPositioning(positionRef, editorRef, show, setShow, align, outsideClick);

    const handelChange = (ev) => {
        if (ev.target) {
            setFormData((d) => ({ ...d, [ev.target.name]: ev.target.value }));
        }
    };

    if (!show) {
        return null;
    }

    return (
        <div
            role="button"
            tabIndex={-1}
            onClick={(ev) => ev.stopPropagation()}
            ref={editorRef}
            style={{ width: `${width}px` }}
            className="info-editor"
        >
            <div className="info-editor-textarea">
                <div className="row-1">
                    <textarea
                        value={formData?.[name] || ''}
                        onChange={handelChange}
                        placeholder={placeholder || 'Type something...'}
                        name={name}
                    />
                </div>

                <div className="row-2 flex-row align-right position-middle">
                    <div className="flex-row">
                        <Cancel cancelClick={(ev) => ev.stopPropagation() + setShow(false)} />
                        <Save saveClick={saveClick} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InfoEditorTextarea;
