import { useState } from 'react';
import { ButtonGray, ButtonPurple } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import LibModal from '../../../../lib/LibModal';
import AnimateInputField, {
    AnimateSelectField,
    addOption
} from '../../../../utils/AnimateInputField';
import { handelAddCurrency } from '../InvConfigOperation';

const Currencies = [
    {
        id: 1,
        title: 'USD',
        value: 'usd'
    },
    {
        id: 2,
        title: 'BDT',
        value: 'bdt'
    },
    {
        id: 3,
        title: 'EUR',
        value: 'eur'
    },
    {
        id: 4,
        title: 'GBP',
        value: 'gbp'
    }
];

const initialState = {
    name: '',
    currency: '',
    rate: ''
};

function CurrencyAddModal({ setOpen }) {
    const [formData, setFormData] = useState(initialState);

    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    const handelChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handelAddButton = () => {
        setMessage('Adding....');
        handelAddCurrency(authInfo, formData)
            .then(({ message }) => {
                setMessage(message);
                setFormData(initialState);
                setOpen(false);
                // handelReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <LibModal title="Add Currency" setOpen={setOpen}>
            <div className="modal-body-cont">
                <div>
                    <div className="input-row">
                        <AnimateInputField
                            title="Currency Name"
                            name="name"
                            formData={formData}
                            handelChange={handelChange}
                        />
                    </div>
                    <div className="input-row">
                        <AnimateSelectField
                            title="Currencies"
                            name="currency"
                            formData={formData}
                            handelChange={handelChange}
                            options={Currencies?.map((item) =>
                                addOption(item.id, item.title, item.value)
                            )}
                        />
                    </div>
                    <div className="input-row">
                        <AnimateInputField
                            title="Custom Rate"
                            name="rate"
                            formData={formData}
                            handelChange={handelChange}
                        />
                    </div>
                </div>
                <div className="modal-action flex-row action align-right">
                    <ButtonGray evOnClick={() => setOpen(false)} title="Cancel" />
                    <ButtonPurple evOnClick={handelAddButton} title="Add" />
                </div>
            </div>
        </LibModal>
    );
}

export default CurrencyAddModal;
