import dayjs from 'dayjs';
import { useState } from 'react';
import { ButtonGray, ButtonGreen } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { getProjectId } from '../../../../features/menus/AdvMenuProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import LibModal from '../../../../lib/LibModal';
import AnimateInputField, {
    AnimateDateField,
    AnimateNumberField,
    AnimateSelectField,
    AnimateTextareaField,
    addOption
} from '../../../../utils/AnimateInputField';
import { handleUpdateTeam } from '../BmTeamsOperation';

const statusArr = [
    {
        id: 'active',
        title: 'Active'
    },
    {
        id: 'inactive',
        title: 'Inactive'
    }
];

function TeamUpdateModal({ handleCloseModal, handleReload, teamData, requiredData }) {
    const {
        id: teamId,
        name: teamName,
        phone,
        designation,
        branch,
        joiningDate,
        salary,
        grade,
        paymentInfo,
        status
    } = teamData || {};

    const [formData, setFormData] = useState({
        name: teamName,
        phone,
        designation,
        designationId: requiredData?.designations?.find((i) => i.name === designation).id,
        branch,
        branchId: requiredData?.branches?.find((i) => i.name === branch).id,
        joiningDate: dayjs.unix(joiningDate).format('YYYY-MM-DD'),
        salary,
        grade,
        paymentInfo,
        status
    });

    console.log(formData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();

    const projectId = getProjectId();

    const handleUpdateButton = () => {
        setMessage('Updating Team...');
        handleUpdateTeam(authInfo, formData, teamId, projectId)
            .then(({ message }) => {
                handleCloseModal();
                setMessage(message);
                handleReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <div className="team-action-modal-cont">
            <LibModal title="Update Team" setOpen={handleCloseModal}>
                <div className="modal-body-cont">
                    <div>
                        <div className="input-row">
                            <AnimateInputField
                                title="Name"
                                name="name"
                                handelChange={handleChange}
                                formData={formData}
                            />
                        </div>
                        <div className="input-row">
                            <AnimateInputField
                                title="Phone"
                                name="phone"
                                handelChange={handleChange}
                                formData={formData}
                                placeholder="880..."
                            />
                        </div>

                        <div className="input-row flex-row align-space-between">
                            <div className="inp-fill-2">
                                <AnimateSelectField
                                    title="Select Designation"
                                    name="designationId"
                                    handelChange={handleChange}
                                    formData={formData}
                                    options={requiredData?.designations?.map((item) =>
                                        addOption(item?.id, item?.name, item?.id)
                                    )}
                                />
                            </div>
                            <div className="inp-fill-2">
                                <AnimateSelectField
                                    title="Select Branch"
                                    name="branchId"
                                    handelChange={handleChange}
                                    formData={formData}
                                    options={requiredData?.branches?.map((item) =>
                                        addOption(item.id, item?.name, item.id)
                                    )}
                                />
                            </div>
                        </div>

                        <div className="input-row flex-row align-space-between">
                            <div className="inp-fill-2">
                                <AnimateDateField
                                    title="Date Joined"
                                    name="joiningDate"
                                    handelChange={handleChange}
                                    formData={formData}
                                />
                            </div>

                            <div className="inp-fill-2">
                                <AnimateNumberField
                                    title="Salary"
                                    name="salary"
                                    handelChange={handleChange}
                                    formData={formData}
                                    unit="/Month"
                                />
                            </div>
                        </div>

                        <div className="input-row">
                            <AnimateTextareaField
                                title="Payment Information"
                                name="paymentInfo"
                                handelChange={handleChange}
                                formData={formData}
                            />
                        </div>

                        <div className="">
                            <AnimateSelectField
                                title="Select Status"
                                name="status"
                                formData={formData}
                                handelChange={handleChange}
                                options={statusArr.map((item) =>
                                    addOption(item.id, item.title, item.id)
                                )}
                            />
                        </div>
                    </div>

                    <div className="modal-action flex-row align-right">
                        <div className="flex-row position-middle">
                            <ButtonGray title="Cancel" evOnClick={handleCloseModal} />

                            <ButtonGreen title="Update" evOnClick={handleUpdateButton} />
                        </div>
                    </div>
                </div>
            </LibModal>
        </div>
    );
}

export default TeamUpdateModal;
