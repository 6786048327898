import { ButtonGray, ButtonGreen } from '../../../../configs/Buttons';
import LibModal from '../../../lib/LibModal';
import { tags } from '../tags';

function TagSelectionModal({ setOpen, selectedTags, setSelectedTags, handleSave }) {
    // const { authInfo } = useAuth();
    // const { setMessage } = useTemplate();

    const handleSelectTag = (tag) => {
        const isTagExist = selectedTags?.find((item) => item?.id === tag?.id);
        if (isTagExist) {
            const newSelectedTags = selectedTags?.filter((item) => item?.id !== tag?.id);
            setSelectedTags(newSelectedTags);
        } else {
            setSelectedTags([...selectedTags, tag]);
        }
    };

    // const handleSave = () => {
    //     setMessage('Saving Selected Tags....');
    //     handleTagSelectOperation(authInfo, selectedTags)
    //         .then(({ message }) => {
    //             setMessage(message);
    //             setOpen(false);
    //             handelReload();
    //         })
    //         .catch((err) => {
    //             setMessage(err.toString());
    //         });
    // };

    return (
        <div className="tag-selection-modal">
            <LibModal title="Select Tag" setOpen={setOpen}>
                <div className="modal-body-cont">
                    <div>
                        <ul>
                            {tags?.map((item) => (
                                <li
                                    key={item.id}
                                    className="flex-row position-stretch align-space-between"
                                >
                                    <div
                                        key={item?.id}
                                        role="button"
                                        tabIndex={-1}
                                        onClick={() => handleSelectTag(item)}
                                        style={{ backgroundColor: item.color }}
                                        className={
                                            selectedTags
                                                ?.filter((i) => i?.id === item?.id)
                                                ?.includes(item)
                                                ? 'active'
                                                : ''
                                        }
                                    />
                                    <div>{item?.notes}</div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="modal-action flex-row align-right">
                        <ButtonGray title="Cancel" evOnClick={() => setOpen(false)} />
                        <ButtonGreen title="Add" evOnClick={handleSave} />
                    </div>
                </div>
            </LibModal>
        </div>
    );
}

export default TagSelectionModal;
