import OverviewSecCol1CustomerInfo from './OverviewSecCol1CustomerInfo';
import OverviewSecCol1Row2 from './OverviewSecCol1Row2';

function OverviewSecCol1Row1({ customerInfo }) {
    return <OverviewSecCol1CustomerInfo customerInfo={customerInfo} />;
}

function OverviewSecCol1({ overviewData }) {
    const { customerInfo, responses, notes } = overviewData || {};

    return (
        <div className="overview-col-1 flex-col position-space-between">
            <OverviewSecCol1Row1 customerInfo={customerInfo} />
            <OverviewSecCol1Row2 responses={responses} notes={notes} />
        </div>
    );
}

export default OverviewSecCol1;
