export const categoriesDemoData = [
    {
        id: 10000001,
        category: 'Ads'
    },
    {
        id: 10000002,
        category: 'Chemical'
    },
    {
        id: 10000003,
        category: 'Food'
    },
    {
        id: 10000004,
        category: 'Fixed'
    },
    {
        id: 10000005,
        category: 'Fuel'
    },
    {
        id: 10000006,
        category: 'Instrument'
    },
    {
        id: 10000007,
        category: 'Marketing Material'
    },
    {
        id: 10000008,
        category: 'Mobile Recharge'
    },
    {
        id: 10000009,
        category: 'Other'
    },
    {
        id: 10000010,
        category: 'Office Setup'
    },
    {
        id: 10000011,
        category: 'Repair'
    },
    {
        id: 10000012,
        category: 'Room Rent'
    },
    {
        id: 10000013,
        category: 'Salary'
    },
    {
        id: 10000014,
        category: 'Tips & Convince'
    },
    {
        id: 10000015,
        category: 'Transport'
    },
    {
        id: 10000016,
        category: 'Working Material'
    }
];

export default null;
