import dayjs from 'dayjs';
import { useState } from 'react';
import FilterRow from '../../../../features/search-filter/components/FilterRow';
import { DateRange } from '../../../../lib/LibDatePicker';
import { AnimateSelectField, addOption } from '../../../../utils/AnimateInputField';

const options = [
    {
        id: 1,
        title: 'All',
        value: 'all'
    },
    {
        id: 2,
        title: 'Form',
        value: 'form'
    },
    {
        id: 3,
        title: 'Facebook',
        value: 'facebook'
    },
    {
        id: 4,
        title: 'Bikiran',
        value: 'bikiran'
    }
];
const statusOptions = [
    {
        id: 1,
        title: 'Any',
        value: 'any'
    },
    {
        id: 2,
        title: 'Active',
        value: 'active'
    },
    {
        id: 3,
        title: 'Inactive',
        value: 'inactive'
    }
];

function TableFiltersOptions({ setFormData, formData, handleChange, dateRef }) {
    const [showMenu, setShowMenu] = useState(false);

    const handleOnChange = (e) => {
        if (e.length === 2) {
            setFormData((cuData) => ({
                ...cuData,
                dateFrom: dayjs(e[0]).format('YYYY-MM-DD') || '',
                dateTo: dayjs(e[1]).format('YYYY-MM-DD') || ''
            }));
        }
    };

    return (
        <div className="table-filter-option">
            <FilterRow title="Source">
                <AnimateSelectField
                    title="Select Source"
                    name="source"
                    formData={formData}
                    handelChange={handleChange}
                    options={options.map((item) => addOption(item.id, item.title, item.value))}
                />
            </FilterRow>

            <FilterRow title="Join Date">
                <DateRange
                    startDate={
                        formData?.dateFrom || dayjs().subtract(1, 'month').format('YYYY-MM-DD')
                    }
                    endDate={formData.dateTo || dayjs().format('YYYY-MM-DD')}
                    handelOnChange={handleOnChange}
                    showMenu={showMenu}
                    setShowMenu={setShowMenu}
                    fRef={dateRef}
                />
            </FilterRow>

            <FilterRow title="Status">
                <AnimateSelectField
                    title="Select Status"
                    name="status"
                    formData={formData}
                    handelChange={handleChange}
                    options={statusOptions.map((item) =>
                        addOption(item.id, item.title, item.value)
                    )}
                />
            </FilterRow>
        </div>
    );
}

export default TableFiltersOptions;
