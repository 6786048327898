import FloatingInputProvider from '../../../features/floating-input/FloatingInputProvider';
import BmCustomerOverviewProvider from './BmCustomerOverviewProvider';
import CustomerOverviewSec from './overview_sec/CustomerOverviewSec';

function BmCustomerOverviewPage() {
    return (
        <BmCustomerOverviewProvider>
            <FloatingInputProvider>
                <div className="page-cont customer-overview">
                    <CustomerOverviewSec />
                </div>
            </FloatingInputProvider>
        </BmCustomerOverviewProvider>
    );
}

export default BmCustomerOverviewPage;
