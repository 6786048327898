import { useEffect, useState } from 'react';
import { useAuth } from '../../../features/auth/AuthProvider';
import { getProjectId } from '../../../features/menus/AdvMenuProvider';
import { useTemplate } from '../../../features/template/TemplateProvider';
import { handleLoadOrdersList } from './BmOrdersPageOperation';
import BmOrdersPageHeader from './components/BmOrdersPageHeader';
import BmOrdersTable from './components/BmOrdersTable';

function BmOrdersPage() {
    const [orderList, setOrderList] = useState(null);
    const [reloadKey, setReloadKey] = useState(Math.random());

    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    const projectId = getProjectId();

    const handleReload = () => {
        setOrderList(null);
        setReloadKey((d) => d + 1);
    };

    useEffect(() => {
        // setOrderList(null);
        handleLoadOrdersList(authInfo, projectId)
            .then(({ data }) => {
                setOrderList(data);
            })
            .catch((err) => {
                setMessage(err.toString(0));
            });
    }, [reloadKey, authInfo, projectId, setMessage, setOrderList]);

    return (
        <div className="page-cont bm-orders-page">
            <BmOrdersPageHeader handleReload={handleReload} />

            <BmOrdersTable orderList={orderList} />
        </div>
    );
}

export default BmOrdersPage;
