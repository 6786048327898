/* eslint-disable import/no-extraneous-dependencies */
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useState } from 'react';
import { ButtonGray, ButtonGreen } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import LibModal from '../../../../lib/LibModal';
import AnimateInputField from '../../../../utils/AnimateInputField';
import { handelSendEmail } from '../InvoiceUpdateOperation';

const toolbarConfig = {
    items: [
        'undo',
        'redo',
        '|',
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList'
    ]
};

const initialState = {
    emails: '',
    subject: '',
    body: ''
};

function FinInvoiceSendEmail({ setOpen, invoiceId, handelReload }) {
    const [formData, setFormData] = useState(initialState);

    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    const handelChange = (ev) => {
        const { name } = ev.target;
        if (name) {
            setFormData((d) => ({ ...d, [name]: ev.target.value }));
        }
    };

    // handel send button
    const handelSend = () => {
        setMessage('Sending....');

        handelSendEmail(authInfo, invoiceId, formData.emails, formData.subject, formData.body)
            .then(({ message }) => {
                setMessage(message);
                setFormData(initialState);
                setOpen(false);
                handelReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <LibModal title="Email" setOpen={setOpen}>
            <div className="sms-email-cont">
                <div>
                    <AnimateInputField
                        title="Email Address"
                        name="emails"
                        formData={formData}
                        handelChange={handelChange}
                    />
                    <AnimateInputField
                        title="Subject"
                        name="subject"
                        formData={formData}
                        handelChange={handelChange}
                    />

                    {/* <AnimateTextareaField
                        title="Body"
                        name="body"
                        formData={formData}
                        handelChange={handelChange}
                    /> */}

                    <CKEditor
                        editor={ClassicEditor}
                        data={formData?.body}
                        config={{ toolbar: toolbarConfig }}
                        onChange={(event, editor) => {
                            const data = editor?.getData();
                            setFormData((cuData) => ({ ...cuData, body: data }));
                        }}
                    />
                </div>
                <div>
                    <button type="button" className="attachment-btn">
                        Attachment
                    </button>
                </div>

                <div className="action flex-row align-right">
                    <ButtonGray evOnClick={() => setOpen(false)} title="Cancel" />

                    <ButtonGreen evOnClick={handelSend} title="Send" />
                </div>
            </div>
        </LibModal>
    );
}

export default FinInvoiceSendEmail;
