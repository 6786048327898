import AxiosAPI from '../../../utils/AxiosAPI';

export function handleAddExpense(authInfo, formData, projectId) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/bm/expense/add/`)
            .post({ ...formData, projectId })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handleUpdateExpense(authInfo, formData, expenseId, projectId) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/bm/expense/${expenseId}/update/`)
            .post({ ...formData, projectId })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handelDeleteExpense(authInfo, expenseId, note) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/bm/expense/${expenseId}/delete/`)
            .post({ note })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function handleLoadSupportDataList(authInfo, projectId) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/bm/team-member/form-support-data/`, { projectId })
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}
