import { icons } from '../../../../../configs/Icons';

function ConLeftColConversationHeader() {
    return (
        <div className="con_conversation-header flex-row align-space-between">
            <div className="flex-row position-middle">
                <div className="con_conversation-user-avatar">
                    <img src={icons.avatar} alt="avatar" />
                </div>
                <div>
                    <h2 className="con_conversation-user-name">Conversation Header</h2>
                </div>
            </div>
            <div className="flex-row position-middle">
                <div className="con_conversation-action flex-row align-center position-middle">
                    <img src={icons.info} alt="info" />
                </div>
            </div>
        </div>
    );
}

export default ConLeftColConversationHeader;
