import { useEffect, useState } from 'react';
import { ButtonPurple } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import { handleSyncMessages } from '../BmPageinfoOperation';

function SyncCount({ customerInfo }) {
    const show = customerInfo?.name;

    const syncedConversation = customerInfo?.counted;

    return (
        <div className={`flex-row position-middle align-center ${show ? 'active' : ''}`}>
            {syncedConversation}
        </div>
    );
}

function CustomerInfo({ customerInfo }) {
    return (
        <div>
            <h3>{customerInfo?.name}</h3>
            <p>{customerInfo?.snippet}</p>
        </div>
    );
}

function ConvSyncStartButton({ projectId, disabled }) {
    const [syncedCon, setSyncedCon] = useState({});

    const { customerInfo, message, start } = syncedCon || {};

    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();

    // Function to handle start syncing messages data
    const handleStartMessageSyncing = () => {
        handleSyncMessages(authInfo, projectId)
            .then((data) => {
                if (data && data?.cont) {
                    setSyncedCon((d) => ({
                        ...d,
                        ...data,
                        customerInfo: data.data,
                        start: true
                    }));

                    handleStartMessageSyncing();
                } else {
                    setMessage(data.message);
                    setSyncedCon((d) => ({ ...d, ...data, start: false }));
                }
            })
            .catch((err) => {
                // Display error message
                setMessage(err.toString(0));
            });
    };

    const handleStop = () => {
        setSyncedCon((d) => ({ ...d, start: false }));
    };

    useEffect(() => {
        if (disabled) {
            // clear conversation synced data
            setSyncedCon({});
        }
    }, [disabled]);

    return (
        <div className="flex-row position-middle align-space-between message-sync-cont">
            <div>
                {start ? (
                    <ButtonPurple
                        disabled={disabled}
                        className="fill-x"
                        evOnClick={handleStop}
                        title="Stop Syncing"
                    />
                ) : (
                    <ButtonPurple
                        disabled={disabled}
                        className="fill-x"
                        evOnClick={() => handleStartMessageSyncing()}
                        title="Start Syncing Messages"
                    />
                )}
            </div>

            <SyncCount customerInfo={customerInfo} />

            {start ? (
                <CustomerInfo customerInfo={customerInfo} />
            ) : (
                <div>
                    <h3>{message}</h3>
                </div>
            )}
        </div>
    );
}

export default ConvSyncStartButton;
