import React from 'react';
import { Link } from 'react-router-dom';
import { icons } from '../../../../configs/Icons';
import { mkRelativeUrl } from '../AdvMenuProvider';

function AdvLabel2({ menu }) {
    return (
        <div className="adv-l2-cont">
            <div className="adv-l2-head flex-row position-middle">
                <img src={menu.iconFill} alt="" />
                <span className="text-dot"> {menu.title}</span>
            </div>
            <ul className="adv-list-2">
                {menu?.subMenu?.map(({ id, title, iconL, iconF, active }) => (
                    <li key={id} className={active ? 'active' : ''}>
                        <Link to={mkRelativeUrl(`/${id}/`)} title={title}>
                            <img src={iconL} alt="Icon L" />
                            <img src={iconF || icons.dashboard} alt="Icon L" />
                            <span>{title}</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default AdvLabel2;
