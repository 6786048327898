import { createContext, useContext, useMemo, useState } from 'react';
import { designationDays } from '../../../../data/designationDemoData';
import { UpperCase } from '../../../utils/Show';

// Context creation
const BmConfigDesignationContext = createContext();

// Custom hook to use the context
export const useDesignation = () => useContext(BmConfigDesignationContext);

const initialState = {
    designation: '',
    salaryMin: '',
    salaryMax: '',
    duration: '',
    day: '',
    weeklyHoliday1: '',
    weeklyHoliday2: '',
    officeHourStart: '',
    officeHourEnd: ''
};

// BmConfigDesignationProvider component definition
function BmConfigDesignationProvider({ children }) {
    const [formData, setFormData] = useState(initialState);
    const [designationInfo, setDesignationInfo] = useState(null);
    const [reloadKey, setReloadKey] = useState(Math.random());
    const [modal, setModal] = useState({
        type: '',
        data: null
    });

    // Create a memoized value object that holds various functions
    const value = useMemo(() => {
        // Function to trigger reloading data
        const handleReload = () => {
            setReloadKey((d) => d + 1);
            setDesignationInfo(null);
        };

        const handleOpenModal = (type, data) => {
            setModal({
                type,
                data
            });
        };

        const handleCloseModal = () => {
            setModal({
                type: '',
                data: null
            });
        };

        const getSalaryDisburse = (item) => {
            if (!item) {
                return null;
            }
            const duration = item?.salaryDisburse?.split(':')?.[0]?.toString()?.toLowerCase();
            const day = item?.salaryDisburse?.split(':')?.[1]?.toString();

            const dayName =
                designationDays?.[duration]?.find((i) => i.id?.toString() === day)?.label || '';

            const output = `${`${UpperCase(duration)}ly`}/${dayName}`;
            return output;
        };

        const getHolidayInfo = (item) => {
            if (!item) {
                return null;
            }

            let holiday = '';
            const weeks = designationDays.week.filter((el) => el.id !== -1 && el.id !== -2);
            const holiday1 = weeks.find((i) => i.id === item?.weeklyHoliday1)?.label || '';
            const holiday2 = weeks.find((i) => i.id === item?.weeklyHoliday2)?.label || '';

            if (
                item?.weeklyHoliday1?.toString()?.length &&
                item?.weeklyHoliday2?.toString()?.length &&
                item?.weeklyHoliday1 !== -1 &&
                item?.weeklyHoliday1 !== -2 &&
                item?.weeklyHoliday2 !== -1 &&
                item?.weeklyHoliday2 !== -2
            ) {
                holiday = `${holiday1} - ${holiday2}`;
            } else if (item?.weeklyHoliday1) {
                holiday = holiday1;
            } else if (item?.weeklyHoliday2) {
                holiday = holiday2;
            }

            return holiday;
        };

        // Return an object with all the values and functions
        return {
            designationInfo,
            setDesignationInfo,
            reloadKey,
            handleReload,
            modalType: modal.type,
            modalData: modal.data,
            handleOpenModal,
            handleCloseModal,
            formData,
            setFormData,
            getSalaryDisburse,
            getHolidayInfo
        };
    }, [designationInfo, formData, modal.data, modal.type, reloadKey]);

    // Provide the value through the context
    return (
        <BmConfigDesignationContext.Provider value={value}>
            {children}
        </BmConfigDesignationContext.Provider>
    );
}

export default BmConfigDesignationProvider;
