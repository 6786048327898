import React, { useState } from 'react';
import { ButtonGray, ButtonGreen } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { getProjectId } from '../../../../features/menus/AdvMenuProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import LibModal from '../../../../lib/LibModal';
import { AnimateTimeField } from '../../../../utils/AnimateInputField';
import { handleUpdateOrder } from '../BmOrdersPageOperation';

function OrderUpdateModal({ setOpen, item }) {
    const [formData, setFormData] = useState({});

    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();

    const projectId = getProjectId();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleUpdate = () => {
        setMessage('Updating Order...');
        handleUpdateOrder(authInfo, formData, item?.id, projectId)
            .then(({ message }) => {
                setOpen(false);
                setMessage(message);
                // handleReload();
                console.log('clicked');
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <div className="order-update-modal">
            <LibModal title="Update Order" setOpen={setOpen}>
                <div className="modal-body-cont">
                    <div>
                        {/* <div className="input-row">
                            <AnimateInputField
                                title="Client Name"
                                name="name"
                                formData={formData}
                                handelChange={handleChange}
                            />
                        </div>
                        <div className="input-row">
                            <AnimateInputField
                                title="Client Phone"
                                name="phone"
                                formData={formData}
                                handelChange={handleChange}
                            />
                        </div>
                        <div className="input-row">
                            <AnimateSelectField
                                title="Branch"
                                name="branchId"
                                formData={formData}
                                handelChange={handleChange}
                                options={branchData?.map((branch) =>
                                    addOption(branch.id, branch.name, branch.id)
                                )}
                            />
                        </div>
                        <div className="input-row">
                            <AnimateInputField
                                title="Area"
                                name="area"
                                formData={formData}
                                handelChange={handleChange}
                            />
                        </div>
                        <div className="input-row">
                            <AnimateTextareaField
                                title="Address"
                                name="address"
                                formData={formData}
                                handelChange={handleChange}
                            />
                        </div> */}
                        <div className="input-row">
                            <AnimateTimeField
                                title="Working Schedule Time"
                                name="time"
                                formData={formData}
                                handelChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="modal-action flex-row align-right">
                        <div className="flex-row align-right">
                            <ButtonGray title="Cancel" evOnClick={() => setOpen(false)} />
                            <ButtonGreen title="Update" evOnClick={handleUpdate} />
                        </div>
                    </div>
                </div>
            </LibModal>
        </div>
    );
}

export default OrderUpdateModal;
