import { useEffect, useState } from 'react';
import { ButtonMenu } from '../../../../../configs/Buttons';
import { icons } from '../../../../../configs/Icons';
import { syncDemoData } from '../../../../../data/syncDemoData';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useAdvMenu } from '../../../../features/menus/AdvMenuProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import InstOption from '../../../../utils/InstOption';
import { useBmConfig } from '../BmConfigProvider';
import { handleLoadPageInfo, handleSyncResume, handleSyncStart } from '../BmPageinfoOperation';
import BmConfigFbPageInfoHeader from './BmConfigFbPageInfoHeader';

function PageInfo({ title, pageId, logo }) {
    return (
        <div className="flex-row position-middle">
            <div className="page-logo">
                <span>
                    <img src={logo || icons.defaultIcon} alt="logo" referrerPolicy="no-referrer" />
                </span>
            </div>

            <div>
                <h2 className="page-title">
                    <span>{title}</span>
                </h2>
                <p className="page-id">
                    <span>{pageId}</span>
                </p>
            </div>
        </div>
    );
}

function PageManageButton({ handleSyncStartClick, handleSyncResumeClick }) {
    const [showMenu, setShowMenu] = useState(false);

    return (
        <ButtonMenu evOnClick={() => setShowMenu(true)}>
            <InstOption showMenu={showMenu} setShowMenu={setShowMenu}>
                <button type="button" onClick={handleSyncStartClick}>
                    Start Syncing
                </button>
                <button type="button" onClick={handleSyncResumeClick}>
                    Resume Syncing
                </button>
            </InstOption>
        </ButtonMenu>
    );
}

function BmConfigFbPageInfo({ projectId }) {
    const { setPageInfo, pageInfo, setSyncData, reloadKey, handleSyncData, handleReload } =
        useBmConfig();

    const { profileImage, name, id, pageCount } = pageInfo || {};

    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();

    const { activeProject } = useAdvMenu();

    const setInitialData = () => {
        setSyncData({ data: syncDemoData, empty: true });
    };

    // Fetch initial page info on component mount
    useEffect(() => {
        // Load page info based on auth, project, reloadKey, and activeProject
        handleLoadPageInfo(authInfo, projectId)
            .then(({ data }) => {
                setPageInfo(data?.fbPage);
            })
            .catch((err) => {
                setMessage(err.toString(0));
            });
    }, [authInfo, setMessage, setPageInfo, activeProject, reloadKey, projectId]);

    // Function to start syncing data
    const handleSyncStartClick = () => {
        // Set initial empty demo data to indicate loading state
        setInitialData();
        // Initiate the sync process by calling handleSyncStart
        handleSyncStart(authInfo, projectId)
            .then(({ data, newPageNumber }) => {
                handleSyncData({ ...data, newPageNumber, currentToken: '' });
            })
            .catch((err) => {
                // Update syncData with error state and display error message
                setSyncData({ data: [], empty: false });
                setMessage(err.toString(0));
            });
    };

    // Function to resume syncing data
    const handleSyncResumeClick = () => {
        // Set initial empty demo data to indicate loading state
        setInitialData();
        // Resume syncing from the last token (pageInfo.after)
        handleSyncResume(authInfo, projectId, pageInfo?.after)
            .then(({ data, newPageNumber }) => {
                handleSyncData({ ...data, newPageNumber, currentToken: pageInfo?.after });
            })
            .catch((err) => {
                // Update syncData with error state and display error message
                setSyncData({ data: [], empty: false });
                setMessage(err.toString(0));
            });
    };

    return (
        <div>
            <BmConfigFbPageInfoHeader handleReload={handleReload} />

            <div
                className={`fb-page-info-cont flex-row align-space-between position-middle ${
                    !pageInfo?.id?.length ? 'placeholder-mode' : ''
                }`}
            >
                <div className="flex-row align-space-between position-middle">
                    <PageInfo
                        logo={profileImage}
                        title={name || '--------------'}
                        pageId={id || '----------------'}
                    />
                    <span className="page-count">Page Count: {pageCount + 1}</span>
                </div>

                <PageManageButton
                    handleSyncStartClick={handleSyncStartClick}
                    handleSyncResumeClick={handleSyncResumeClick}
                />
            </div>
        </div>
    );
}

export default BmConfigFbPageInfo;
