import React from 'react';
import { showCurrency } from '../../../utils/Show';

function FinInvoiceProductTable({
    // currency,
    items = []
    // status,
    // openProductModal,
    // handelRemoveProduct
}) {
    // const isInvoiceActive = status === 'active' || openProductModal === undefined;

    return (
        <table className="inv-table" cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th width="12%">Sl</th>
                    <th width="39%">Description</th>
                    <th width="18%">Quantity</th>
                    <th width="18%">Unit Price</th>
                    <th width="13%">Amount</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className="text-center">100100</td>
                    <td>
                        Building-1 <br />
                        10,000 L PVC Tank
                    </td>
                    <td>3</td>
                    <td>2000</td>
                    <td>6,000.00</td>
                </tr>

                <tr className="tfoot-cont">
                    <td colSpan={4} className="text-right">
                        <b>Total Cost:</b>
                    </td>
                    <td>
                        <b>
                            <span className="price-amount">
                                {showCurrency(
                                    items.reduce(
                                        (total, item) => item.price * item.quantity + total,
                                        0
                                    )
                                )}
                            </span>
                        </b>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

export default FinInvoiceProductTable;
