/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ButtonGreen } from '../../../configs/Buttons';
import { icons } from '../../../configs/Icons';
import { useAuth } from '../auth/AuthProvider';
import { getProjectId, useAdvMenu } from '../menus/AdvMenuProvider';
import { useTemplate } from '../template/TemplateProvider';
import { handelAddApp } from './AppOperation';

function AppActivationSection({ show }) {
    const [isChecked, setIsChecked] = useState(false);

    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();
    const { pathname } = useLocation();

    const { handleProjectReload } = useAdvMenu();

    const projectId = getProjectId();

    const handleCheck = () => {
        setIsChecked((prevState) => !prevState);
    };

    const handleActiveButton = () => {
        let appKey = '';
        if (pathname) {
            appKey = pathname.split('/')[1]?.toString();
        }

        setMessage('Adding App...');
        handelAddApp(authInfo, projectId, { appKey, acceptTc: isChecked })
            .then(({ message }) => {
                handleProjectReload();
                setMessage(message);
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    if (!show) {
        return (
            <div className="app-manage-section flex-row position-middle align-center">
                <div className="flex-col position-middle align-center">
                    <div className="bik-logo">
                        <img src={icons.bikLogoV2} alt="" />
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className="app-manage-section flex-row position-middle align-center">
            <div className="flex-col position-middle align-center">
                <p>Please activate this service as this service is inactive.</p>
                <p
                    role="button"
                    tabIndex={0}
                    onClick={handleCheck}
                    className="terms-text flex-row position-middle"
                >
                    <input
                        type="checkbox"
                        checked={isChecked}
                        onClick={handleCheck}
                        onChange={handleCheck}
                        name="check"
                    />{' '}
                    I Agree to all the <span>Terms & Conditions</span>
                </p>

                <ButtonGreen evOnClick={handleActiveButton} disabled={!isChecked} title="Active" />
            </div>
        </div>
    );
}

export default AppActivationSection;
