import { ButtonReload } from '../../../../configs/Buttons';

function InvConfigPageHeader() {
    return (
        <div className="page-header-cont flex-row w-100p align-space-between position-middle">
            <div>
                <h2 className="page-header_title">Configuration</h2>
            </div>
            <div>
                <ButtonReload evOnClick={null} />
            </div>
        </div>
    );
}

export default InvConfigPageHeader;
