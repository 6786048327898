import dayjs from 'dayjs';
import { ButtonGray, ButtonGreen } from '../../../../../configs/Buttons';
import { bmExpenseTypes, expenseCategoryData } from '../../../../../data/expenseDemoData';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import UserSearchField from '../../../../features/user-search/UserSearchField';
import LibModal from '../../../../lib/LibModal';
import AnimateInputField, {
    AnimateDateField,
    AnimateNumberField,
    AnimateSelectField,
    AnimateTextareaField,
    addOption
} from '../../../../utils/AnimateInputField';
import { showCurrency } from '../../../../utils/Show';
import { handleUpdateExpense } from '../BmExpenseOperation';

const statusData = [
    { id: 'active', title: 'Active' },
    { id: 'inactive', title: 'Inactive' }
];

function BmExpenseUpdateModal({
    handleCloseModal,
    handleReload,
    handleChange,
    formData,
    projectId,
    requiredData,
    selectedUser,
    setSelectedUser
}) {
    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    const handleUpdate = () => {
        setMessage('Updating...');

        const updateData = {
            userId: formData?.userId,
            title: formData?.title,
            category: formData?.category?.toLowerCase(),
            branchId: formData?.branchId,
            quantity: formData?.quantity,
            type: formData?.type,
            unitPrice: formData?.unitPrice,
            date:
                typeof formData?.date === 'number'
                    ? dayjs(formData?.date * 1000).format('YYYY-MM-DD')
                    : formData?.date,
            note: formData?.note,
            status: formData?.status
        };

        handleUpdateExpense(authInfo, updateData, projectId)
            .then(({ message }) => {
                setMessage(message);
                handleCloseModal();
                handleReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <div className="expense-action-modal">
            <LibModal title="Update Expense" setOpen={handleCloseModal}>
                <div className="modal-body-cont">
                    <div>
                        <UserSearchField
                            selectedUser={selectedUser}
                            setSelectedUser={setSelectedUser}
                        />

                        <div className="input-row">
                            <AnimateInputField
                                title="Title"
                                name="title"
                                handelChange={handleChange}
                                formData={formData}
                            />
                        </div>

                        <div className="input-row flex-row align-space-between">
                            <div className="inp-fill-2">
                                <AnimateSelectField
                                    title="Select Category"
                                    name="category"
                                    handelChange={handleChange}
                                    formData={formData}
                                    options={expenseCategoryData?.map((item) =>
                                        addOption(item.title, item.title, item.title)
                                    )}
                                />
                            </div>

                            <div className="inp-fill-2">
                                <AnimateSelectField
                                    title="Select Branch"
                                    name="branchId"
                                    handelChange={handleChange}
                                    formData={formData}
                                    options={requiredData?.branches?.map((branch) =>
                                        addOption(branch.id, branch.name, branch.id)
                                    )}
                                />
                            </div>
                        </div>
                        <div className="input-row flex-row align-space-between">
                            <div className="inp-fill-2">
                                <AnimateInputField
                                    title="Quantity"
                                    name="quantity"
                                    handelChange={handleChange}
                                    formData={formData}
                                />
                            </div>

                            <div className="inp-fill-2">
                                <AnimateSelectField
                                    title="Select Type"
                                    name="type"
                                    handelChange={handleChange}
                                    formData={formData}
                                    options={bmExpenseTypes?.map((item) =>
                                        addOption(item.id, item.title, item.id)
                                    )}
                                />
                            </div>
                        </div>
                        <div className="input-row">
                            <AnimateNumberField
                                title="Price Per Unit"
                                name="unitPrice"
                                handelChange={handleChange}
                                formData={formData}
                            />
                        </div>

                        {formData?.unitPrice && formData?.quantity ? (
                            <div className="">
                                <p className="calculation-text">
                                    Total: {showCurrency(formData?.unitPrice * formData?.quantity)}
                                </p>
                            </div>
                        ) : null}

                        <div className="input-row">
                            <AnimateDateField
                                title="Select Date"
                                name="date"
                                handelChange={handleChange}
                                formData={formData}
                            />
                        </div>

                        <div className="input-row">
                            <AnimateTextareaField
                                title="Note"
                                name="note"
                                handelChange={handleChange}
                                formData={formData}
                            />
                        </div>

                        <div className="input-row">
                            <AnimateSelectField
                                title="Select Status"
                                name="status"
                                handelChange={handleChange}
                                formData={formData}
                                options={statusData?.map((item) =>
                                    addOption(item.id, item.title, item.id)
                                )}
                            />
                        </div>
                    </div>
                    <div className="modal-action flex-row align-right">
                        <div className="flex-row">
                            <ButtonGray title="Cancel" evOnClick={handleCloseModal} />
                            <ButtonGreen title="Update" evOnClick={handleUpdate} />
                        </div>
                    </div>
                </div>
            </LibModal>
        </div>
    );
}

export default BmExpenseUpdateModal;
