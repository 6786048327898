import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { icons } from '../../../../configs/Icons';
import InstOption from '../../../utils/InstOption';
import { GetDate } from '../../../utils/Show';
import { mkRelativeUrl } from '../../menus/AdvMenuProvider';
import NotFound from '../../not-found/NotFound';

function Tr({ activeProject, handleOptionClick, projects, handleConfig, handleChangeStatus }) {
    const instOptRef = useRef();
    const [showMenu, setShowMenu] = useState(false);

    const handleMenu = (ev, id) => {
        ev.stopPropagation();
        setShowMenu(id);
    };

    return projects?.map((item) => (
        <tr
            key={item?.id}
            onClick={() => handleOptionClick(item)}
            className={item?.empty ? 'placeholder-mode' : ''}
        >
            <td className="text-left">
                <span>{item.id}</span>
            </td>
            <td className="text-left">
                <span>
                    <div className="flex-row col-gap-10">
                        {item.title}{' '}
                        {activeProject?.id === item.id && <img src={icons?.tickIcon} alt="" />}
                    </div>
                </span>
            </td>
            <td className="text-left">
                <span>{GetDate(1691373078)}</span>
            </td>
            <td className="text-left">
                <span className={`status ${item.status}`}>{item.status}</span>
            </td>
            <td className="text-right">
                <div className="flex-row align-right p-relative action">
                    <span>
                        <button
                            type="button"
                            className=""
                            ref={instOptRef}
                            onClick={(ev) => handleMenu(ev, item.id)}
                        >
                            <img src={icons?.optionFill} alt="" />
                        </button>
                    </span>
                    <InstOption
                        buttonRef={instOptRef}
                        align="right"
                        setShowMenu={setShowMenu}
                        showMenu={showMenu === item.id}
                    >
                        <button type="button" onClick={(ev) => handleChangeStatus(ev, item)}>
                            Change Status
                        </button>
                        {item?.status === 'active' && (
                            <button type="button" onClick={(ev) => handleConfig(ev, item)}>
                                Config
                            </button>
                        )}
                    </InstOption>
                </div>
            </td>
        </tr>
    ));
}

function ProjectsTable({
    activeProject,
    handleOptionClick,
    projectList,
    setOpenProjectModal,
    handleChangeStatus
}) {
    const navigate = useNavigate();
    const handleConfig = (ev, item) => {
        ev.stopPropagation();
        navigate(
            mkRelativeUrl(`/project-manage/`, {
                'project-id': item.id
            })
        );
        setOpenProjectModal(false);
        // added onclick body
        document.getElementsByClassName('project-dropdown-container')[0].classList.remove('active');
    };

    if (projectList && !projectList.length) {
        return <NotFound title="No Projects Found!" />;
    }
    return (
        <table className="bik-table project-manage-table">
            <thead>
                <tr>
                    <th width="15%" className="text-left">
                        ID
                    </th>
                    <th className="text-left">Title</th>
                    <th width="25%" className="text-left">
                        Creation Date
                    </th>
                    <th width="15%" className="text-left">
                        Status
                    </th>
                    <th width="15%" className="text-right">
                        Action
                    </th>
                </tr>
            </thead>
            <tbody className="">
                <Tr
                    handleOptionClick={handleOptionClick}
                    activeProject={activeProject}
                    projects={projectList}
                    handleConfig={handleConfig}
                    handleChangeStatus={handleChangeStatus}
                />
            </tbody>
        </table>
    );
}

export default ProjectsTable;
