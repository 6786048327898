import { useEffect } from 'react';
import { useAuth } from '../../../features/auth/AuthProvider';
import { getProjectId, useAdvMenu } from '../../../features/menus/AdvMenuProvider';
import { useTemplate } from '../../../features/template/TemplateProvider';
import { handleLoadSupportDataList } from './BmExpenseOperation';
import BmExpensePageHeader from './BmExpensePageHeader';
import BmExpenseProvider, { useExpense } from './BmExpenseProvider';
import BmExpenseTable from './BmExpenseTable';

function PageContent({ projectId }) {
    const { handleReload, handleOpenModal, expenseInfo, setRequiredData } = useExpense();

    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();

    useEffect(() => {
        handleLoadSupportDataList(authInfo, projectId)
            .then(({ data }) => {
                setRequiredData(data);
            })
            .catch((err) => {
                setMessage(err.toString(0));
            });
    }, [authInfo, projectId, setMessage, setRequiredData]);

    return (
        <div className="page-cont expense-page-cont">
            <BmExpensePageHeader handleReload={handleReload} openModal={handleOpenModal} />

            <BmExpenseTable
                expenseInfo={expenseInfo}
                handleOpenModal={handleOpenModal}
                handelReload={handleReload}
            />
        </div>
    );
}

function BmExpensePage() {
    const { activeProject } = useAdvMenu();
    const projectId = activeProject?.id || getProjectId();
    return (
        <BmExpenseProvider projectId={projectId}>
            <PageContent projectId={projectId} />
        </BmExpenseProvider>
    );
}

export default BmExpensePage;
