/* eslint-disable import/no-extraneous-dependencies */
import BmTransactionsPageHeader from './components/BmTransactionsPageHeader';

function BmTransactionsPage() {
    return (
        <div className="page-cont">
            <BmTransactionsPageHeader />
        </div>
    );
}

export default BmTransactionsPage;
