import AxiosAPI from '../../utils/AxiosAPI';

export const handleLoadProjectList = (authInfo) =>
    new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/users/project/list/`)
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });

export function handelAddProject(authInfo, formData) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/users/project/add-project/`)
            .post({ ...formData })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export const handleChangeStatusOpe = (authInfo, projectId, formData) =>
    new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/users/project/${projectId}/change-status/`)
            .post({ ...formData })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
export default null;
