/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonPurple } from '../../../configs/Buttons';
import { icons } from '../../../configs/Icons';
import { useAuth } from '../auth/AuthProvider';
import { setProject, useAdvMenu } from '../menus/AdvMenuProvider';
import { useTemplate } from '../template/TemplateProvider';
import { handleLoadProjectList } from './ProjectOperation';
import ProjectAddModal from './modals/ProjectAddModal';
import ProjectSettingModal from './modals/ProjectSettingModal';

export function addItem(id, icon, title) {
    return { id, icon, title };
}

function DropdownToggleMenu({ handleDropdownClick, activeProject }) {
    return (
        <button type="button" className="dropdown-toggle" onClick={handleDropdownClick}>
            <div className="project-dropdown_option flex-row position-middle align-space-between">
                <div className="flex-row position-middle">
                    <div className="project-dropdown_logo flex-row align-center position-middle">
                        <img
                            src={activeProject?.icon || icons.defaultIcon}
                            alt={activeProject?.title}
                        />
                    </div>
                </div>
                <div className="flex-row position-middle">
                    <h2 className="project-dropdown_title">
                        {activeProject?.title || 'Select Project'}
                    </h2>
                </div>
                <div className="flex-row position-middle align-right">
                    <img className="arrow" src={icons.downArrow} alt="arrow" />
                </div>
            </div>
        </button>
    );
}

function Option({ activeProject, handleOptionClick, item }) {
    const isProjectActive = activeProject?.id === item?.id;

    return (
        <li
            role="button"
            tabIndex={-1}
            onClick={() => handleOptionClick(item)}
            className={`project-dropdown_option flex-row position-stretch align-space-between ${
                isProjectActive ? 'active' : ''
            }`}
        >
            <div className="flex-row position-middle">
                <div className="project-dropdown_logo flex-row align-center position-middle">
                    <img src={item?.icon || icons.defaultIcon} alt="" />
                </div>
            </div>
            <div className="flex-row position-middle">
                <h2 className="project-dropdown_title">{item?.title}</h2>
            </div>
            <div className="flex-row position-middle align-right">
                {isProjectActive && (
                    <div className="project-dropdown_tick-icon flex-row position-middle ">
                        <img src={icons.tickIcon} alt="tick" />
                    </div>
                )}
            </div>
        </li>
    );
}

function ProjectDropdown({ projects }) {
    const ref = useRef();
    const ulRef = useRef(); // Add a ref for the ul element
    const [show, setShow] = useState(false);
    const [openProjectModal, setOpenProjectModal] = useState(false);

    const navigate = useNavigate();
    const { handleProjectReload, activeProject, setProjectList, reloadKey } = useAdvMenu();

    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    const handleDropdownClick = () => {
        setShow((st) => !st);
    };

    const handleOptionClick = (item) => {
        setProject(navigate, item);
        setShow(false);
        setOpenProjectModal(false);

        // After selecting an option, calculate the scroll position of the ul element
    };

    useEffect(() => {
        function handelClickOutside(event) {
            if (ref?.current && !ref?.current.contains(event?.target)) {
                setShow(false);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handelClickOutside);
        return () => {
            document.removeEventListener('mousedown', handelClickOutside);
        };
    }, [ref]);

    const activeItemId = activeProject?.id;
    useEffect(() => {
        if (ulRef.current && ref.current) {
            const itemIndex = projects?.findIndex((project) => {
                if (!project) {
                    return null;
                }
                return project?.id === activeItemId;
            });
            const itemHeight = 35; // Height of each li item
            const scrollTop = itemIndex * itemHeight;
            ulRef.current.scrollTop = scrollTop;
        }
    }, [activeItemId, projects, show]);

    useEffect(() => {
        // setProjectList(null);
        handleLoadProjectList(authInfo)
            .then(({ data }) => {
                setProjectList(data);
            })
            .catch((err) => {
                setMessage(err.toString(0));
            });
    }, [authInfo, setMessage, setProjectList, reloadKey]);

    return (
        <div ref={ref} className={`project-dropdown-container ${show ? 'active' : ''}`}>
            <DropdownToggleMenu
                handleDropdownClick={handleDropdownClick}
                activeProject={activeProject}
            />

            <div className="project-item-cont">
                <ul className="" ref={ulRef}>
                    {projects?.map((item) => {
                        if (!item) {
                            return null;
                        }
                        return (
                            <Option
                                key={item?.id}
                                activeProject={activeProject}
                                handleOptionClick={handleOptionClick}
                                item={item}
                            />
                        );
                    })}
                </ul>
                <div className="flex-row align-space-between m-5 manage-project-button col-gap-5">
                    <ButtonPurple
                        title="Add Project"
                        evOnClick={() => setOpenProjectModal('add')}
                        className="fill"
                    />
                    <button
                        onClick={() => setOpenProjectModal('manage')}
                        type="button"
                        className="button"
                    >
                        <img src={icons.defaultIconWhite} alt="" className="w-100p" />
                    </button>
                </div>

                {openProjectModal === 'add' ? (
                    <ProjectAddModal
                        setOpen={setOpenProjectModal}
                        handleReload={handleProjectReload}
                        closeDropdownMenu={() => setShow(false)}
                    />
                ) : null}

                {openProjectModal === 'manage' ? (
                    <ProjectSettingModal
                        handleOptionClick={handleOptionClick}
                        activeProject={activeProject}
                        setOpenProjectModal={setOpenProjectModal}
                        handleProjectReload={handleProjectReload}
                    />
                ) : null}
            </div>
        </div>
    );
}

export default ProjectDropdown;
