import { useState } from 'react';
import { ButtonPurple, ButtonReload } from '../../../../configs/Buttons';
import ProductAddModal from './modals/ProductAddModal';

function InvProductPageHeader({ handleReload }) {
    const [showModal, setShowModal] = useState(false);
    return (
        <div className="page-header-cont flex-row w-100p align-space-between position-middle">
            <div className="flex-row position-middle">
                <h2 className="page-header_title">Product/Service</h2>
            </div>

            <div className="flex-row position-stretch">
                <ButtonReload evOnClick={handleReload} className="mr-2" />
                <ButtonPurple evOnClick={() => setShowModal(true)} title="Add Product" />
                {showModal ? <ProductAddModal closeModal={setShowModal} /> : null}
            </div>
        </div>
    );
}

export default InvProductPageHeader;
