function ProjectManageHeader() {
    return (
        <div className="page-header-cont flex-row align-space-between position-middle">
            <div className="flex-row position-middle gap-50">
                <div>
                    <h2 className="page-header_title">Project Manage</h2>
                </div>
            </div>
        </div>
    );
}

export default ProjectManageHeader;
