export const invInvoiceData = [
    {
        id: 10001072,
        title: 'TDC-BD-domain-hosting-service-tdcbd',
        currency: 'BDT',
        ratio: 109,
        amountBilled: 6236,
        amountPaid: 0,
        paymentStatus: 'due',
        timeIssue: 1689616800,
        timeDue: 1691863200,
        status: 'pending',
        userInfo: {
            name: 'Milon Sheikh',
            photoUrl: '',
            email: 'milonsheikh7@gmail.com',
            phone: '8801670224720'
        }
    },
    {
        id: 10001045,
        title: 'Milan-Vai-Domain-Hosting-sstradersjes',
        currency: 'BDT',
        ratio: 106,
        amountBilled: 6000,
        amountPaid: 0,
        paymentStatus: 'due',
        timeIssue: 1689616800,
        timeDue: 1691863200,
        status: 'pending',
        userInfo: {
            name: 'Golam Moinul Haque Milan',
            photoUrl: '',
            email: 'tumitelecom@gmail.com',
            phone: '8801711368448'
        }
    },
    {
        id: 10001056,
        title: 'Bangladesh-Seed-Association-product',
        currency: 'BDT',
        ratio: 106,
        amountBilled: 701.75,
        amountPaid: 0,
        paymentStatus: 'due',
        timeIssue: 1688925600,
        timeDue: 1691604000,
        status: 'pending',
        userInfo: {
            name: 'Faqrul Islam',
            photoUrl: '',
            email: 'ed@bsabd.org',
            phone: '8801844514621'
        }
    },
    {
        id: 10001094,
        title: 'Bikiran',
        currency: 'USD',
        ratio: 1,
        amountBilled: 0,
        amountPaid: 0,
        paymentStatus: 'due',
        timeIssue: 1691471546,
        timeDue: 1691557946,
        status: 'pending',
        userInfo: {
            name: 'Yeasin Arafat',
            photoUrl: '',
            email: 'yeasinn2002@gmail.com',
            phone: null
        }
    },
    {
        id: 10001042,
        title: 'Milan-Vai-Fatimah-Hospital-domain-hosting',
        currency: 'BDT',
        ratio: 106,
        amountBilled: 6000,
        amountPaid: 0,
        paymentStatus: 'due',
        timeIssue: 1688839200,
        timeDue: 1691517600,
        status: 'pending',
        userInfo: {
            name: 'Golam Moinul Haque Milan',
            photoUrl: '',
            email: 'tumitelecom@gmail.com',
            phone: '8801711368448'
        }
    },
    {
        id: 10001093,
        title: 'Bikiran',
        currency: 'USD',
        ratio: 1,
        amountBilled: 0,
        amountPaid: 0,
        paymentStatus: 'due',
        timeIssue: 1691386684,
        timeDue: 1691473084,
        status: 'pending',
        userInfo: {
            name: 'Yeasin Arafat',
            photoUrl: '',
            email: 'yeasinn2002@gmail.com',
            phone: null
        }
    },
    {
        id: 10001088,
        title: 'Uliana-Ivankiv-server-dedicated',
        currency: 'USD',
        ratio: 1,
        amountBilled: 97.099999999999994315658113919198513031005859375,
        amountPaid: 0,
        paymentStatus: 'due',
        timeIssue: 1690999200,
        timeDue: 1691431200,
        status: 'pending',
        userInfo: {
            name: 'Ivankiv USD Uliana',
            photoUrl: '',
            email: 'ivankivuljana@gmail.com',
            phone: '380971403549'
        }
    },
    {
        id: 10001051,
        title: 'Milan-Vai-domain-hosting-citytradersbd',
        currency: 'BDT',
        ratio: 106,
        amountBilled: 6000,
        amountPaid: 0,
        paymentStatus: 'due',
        timeIssue: 1688493600,
        timeDue: 1691344800,
        status: 'pending',
        userInfo: {
            name: 'Golam Moinul Haque Milan',
            photoUrl: '',
            email: 'tumitelecom@gmail.com',
            phone: '8801711368448'
        }
    },
    {
        id: 10001050,
        title: 'Milan-Vai-sarkergroupbd-domain-hosting',
        currency: 'BDT',
        ratio: 106,
        amountBilled: 6000,
        amountPaid: 0,
        paymentStatus: 'due',
        timeIssue: 1688320800,
        timeDue: 1691258400,
        status: 'pending',
        userInfo: {
            name: 'Golam Moinul Haque Milan',
            photoUrl: '',
            email: 'tumitelecom@gmail.com',
            phone: '8801711368448'
        }
    },
    {
        id: 10001059,
        title: 'HRS-domain-hosting-gsuite-hrsattiresourcingltd',
        currency: 'BDT',
        ratio: 109,
        amountBilled: 62968,
        amountPaid: 0,
        paymentStatus: 'due',
        timeIssue: 1690567200,
        timeDue: 1691172000,
        status: 'pending',
        userInfo: {
            name: 'Shakhawat Hossain Liton',
            photoUrl: '',
            email: 'liton@hrsattiresourcingltd.com',
            phone: '8801740646064'
        }
    },
    {
        id: 10001069,
        title: 'SSIL-domain-hosting-ssilbd-com',
        currency: 'BDT',
        ratio: 109,
        amountBilled: 14918.399999999999636202119290828704833984375,
        amountPaid: 14208,
        paymentStatus: 'due',
        timeIssue: 1689616800,
        timeDue: 1690999200,
        status: 'pending',
        userInfo: {
            name: 'Zaidi Khan',
            photoUrl: '',
            email: 'zaidi_khan@ssibd.org',
            phone: '8801711150166'
        }
    },
    {
        id: 10001092,
        title: 'Enroute-International-Limited-product',
        currency: 'BDT',
        ratio: 109,
        amountBilled: 2100,
        amountPaid: 2000,
        paymentStatus: 'due',
        timeIssue: 1690826400,
        timeDue: 1690826400,
        status: 'pending',
        userInfo: {
            name: 'Habibur Enroute',
            photoUrl: '',
            email: 'habibur@enroute.com.bd',
            phone: '8801721714960'
        }
    },
    {
        id: 10001083,
        title: 'Part-One-Technology-hosting-srseedsbd',
        currency: 'BDT',
        ratio: 109,
        amountBilled: 1980,
        amountPaid: 1980,
        paymentStatus: 'paid',
        timeIssue: 1690718162,
        timeDue: 1690804562,
        status: 'active',
        userInfo: {
            name: 'Nazmul Haque',
            photoUrl: '',
            email: 'partonebd@gmail.com',
            phone: '8801824295295'
        }
    },
    {
        id: 10001086,
        title: 'Milan-Vai-Hasan-Traders-Support-Service-May-June-2023',
        currency: 'BDT',
        ratio: 106,
        amountBilled: 2000,
        amountPaid: 2000,
        paymentStatus: 'paid',
        timeIssue: 1690740000,
        timeDue: 1690740000,
        status: 'active',
        userInfo: {
            name: 'Golam Moinul Haque Milan',
            photoUrl: '',
            email: 'tumitelecom@gmail.com',
            phone: '8801711368448'
        }
    },
    {
        id: 10001085,
        title: 'Bahanno-News-FB-Campaign',
        currency: 'BDT',
        ratio: 106,
        amountBilled: 79821.270000000004074536263942718505859375,
        amountPaid: 79821.270000000004074536263942718505859375,
        paymentStatus: 'paid',
        timeIssue: 1690740000,
        timeDue: 1690740000,
        status: 'active',
        userInfo: {
            name: 'Bivash Barai',
            photoUrl: '',
            email: 'bahannonews@gmail.com',
            phone: '8801535234554'
        }
    },
    {
        id: 10001084,
        title: 'Jahanara-Enterprise-domain-hosting',
        currency: 'BDT',
        ratio: 106,
        amountBilled: 6000,
        amountPaid: 6000,
        paymentStatus: 'paid',
        timeIssue: 1690740000,
        timeDue: 1690740000,
        status: 'active',
        userInfo: {
            name: 'Golam Moinul Haque Milan',
            photoUrl: '',
            email: 'tumitelecom@gmail.com',
            phone: '8801711368448'
        }
    },
    {
        id: 10001081,
        title: 'BEIOA-SEIP-PROJECT-product',
        currency: 'BDT',
        ratio: 109,
        amountBilled: 9660,
        amountPaid: 0,
        paymentStatus: 'due',
        timeIssue: 1690221600,
        timeDue: 1690740000,
        status: 'pending',
        userInfo: {
            name: 'Asis Kumar Das',
            photoUrl: '',
            email: 'asish435@gmail.com',
            phone: '8801818349445'
        }
    },
    {
        id: 10001068,
        title: 'Aricho-Limited-domain-hosting-iftekabir',
        currency: 'BDT',
        ratio: 109,
        amountBilled: 5127,
        amountPaid: 5127,
        paymentStatus: 'paid',
        timeIssue: 1689616800,
        timeDue: 1690740000,
        status: 'active',
        userInfo: {
            name: 'Palash Proshanjit Malaker',
            photoUrl: '',
            email: 'aricholimited@gmail.com',
            phone: '8820127001914'
        }
    },
    {
        id: 10001067,
        title: 'Hamko-Battery-domain-mexsa-org',
        currency: 'BDT',
        ratio: 109,
        amountBilled: 1437.7100000000000363797880709171295166015625,
        amountPaid: 1437.7100000000000363797880709171295166015625,
        paymentStatus: 'paid',
        timeIssue: 1689616800,
        timeDue: 1690740000,
        status: 'active',
        userInfo: {
            name: 'Iftekhar Mamun',
            photoUrl: '',
            email: 'iftekhar.mamun@hamko.com.bd',
            phone: '8801755535536'
        }
    },
    {
        id: 10001066,
        title: 'Rasel-Vai-server-vps-Jul-23',
        currency: 'BDT',
        ratio: 106,
        amountBilled: 3500,
        amountPaid: 3500,
        paymentStatus: 'paid',
        timeIssue: 1689530400,
        timeDue: 1690740000,
        status: 'active',
        userInfo: {
            name: 'Rasel Vai',
            photoUrl: '',
            email: 'raslmania07@gmail.com',
            phone: '8801841003385'
        }
    },
    {
        id: 10001063,
        title: 'Mastermind-Collegiate-School-&-Child-Care-product-contract',
        currency: 'BDT',
        ratio: 109,
        amountBilled: 26000,
        amountPaid: 0,
        paymentStatus: 'due',
        timeIssue: 1689444000,
        timeDue: 1690740000,
        status: 'pending',
        userInfo: {
            name: 'Babul Hasan',
            photoUrl: '',
            email: 'mcsc.noapara@gmail.com',
            phone: '8801736005484'
        }
    }
];
export default null;
