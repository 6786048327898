import AxiosAPI from '../../utils/AxiosAPI';

export const handleLoadRoleData = (authInfo, projectId) =>
    new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/users/project/${projectId}/roles-list/`)
            .get()
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });

export const handleSendInvite = (authInfo, projectId, email, role) =>
    new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/roles/${projectId}/invite-send/`)
            .post({
                email,
                role
            })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });

export const handleResendInvite = (authInfo, projectId, roleId) =>
    new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/roles/${projectId}/invite-resend/`)
            .post({
                roleId
            })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });

export const handleCancelInvite = (authInfo, projectId, roleId) =>
    new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/roles/${projectId}/invite-cancel/`)
            .post({
                roleId
            })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });

export const handleChangeOwner = (authInfo, projectId, roleId) =>
    new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/roles/${projectId}/change-ownership/`)
            .post({
                roleId
            })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });

export const handleChangeRole = (authInfo, projectId, roleId, roleKey) =>
    new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/roles/${projectId}/modify/`)
            .post({
                roleId,
                roleKey
            })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });

export const handleRemoveRole = (authInfo, projectId, roleId) =>
    new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/roles/${projectId}/delete/`)
            .post({
                roleId
            })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });

export default null;
