import BmAccountsPageHeader from './components/BmAccountsPageHeader';

function BmAccountsPage() {
    return (
        <div className="page-cont">
            <BmAccountsPageHeader />
        </div>
    );
}

export default BmAccountsPage;
