import CustomerAddModal from '../modals/CustomerAddModal';

function CustomersModalController({ modalType, closeClick, handleReload }) {
    if (modalType === 'addCustomer') {
        return <CustomerAddModal setOpen={closeClick} handleReload={handleReload} />;
    }

    return null;
}

export default CustomersModalController;
