import { useState } from 'react';
import { ButtonGray, ButtonGreen } from '../../../../configs/Buttons';
import LibModal from '../../../lib/LibModal';
import { useAuth } from '../../auth/AuthProvider';
import { useAdvMenu } from '../../menus/AdvMenuProvider';
import ProjectsTable from '../components/ProjectsTable';
import ProjectStatusModal from './ProjectStatusModal';

const projects = [
    {
        id: 101123,
        title: '------------',
        empty: true
    },
    {
        id: 233224,
        title: '------------',
        empty: true
    }
];

function ProjectAction({ projectList, setOpenModal }) {
    if (projectList && !projectList.length) {
        return <ButtonGreen evOnClick={() => setOpenModal('add')} title="Add Project" />;
    }
    return <ButtonGreen evOnClick={() => setOpenModal('add')} title="Add Project" />;
}

function ProjectSettingModal({
    activeProject,
    handleOptionClick,
    setOpenProjectModal,
    handleProjectReload
}) {
    const { projectList } = useAdvMenu();
    const { authInfo } = useAuth();
    const [openStatusModal, setOpenStatusModal] = useState({ show: false, item: null });

    const arr = projectList || projects;

    const handleChangeStatus = (ev, item) => {
        ev.stopPropagation();
        setOpenStatusModal({ show: true, item });
    };

    const closeModal = () => {
        setOpenStatusModal({ show: false, item: null });
    };

    return (
        <div className="project-setting-modal">
            <LibModal
                title="Manage Projects"
                subTitle={authInfo?.currentUser?.name}
                setOpen={setOpenProjectModal}
            >
                <div className="modal-content-body">
                    <div>
                        <ProjectsTable
                            handleOptionClick={handleOptionClick}
                            activeProject={activeProject}
                            projectList={arr}
                            setOpenProjectModal={setOpenProjectModal}
                            handleChangeStatus={handleChangeStatus}
                        />
                    </div>
                    <div className="flex-row align-right position-bottom">
                        <div className="modal-action flex-row position-middle">
                            <ButtonGray
                                evOnClick={() => setOpenProjectModal(false)}
                                title="Cancel"
                            />
                            <ProjectAction
                                projectList={projects}
                                setOpenModal={setOpenProjectModal}
                            />
                        </div>
                    </div>
                </div>
            </LibModal>
            {openStatusModal.show && (
                <ProjectStatusModal
                    item={openStatusModal.item}
                    closeModal={closeModal}
                    handleProjectReload={handleProjectReload}
                />
            )}
        </div>
    );
}

export default ProjectSettingModal;
