export const branchDemoData = [
    {
        id: 10000001,
        branchName: 'Khulna branch',
        branchAddress: 'House No. P/55, Road No. 102',
        name: 'Chris Henry',
        contact: '01765984236',
        position: '095555',
        district: 'Khulna',
        status: 'inactive',
        demo: true
    },
    {
        id: 10000002,
        branchName: 'Khulna branch',
        branchAddress: 'House No. P/55, Road No. 102',
        name: 'Chris Henry',
        contact: '01765984236',
        position: '195555',
        district: 'Jashore',
        status: 'active',
        demo: true
    }
    // {
    //     id: 10000003,
    //     branchName: 'Khulna Branch',
    //     branchAddress: 'House No. P/55, Road No. 102',
    //     name: 'Chris Henry',
    //     contact: '01765984236',
    //     position: '295555',
    //     district: 'Kushtia',
    //     status: 'inactive',
    //     demo: true
    // },
    // {
    //     id: 10000004,
    //     branchName: 'Khulna Branch',
    //     branchAddress: 'House No. P/55, Road No. 102',
    //     name: 'Chris Henry',
    //     contact: '01765984236',
    //     position: '055555',
    //     district: 'Dhaka',
    //     status: 'active',
    //     demo: true
    // },
    // {
    //     id: 10000005,
    //     branchName: 'Khulna Branch',
    //     branchAddress: 'House No. P/55, Road No. 102',
    //     name: 'Chris Henry',
    //     contact: '01765984236',
    //     position: '155555',
    //     district: 'Khulna',
    //     status: 'active',
    //     demo: true
    // }
];

export default null;
