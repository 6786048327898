import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { categoriesDemoData } from '../../../../data/categoriesDemoData';
import BmConfigCategoryAddModal from './modals/BmConfigCategoryAddModal';
import BmConfigCategoryUpdateModal from './modals/BmConfigCategoryUpdateModal';

// Context creation
const BmConfigCategoriesContext = createContext();

// Custom hook to use the context
export const useCategories = () => useContext(BmConfigCategoriesContext);

// BmConfigBranchProvider component definition
function BmConfigCategoriesProvider({ children, projectId }) {
    const [categoryInfo, seCategoryInfo] = useState(categoriesDemoData);
    const [reloadKey, setReloadKey] = useState(Math.random());
    const [modal, setModal] = useState({
        type: '',
        data: null
    });

    const modalType = modal.type;
    const modalData = modal.data;

    const [formData, setFormData] = useState({});

    useEffect(() => {
        if (modalType === 'update' && modalData) setFormData(modalData || {});
    }, [modalData, modalType]);

    // Function to handle form data change
    const handleChange = (e) => {
        const { name, value: v } = e.target;
        setFormData((d) => ({ ...d, [name]: v }));
    };

    const handleReload = () => {
        setReloadKey((d) => d + 1);
        // seCategoryInfo(null);
    };

    const handleOpenModal = (type, data) => {
        setModal({
            type,
            data
        });
    };

    const handleCloseModal = () => {
        setModal({
            type: '',
            data: null
        });
        setFormData({});
    };

    // Create a memoized value object that holds various functions
    const value = useMemo(
        () =>
            // Function to trigger reloading data

            // Return an object with all the values and functions
            ({
                categoryInfo,
                seCategoryInfo,
                reloadKey,
                handleReload,
                modalType,
                modalData,
                handleOpenModal,
                handleCloseModal
            }),
        [categoryInfo, modalData, modalType, reloadKey]
    );

    // Provide the value through the context
    return (
        <BmConfigCategoriesContext.Provider value={value}>
            {children}

            {modalType === 'add' ? (
                <BmConfigCategoryAddModal
                    formData={formData}
                    handleChange={handleChange}
                    handleCloseModal={handleCloseModal}
                    handleReload={handleReload}
                    projectId={projectId}
                />
            ) : null}

            {modalType === 'update' ? (
                <BmConfigCategoryUpdateModal
                    formData={formData}
                    handleChange={handleChange}
                    handleCloseModal={handleCloseModal}
                    handleReload={handleReload}
                    projectId={projectId}
                />
            ) : null}
        </BmConfigCategoriesContext.Provider>
    );
}

export default BmConfigCategoriesProvider;
