import BmApiPageHeader from './components/BmApiPageHeader';

function BmApiPage() {
    return (
        <div className="page-cont">
            <BmApiPageHeader />
        </div>
    );
}

export default BmApiPage;
