import { icons } from '../../../../configs/Icons';
import { UpperCase } from '../../../utils/Show';

function SearchField({ setShowFilter, name, setFormData, formData, placeholder }) {
    // const navigate = useNavigate();
    // const [searchParams] = useSearchParams();

    // const handleKeyPress = (e) => {
    //     if (e.key === 'Enter') {
    //         navigate(mkRelativeUrl('/business-manager/customers/', { ...formData }));
    //     }
    // };

    // useEffect(() => {
    //     const searchQuery = {};
    //     if (searchParams.get('q')) {
    //         searchQuery.q = searchParams.get('q');
    //     }
    //     if (searchParams.get('source')) {
    //         searchQuery.source = searchParams.get('source');
    //     }
    //     if (searchParams.get('dateFrom')) {
    //         searchQuery.dateFrom = searchParams.get('dateFrom');
    //     }
    //     if (searchParams.get('dateTo')) {
    //         searchQuery.dateTo = searchParams.get('dateTo');
    //     }
    //     if (searchParams.get('status')) {
    //         searchQuery.status = searchParams.get('status');
    //     }

    //     setFormData(searchQuery);
    // }, [searchParams, setFormData]);

    const handleFilterInput = (e) => {
        const { value } = e.target;
        const [key, val] = value.split(':');
        setFormData((cuData) => ({ ...cuData, [UpperCase(key)]: val }));
    };

    const handleKeyPress = () => {
        console.log('clicked');
    };

    console.log(formData);

    return (
        <div
            role="button"
            tabIndex={-1}
            onClick={() => setShowFilter(true)}
            className="search-filter_input flex-row align-space-between position-middle"
        >
            <input
                name={name || 'q'}
                autoComplete="off"
                defaultValue={Object.keys(formData).reduce((acc, key) => {
                    if (formData[key] !== '') {
                        return `${acc} ${UpperCase(key)}:${formData[key]}`;
                    }
                    return acc;
                }, '')}
                onChange={handleFilterInput}
                onKeyPress={handleKeyPress}
                placeholder={placeholder || 'Search Something...'}
                type="text"
            />
            <div className="img-filter flex-row align-center position-middle">
                <img src={icons.filter} alt="filter" />
            </div>
        </div>
    );
}

export default SearchField;
