export const teamsDemoData = [
    {
        id: 10000010,
        name: 'Christopher Henry',
        phone: '01777777777',
        designation: 'Branch Manager',
        grade: '0555',
        branch: 'Khulna Branch',
        joiningDate: 1693459476,
        salary: '555555555',
        durationType: 'month',
        status: 'active',
        demo: true
    },
    {
        id: 10000011,
        name: 'Christopher Nolan',
        phone: '01777777777',
        designation: 'Field Worker',
        grade: '0555',
        branch: 'Jashore Branch',
        joiningDate: 1693459476,
        salary: '555555555',
        durationType: 'week',
        status: 'active',
        demo: true
    },
    {
        id: 10000012,
        name: 'Christopher Walken',
        phone: '01777777777',
        designation: 'Branch Manager',
        grade: '0555',
        branch: 'Kushtia Branch',
        joiningDate: 1693459476,
        salary: '555555555',
        durationType: 'week',
        status: 'active',
        demo: true
    }
];

export const teamsDurationOptions = [
    { id: 'week', title: 'Weekly' },
    { id: 'month', title: 'Monthly' }
];

export const teamsGrades = [
    {
        id: 1,
        title: 'Grade-1'
    },
    {
        id: 2,
        title: 'Grade-2'
    },
    {
        id: 3,
        title: 'Grade-3'
    },
    {
        id: 4,
        title: 'Grade-4'
    },
    {
        id: 5,
        title: 'Grade-5'
    },
    {
        id: 6,
        title: 'Grade-6'
    },
    {
        id: 7,
        title: 'Grade-7'
    },
    {
        id: 8,
        title: 'Grade-8'
    },
    {
        id: 9,
        title: 'Grade-9'
    },
    {
        id: 10,
        title: 'Grade-10'
    }
];
export default null;
