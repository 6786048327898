import { ButtonPurple } from '../../../../configs/Buttons';

function FilterArea({ show, evApplyClick, children }) {
    if (!show) {
        return null;
    }
    return (
        <div className="search-filter_popup">
            {children}
            <div className="flex-row align-right">
                <ButtonPurple evOnClick={evApplyClick} title="Apply" />
            </div>
        </div>
    );
}

export default FilterArea;
