import { useEffect, useState } from 'react';
import { ButtonGray, ButtonGreen } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { getProjectId, useAdvMenu } from '../../../../features/menus/AdvMenuProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import LibModal from '../../../../lib/LibModal';
import { AnimateSelectField, addOption } from '../../../../utils/AnimateInputField';
import { handleLoadTeamSupportList, handleUpdateBranch } from '../BmCustomerOverviewOperation';

function UpdateBranchModal({ handleCloseModal }) {
    const [formData, setFormData] = useState({});
    const [requiredData, setRequiredData] = useState({});

    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();

    const { activeProject } = useAdvMenu();
    const projectId = activeProject?.id || getProjectId();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    useEffect(() => {
        handleLoadTeamSupportList(authInfo, projectId)
            .then(({ data }) => {
                setRequiredData(data);
            })
            .catch((err) => {
                setMessage(err.toString(0));
            });
    }, [authInfo, projectId, setMessage]);

    const handleSave = () => {
        setMessage('Updating Branch...');
        handleUpdateBranch(authInfo, formData, projectId)
            .then(({ message }) => {
                // handleCloseModal();
                setFormData({});
                setMessage(message);
                // handleReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <div className="update-branch-modal">
            <LibModal title="Update Branch" setOpen={handleCloseModal}>
                <div className="modal-body-cont">
                    <div>
                        <AnimateSelectField
                            title="Select Branch"
                            name="branchId"
                            formData={formData}
                            handelChange={handleChange}
                            options={requiredData?.branches?.map((item) =>
                                addOption(item.id, item.name, item.id)
                            )}
                        />
                    </div>
                    <div className="modal-action flex-row align-right">
                        <div className="flex-row align-right">
                            <ButtonGray title="Cancel" evOnClick={handleCloseModal} />
                            <ButtonGreen title="Save" evOnClick={handleSave} />
                        </div>
                    </div>
                </div>
            </LibModal>
        </div>
    );
}

export default UpdateBranchModal;
