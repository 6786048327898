import { createContext, useContext, useMemo, useState } from 'react';

// Context creation
const BmConfigContext = createContext();

// Custom hook to use the context
export const useBmConfig = () => useContext(BmConfigContext);

// BmConfigProvider component definition
function BmConfigProvider({ children }) {
    // State declarations
    const [pageInfo, setPageInfo] = useState(null);
    const [reloadKey, setReloadKey] = useState(Math.random());
    const [syncData, setSyncData] = useState(null);

    // Destructure the 'after' and 'before' values from syncData
    const { after } = syncData?.paging?.cursors || {};
    const { before } = syncData?.paging?.cursors || {};
    const currentToken = syncData?.currentToken;
    const prevToken = syncData?.prevToken;

    // disable sync button if syncData is empty or disabled
    const disabled =
        !syncData?.data?.length ||
        (syncData?.data?.length && syncData?.empty) ||
        syncData?.disabled;

    // Create a memoized value object that holds various functions
    const value = useMemo(() => {
        // Function to trigger reloading data
        const handleReload = () => {
            // Increment the reloadKey to trigger a re-fetch of page info
            setReloadKey((d) => d + 1);
            // Reset pageInfo to null to indicate loading state
            setPageInfo(null);
        };

        const handleSyncData = (updateData) => {
            const newData = {
                ...syncData,
                ...updateData,
                empty: false
            };
            setSyncData(newData);

            const pageInfoUpdate = {
                pageCount: newData.newPageNumber,
                after: newData.paging?.cursors?.after || ''
            };
            setPageInfo((d) => ({ ...d, ...pageInfoUpdate }));
        };

        // Return an object with all the values and functions
        return {
            setPageInfo,
            setSyncData,
            pageInfo,
            syncData,
            disabled,
            reloadKey,
            handleReload,
            handleSyncData,
            prevToken,
            currentToken,
            before,
            after
        };
    }, [after, before, currentToken, disabled, pageInfo, prevToken, reloadKey, syncData]);

    // Provide the value through the context
    return <BmConfigContext.Provider value={value}>{children}</BmConfigContext.Provider>;
}

export default BmConfigProvider;
