/* eslint-disable react/no-danger */
import { useEffect, useRef, useState } from 'react';
import { icons } from '../../../configs/Icons';

export function UserInfoV5({
    img,
    name,
    email,
    phone,
    evOnClick,
    className = '',
    refName,
    nameRef,
    emailRef
}) {
    return (
        <div
            ref={refName}
            role={evOnClick ? 'button' : ''}
            tabIndex={-1}
            onClick={evOnClick}
            className={`user-info v5 flex-row position-middle ${className}`}
        >
            <div>
                <div className="user-info_avatar">
                    <span>
                        <img src={img || icons.avatar} alt={name} referrerPolicy="no-referrer" />
                    </span>
                </div>
            </div>

            <div>
                <h2 ref={nameRef} className="user-info_name">
                    <span>{name || '----------------------'}</span>
                </h2>
                <h2 ref={emailRef} className="user-info_email">
                    {email && <span>{email}</span>}, {phone && <span>{phone}</span>}
                </h2>
            </div>
        </div>
    );
}

export function UserInfoV4({ img, name, email, title = '', className = '', refName }) {
    const [showProfile, setShowProfile] = useState(false);
    const ref = useRef();

    const handleProfileView = (ev) => {
        ev.stopPropagation();
        setShowProfile((cuData) => !cuData);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current?.contains(event?.target)) {
                setShowProfile((cuData) => !cuData);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div ref={refName} className={`user-info v4 flex-row position-middle ${className}`}>
            {showProfile && (
                <div className="user-floating-menu flex-row" ref={ref}>
                    <div className="user-info_avatar">
                        <img src={img || icons.avatar} alt={name} referrerPolicy="no-referrer" />
                    </div>
                    <div className=" flex-col w-100p gap-5">
                        <h2 className="user-info_name"> {name || '----------------------'}</h2>
                        <h2 className="user-info_email">{email && `${email}`}</h2>
                    </div>
                </div>
            )}

            <div
                role="button"
                tabIndex={-1}
                className="user-info_avatar"
                onClick={handleProfileView}
            >
                <span>
                    <img src={img || icons.avatar} alt={name} referrerPolicy="no-referrer" />
                </span>
            </div>

            <div>
                <h2 className="user-info_title">
                    {title && <span dangerouslySetInnerHTML={{ __html: title }} />}
                </h2>
            </div>
        </div>
    );
}

export function UserInfoV3({
    img,
    name,
    email = '',
    evOnClick,
    className = '',
    refName,
    nameRef,
    emailRef
}) {
    return (
        <div
            ref={refName}
            role={evOnClick ? 'button' : ''}
            tabIndex={-1}
            onClick={evOnClick}
            className={`user-info v3 flex-row position-middle ${className}`}
        >
            <div>
                <div className="user-info_avatar">
                    <span>
                        <img src={img || icons.avatar} alt={name} referrerPolicy="no-referrer" />
                    </span>
                </div>
            </div>

            <div>
                <h2 ref={nameRef} className="user-info_name">
                    <span>{name || '----------------------'}</span>
                </h2>
                <h2 ref={emailRef} className="user-info_email">
                    {email && <span>{email}</span>}
                </h2>
            </div>
        </div>
    );
}

export function UserInfoV2({
    img,
    name,
    email,
    evOnClick,
    className = '',
    refName,
    nameRef,
    emailRef
}) {
    return (
        <div
            ref={refName}
            role={evOnClick ? 'button' : ''}
            tabIndex={-1}
            onClick={evOnClick}
            className={`user-info v2  flex-row position-middle ${className}`}
        >
            <div>
                <div className="user-info_avatar">
                    <span>
                        <img src={img || icons.avatar} alt="avatar" />
                    </span>
                </div>
            </div>

            <div>
                <h2 ref={nameRef} className="user-info_name">
                    <span>{name || '---------------'}</span>
                </h2>
                <h2 ref={emailRef} className="user-info_email">
                    {email && <span>{email}</span>}
                </h2>
            </div>
        </div>
    );
}

function UserInfo({ img, name, email, evOnClick, className = '', refName, nameRef, emailRef }) {
    return (
        <div
            ref={refName}
            role={evOnClick ? 'button' : ''}
            tabIndex={-1}
            onClick={evOnClick}
            className={`user-info flex-row position-middle ${className}`}
        >
            <div>
                <div className="user-info_avatar">
                    <span>
                        <img src={img || icons.avatar} alt="avatar" />
                    </span>
                </div>
            </div>

            <div>
                <h2 ref={nameRef} className="user-info_name">
                    <span>{name || '---------------'}</span>
                </h2>
                <h2 ref={emailRef} className="user-info_email">
                    {email && <span>{email}</span>}
                </h2>
            </div>
        </div>
    );
}

export default UserInfo;
