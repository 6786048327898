function ConLeftColConversationThread() {
    return (
        <div className="con_conversation-thread">
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure, non deserunt!
                Expedita enim hic natus! Obcaecati suscipit voluptatem aperiam deserunt eveniet
                velit iure quo illo, optio cumque cupiditate voluptatum dicta.
            </p>
        </div>
    );
}

export default ConLeftColConversationThread;
