export const pvcTankPrice = [
    {
        id: 1,
        size: 500,
        price: 399
    },
    {
        id: 2,
        size: 800,
        price: 500
    },
    {
        id: 3,
        size: 1000,
        price: 600
    },
    {
        id: 4,
        size: 1500,
        price: 700
    },
    {
        id: 5,
        size: 2000,
        price: 800
    },
    {
        id: 6,
        size: 3000,
        price: 1000
    },
    {
        id: 7,
        size: 5000,
        price: 1200
    },
    {
        id: 8,
        size: 7000,
        price: 1400
    },
    {
        id: 9,
        size: 10000,
        price: 1500
    }
];

export default null;
