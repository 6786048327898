import { ButtonGray, ButtonGreen } from '../../../../../configs/Buttons';
import { bmExpenseTypes, expenseCategoryData } from '../../../../../data/expenseDemoData';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import UserSearchField from '../../../../features/user-search/UserSearchField';
import LibModal from '../../../../lib/LibModal';
import AnimateInputField, {
    AnimateDateField,
    AnimateNumberField,
    AnimateSelectField,
    AnimateTextareaField,
    addOption
} from '../../../../utils/AnimateInputField';
import { showCurrency } from '../../../../utils/Show';
import { handleAddExpense } from '../BmExpenseOperation';

function BmExpenseAddModal({
    handleCloseModal,
    handleReload,
    handleChange,
    formData,
    requiredData,
    selectedUser,
    setSelectedUser,
    projectId
}) {
    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    const handleSave = () => {
        setMessage('Adding...');
        handleAddExpense(
            authInfo,
            { ...formData, userId: selectedUser ? selectedUser?.id : '' },
            projectId
        )
            .then(({ message }) => {
                setMessage(message);
                handleCloseModal();
                handleReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <div className="expense-action-modal">
            <LibModal title="Add New Expense" setOpen={handleCloseModal}>
                <div className="modal-body-cont">
                    <div>
                        <UserSearchField
                            selectedUser={selectedUser}
                            setSelectedUser={setSelectedUser}
                        />

                        <div className="input-row">
                            <AnimateInputField
                                title="Title"
                                name="title"
                                handelChange={handleChange}
                                formData={formData}
                            />
                        </div>

                        <div className="input-row flex-row align-space-between">
                            <div className="inp-fill-2">
                                <AnimateSelectField
                                    title="Select Category"
                                    name="category"
                                    handelChange={handleChange}
                                    formData={formData}
                                    options={expenseCategoryData?.map((item) =>
                                        addOption(item.id, item.title, item.id)
                                    )}
                                />
                            </div>

                            <div className="inp-fill-2">
                                <AnimateSelectField
                                    title="Select Branch"
                                    name="branch"
                                    handelChange={handleChange}
                                    formData={formData}
                                    options={requiredData?.branches?.map((branch) =>
                                        addOption(branch.id, branch.name, branch.id)
                                    )}
                                />
                            </div>
                        </div>

                        <div className="input-row flex-row align-space-between">
                            <div className="inp-fill-2">
                                <AnimateInputField
                                    title="Quantity"
                                    name="quantity"
                                    handelChange={handleChange}
                                    formData={formData}
                                />
                            </div>

                            <div className="inp-fill-2">
                                <AnimateSelectField
                                    title="Select Type"
                                    name="type"
                                    handelChange={handleChange}
                                    formData={formData}
                                    options={bmExpenseTypes?.map((item) =>
                                        addOption(item.id, item.title, item.id)
                                    )}
                                />
                            </div>
                        </div>

                        <div className="input-row ">
                            <AnimateNumberField
                                title="Price Per Unit"
                                name="unitPrice"
                                handelChange={handleChange}
                                formData={formData}
                            />
                        </div>

                        {formData?.unitPrice && formData?.quantity ? (
                            <div className="">
                                <p className="calculation-text">
                                    Total: {showCurrency(formData?.unitPrice * formData?.quantity)}
                                </p>
                            </div>
                        ) : null}

                        <div className="input-row">
                            <AnimateDateField
                                title="Select Date"
                                name="date"
                                handelChange={handleChange}
                                formData={formData}
                            />
                        </div>

                        <div className="input-row">
                            <AnimateTextareaField
                                title="Note"
                                name="note"
                                handelChange={handleChange}
                                formData={formData}
                            />
                        </div>
                    </div>
                    <div className="modal-action flex-row align-right">
                        <div className="flex-row">
                            <ButtonGray title="Cancel" evOnClick={handleCloseModal} />
                            <ButtonGreen title="Save" evOnClick={handleSave} />
                        </div>
                    </div>
                </div>
            </LibModal>
        </div>
    );
}

export default BmExpenseAddModal;
