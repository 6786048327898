export const rolesData = {
    error: 0,
    message: 'OK',
    data: [
        {
            roleId: 0,
            userId: 10000001,
            name: 'Kumar Bishojit Paul',
            email: '',
            roleKey: 'owner',
            roleTitle: 'Owner',
            isYou: false,
            isPending: true,
            timeAccepted: 1677561986,
            buttons: []
        },
        {
            roleId: 10000003,
            userId: 10000115,
            name: 'Yeasin Arafat',
            email: 'yeasinn2002@gmail.com',
            roleKey: 'manager',
            roleTitle: 'Manager',
            isYou: true,
            isPending: false,
            timeAccepted: 1679302448,
            buttons: ['makeAsOwner', 'modify', 'cancel', 'remove', 'resend']
        }
    ],
    myRole: 'manager',
    addButton: { error: 0, message: 'OK' }
};

export default null;
