import { useState } from 'react';
import { ButtonMenu } from '../../../../../configs/Buttons';
import { tagNames } from '../../../../../data/tagNames';
import InstOption from '../../../../utils/InstOption';

function BmManageTagTable() {
    const [showMenu, setShowMenu] = useState(false);

    return (
        <div className="bik-table-parent">
            <table className="bik-table">
                <thead>
                    <tr>
                        <th width="10%" className="text-left">
                            SL
                        </th>
                        <th className="text-left">Tag Names</th>
                        <th className="text-center" width="10%">
                            Tag Color
                        </th>
                        <th width="10%" className="text-right">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {tagNames?.map((item) => (
                        <tr key={item.id}>
                            <td className="text-left">{item?.id}</td>
                            <td className="text-left">{`#${item?.title}`}</td>
                            <td className="text-center">
                                <div className="flex-row position-middle align-center">
                                    <div
                                        className="tag-color"
                                        style={{
                                            width: '15px',
                                            height: '15px',
                                            borderRadius: '50%',
                                            backgroundColor: item?.color
                                        }}
                                    />
                                </div>
                            </td>
                            <td className="text-right">
                                <div className="flex-row align-right">
                                    <span>
                                        <ButtonMenu evOnClick={() => setShowMenu(item.id)}>
                                            <InstOption
                                                setShowMenu={setShowMenu}
                                                showMenu={showMenu === item.id}
                                            >
                                                <button type="button" onClick={null}>
                                                    Edit
                                                </button>
                                                <button type="button" onClick={null}>
                                                    Manage
                                                </button>
                                            </InstOption>
                                        </ButtonMenu>
                                    </span>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default BmManageTagTable;
