const pricePerSize = [
    {
        id: 1,
        size: 500,
        price: 399
    },
    {
        id: 2,
        size: 800,
        price: 500
    },
    {
        id: 3,
        size: 1000,
        price: 600
    },
    {
        id: 4,
        size: 1500,
        price: 700
    },
    {
        id: 5,
        size: 2000,
        price: 800
    },
    {
        id: 6,
        size: 3000,
        price: 1000
    },
    {
        id: 7,
        size: 5000,
        price: 1200
    },
    {
        id: 8,
        size: 7000,
        price: 1400
    },
    {
        id: 9,
        size: 10000,
        price: 1500
    }
];
function AssetItemRow({ evOnClick, item, active }) {
    const price = pricePerSize.find((i) => i.size === item.size)?.price || 0;

    return (
        <li>
            <div
                role="button"
                tabIndex={-1}
                onClick={() => evOnClick(item)}
                className="asset-item-row flex-row align-space-between position-middle"
            >
                <div className="flex-row position-middle">
                    <input readOnly checked={active || false} type="checkbox" name="" id="" />
                </div>
                <div className="flex-row position-middle align-center">{item.size} LTR</div>
                <div>
                    <input className="form-input" value={`৳${price}`} disabled type="text" />
                </div>
            </div>
        </li>
    );
}

export default AssetItemRow;
