import CheckBox from '../../../../../utils/CheckBox';

function AssetType({ title, checked, evOnclick }) {
    return (
        <div
            onClick={evOnclick}
            role="button"
            tabIndex={-1}
            className="asset-type flex-col align-center"
        >
            <div className="asset-type-title">{title}</div>
            <CheckBox checked={checked} />
        </div>
    );
}

function AssetTypeSelectionArea({ handleAssetTypeClick, assetType = [] }) {
    return (
        <div className="asset-type-selection-area flex-row align-center">
            <div className="assets-type-cont flex-row align-space-between">
                <AssetType
                    evOnclick={() => handleAssetTypeClick('pvc')}
                    title="PVC"
                    checked={assetType.includes('pvc')}
                />
                <AssetType
                    evOnclick={() => handleAssetTypeClick('concrete')}
                    title="Concrete"
                    checked={assetType.includes('concrete')}
                />
                <AssetType
                    evOnclick={() => handleAssetTypeClick('pipe')}
                    title="Pipe"
                    checked={assetType.includes('pipe')}
                />
            </div>
        </div>
    );
}

export default AssetTypeSelectionArea;
