/* eslint-disable import/no-extraneous-dependencies */
import EmojiPicker from 'emoji-picker-react';
import React, { useEffect, useRef, useState } from 'react';
import { icons } from '../../../../../configs/Icons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import { getFileIcon } from '../../../../utils/Show';
import { handleSendMessage } from '../BmCustomerOverviewOperation';
import { useCustomerOverview } from '../BmCustomerOverviewProvider';

function AttachedItemRow({ show, attachmentFiles, handleRemoveAttachmentItem }) {
    if (!show) {
        return null;
    }
    return (
        <div className="file-attachment-row flex-row position-middle">
            {attachmentFiles?.map((item) => (
                <div key={item?.lastModified}>
                    <img className="w-100p" src={getFileIcon(item)} alt="" />
                    <span
                        onClick={(ev) => handleRemoveAttachmentItem(ev, item)}
                        role="button"
                        tabIndex={-1}
                        className="remove-attachment-btn"
                    >
                        <img src={icons.crossRed} alt="" />
                    </span>
                </div>
            ))}
        </div>
    );
}

function AttachmentButton({ evOnClick }) {
    return (
        <div
            role="button"
            tabIndex={-1}
            onClick={evOnClick}
            className="chat-attach flex-row position-middle align-center"
        >
            <img src={icons.attachment} alt="attachment" />
        </div>
    );
}

function EmojiButton({ evOnClick }) {
    return (
        <div
            role="button"
            tabIndex={-1}
            onClick={evOnClick}
            className="chat-emoji flex-row position-middle align-center"
        >
            <img src={icons.smile} alt="smile" />
        </div>
    );
}

function ChatInputField({ name, placeholder, formData, setFormData, handleKeyPress }) {
    // Create a reference to the textarea element
    const textareaRef = useRef();

    // Function to update the height of the textarea based on its content
    const updateTextareaHeight = () => {
        const textarea = textareaRef.current;
        textarea.style.height = 'auto'; // Set the height to auto to calculate the required height
        const newHeight = textarea.scrollHeight; // Calculate the new height based on the content
        if (newHeight <= 80) {
            textarea.style.height = `${newHeight}px`; // Set the height of the textarea
        } else {
            textarea.style.height = '80px'; // Set the height to the maximum value (80px)
        }
    };

    // Function to handle changes in the textarea content
    const handleChange = (ev) => {
        const { value } = ev.target;
        // Update the formData with the new value for the corresponding name
        setFormData((prevData) => ({ ...prevData, [ev.target.name]: value }));
        // Update the textarea height based on the new content
        updateTextareaHeight();
    };

    // Use effect to update the textarea height when the text in formData changes
    useEffect(() => {
        updateTextareaHeight();
    }, [formData.text]);

    // Render the textarea element with specified properties and event handlers
    return (
        <div className="flex-row position-stretch">
            <textarea
                ref={textareaRef} // Attach the textareaRef to the textarea element
                name={name}
                placeholder={placeholder}
                value={formData?.[name]}
                onChange={handleChange} // Call handleChange when the content changes
                onKeyPress={handleKeyPress} // Call handleKeyPress on key press
                type="text"
                rows={1} // Initially set the textarea to 1 row
            />
        </div>
    );
}

function SendButton({ evOnClick }) {
    return (
        <div
            role="button"
            tabIndex={-1}
            onClick={evOnClick}
            className="chat-send-button flex-row position-middle "
        >
            <img src={icons.send} alt="send" />
        </div>
    );
}

function OverviewSecConversationFooter({ formData, setFormData }) {
    // state to show emoji picker
    const [showEmoji, setShowEmoji] = useState(false);

    const ref = useRef();
    const fileInputRef = useRef();

    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    const { handleFileInputChange, handleRemoveAttachmentItem, attachmentFiles, responseData } =
        useCustomerOverview();

    // console.log(formData);

    const sendMessage = () => {
        // setMessage('Updating....');
        handleSendMessage(authInfo, formData)
            .then(() => {
                // setMessage(message);
                setFormData({ text: '' });
                // handelReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    const textWithoutLineBreaks = formData?.text?.length ? formData?.text?.replace(/\n/g, '') : '';

    // handler for enter key press
    const handleKeyPress = (event) => {
        // Check if the text is not empty and the enter key is pressed and the shift key is not pressed
        if (textWithoutLineBreaks?.length > 0 && event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            sendMessage();
        }
    };

    // handler for show emoji picker
    const handleShowEmoji = () => {
        setShowEmoji((prev) => !prev);
    };

    // handler for select emoji
    const handleSelectEmoji = (emojiObject) => {
        if (emojiObject?.imageUrl) {
            setFormData((prevData) => ({
                ...prevData,
                text: prevData.text + emojiObject?.emoji
            }));
            setShowEmoji((prev) => !prev);
        }
    };

    // handler for send message button click
    const handelSendClick = () => {
        sendMessage();
    };

    // if click outside of emoji picker then hide emoji picker
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current?.contains(event.target)) {
                setShowEmoji(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (responseData) {
            setFormData((prevData) => ({ ...prevData, text: responseData }));
        }
    }, [responseData, setFormData]);

    // handler for add attachment item
    const handleAttachmentClick = () => {
        // Check if the file input is available
        if (fileInputRef.current) {
            // Trigger the input[type='file'] click event
            fileInputRef.current.click();
        }
    };

    return (
        <div className="con-footer flex-col">
            <AttachedItemRow
                show={attachmentFiles?.length}
                attachmentFiles={attachmentFiles}
                handleRemoveAttachmentItem={handleRemoveAttachmentItem}
            />
            <div className="chat-input-container flex-row position-stretch align-space-between">
                <div className="col-1 flex-row position-bottom">
                    <AttachmentButton evOnClick={handleAttachmentClick} />
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileInputChange}
                    />
                </div>

                <div ref={ref} className="col-2 flex-row position-bottom">
                    <EmojiButton evOnClick={handleShowEmoji} />
                    {showEmoji ? <EmojiPicker onEmojiClick={handleSelectEmoji} /> : null}
                </div>

                <div className="col-3 flex-row position-middle">
                    <ChatInputField
                        name="text"
                        setFormData={setFormData}
                        formData={formData}
                        handleKeyPress={handleKeyPress}
                        placeholder="Type a message"
                    />
                </div>

                <div className="col-4 flex-row position-bottom align-right">
                    <SendButton evOnClick={handelSendClick} />
                </div>
            </div>
        </div>
    );
}

export default OverviewSecConversationFooter;
