import { createContext, useContext, useMemo, useState } from 'react';

const InvConfigurationContext = createContext();

export function useConfiguration() {
    return useContext(InvConfigurationContext);
}

function InvConfigurationProvider({ children }) {
    const [modal, setModal] = useState('');

    const value = useMemo(() => {
        // console.log(invoiceData);

        // open modal
        const openModal = (type) => {
            setModal(type);
        };

        return {
            openModal,
            modalType: modal?.type
        };
    }, [modal.type]);

    return (
        <InvConfigurationContext.Provider value={value}>
            {children}
        </InvConfigurationContext.Provider>
    );
}

export default InvConfigurationProvider;
