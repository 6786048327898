import { useRef, useState } from 'react';
import { icons } from '../../../../../configs/Icons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import InfoEditorInputV2 from '../../../../features/info-editor/InfoEditorInputV2';
import InfoEditorTextarea from '../../../../features/info-editor/InfoEditorTextarea';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import { handleAddNote } from '../BmCustomerOverviewOperation';

function SearchButton({ formData, setFormData }) {
    const buttonRef = useRef();

    const [show, setShow] = useState(false);

    return (
        <div
            ref={buttonRef}
            role="button"
            tabIndex={-1}
            onClick={() => setShow(true)}
            className="list-search"
        >
            <img src={icons.search} alt="search" />

            <InfoEditorInputV2
                width={220}
                show={show}
                setShow={setShow}
                positionRef={buttonRef}
                name="text"
                placeholder="Search Notes..."
                formData={formData}
                setFormData={setFormData}
                saveClick={null}
            />
        </div>
    );
}

function OverviewSecNotesHeader({
    openEditor,
    closeEditor,
    showEditor,
    isExpand,
    formData,
    setFormData
}) {
    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    const positionRef = useRef(null);

    const addClick = (ev) => {
        ev.stopPropagation();
        openEditor({ type: 'note-add' });
    };

    const handelSaveClick = () => {
        setMessage('Adding notes....');
        handleAddNote(authInfo, formData?.note)
            .then(({ message }) => {
                setMessage(message);
                closeEditor();
                // handelReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <div className="section-header flex-row align-space-between position-middle">
            <h2 className="section-title ">Notes</h2>
            <div className="flex-row position-middle">
                <SearchButton setFormData={setFormData} formData={formData} />

                {isExpand ? (
                    <div
                        ref={positionRef}
                        role="button"
                        tabIndex={-1}
                        onClick={addClick}
                        className={`list-add-button ${showEditor ? 'active' : ''}`}
                    >
                        +
                    </div>
                ) : null}
            </div>
            <InfoEditorTextarea
                show={showEditor}
                setShow={closeEditor}
                positionRef={positionRef}
                name="note"
                formData={formData}
                setFormData={setFormData}
                saveClick={handelSaveClick}
            />
        </div>
    );
}

export default OverviewSecNotesHeader;
