import { icons } from '../../../../../configs/Icons';

function InvoiceSetItem({ value, title, evSetClick, evChangeClick }) {
    if (!value) {
        return (
            <div
                onClick={evSetClick}
                role="button"
                tabIndex={-1}
                className="config-invoice-item default flex-row align-center position-middle"
            >
                <h2 className="title">
                    <span>{title}</span>
                </h2>
            </div>
        );
    }
    return (
        <div className="config-invoice-item">
            <img src={value} alt="" />
            <div
                onClick={evChangeClick}
                role="button"
                tabIndex={-1}
                className="change-invoice-btn flex-row align-center position-middle"
            >
                <span>Change</span>
            </div>
        </div>
    );
}

function ConfigInvoiceSection() {
    return (
        <div className="config-invoice-section">
            <h2 className="section-title">
                <span>Invoice</span>
            </h2>
            <InvoiceSetItem
                title="Add Header"
                value={icons.invoiceHeader}
                evChangeClick={null}
                evSetClick={null}
            />
            <InvoiceSetItem
                title="Add Footer"
                value={null}
                evChangeClick={null}
                evSetClick={null}
            />
        </div>
    );
}

export default ConfigInvoiceSection;
