import dayjs from 'dayjs';
import { useState } from 'react';
import { ButtonGray, ButtonGreen } from '../../../../../configs/Buttons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import LibModal from '../../../../lib/LibModal';
import AnimateInputField, {
    AnimateDateField,
    AnimateSelectField,
    addOption
} from '../../../../utils/AnimateInputField';
import { handleCreateVisit } from '../BmCustomerOverviewOperation';
import { calculateApproxHours, calculateAvailableHours } from './utilities';

const hours = [
    {
        id: 1,
        title: '07:00',
        value: '07:00',
        range: '07:00 - 07:30'
    },
    {
        id: 2,
        title: '07:30',
        value: '07:30',
        range: '07:30 - 08:00'
    },
    {
        id: 3,
        title: '08:00',
        value: '08:00',
        range: '08:00 - 08:30'
    },
    {
        id: 4,
        title: '08:30',
        value: '08:30',
        range: '08:30 - 09:00'
    },
    {
        id: 5,
        title: '09:00',
        value: '09:00',
        range: '09:00 - 09:30'
    },
    {
        id: 6,
        title: '09:30',
        value: '09:30',
        range: '09:30 - 10:00'
    },
    {
        id: 7,
        title: '10:00',
        value: '10:00',
        range: '10:00 - 10:30'
    },
    {
        id: 8,
        title: '10:30',
        value: '10:30',
        range: '10:30 - 11:00'
    },
    {
        id: 9,
        title: '11:00',
        value: '11:00',
        range: '11:00 - 11:30'
    },
    {
        id: 10,
        title: '11:30',
        value: '11:30',
        range: '11:30 - 12:00'
    },
    {
        id: 11,
        title: '12:00',
        value: '12:00',
        range: '12:00 - 12:30'
    },
    {
        id: 12,
        title: '12:30',
        value: '12:30',
        range: '12:30 - 01:00'
    },
    {
        id: 13,
        title: '13:00',
        value: '01:00',
        range: '13:00 - 13:30'
    },
    {
        id: 14,
        title: '13:30',
        value: '01:30',
        range: '13:30 - 14:00'
    },
    {
        id: 15,
        title: '14:00',
        value: '02:00',
        range: '14:00 - 14:30'
    },
    {
        id: 16,
        title: '14:30',
        value: '02:30',
        range: '14:30 - 15:00'
    },
    {
        id: 17,
        title: '15:00',
        value: '03:00',
        range: '15:00 - 15:30'
    },
    {
        id: 18,
        title: '15:30',
        value: '03:30',
        range: '15:30 - 16:00'
    },
    {
        id: 19,
        title: '16:00',
        value: '04:00',
        range: '16:00 - 16:30'
    },
    {
        id: 20,
        title: '16:30',
        value: '04:30',
        range: '16:30 - 17:00'
    }
];

const branchNames = [
    {
        id: 'khulna',
        title: 'Khulna'
    },
    {
        id: 'dhaka',
        title: 'Dhaka'
    },
    {
        id: 'chittagong',
        title: 'Chittagong'
    },
    {
        id: 'barisal',
        title: 'Barisal'
    },
    {
        id: 'rajshahi',
        title: 'Rajshahi'
    },
    {
        id: 'rangpur',
        title: 'Rangpur'
    },
    {
        id: 'sylhet',
        title: 'Sylhet'
    },
    {
        id: 'mymensingh',
        title: 'Mymensingh'
    }
];

const visitorNames = [
    {
        id: 1,
        title: 'Visitor 1',
        value: 'visitor1'
    },
    {
        id: 2,
        title: 'Visitor 2',
        value: 'visitor2'
    },
    {
        id: 3,
        title: 'Visitor 3',
        value: 'visitor3'
    },
    {
        id: 4,
        title: 'Visitor 4',
        value: 'visitor4'
    },
    {
        id: 5,
        title: 'Visitor 5',
        value: 'visitor5'
    },
    {
        id: 6,
        title: 'Visitor 6',
        value: 'visitor6'
    },
    {
        id: 7,
        title: 'Visitor 7',
        value: 'visitor7'
    },
    {
        id: 8,
        title: 'Visitor 8',
        value: 'visitor8'
    },
    {
        id: 9,
        title: 'Visitor 9',
        value: 'visitor9'
    }
];

const initialState = {
    branch: '',
    visitorName: '',
    visitTime: [],
    visitDate: '',
    phone: '',
    customerName: ''
};

function Hour({ evHourClick, title, isSelected, pickedHours, disabled }) {
    const cName = [];

    if (isSelected) {
        cName.push('selected');
    }
    if (pickedHours) {
        cName.push('picked');
    }
    if (disabled) {
        cName.push('disabled');
    }

    return (
        <div
            onClick={!pickedHours ? evHourClick : null}
            role="button"
            tabIndex={-1}
            className={`hour-selector flex-row align-center position-middle ${cName.join(' ')}`}
        >
            {title}
        </div>
    );
}

function CreateVisitModal({ handleCloseModal, customerInfo }) {
    const [formData, setFormData] = useState({
        ...initialState,
        visitDate: dayjs().format('YYYY-MM-DD'),
        phone: customerInfo?.phone,
        customerName: customerInfo?.name
    });
    const [selectedHour, setSelectedHour] = useState([]);

    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    const handelChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const visitTime = selectedHour?.length
        ? [selectedHour?.[0]?.title, selectedHour?.[selectedHour?.length - 1]?.title]
        : [];

    const handleSave = () => {
        handleCreateVisit(authInfo, {
            ...formData,
            visitTime
        })
            .then(({ message }) => {
                setMessage(message);
                handleCloseModal();
                // handelReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };
    const pickedHours = ['09:00', '11:30', '13:00'];

    const handleHourSelect = (hour) => {
        const hourObj = hours.find((item) => item.id === hour);
        const hourIndex = hours.findIndex((item) => item.id === hour);

        if (selectedHour.length === 0) {
            setSelectedHour([hourObj]);
            return;
        }

        const prevHour = selectedHour[0];
        const prevHourIndex = hours.findIndex((item) => item.id === prevHour.id);

        // Check if the selected range intersects with the pickedHours hours
        const isIntersectingPicked = selectedHour.some((selected) =>
            pickedHours.includes(selected.title)
        );

        if (isIntersectingPicked) {
            setSelectedHour([hourObj]); // Reset selection if intersecting
            return;
        }

        if (prevHourIndex === hourIndex) {
            setSelectedHour([]);
            return;
        }

        const start = Math.min(prevHourIndex, hourIndex);
        const end = Math.max(prevHourIndex, hourIndex);

        const newSelectedHour = hours.slice(start, end + 1);

        const pickedHoursIndex = newSelectedHour.findIndex((item) =>
            pickedHours.includes(item.title)
        );

        if (pickedHoursIndex !== -1) {
            setSelectedHour(newSelectedHour.slice(0, pickedHoursIndex));
            return;
        }

        setSelectedHour(newSelectedHour);
    };

    const approximateHour = calculateApproxHours(selectedHour);

    const availableHours = calculateAvailableHours(hours, pickedHours, selectedHour);

    function add30Minutes(time = ':') {
        const [hour, minutes] = time.split(':').map(Number);

        let newHours = hour;
        let newMinutes = minutes + 30;

        if (newMinutes >= 60) {
            newHours += Math.floor(newMinutes / 60);
            newMinutes %= 60;
        }

        newHours %= 24;

        const formattedHours = String(newHours).padStart(2, '0');
        const formattedMinutes = String(newMinutes).padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}`;
    }

    return (
        <div className="create-customer-visit-modal">
            <LibModal title="Create a visit" setOpen={handleCloseModal}>
                <div className="modal-body-cont">
                    <div>
                        <div className="input-row flex-row align-space-between">
                            <div className="inp-fill-2">
                                <AnimateSelectField
                                    required
                                    title="Select Branch"
                                    name="branch"
                                    formData={formData}
                                    handelChange={handelChange}
                                    options={branchNames.map((item) =>
                                        addOption(item.id, item.title, item.id)
                                    )}
                                />
                            </div>
                            <div className="inp-fill-2">
                                <AnimateSelectField
                                    required
                                    title="Select Visitor Name"
                                    name="visitorName"
                                    formData={formData}
                                    handelChange={handelChange}
                                    options={visitorNames.map((item) =>
                                        addOption(item.id, item.title, item.value)
                                    )}
                                />
                            </div>
                        </div>
                        <div className="input-row flex-row align-space-between">
                            <div className="inp-fill">
                                <AnimateDateField
                                    required
                                    title="Visit date"
                                    name="visitDate"
                                    formData={formData}
                                    handelChange={handelChange}
                                />
                            </div>

                            <div className="inp-fill-3">
                                <div className="approx-time flex-col align-center position-middle">
                                    <span>{approximateHour || 'Approx. Hour'}</span>
                                </div>
                            </div>
                        </div>
                        <div className="input-row visit-hour-row ">
                            <div className="hour-selector-label flex-row position-middle">
                                <span>Select Time</span>
                                {selectedHour?.length ? (
                                    <span
                                        onClick={() => setSelectedHour([])}
                                        role="button"
                                        tabIndex={-1}
                                    >
                                        Clear
                                    </span>
                                ) : null}

                                {selectedHour?.length ? (
                                    <span>
                                        {selectedHour.length > 1
                                            ? `${selectedHour[0].value} to ${add30Minutes(
                                                  selectedHour[selectedHour.length - 1]?.value
                                              )}`
                                            : selectedHour[0].value}
                                    </span>
                                ) : null}
                            </div>
                            <div className="flex-row align-space-between flex-wrap">
                                {hours.map((hour) => (
                                    <Hour
                                        key={hour.id}
                                        pickedHours={pickedHours.includes(hour.title)}
                                        isSelected={selectedHour?.includes(hour)}
                                        disabled={availableHours.includes(hour)}
                                        evHourClick={() => handleHourSelect(hour.id)}
                                        title={hour.range}
                                    />
                                ))}
                            </div>
                        </div>

                        <div className="input-row">
                            <AnimateInputField
                                required
                                title="Phone Number"
                                name="phone"
                                formData={formData}
                                handelChange={handelChange}
                            />
                        </div>
                        <div className="input-row">
                            <AnimateInputField
                                title="Customer Name"
                                name="customerName"
                                formData={formData}
                                handelChange={handelChange}
                            />
                        </div>
                    </div>

                    <div className="modal-action flex-row align-right">
                        <div className="flex-row position-middle">
                            <ButtonGray evOnClick={handleCloseModal} title="Cancel" />
                            <ButtonGreen evOnClick={handleSave} title="Save" />
                        </div>
                    </div>
                </div>
            </LibModal>
        </div>
    );
}

export default CreateVisitModal;
