import { useCustomerOverview } from '../BmCustomerOverviewProvider';

function AssetInfoRow({ title, value, quantity }) {
    return (
        <div className="flex-row position-middle align-space-between editable">
            {quantity ? (
                <div>
                    <span>{title || '---'}</span>

                    <p className="asset-quantity">{quantity}</p>
                </div>
            ) : (
                <span>{title || '---'}</span>
            )}

            <div>{value || '---'}</div>
        </div>
    );
}

function OverviewSecCol2Asset({ assets }) {
    const { house, floor, unit, pvcTank, concreteTank } = assets || {};
    const { handleOpenModal } = useCustomerOverview();

    return (
        <div className="section assets-section overview_section-item">
            <div className="flex-row position-stretch align-space-between">
                <h2 className="section-title">Asset</h2>

                <div
                    onClick={() => handleOpenModal('add-asset')}
                    role="button"
                    tabIndex={-1}
                    className="add-btn"
                >
                    Add
                </div>
            </div>

            <AssetInfoRow title="Total House" value={house} />

            <AssetInfoRow title="Floor" value={floor} />

            <AssetInfoRow title="Unit" value={unit} />

            <AssetInfoRow title="PVC Tank" value={pvcTank} quantity="2000*2, 1000L*1" />

            <AssetInfoRow title="Concrete Tank" value={concreteTank} quantity="4000*2" />
        </div>
    );
}

export default OverviewSecCol2Asset;
