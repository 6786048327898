import { useEffect, useRef, useState } from 'react';
import OverviewSecThreadReceiver from '../overview_sec_conversation_thread/OverviewSecThreadReceiver';
import OverviewSecThreadSender from '../overview_sec_conversation_thread/OverviewSecThreadSender';

// Generate fake data for the chat
const fakeChatData = [
    {
        id: 1,
        type: 'receiver',
        name: 'Yeasin Arafat',
        text: 'Lorem ipsum dolor sit amet.',
        time: 1691559680
    },
    {
        id: 2,
        type: 'receiver',
        name: 'Yeasin Arafat',
        text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloremque corrupti saepe expedita earum sunt? Repellendus dicta animi quidem natus eius, ducimus laboriosam, maxime illo optio dignissimos soluta velit architecto dolores.',
        time: 1691559680
    },
    {
        id: 3,
        type: 'sender',
        text: 'Lorem ipsum dolor sit amet.',
        time: 1691559680
    },
    {
        id: 4,
        type: 'sender',
        text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloremque corrupti saepe expedita earum sunt? Repellendus dicta animi quidem natus eius, ducimus laboriosam, maxime illo optio dignissimos soluta velit architecto dolores.',
        time: 1691559680
    },
    {
        id: 5,
        type: 'sender',
        text: 'Lorem ipsum dolor sit amet.',
        time: 1691559680
    },
    {
        id: 6,
        type: 'receiver',
        name: 'Yeasin Arafat',
        text: 'Lorem ipsum dolor sit amet.',
        time: 1691559680
    },
    {
        id: 7,
        type: 'receiver',
        name: 'Yeasin Arafat',
        text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloremque corrupti saepe expedita earum sunt? Repellendus dicta animi quidem natus eius, ducimus laboriosam, maxime illo optio dignissimos soluta velit architecto dolores.',
        time: 1691559680
    },
    {
        id: 8,
        type: 'sender',
        text: 'Lorem ipsum dolor sit amet.',
        time: 1691559680
    },
    {
        id: 9,
        type: 'sender',
        text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloremque corrupti saepe expedita earum sunt? Repellendus dicta animi quidem natus eius, ducimus laboriosam, maxime illo optio dignissimos soluta velit architecto dolores.',
        time: 1691559680
    },
    {
        id: 10,
        type: 'sender',
        text: 'Lorem ipsum dolor sit amet.',
        time: 1691559680
    },
    {
        id: 11,
        type: 'receiver',
        name: 'Yeasin Arafat',
        text: 'Lorem ipsum dolor sit amet.',
        time: 1691559680
    },
    {
        id: 12,
        type: 'receiver',
        name: 'Yeasin Arafat',
        text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloremque corrupti saepe expedita earum sunt? Repellendus dicta animi quidem natus eius, ducimus laboriosam, maxime illo optio dignissimos soluta velit architecto dolores.',
        time: 1691559680
    }
];

function OverviewSecConversationBody({ text }) {
    const [showMenu, setShowMenu] = useState(0);

    const bodyRef = useRef(null);

    useEffect(() => {
        const body = bodyRef.current;
        body.scrollTop = body.scrollHeight;
    }, [text]);

    // show menu option
    const showThreadMenu = (id) => {
        setShowMenu(id);
    };

    return (
        <div ref={bodyRef} className="con-body info-scroll">
            <div className="con-thread-container">
                <div className="flex-row position-stretch align-left">
                    <div className="receiver-cont">
                        {fakeChatData.map((message) => {
                            if (message.type === 'receiver') {
                                return (
                                    <OverviewSecThreadReceiver
                                        key={message.id}
                                        data={message}
                                        showThreadMenu={showThreadMenu}
                                        show={showMenu === message.id}
                                    />
                                );
                            }
                            return null;
                        })}
                    </div>
                </div>

                <div className="flex-row position-stretch align-right">
                    <div className="sender-cont flex-col align-right">
                        {fakeChatData.map((message) => {
                            if (message.type === 'sender') {
                                return (
                                    <OverviewSecThreadSender
                                        key={message.id}
                                        data={message}
                                        showThreadMenu={showThreadMenu}
                                        show={showMenu === message.id}
                                    />
                                );
                            }
                            return null;
                        })}
                        {text ? <OverviewSecThreadSender text={text} time="05:57 am" /> : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OverviewSecConversationBody;
