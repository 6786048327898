export const getRoleStatus = (data) => {
    if (data?.isYou) {
        return <span className="role-status default ml-1">You</span>;
    }
    if (data?.isPending) {
        return <span className="role-status pending ml-1">Pending</span>;
    }
    return null;
};

export default null;
