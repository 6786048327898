export const tagNames = [
    {
        id: 1,
        title: 'Abroad',
        color: '#FF5733' // Red-Orange
    },
    {
        id: 2,
        title: 'All Location',
        color: '#C70039' // Red
    },
    {
        id: 3,
        title: 'Auto Message',
        color: '#900C3F' // Dark Red
    },
    {
        id: 4,
        title: 'Canceled For Client Absence',
        color: '#581845' // Dark Purple
    },
    {
        id: 5,
        title: 'Canceled For Done By Other',
        color: '#955BA5' // Purple
    },
    {
        id: 6,
        title: 'Canceled For Price',
        color: '#5E239D' // Violet
    },
    {
        id: 7,
        title: 'Canceled For Property Issue',
        color: '#663399' // Rebecca Purple
    },
    {
        id: 8,
        title: 'Close Fisted',
        color: '#3F51B5' // Indigo
    },
    {
        id: 9,
        title: 'Having Phone Number',
        color: '#2196F3' // Blue
    },
    {
        id: 10,
        title: 'Inquired',
        color: '#00BCD4' // Cyan
    },
    {
        id: 11,
        title: 'Insufficient Ability',
        color: '#4CAF50' // Green
    },
    {
        id: 12,
        title: 'Never Response',
        color: '#8BC34A' // Light Green
    },
    {
        id: 13,
        title: 'Ordered',
        color: '#FFC107' // Amber
    },
    {
        id: 14,
        title: 'Out Of Service Area',
        color: '#FF9800' // Orange
    },
    {
        id: 15,
        title: 'Subscribed',
        color: '#FF5722' // Deep Orange
    },
    {
        id: 16,
        title: 'Vip',
        color: '#795548' // Brown
    },
    {
        id: 17,
        title: 'Visited',
        color: '#9E9E9E' // Grey
    }
];

export default null;
