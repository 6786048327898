import { useState } from 'react';
import { ButtonPurple } from '../../../../../configs/Buttons';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import CurrencyAddModal from '../modals/CurrencyAddModal';
import CurrencyUpdateModal from '../modals/CurrencyUpdateModal';

const currencyList = [
    {
        id: 10001010,
        name: 'BDT',
        code: 'BDT',
        symbol: '৳',
        rate: 1,
        isDefault: true
    },
    {
        id: 10001011,
        name: 'USD',
        code: 'USD',
        symbol: '$',
        rate: 85,
        isDefault: false
    },
    {
        id: 10001012,
        name: 'EUR',
        code: 'EUR',
        symbol: '€',
        rate: 100,
        isDefault: false
    },
    {
        id: 10001013,
        name: 'GBP',
        code: 'GBP',
        symbol: '£',
        rate: 120,
        isDefault: false
    }
];

// const currencyList = [];

function SetDefaultButton({ show, item }) {
    const { setConfirm } = useTemplate();

    const handleSetDefault = () => {
        setConfirm({
            text: 'Are you sure you want to set this currency as default?',
            textAction: 'Set Default',
            clickAction: () => {
                console.log('set default', item?.id);
                setConfirm(null);
            }
        });
    };

    if (!show) {
        return null;
    }
    return (
        <div role="button" tabIndex={-1} onClick={handleSetDefault}>
            Set As Default
        </div>
    );
}

function Tr() {
    const [mode, setMode] = useState(false);

    if (currencyList && !currencyList.length) {
        return (
            <tr>
                <td
                    style={{ height: '100px' }}
                    colSpan="4"
                    className="text-center table-not-found-cont"
                >
                    <div className="flex-col align-center">
                        <h2>No Currency Found!</h2>

                        <ButtonPurple evOnClick={() => setMode('add')} title="Add Currency" />
                    </div>

                    {mode === 'add' ? <CurrencyAddModal setOpen={setMode} /> : null}
                </td>
            </tr>
        );
    }
    return currencyList.map((item) => (
        <tr key={item?.id}>
            <td className="text-left">{item.id}</td>
            <td className="text-left">{item.name}</td>
            <td className="text-center">{item.rate}</td>
            <td className="text-right">
                <div className="flex-row align-right currency-action">
                    <div role="button" tabIndex={-1} onClick={() => setMode(item.id)}>
                        Edit
                    </div>

                    <SetDefaultButton show={item.isDefault} item={item} />
                </div>
                {mode === item.id ? <CurrencyUpdateModal data={item} setOpen={setMode} /> : null}
            </td>
        </tr>
    ));
}

function ConfigCurrencySection() {
    return (
        <div className="currency-section">
            <h2 className="section-title">
                <span>Currencies</span>
            </h2>

            <table className="bik-table">
                <thead>
                    <tr>
                        <th width="10%" className="text-left">
                            SL
                        </th>
                        <th width="60%" className="text-left">
                            Currency Name
                        </th>
                        <th width="10%" className="text-center">
                            Rate
                        </th>
                        <th width="20%" className="text-right">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody className="">
                    <Tr />
                </tbody>
            </table>
        </div>
    );
}

export default ConfigCurrencySection;
