import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { icons } from '../../../../../configs/Icons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import InfoEditorInput from '../../../../features/info-editor/InfoEditorInput';
import InfoEditorTextarea from '../../../../features/info-editor/InfoEditorTextarea';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import { UserInfoV2 } from '../../../../features/user-info/UserInfo';
import { InstOptionV2 } from '../../../../utils/InstOption';
import { handleUpdateAddress, handleUpdateName } from '../BmCustomerOverviewOperation';
import { useCustomerOverview } from '../BmCustomerOverviewProvider';

function Menu({ buttonRef, setShowMenu }) {
    return (
        <div
            ref={buttonRef}
            onClick={() => setShowMenu(true)}
            role="button"
            tabIndex={-1}
            className="menu"
        >
            <img src={icons.lgDot} alt="" />
        </div>
    );
}

function Address({ evOnClick, value, addressRef }) {
    return (
        <div
            ref={addressRef}
            onClick={evOnClick}
            role="button"
            tabIndex={-1}
            className="customer-overview_customerinfo_address flex-row align-space-between position-middle"
        >
            <div>
                <img src={icons.location} alt="location" />
            </div>
            <div>
                <span className="">{value}</span>
            </div>
        </div>
    );
}

function JoinedDate({ value }) {
    return (
        <div className="customer-overview_customerinfo_joined-date flex-row position-middle">
            <span>Joined: &nbsp; </span>
            <span>{value}</span>
        </div>
    );
}

function OverviewSecCol1CustomerInfo() {
    const [formData, setFormData] = useState({});
    const [mode, setMode] = useState({});
    const [showMenu, setShowMenu] = useState(false);

    const { handleOpenModal } = useCustomerOverview();

    const buttonRef = useRef();
    const nameRef = useRef();
    const addressRef = useRef();

    const { authInfo } = useAuth();
    const { setMessage, createEditorFunction } = useTemplate();

    const { openEditor, closeEditor } = createEditorFunction(setFormData, setMode);

    const handelSaveAddress = () => {
        setMessage('Updating address....');
        handleUpdateAddress(authInfo, formData?.address)
            .then(({ message }) => {
                setMessage(message);
                closeEditor();
                // handelReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    const handelSaveName = () => {
        setMessage('Updating Name....');
        handleUpdateName(authInfo, formData?.name)
            .then(({ message }) => {
                setMessage(message);
                closeEditor();
                // handelReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <div className="customer-overview_customerinfo overview_section-item flex-row align-space-between position-stretch">
            <div className="flex-row position-middle">
                <UserInfoV2
                    nameRef={nameRef}
                    evOnClick={() => openEditor({ type: 'name', name: 'name', value: 'name' })}
                    name="Yeasin Arafat"
                    email="011980427"
                />
            </div>
            <div className="flex-row position-middle">
                <Address
                    evOnClick={() =>
                        openEditor({
                            type: 'address',
                            name: 'address',
                            value: '123, Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quibusdam.'
                        })
                    }
                    addressRef={addressRef}
                    value="123, Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quibusdam."
                />
            </div>
            <div className="flex-row position-middle">
                <div className="flex-col align-start">
                    <JoinedDate value="03 Aug 2023" />
                    <div className="customer-overview_customerinfo_source flex-row position-middle">
                        <span> Source:</span>{' '}
                        <div className="flex-row position-middle">
                            <Link
                                to="https://www.facebook.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img className="source-logo" src={icons.fb} alt="fb" />
                            </Link>
                            <Link
                                to="https://www.bahannonews.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img className="source-logo" src={icons.bahannoNews} alt="fb" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-row position-middle align-right">
                <Menu buttonRef={buttonRef} setShowMenu={setShowMenu} />
                <InstOptionV2
                    buttonRef={buttonRef}
                    align="center"
                    setShowMenu={setShowMenu}
                    showMenu={showMenu}
                >
                    <button type="button" onClick={() => handleOpenModal('create-visit')}>
                        Create a visit
                    </button>
                    <button type="button" onClick={() => handleOpenModal('create-order')}>
                        Create a order
                    </button>
                    <button type="button" onClick={() => handleOpenModal('update-branch')}>
                        Update Branch
                    </button>
                </InstOptionV2>
            </div>

            {/* editors */}

            <InfoEditorInput
                width={140}
                outsideClick={false}
                show={mode.type === 'name'}
                setShow={closeEditor}
                positionRef={nameRef}
                name="name"
                formData={formData}
                setFormData={setFormData}
                saveClick={handelSaveName}
            />

            <InfoEditorTextarea
                width={250}
                outsideClick={false}
                positionRef={addressRef}
                show={mode.type === 'address'}
                setShow={closeEditor}
                name="address"
                formData={formData}
                setFormData={setFormData}
                saveClick={handelSaveAddress}
            />
        </div>
    );
}

export default OverviewSecCol1CustomerInfo;
