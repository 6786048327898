import React from 'react';
import { icons } from '../../configs/Icons';

function PdfLayout({ children, version, header, footer }) {
    const isVersion2 = version === 2;

    return (
        <>
            <table
                cellPadding={0}
                cellSpacing={0}
                className={`pdf-layout ${isVersion2 ? 'v2' : ' '}`}
                // style={{ width: '800px' }}
            >
                <thead>
                    <tr>
                        <th className="pdf-header">
                            {header || <img src={icons.invoiceHeader} alt="" />}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="pdf-cont">{children}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td style={{ width: '800px', height: '104px' }} />
                    </tr>
                </tfoot>
            </table>

            <div className={`pdf-layout-foot ${isVersion2 ? 'layout-hidden' : ''}`}>
                {footer || <img src={icons.invoiceFooter} alt="" />}
            </div>
        </>
    );
}

export default PdfLayout;
