import { useState } from 'react';
import { ButtonMenu } from '../../../../../configs/Buttons';
import { overviewDemoData } from '../../../../../data/overviewDemoData';
import InstOption from '../../../../utils/InstOption';
import { GetDate } from '../../../../utils/Show';
import OrderUpdateModal from '../modals/OrderUpdateModal';

function Tr() {
    const [showMenu, setShowMenu] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const arr = overviewDemoData?.order?.orders;

    if (arr && !arr?.length) {
        return (
            <tr>
                <td colSpan="7" className="text-left">
                    No data found
                </td>
            </tr>
        );
    }

    return arr.map((item) => (
        <tr key={item.id} className={item?.demo ? 'placeholder-mode' : ''}>
            <td className="text-left">
                <span>{item.id}</span>
            </td>
            <td className="text-left">
                <span>{GetDate(item.date)}</span>
            </td>
            <td className="text-left">
                <span>{item.address}</span>
            </td>
            <td className="text-left">
                <span>
                    <b>{item.title}</b>
                </span>{' '}
                <br />
                <span>Size: {item.size}</span>
            </td>
            <td className="text-center">
                <span>{item.quantity}</span>
            </td>
            <td className="text-center">
                <span>{item.price}</span>
            </td>
            <td className="text-left">
                <span>{item.status}</span>
            </td>

            <td className="text-right">
                <div className="flex-row align-right">
                    <span>
                        <ButtonMenu evOnClick={() => setShowMenu(item)}>
                            <InstOption setShowMenu={setShowMenu} showMenu={showMenu === item}>
                                <button type="button" onClick={() => setOpenModal(item.id)}>
                                    Edit
                                </button>
                            </InstOption>
                        </ButtonMenu>

                        {openModal === item.id && (
                            <OrderUpdateModal item={item} setOpen={setOpenModal} />
                        )}
                    </span>
                </div>
            </td>
        </tr>
    ));
}

function BmOrdersTable({ orderList }) {
    return (
        <div className="bik-table-parent">
            <table className="bik-table">
                <thead>
                    <tr>
                        <th className="text-left" width="7%">
                            SL
                        </th>
                        <th className="text-left" width="12%">
                            Date
                        </th>
                        <th className="text-left">Address</th>
                        <th className="text-left" width="20%">
                            Service Info
                        </th>
                        <th className="text-center" width="10%">
                            Quantity
                        </th>

                        <th width="10%" className="text-center">
                            Amount
                        </th>
                        <th width="10%" className="text-left">
                            Status
                        </th>
                        <th width="8%" className="text-right">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <Tr orderList={orderList} />
                </tbody>
            </table>
        </div>
    );
}

export default BmOrdersTable;
