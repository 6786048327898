import AxiosAPI from '../../utils/AxiosAPI';

export function handelAddApp(authInfo, projectId, formData) {
    return new Promise((resolve, reject) => {
        AxiosAPI(authInfo)
            .setPath(`/api2/users/project/${projectId}/add-app/`)
            .post({ ...formData })
            .then(({ data }) => {
                if (data.error === 0) {
                    resolve(data);
                } else {
                    reject(Error(data.message));
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export default null;
