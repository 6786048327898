import { useEffect, useState } from 'react';
import { ButtonMenu } from '../../../../../configs/Buttons';
import { teamsDemoData } from '../../../../../data/teamsDemoData';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import InstOption from '../../../../utils/InstOption';
import { GetDate, formatSingleDigit, showCurrency } from '../../../../utils/Show';
import { handleLoadTeamsData } from '../BmTeamsOperation';

function Tr({ arr, handleOpenModal }) {
    const [showMenu, setShowMenu] = useState(false);

    // className={item.demo ? 'placeholder-mode' : ''}

    if (arr && !arr?.length) {
        return (
            <tr>
                <td colSpan="6" className="text-left">
                    <span>No Data Found</span>
                </td>
            </tr>
        );
    }
    return arr?.map((item) => (
        <tr key={item?.id} className={item?.demo ? 'placeholder-mode' : ''}>
            <td className="text-left">
                <span>{item?.id}</span>
            </td>
            <td className="text-left">
                <span>
                    <b>{item?.name}</b>
                </span>
                <br />
                <span>{item?.phone}</span>
            </td>
            <td className="text-left">
                <span>{item?.designation}</span>
            </td>
            <td className="text-center">
                <span className="amount-font">{formatSingleDigit(item?.grade) || '--'}</span>
            </td>
            <td className="text-center">
                <span>{item?.branch}</span>
            </td>
            <td className="text-left">
                <span>{GetDate(item?.joiningDate)}</span>
            </td>
            <td className="text-center">
                <span className="amount-font">{showCurrency(item?.salary)}</span>
            </td>
            <td className="text-left">
                <span className={`status ${item?.status}`}>{item?.status}</span>
            </td>
            <td className="text-right">
                <div className="flex-row align-right">
                    <span>
                        <ButtonMenu evOnClick={() => setShowMenu(item.id)}>
                            <InstOption setShowMenu={setShowMenu} showMenu={showMenu === item.id}>
                                <button
                                    type="button"
                                    onClick={() => handleOpenModal('update-team', item)}
                                >
                                    Edit
                                </button>
                            </InstOption>
                        </ButtonMenu>
                    </span>
                </div>
            </td>
        </tr>
    ));
}

function BmTeamsPageTable({ projectId, handleOpenModal, setTeamsData, teamsData, reloadKey }) {
    const arr = teamsData || teamsDemoData;

    const { setMessage } = useTemplate();
    const { authInfo } = useAuth();

    useEffect(() => {
        setTeamsData(null);
        handleLoadTeamsData(authInfo, projectId)
            .then(({ data }) => {
                setTeamsData(data);
            })
            .catch((err) => {
                setMessage(err.toString(0));
            });
    }, [authInfo, projectId, setMessage, setTeamsData, reloadKey]);

    return (
        <div>
            <div className="bik-table-parent">
                <table className="bik-table">
                    <thead>
                        <tr>
                            <th width="9%" className="text-left">
                                SL
                            </th>
                            <th width="" className="text-left">
                                Name
                            </th>
                            <th width="" className="text-left">
                                Designation
                            </th>
                            <th width="7%" className="text-center">
                                Grade
                            </th>
                            <th width="12%" className="text-center">
                                Branch
                            </th>
                            <th width="12%" className="text-left">
                                Joined Date
                            </th>
                            <th width="10%" className="text-center">
                                Salary
                            </th>
                            <th width="7%" className="text-left">
                                Status
                            </th>
                            <th width="7%" className="text-right">
                                #
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <Tr arr={arr} handleOpenModal={handleOpenModal} />
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default BmTeamsPageTable;
