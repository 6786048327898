import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import InfoEditorTextarea from '../info-editor/InfoEditorTextarea';

const FloatingInputContext = createContext();

export function useFloatInput() {
    return useContext(FloatingInputContext);
}

function FloatingInputProvider({ children }) {
    const [params, setParams] = useState(null);
    const {
        show,
        width,
        name,
        positionRef,
        formData,
        setFormData,
        defaultValue,
        outsideClick,
        clickAction,
        clickCancel
    } = params || {};

    // const [formData, setFormData] = useState({ [name]: defaultValue });

    const closeClick = () => {
        setParams(null);
        if (typeof clickCancel === 'function') {
            clickCancel();
        }
    };

    const value = useMemo(() => {
        const openEditor = (param) => {
            setParams({ ...param });
        };

        const closeEditor = () => {
            setParams(null);
        };

        return { openEditor, closeEditor };
    }, []);

    useEffect(() => {
        if (setFormData) {
            setFormData({ [name]: defaultValue });
        }
    }, [defaultValue, name, setFormData]);

    return (
        <FloatingInputContext.Provider value={value}>
            {children}

            <InfoEditorTextarea
                setShow={closeClick}
                show={show || params}
                name={name}
                formData={formData}
                setFormData={setFormData}
                defaultValue={defaultValue}
                positionRef={positionRef}
                width={width}
                saveClick={typeof clickAction === 'function' ? clickAction : null}
                outsideClick={outsideClick}
            />
        </FloatingInputContext.Provider>
    );
}

export default FloatingInputProvider;
