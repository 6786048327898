import BmCustomersProvider, { useBmCustomer } from './BmCustomersProvider';
import CustomersModalController from './__models/CustomersModalController';
import BmCustomersPageHeader from './components/BmCustomersPageHeader';
import CustomersTable from './components/CustomersTable';

function PageContent() {
    const { showModal, openModal, closeModal, handleReload } = useBmCustomer();
    return (
        <div className="page-cont customer-list-page">
            <BmCustomersPageHeader openModal={openModal} handleReload={handleReload} />

            <CustomersTable />

            {/* modals */}
            <CustomersModalController
                modalType={showModal.type}
                closeClick={closeModal}
                handleReload={handleReload}
            />
        </div>
    );
}

function BmCustomersPage() {
    return (
        <BmCustomersProvider>
            <PageContent />
        </BmCustomersProvider>
    );
}

export default BmCustomersPage;
