import { useRef, useState } from 'react';
import { ButtonGray, ButtonGreen } from '../../../../../../configs/Buttons';
import { icons } from '../../../../../../configs/Icons';
import { useAuth } from '../../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../../features/template/TemplateProvider';
import LibModal from '../../../../../lib/LibModal';
import { getFileIcon } from '../../../../../utils/Show';
import { handleAddMedia } from '../../BmCustomerOverviewOperation';

function FileSelectionArea({
    selected,
    fileInputRef,
    imageSource,
    handleAttachmentClick,
    handleFileInputChange,
    handleRemoveAttachment
}) {
    if (selected) {
        return (
            <div className="file-selection-area flex-row align-center position-middle">
                <img src={imageSource} alt="" />
                <span
                    onClick={handleRemoveAttachment}
                    role="button"
                    tabIndex={-1}
                    className="remove-attachment"
                >
                    <img src={icons.crossRed} alt="cross" />
                </span>
            </div>
        );
    }
    return (
        <div
            onClick={handleAttachmentClick}
            role="button"
            tabIndex={-1}
            className="file-selection-area flex-row align-center position-middle"
        >
            <div className="file-selection-area_title">
                Select a file <span>or drag it here</span>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileInputChange}
                />
            </div>
        </div>
    );
}

function AddMediaModal({ handleCloseModal }) {
    const [attachmentFile, setAttachmentFile] = useState(null);

    const { authInfo } = useAuth();
    const { setMessage } = useTemplate();

    const fileInputRef = useRef();

    // Function to handle input[type='file'] change. to pick file file explorer
    const handleFileInputChange = (event) => {
        // Get the file from the event
        const { files } = event.target;

        setAttachmentFile(files?.[0]);
    };

    // handler for add attachment item
    const handleAttachmentClick = () => {
        // Check if the file input is available
        if (fileInputRef.current) {
            // Trigger the input[type='file'] click event
            fileInputRef.current.click();
        }
    };

    const handleRemoveAttachment = (ev) => {
        ev.stopPropagation();
        setAttachmentFile(null);
    };

    const handleAddButton = () => {
        setMessage('Adding media....');
        handleAddMedia(authInfo, getFileIcon(attachmentFile))
            .then(({ message }) => {
                setMessage(message);
                // closeEditor();
                // handelReload();
            })
            .catch((err) => {
                setMessage(err.toString());
            });
    };

    return (
        <div className="media-add-modal">
            <LibModal title="Add Media" setOpen={handleCloseModal}>
                <div className="modal-body-cont">
                    <div>
                        <FileSelectionArea
                            imageSource={getFileIcon(attachmentFile) || null}
                            selected={attachmentFile}
                            fileInputRef={fileInputRef}
                            handleAttachmentClick={handleAttachmentClick}
                            handleFileInputChange={handleFileInputChange}
                            handleRemoveAttachment={handleRemoveAttachment}
                        />
                    </div>
                    <div className="modal-action flex-row align-right">
                        <div className="flex-row position-middle">
                            <ButtonGray title="Cancel" evOnClick={handleCloseModal} />

                            <ButtonGreen title="Add" evOnClick={handleAddButton} />
                        </div>
                    </div>
                </div>
            </LibModal>
        </div>
    );
}

export default AddMediaModal;
