import RadioButton from '../../../../../utils/RadioButton';

function AssetType({ title, checked, evOnclick }) {
    return (
        <div
            onClick={evOnclick}
            role="button"
            tabIndex={-1}
            className="asset-type flex-col align-center"
        >
            <div className="">{title}</div>
            <RadioButton checked={checked} />
        </div>
    );
}

function AssetSelectionTab({ handleAssetTypeClick, assetType }) {
    return (
        <div className="assets-tab-cont flex-row align-space-between flex-wrap">
            <AssetType
                evOnclick={() => handleAssetTypeClick('house')}
                title="House"
                checked={assetType === 'house'}
            />
            <AssetType
                evOnclick={() => handleAssetTypeClick('floor')}
                title="Floor"
                checked={assetType === 'floor'}
            />
            <AssetType
                evOnclick={() => handleAssetTypeClick('unit')}
                title="Unit"
                checked={assetType === 'unit'}
            />
            <AssetType
                evOnclick={() => handleAssetTypeClick('pvc')}
                title="PVC Tank"
                checked={assetType === 'pvc'}
            />
            <AssetType
                evOnclick={() => handleAssetTypeClick('concrete')}
                title="Concrete Tank"
                checked={assetType === 'concrete'}
            />
            {/* <AssetType
                evOnclick={() => handleAssetTypeClick('pipe')}
                title="Pipe"
                checked={assetType === 'pipe'}
            /> */}
        </div>
    );
}

export default AssetSelectionTab;
