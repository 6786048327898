import React from 'react';
import { icons } from '../../../../../configs/Icons';
import { GetTime } from '../../../../utils/Show';
import OverviewSecReceiverMenu from './OverviewSecReceiverMenu';

function ReceiverInfo({ img, name, time }) {
    return (
        <div className="flex-row position-middle align-space-between">
            <div className="receiver-info flex-row position-middle">
                <div>
                    <span className="receiver-img">
                        <img src={img || icons.avatar} alt="" />
                    </span>
                </div>
                <h2 className="receiver-name">
                    <span>{name || '--'}</span>
                </h2>
            </div>
            <span className="con-time">{GetTime(time) || '--'}</span>
        </div>
    );
}

function Text({ text }) {
    return (
        <p className="con-text">
            <span>{text || '--'}</span>
        </p>
    );
}

function OverviewSecThreadReceiver({ data, show, showThreadMenu }) {
    return (
        <div className={`con-thread ${show ? 'active' : ''}`}>
            <div>
                <ReceiverInfo name={data?.name} time={data?.time} />

                <Text text={data?.text} />

                <OverviewSecReceiverMenu data={data} showThreadMenu={showThreadMenu} show={show} />
            </div>
        </div>
    );
}

export default OverviewSecThreadReceiver;
