import { useRef } from 'react';
import { icons } from '../../../../../configs/Icons';
import { useAuth } from '../../../../features/auth/AuthProvider';
import { useTemplate } from '../../../../features/template/TemplateProvider';
import { InstOptionV2 } from '../../../../utils/InstOption';
import { handleRemoveMessage } from '../BmCustomerOverviewOperation';

function Menu({ evOnClick, buttonRef }) {
    return (
        <div
            ref={buttonRef}
            className="thread-menu"
            onClick={evOnClick}
            role="button"
            tabIndex={-1}
        >
            <img src={icons.threeDot} alt="" />
        </div>
    );
}

function OverviewSecReceiverMenu({ show, data, showThreadMenu }) {
    const buttonRef = useRef(null);

    const { authInfo } = useAuth();
    const { setMessage, setConfirm } = useTemplate();

    const { id } = data;

    const removeMessage = () => {
        setConfirm({
            text: 'This message will be removed for you. Others in the chat will still be able to see it.',
            textAction: 'Remove',
            clickAction: () => {
                console.log('Remove item', id);
                handleRemoveMessage(authInfo, data)
                    .then(({ message }) => {
                        setMessage(message);
                        setConfirm(null);
                    })
                    .catch((err) => {
                        setMessage(err.toString());
                    });
            }
        });
    };

    const replyMessage = () => {
        console.log('replyMessage');
    };

    return (
        <div className="thread-menu-cont flex-row position-middle">
            <div>
                <Menu buttonRef={buttonRef} evOnClick={() => showThreadMenu(id)} />
                <InstOptionV2 buttonRef={buttonRef} setShowMenu={showThreadMenu} showMenu={show}>
                    <button type="button" onClick={removeMessage}>
                        Remove
                    </button>
                    <button type="button" onClick={replyMessage}>
                        Reply
                    </button>
                </InstOptionV2>
            </div>
        </div>
    );
}

export default OverviewSecReceiverMenu;
