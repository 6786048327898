import { createContext, useContext, useMemo, useState } from 'react';

// Context creation
const BmConfigBranchContext = createContext();

// Custom hook to use the context
export const useBranch = () => useContext(BmConfigBranchContext);

// BmConfigBranchProvider component definition
function BmConfigBranchProvider({ children }) {
    const [branchInfo, setBranchInfo] = useState(null);
    const [reloadKey, setReloadKey] = useState(Math.random());
    const [modal, setModal] = useState({
        type: '',
        data: null
    });

    // Create a memoized value object that holds various functions
    const value = useMemo(() => {
        // Function to trigger reloading data
        const handleReload = () => {
            setReloadKey((d) => d + 1);
            setBranchInfo(null);
        };

        const handleOpenModal = (type, data) => {
            setModal({
                type,
                data
            });
        };

        const handleCloseModal = () => {
            setModal({
                type: '',
                data: null
            });
        };

        // Return an object with all the values and functions
        return {
            branchInfo,
            setBranchInfo,
            reloadKey,
            handleReload,
            modalType: modal.type,
            modalData: modal.data,
            handleOpenModal,
            handleCloseModal
        };
    }, [branchInfo, modal.data, modal.type, reloadKey]);

    // Provide the value through the context
    return (
        <BmConfigBranchContext.Provider value={value}>{children}</BmConfigBranchContext.Provider>
    );
}

export default BmConfigBranchProvider;
