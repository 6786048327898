import { useState } from 'react';
import { ButtonGray, ButtonGreen } from '../../../../configs/Buttons';
import LibModal from '../../../lib/LibModal';
import { templateIcons } from '../../template/TemplateIcon';
import { useRole } from '../RoleManageProvider';
import { handleChangeOwner } from '../RoleOperation';

function RoleMakeOwnerWarning({ projectId, data, authInfo, setMessage, handleCloseModal }) {
    const [formData, setFormData] = useState({
        checkBox1: false,
        checkBox2: false
    });

    const { handleReload } = useRole();

    const handleAgreement1 = () => {
        setFormData((cuData) => ({
            ...cuData,
            checkBox1: !formData?.checkBox1
        }));
    };
    const handleAgreement2 = () => {
        setFormData((cuData) => ({
            ...cuData,
            checkBox2: !formData?.checkBox2
        }));
    };

    const handleMakeOwner = () => {
        handleChangeOwner(authInfo, projectId, data?.roleId)
            .then(() => {
                handleCloseModal();
                handleReload();
            })
            .catch((err) => {
                setMessage(err.toString(0));
            });
    };

    return (
        <LibModal header="none">
            <div className="role-owner-warning">
                <div className="flex-row align-center position-middle">
                    <img src={templateIcons.tConfirm} alt="" />
                </div>

                <div>
                    <p>Are you sure you want to transfer the ownership of the project?</p>
                    <div
                        role="button"
                        tabIndex={-1}
                        onClick={handleAgreement1}
                        className="flex-row"
                    >
                        <input
                            onClick={handleAgreement1}
                            onChange={handleAgreement1}
                            checked={formData?.checkBox1}
                            type="checkbox"
                            name=""
                        />
                        <p>The new owner will have full control over this project!</p>
                    </div>

                    <div
                        role="button"
                        tabIndex={-1}
                        onClick={handleAgreement2}
                        className="flex-row"
                    >
                        <input
                            onClick={handleAgreement2}
                            onChange={handleAgreement2}
                            checked={formData?.checkBox2}
                            type="checkbox"
                            name=""
                        />
                        <p>
                            Once the transfer is complete, the previous owner will no longer have
                            access!
                        </p>
                    </div>
                </div>

                <div className="flex-row align-center position-middle">
                    <ButtonGray evOnClick={handleCloseModal} title="No" />
                    <ButtonGreen
                        evOnClick={handleMakeOwner}
                        disabled={!formData?.checkBox1 || !formData?.checkBox2}
                        title="Yes"
                    />
                </div>
            </div>
        </LibModal>
    );
}

export default RoleMakeOwnerWarning;
