import { icons } from '../../../../../configs/Icons';

function BmConversationPageHeader() {
    const handleReload = () => {
        console.log('reload');
    };

    return (
        <div className="page-header-cont flex-row position-middle align-space-between">
            <div>
                <h2 className="page-header_title">Conversation</h2>
            </div>

            <div className="reload-button">
                <button type="button" className="p-relative" onClick={handleReload}>
                    <img src={icons.reloadLine} alt="" />
                    <img src={icons.reloadFill} alt="" />
                </button>
            </div>
        </div>
    );
}

export default BmConversationPageHeader;
